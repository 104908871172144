import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { generateUrl } from '../../../routes/util';
import { Menu } from '../../menu/Menu';

export enum BenchmarkingTabCodes {
  LatestSurveyProgress = 'latest-survey-progress',
  PackUsage = 'pack-usage',
  SubmissionInsights = 'submission-insights',
}

const BENCHMARKING_TABS: { code: string; label: string }[] = [
  {
    code: BenchmarkingTabCodes.LatestSurveyProgress,
    label: 'Latest surveys progress',
  },
  {
    code: BenchmarkingTabCodes.PackUsage,
    label: 'Pack usage',
  },
  {
    code: BenchmarkingTabCodes.SubmissionInsights,
    label: 'Submission insights',
  },
];

export const BenchmarkingNavigation = () => {
  const history = useHistory();
  const { portfolioId, page } = useParams<{ portfolioId: string; page: string }>();

  const onClick = (code: string) => history.push(generateUrl(ROUTES.PORTFOLIO_TRACKER_BENCHMARKING, { portfolioId, page: code }));
  const isActive = (code: string) => code === page;
  return (
    <Menu
      items={
        BENCHMARKING_TABS.map((tab) => ({
          active: isActive(tab.code),
          onClick: () => onClick(tab.code),
          label: tab.label
        }))
      }
    />
  );
};
