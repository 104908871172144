import { useDispatch } from 'react-redux';
import { loadUniversalTrackerModal } from '../../../actions/universalTrackerModal';
import { UniversalTrackerPlain } from '../../../types/universalTracker';
import IconButton from '../../button/IconButton';
import SimpleTooltip from '../../simple-tooltip';

interface MenuButtonProps {
  universalTracker: UniversalTrackerPlain;
  handleOpenEditUtr: (utrId: string) => void;
  handleOpenDeleteUtr: (utrId: string) => void;
  isDisabled: boolean;
}

export const CTAButtons = ({
  universalTracker,
  handleOpenEditUtr,
  handleOpenDeleteUtr,
  isDisabled,
}: MenuButtonProps) => {
  const dispatch = useDispatch();

  const utrId = universalTracker._id;
  const deleteButtonTooltip = isDisabled
    ? 'Custom metrics that have been added to surveys cannot be deleted'
    : 'Delete metric';

  return (
    <div className='d-flex align-items-center gap-2 pr-3'>
      <SimpleTooltip text='Edit details'>
        <IconButton
          icon='fal fa-pen-to-square'
          color='transparent'
          size='xs'
          className='icon-button--size-xs'
          outline={false}
          onClick={() => handleOpenEditUtr(utrId)}
        />
      </SimpleTooltip>
      <SimpleTooltip text='Open data history'>
        <IconButton
          icon='fal fa-clock-rotate-left'
          color='transparent'
          size='xs'
          className='icon-button--size-xs'
          outline={false}
          onClick={() => dispatch(loadUniversalTrackerModal({ universalTrackerId: utrId }))}
        />
      </SimpleTooltip>
      <SimpleTooltip text={deleteButtonTooltip}>
        <IconButton
          icon='fal fa-trash-can'
          color='danger'
          className='icon-button--size-xs'
          size='xs'
          onClick={() => handleOpenDeleteUtr(utrId)}
          disabled={isDisabled}
          outline={!isDisabled}
        />
      </SimpleTooltip>
    </div>
  );
};
