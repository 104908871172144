import { QUESTION } from '../../../constants/terminology';
import { StatusOption } from '../types';

const getOption = (label: string, icon: string, colour: string) => {
  return (
    <div className='w-100'>
      {icon ? <i style={{ width: '18px' }} className={`fa ${icon} ${colour} mr-2`} /> : null}
      {label}
    </div>
  );
}


export const getFilterOptions: StatusOption[] = [
  {
    value: 'all',
    label: getOption(`All ${QUESTION.SINGULAR} statuses`, 'fa-users-line', 'text-primary'),
  },
  {
    value: 'created',
    label: getOption(`Unanswered ${QUESTION.PLURAL}`, 'fa-user-clock', 'text-muted'),
  },
  {
    value: 'updated',
    label: getOption(`Updated ${QUESTION.PLURAL}`, 'fa-user-edit', 'text-primary'),
  },
  {
    value: 'rejected',
    label: getOption(`Rejected ${QUESTION.PLURAL}`, 'fa-user-times', 'text-danger'),
  },
  {
    value: 'verified',
    label: getOption(`Verified ${QUESTION.PLURAL}`, 'fa-user-check', 'text-success'),
  },
];
