import { useEffect, useRef, useState } from 'react';
import {
  HierarchyNode,
  InitiativeDatum,
  SelectionSVG,
  SelectionTreeNode,
  SelectionTreeNodePath,
  StepPathNode,
  TreeNode,
} from '../types';
import * as d3 from 'd3';
import { FlextreeLayout, FlextreeNode, flextree } from 'd3-flextree';
import {
  ANIMATION_EXPAND_DEPTH,
  BUTTON_WIDTH,
  DEFAULT_EXPAND_DEPTH,
  DURATION,
  HEIGHT_CENTRE_OFFSET,
  MAX_LENGTH,
  NODE_HEIGHT,
  SCALE_EXTENT,
} from '../constants';
import {
  drawConnectingLineToExpandButton,
  drawStepPath,
  getCTLightPreviewOrgMap,
  getExpandButtonDistance,
  getLevelColor,
  getNodeHtmlWidth,
  getNodeSize,
} from '../utils';
import { ChildrenTree, Tree } from '../../../types/tree';
import { findCurrentBranchInTree } from '../../../utils/initiative';

export interface BaseOrgMapProps {
  initiativeTree: Tree;
  onMapClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  actionButtons?: {
    html: string;
    onClick: (svg: SelectionSVG) => (event: React.MouseEvent<HTMLElement, MouseEvent>, d: TreeNode) => void;
  };
  currentId?: string;
  getTreeFilterRoot: () => ChildrenTree;
  runOrgMapAnimation?: (nodeEnter: SelectionTreeNode, linkEnter: SelectionTreeNodePath) => void;
  customStyles?: string;
}

interface BaseState {
  zoomInDisabled: boolean;
  zoomOutDisabled: boolean;
  expandedNodes: string[];
  handleReset: () => void;
  handleZoom: (amount: number) => void;
}

const defaultBaseState: BaseState = {
  zoomInDisabled: false,
  zoomOutDisabled: false,
  expandedNodes: [],
  handleReset:() => {},
  handleZoom: (amount: number) => {},
};

export const useBaseOrgMap = (props: BaseOrgMapProps) => {
  const {
    initiativeTree,
    onMapClick,
    actionButtons,
    currentId,
    getTreeFilterRoot,
    runOrgMapAnimation,
    customStyles,
  } = props;
  const [baseState, setBaseState] = useState<BaseState>(defaultBaseState);
  const setState = (newState: Partial<BaseState>) => {
    setBaseState((prev) => ({ ...prev, ...newState }));
  };
  const mindMapRef = useRef<HTMLDivElement>(null);
  const initialScale = runOrgMapAnimation ? 0.8 : 1;

  const collapse = (expandedIds?: string[]) => (d: TreeNode, depth = 0) => {

    const expandItems = expandedIds ?? baseState.expandedNodes;
    if (d.children) {
      depth++;
      d._children = d.children;
      d.children.forEach((e) => collapse(expandedIds)(e, depth));
      if (depth > (runOrgMapAnimation ? ANIMATION_EXPAND_DEPTH : DEFAULT_EXPAND_DEPTH)) {
        if (!expandItems.includes(d.data.id)) {
          d.children = undefined;
        }
      }
    }
  };

  const getInitiativeStructure = (expandedIds?: string[]) => {

    const initiativeStructure = mindMapRef.current;
    if (initiativeTree.children.length === 0 || !initiativeStructure) {
      return;
    }

    // ************** Generate the tree diagram	 *****************
    const width = initiativeStructure.offsetWidth;
    const height = initiativeStructure.offsetHeight;

    //zoom
    const defaultTranslateX = runOrgMapAnimation ? width / 4 : 100;

    // init tree data
    const treeFilteredRoot = getTreeFilterRoot();
    const treeData = runOrgMapAnimation ? getCTLightPreviewOrgMap(treeFilteredRoot) : treeFilteredRoot;
    const root: InitiativeDatum = { ...treeData, x0: height / 2, y0: 0 };
    const hierarchyNode: HierarchyNode = d3.hierarchy(root, (d: InitiativeDatum) => d.children);
    hierarchyNode.x0 = height / 2;
    hierarchyNode.y0 = 0;

    // create a hierarchy from the root
    // flextree allows for more compact tree since nodes have variable sizes
    const treemap = flextree<InitiativeDatum>({}).nodeSize(getNodeSize);

    // treeRoot will always have x0, y0 and _children as initialized above
    const treeRoot = treemap(hierarchyNode) as TreeNode;

    const actualSvg = d3
      .select<HTMLDivElement, InitiativeDatum>(initiativeStructure)
      .append('svg')
      .attr('width', width)
      .attr('height', height);
    const svg = actualSvg.append<SVGElement>('g');

    const zoomed = (event: d3.D3ZoomEvent<SVGSVGElement, InitiativeDatum>, d: InitiativeDatum) => {
      const currentEvent = event.transform;
      svg.attr('transform', currentEvent.toString());

      const currentScale = currentEvent.k;
      const [min, max] = SCALE_EXTENT;
      // limit translation to thresholds
      if (currentScale <= min) {
        setState({ zoomOutDisabled: true });
      } else if (currentScale >= max) {
        setState({ zoomInDisabled: true });
      } else {
        setState({ zoomInDisabled: false, zoomOutDisabled: false });
      }
    };

    const zoom = d3.zoom<SVGSVGElement, InitiativeDatum>().scaleExtent(SCALE_EXTENT).on('zoom', zoomed);

    actualSvg.call(zoom.transform, d3.zoomIdentity.translate(defaultTranslateX, height / 2).scale(initialScale));

    actualSvg.call(zoom);

    if (treeRoot.children) {
      treeRoot.children.forEach(collapse(expandedIds));
    }

    setState({
      handleReset: () => {
        actualSvg
          .transition()
          .duration(750)
          .call(zoom.transform, d3.zoomIdentity.translate(defaultTranslateX, height / 2).scale(initialScale));
      },
      handleZoom: (amount: number) => {
        actualSvg.transition().call(zoom.scaleBy, amount);
      },
    });

    update({ source: treeRoot, treeRoot, treemap, svg });
  };

  useEffect(() => {
    // remove the tree and rerender with updated data
    d3.select('.mindmap').html('');

    getInitiativeStructure();
    // equivalent to the old logic of componentDidUpdate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiativeTree.children]);

  useEffect(() => {
    if (initiativeTree.loaded) {
      d3.select('.mindmap').html('');
      const treeData = getTreeFilterRoot();
      const currentBranch = currentId ? findCurrentBranchInTree(treeData, currentId).branch : [];
      const expandedIds = currentBranch.filter((node) => node.id !== currentId).map((node) => node.id);
      setState({ expandedNodes: expandedIds });
      getInitiativeStructure(expandedIds);
    }
    // equivalent to the old logic of componentDidMount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToExpandedNodes = (node: string) => {
    setState({ expandedNodes: [...baseState.expandedNodes, node] });
  };

  const removeFromExpandedNodes = (node: string) => {
    setState({ expandedNodes: baseState.expandedNodes.filter((n) => n !== node) });
  };

  const drawExpandButton = ({
    nodeEnter,
    treeRoot,
    treemap,
    svg,
  }: {
    nodeEnter: SelectionTreeNode;
    treeRoot: TreeNode;
    treemap: FlextreeLayout<InitiativeDatum>;
    svg: SelectionSVG;
  }) => {
    nodeEnter
      .append('svg:foreignObject')
      .attr('width', BUTTON_WIDTH)
      .attr('height', BUTTON_WIDTH)
      .attr('class', 'expand-container')
      .attr('transform', (d) => {
        return `translate(${getExpandButtonDistance(d)}, 10)`;
      })
      .html((d) => {
        if (d.children && d.children.length) {
          const expandButton = '<button class=\'expand-button\'><i class=\'fal fa-minus\'></i></button>';
          return `<div class='wrapper'>${expandButton}</div>`;
        }
        if (d._children && d._children.length) {
          const expandButton = '<button class=\'expand-button\'><i class=\'fal fa-plus\'></i></button>';
          return `<div class='wrapper'>${expandButton}</div>`;
        }
        return null;
      })
      .on('click', function onExpandButtonClick(event: d3.ClientPointEvent, d: TreeNode) {
        if (!this || !('parentElement' in this) || !this.parentElement) {
          return;
        }

        const parentSelect = d3.select<HTMLElement, TreeNode>(this.parentElement);
        const button = parentSelect.select('.expand-button');
        const line = parentSelect.select('.expand-line');
        line.style('opacity', '25%');
        line.style('animation', 'none');
        if (d.children) {
          d._children = d.children;
          d.children = undefined;
          button.html('<i class=\'fal fa-plus\'></i>');
          line.style('display', 'inline-block');
          removeFromExpandedNodes(d.data.id);
        } else {
          button.html('<i class=\'fal fa-minus\'></i>');
          d.children = d._children;
          d._children = undefined;
          line.style('display', 'none');
          addToExpandedNodes(d.data.id);
        }

        // Update current node container
        parentSelect
          .select('.expand-container')
          .attr('transform', (d) => `translate(${getExpandButtonDistance(d)}, 10)`);
        line.attr('width', (d) => {
          return getExpandButtonDistance(d);
        });
        update({ source: d, treeRoot, treemap, svg });
      });
  };

  const update = ({
    source,
    treeRoot,
    treemap,
    svg,
  }: {
    source: TreeNode;
    treeRoot: TreeNode;
    treemap: FlextreeLayout<InitiativeDatum>;
    svg: SelectionSVG;
  }) => {
    // Assigns the x and y position for the nodes
    const treeData = treemap(treeRoot) as TreeNode;
    // Compute the new tree layout.
    const nodes: TreeNode[] = treeData.descendants();
    const links: TreeNode[] = treeData.descendants().slice(1);
    // Update the nodes…
    const node = svg.selectAll<SVGGElement, FlextreeNode<InitiativeDatum>>('g.node').data(nodes, (d) => d.data.id);

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = node
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', `translate(${source.y0},${source.x0})`);

    // Add a connecting line between node & expand button
    drawConnectingLineToExpandButton(nodeEnter);

    // Create an expand/collapse button
    drawExpandButton({ nodeEnter, treeRoot, treemap, svg });

    nodeEnter
      .append('svg:foreignObject')
      .attr('height', NODE_HEIGHT)
      .attr('width', getNodeHtmlWidth)
      .attr('class', function (d) {
        return 'shadow-lg mindmap-text-wrap rounded-1';
      })
      .attr('style', function (d) {
        return customStyles ? customStyles : getLevelColor(d.depth);
      })
      .html(function (d) {
        const name = d.data.name;
        const ellipsisName = name.length > MAX_LENGTH ? name.substring(0, MAX_LENGTH - 3) + '...' : name;
        const mainClass = !d.data.disabled
          ? 'mindmap-text text-ThemeTextMedium'
          : 'text-ThemeTextPlaceholder mindmap-disabled-text';
        return `<div class="${mainClass} name d-flex align-items-center justify-content-between h-100 gap-1">
      <div class='flex-grow-1 h-100'>
      <div class='d-flex w-100 h-100 align-items-center text-md' id='initiativename'>${ellipsisName}</div>
      </div>
      ${!d.data.id || d.data.disabled || !actionButtons ? '' : `<div class="d-flex flex-column">${actionButtons.html}</div>`}
      </div>`;
      })
      .on('click', actionButtons?.onClick(svg) ?? function () {})
      .append('title')
      .text((d) => d.data.name);

    // UPDATE
    const nodeUpdate = nodeEnter.merge(node);

    // Transition to the proper position for the node
    nodeUpdate
      .transition()
      .duration(DURATION)
      .attr('transform', function (d) {
        return `translate(${d.y}, ${d.x - HEIGHT_CENTRE_OFFSET})`;
      });

    // Transition exiting nodes to the parent's new position.
    const nodeExit = node
      .exit()
      .transition()
      .duration(DURATION)
      .attr('transform', (d) => `translate(${source.y},${source.x})`)
      .remove();

    // On exit reduce the opacity of text labels
    nodeExit.selectAll('text').style('fill-opacity', 0);

    // ****************** links section ***************************

    // Update the links...
    const link = svg.selectAll<SVGPathElement, TreeNode>('path.link').data(links, (d) => d.data.id);

    // Enter any new links at the parent's previous position.
    const time = Date.now();
    const linkEnter = link
      .enter()
      .insert('path', 'g')
      .attr('class', 'link ' + time)
      .attr('d', () => {
        const o = { x: source.x0, y: source.y0, data: source.data };
        return drawStepPath(o, o);
      });

    runOrgMapAnimation?.(nodeEnter, linkEnter);

    // UPDATE
    const linkUpdate = linkEnter.merge(link);

    // Transition back to the parent element position
    // Parent will always be defined because non-root link will always have a parent
    linkUpdate
      .transition()
      .duration(DURATION)
      .attr('d', (d) => drawStepPath(d, d.parent as TreeNode));

    // Remove any exiting links
    link
      .exit()
      .transition()
      .duration(DURATION)
      .attr('d', () => {
        const o: StepPathNode = { x: source.x, y: source.y, data: source.data };
        return drawStepPath(o, o);
      })
      .remove();

    // Store the old positions for transition.
    nodes.forEach(function (d) {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  };

  return {
    component: <div ref={mindMapRef} className='mindmap' onClick={onMapClick}></div>,
    handleReset: baseState.handleReset,
    handleZoom: baseState.handleZoom,
    zoomInDisabled: baseState.zoomInDisabled,
    zoomOutDisabled: baseState.zoomOutDisabled,
    addToExpandedNodes: addToExpandedNodes,
  }
}
