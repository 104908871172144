export enum TemplateMessage {
  Created = 'Survey template successfully created.',
  Updated = 'Survey template successfully updated.',
  AggregatedCreated = 'Aggregated survey template successfully created.',
  AggregatedUpdated = 'Aggregated survey template successfully updated.',
}

export enum Actions {
  Delete = 'delete',
  Duplicate = 'duplicate',
}

export enum NavCode {
  Configuration = 'configuration',
  Scope = 'scope',
}

export enum MergeTags {
  Subsidiary = '{{subsidiary}}',
  SurveyDuration = '{{duration}}',
  Month = '{{month}}',
  Year = '{{year}}',
}
