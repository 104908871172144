import { Button } from 'reactstrap';
import { useToggle } from '../../../hooks/useToggle';
import { SharingModal } from './SharingModal';
import { InsightDashboard } from '../../../types/insight-custom-dashboard';

type Props = {
  dashboard: InsightDashboard;
};

export const SharingButton = ({ dashboard }: Props) => {
  const [isOpen, toggle] = useToggle();

  return (
    <>
      <Button color='transparent' outline onClick={toggle} className='ml-3'>
        Share <i className='fa-solid fa-share'></i>
      </Button>
      <SharingModal isOpen={isOpen} toggle={toggle} dashboard={dashboard} />
    </>
  );
};
