import { Input, Label } from 'reactstrap';
import { BasicAlert } from '../alert/BasicAlert';
import { DownloadSettingPropType } from './DownloadSettingModal';
import { InfoIconWithTooltip } from './DownloadSettingOptions';
import { SelectedGroup } from './util/downloadReportHandler';

interface ScopeSelectProps extends Pick<DownloadSettingPropType, 'titleOptions' | 'handleChange' | 'requiredScope'> {
  hidden: boolean;
  isEmptyScope: boolean;
  selectedGroups: SelectedGroup[];
}

const defaultScopesTooltip =
  'Selected survey packs will be included within the report. By default, SGX Core is always included in the sustainability report';

export const ScopeSelect = (props: ScopeSelectProps) => {
  const { hidden, requiredScope, isEmptyScope, selectedGroups, titleOptions, handleChange } = props;
  if (hidden || selectedGroups.length === 0) {
    return null;
  }

  return (
    <>
      <div className='d-flex align-items-baseline'>
        <h5>{titleOptions?.scopes?.title ?? 'Included survey packs:'}</h5>
        <InfoIconWithTooltip text={titleOptions?.scopes?.tooltip ?? defaultScopesTooltip} />
      </div>
      <div className='d-flex flex-column mb-2 pl-1 overflow-auto position-relative scope-select__container'>
        <BasicAlert hide={!isEmptyScope} type={'warning'}>
          Please select at least one option
        </BasicAlert>
        {selectedGroups.map((item, index) => (
          <Label check className='mb-1' key={item.code}>
            <Input
              type='checkbox'
              name={item.code}
              className='mr-2'
              onChange={handleChange}
              checked={item.checked}
              disabled={item.code === requiredScope}
            />
            {item.name}
          </Label>
        ))}
      </div>
    </>
  );
};
