import { Button } from 'reactstrap';
import { DashboardRow, DashboardSection } from '../../components/dashboard';
import { InsightDashboard } from '../../types/insight-custom-dashboard';
import { FiltersEditing } from './FiltersEditing';
import { GridLayoutDashboard } from './GridLayoutDashboard';
import { GridItemProps } from './GridItem';
import { SharingButton } from './shared-dashboard/SharingButton';
import { SdgChart } from '../../components/impact-performance/SdgChart';
import { SafeInitiativeFields } from '../../types/initiative';
import { CompanyProfile } from '../../components/company-profile';
import { CustomDashboardInfoIcon } from './CustomDashboardInfoIcon';
import { TOOLTIP_MESSAGE } from '../summary/insights/utils/sidebar';
import { DataPeriods } from '../../types/universalTracker';
import { useContext } from 'react';
import { CustomDashboardContext } from './context/CustomDashboardWrapper';
import { SurveyTypeDropdown, SurveyTypeDropdownProps } from '@components/survey-type-dropdown/SurveyTypeDropdown';
import { PeriodDropdownProps, SurveyPeriodDropdown } from '@components/survey-period-dropdown';

type Props = Pick<GridItemProps, 'handleOpenUtrvHistoryModal' | 'survey'> &
  Partial<Pick<SurveyTypeDropdownProps, 'surveyType' | 'setSurveyType'>> &
  Pick<PeriodDropdownProps, 'period' | 'setPeriod'> & {
    dashboard: InsightDashboard;
    handleClickEdit: () => void;
    handleSave: (dashboard: Partial<InsightDashboard>) => void;
    canManage?: boolean;
    initiative: SafeInitiativeFields;
    isCurrentLevelDashboard: boolean;
    availablePeriods: DataPeriods[];
  };

export const CustomDashboard = (props: Props) => {
  const {
    handleSave,
    dashboard,
    handleClickEdit,
    canManage,
    handleOpenUtrvHistoryModal,
    initiative,
    isCurrentLevelDashboard,
    survey,
    period,
    availablePeriods,
    setPeriod,
    surveyType,
    setSurveyType,
  } = props;
  const initiativeId = initiative._id;
  const { hideFilterOptions, hideShareButton, hideQuestionReference, readOnly } = useContext(CustomDashboardContext);

  return (
    <>
      <DashboardRow>
        <div className='d-flex w-100 justify-content-end align-items-start'>
          <div className='d-flex'>
            {canManage && isCurrentLevelDashboard ? (
              <>
                <FiltersEditing handleSave={handleSave} dashboard={dashboard} hideOptions={hideFilterOptions} />
                <Button color='transparent' outline onClick={handleClickEdit} className='ml-3'>
                  Edit
                </Button>
                {hideShareButton ? null : <SharingButton dashboard={dashboard} />}
              </>
            ) : null}
            <>
              {surveyType ? <SurveyTypeDropdown surveyType={surveyType} setSurveyType={setSurveyType} /> : null}
              <SurveyPeriodDropdown
                key={availablePeriods.length}
                className='ml-3'
                period={period}
                availablePeriods={availablePeriods}
                setPeriod={setPeriod}
              />
            </>
          </div>
        </div>
      </DashboardRow>
      {dashboard.filters.initiativeInfo?.enabled ? <CompanyProfile initiative={initiative} readOnly={false} /> : null}
      <DashboardRow>
        <div className='d-flex align-items-center'>
          <h3 className='text-ThemeHeadingLight'>{dashboard.title}</h3>
          <CustomDashboardInfoIcon text={!isCurrentLevelDashboard ? TOOLTIP_MESSAGE.IS_SHARED_BY_PARENT : ''} />
        </div>
      </DashboardRow>
      {dashboard.filters.sdgContribution?.enabled && dashboard.scorecard && initiativeId ? (
        <DashboardSection>
          <SdgChart initiativeId={initiativeId} scorecard={dashboard.scorecard} />
        </DashboardSection>
      ) : null}
      <GridLayoutDashboard
        isEditing={false}
        gridItems={dashboard.items}
        utrsData={dashboard.utrsData}
        handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
        readOnly={readOnly || !dashboard.filters.baselinesTargets?.enabled || !canManage}
        initiativeId={initiativeId}
        survey={survey}
        hideQuestionReference={hideQuestionReference}
      />
    </>
  );
};
