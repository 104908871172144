import { useState } from 'react';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { RequestScope } from '../../../../actions/api';
import { TIME_RANGE_OPTIONS } from '../../../../utils/date';
import { useIndustrySector } from '../../../industry-sector-selector/useIndustrySector';
import { PeriodDropdown } from '../../../period-dropdown';
import { DateRangeType } from '../../../period-dropdown/DateRangePicker';
import { SelectOption } from '../../exchange-view/exchange-filters';
import { FilterSurveyPacks } from './FilterSurveyPacks';
import { getStyles } from '../../../select-packs';

export interface Filters {
  sector?: string;
  industry?: string | null;
  scopeGroups?: RequestScope[];
}

interface BenchmarkingFiltersProps {
  filters: Filters;
  dateRange: DateRangeType;
  changeFilters: (filters: Filters) => void;
  changeDateRange: (dateRange: DateRangeType) => void;
  downloadPackUsage: () => void;
}

export const BenchmarkingFilters = (props: BenchmarkingFiltersProps) => {
  const { filters, dateRange, changeFilters, changeDateRange, downloadPackUsage } = props;
  const [period, setPeriod] = useState(TIME_RANGE_OPTIONS[0].code);
  const { sectorOptions, industryOptions } = useIndustrySector(filters.sector);

  const handleChangeFilters = (name: string, scopeGroups: RequestScope[]) => {
    const newFilters = { ...filters, [name]: scopeGroups };
    changeFilters(newFilters);
  };

  const handleChangeSectorIndustryFilters = (name: string, option: SelectOption) => {
    let newFilters = { ...filters };
    if (name === 'sector') {
      // reset industry if sector change
      newFilters = { ...newFilters, industry: null };
    }
    newFilters = { ...newFilters, [name]: option.value ? option.label : null };

    changeFilters(newFilters);
  };

  return (
    <div className='d-flex justify-content-between align-items-center gap-3'>
      <Select
        placeholder='Sector'
        styles={getStyles()}
        options={sectorOptions}
        value={sectorOptions.find(item => item.label === filters.sector) ?? null}
        onChange={(option) => handleChangeSectorIndustryFilters('sector', option)}
      />
      <Select
        placeholder='Industry'
        styles={getStyles()}
        options={industryOptions}
        value={industryOptions.find(item => item.label === filters.industry) ?? null}
        onChange={(option) => handleChangeSectorIndustryFilters('industry', option)}
        isDisabled={!filters.sector}
      />
      <FilterSurveyPacks selectedScopes={filters.scopeGroups?.map(scope => scope.code)} handleSurveyPackFilter={(option: RequestScope[]) => handleChangeFilters('scopeGroups', option)} />
      <PeriodDropdown
        period={period}
        onChangePeriod={(value) => setPeriod(value)}
        dateRange={dateRange}
        onChangeDateRange={(range) => changeDateRange(range)}
        timeRangeOptions={TIME_RANGE_OPTIONS}
      />
      <Button color='link' className='ml-2'>
        <i className='fal fa-file-excel' onClick={downloadPackUsage}></i>
      </Button>
    </div>
  );
};
