import { useEffect, useMemo, useState } from 'react';
import { InitiativeQuestionManagementResponse, useGetInitiativeQuestionsQuery } from '../../../../api/admin-dashboard';
import LoadingPlaceholder from '../../../../components/LoaderContainer/LoadingPlaceholder';
import { CheckboxState } from '../../../../components/common/Checkbox';
import { ActionsToolbar } from '../../../../components/survey-question-list/partials/ActionsToolbar';
import { BulkActionUtr } from '../../../../components/survey-question-list/partials/BulkActionToolbar';
import { BulkActionProps } from '../../../../components/survey-question-list/survey-question-list';
import './QuestionManagement.scss';
import { getNumericSelectedQuestions } from '../../../../components/survey-question-list/utils';
import { TagButton } from '../../../../components/tags/TagButton';
import { DecimalButton } from '../../../../components/utr-decimal/DecimalButton';
import { QuestionFilter, QuestionFilters } from './QuestionFilter';
import { QuestionTable } from './QuestionTable';
import { useAppSelector } from '../../../../reducers';
import { getRootOrg, isRootOrg } from '../../../../selectors/initiative';
import { BasicAlert } from '../../../../components/alert/BasicAlert';
import { useSearchDebounceWithFlexMap } from '../../../../hooks/useSearchDebounceWithFlexMap';
import { useGetCustomTagsQuery } from '../../../../api/metric-groups';
import { getFilteredUtrIdsByTag, isMatchSelectedPacks } from '../../util';
import Loader from '../../../../components/loader';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { QUESTION } from '../../../../constants/terminology';

export const NO_QUESTIONS_MESSAGE = {
  FILTER: `No ${QUESTION.PLURAL} match your criteria`,
  IN_SCOPE: `No ${QUESTION.PLURAL} are used in scope`
}

export enum ViewType {
  InScope = 'in-scope',
  Blueprint = 'blueprint',
}

const initialState = {
  packs: [],
  tags: [],
};

interface QuestionManagementProps {
  pageSize?: number;
  showFilters?: boolean;
  initiativeId: string;
}

export const QuestionManagement = ({ pageSize, showFilters, initiativeId }: QuestionManagementProps) => {
  const isRootOrganization = useAppSelector(isRootOrg);
  const rootOrg = useAppSelector(getRootOrg);
  const [viewType, setViewType] = useState<ViewType>(ViewType.InScope);
  const [filters, setFilters] = useState<QuestionFilters>(initialState);
  const [selectedQuestions, setSelectedQuestions] = useState<BulkActionUtr[]>([]);
  const [decimalModal, setDecimalModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);

  const {
    data = {} as InitiativeQuestionManagementResponse,
    isLoading,
    isSuccess,
    refetch,
    error: loadUtrError,
  } = useGetInitiativeQuestionsQuery({ initiativeId, viewType }, { skip: !initiativeId });

  const { data: tags = [], error: loadTagError } = useGetCustomTagsQuery(rootOrg?._id ?? skipToken);

  const utrIds = useMemo(() => {
    return (data.utrs || []).map((utr) => utr._id);
  }, [data.utrs]);

  const { onSearch, matchedQuestions } = useSearchDebounceWithFlexMap(data.utrs);

  const hasPackFilter = filters.packs.length > 0;

  const hasTagFilter = filters.tags.length > 0;

  const filteredQuestions = useMemo(() => {
    if (hasTagFilter || hasPackFilter) {
      const filteredUtrIdsByTag = getFilteredUtrIdsByTag({ utrIds, tags, values: filters.tags });
      return matchedQuestions.filter((utr) => {
        if (hasTagFilter && !filteredUtrIdsByTag.includes(utr._id)) {
          return false;
        }
        return isMatchSelectedPacks(utr, filters.packs);
      });
    }

    return matchedQuestions;
  }, [matchedQuestions, utrIds, tags, filters.packs, filters.tags, hasTagFilter, hasPackFilter]);

  useEffect(() => {
    if (selectedQuestions.length === 0) {
      return;
    }
    const filteredUtrIds = filteredQuestions.map((question) => question._id);
    setSelectedQuestions((prev) => prev.filter((question) => filteredUtrIds.includes(question._id)));
  }, [filteredQuestions, selectedQuestions.length]);

  if (!rootOrg) {
    return <Loader />;
  }

  const numericSelectedQuestions = getNumericSelectedQuestions(selectedQuestions);

  const isSelectAll = selectedQuestions.length > 0 && selectedQuestions.length === filteredQuestions?.length;

  const handleUnselectAll = () => setSelectedQuestions([]);

  const toggleSelectAll = () => (isSelectAll ? handleUnselectAll() : setSelectedQuestions(filteredQuestions));

  const toggleSelectQuestion = (utr: BulkActionUtr, status: CheckboxState) => {
    const newSelectedQuestions =
      status === CheckboxState.Checked
        ? selectedQuestions.filter((q) => q._id !== utr._id)
        : [...selectedQuestions, utr];

    return setSelectedQuestions(newSelectedQuestions);
  };

  const handleQuestionFilter = ({ name, value }: { name: string; value: string[] | boolean }) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleReload = async ({ reloadSurvey = false }: { reloadSurvey?: boolean } = {}) => {
    setTagModal(false);
    setDecimalModal(false);
    if (reloadSurvey) {
      await refetch();
    }
  };

  const renderActionToolbar = (
    props: Omit<BulkActionProps, 'surveyId' | 'triggerBulkAction' | 'selectedQuestions'> & {
      selectedQuestions: BulkActionUtr[];
    }
  ) => {
    const { selectedQuestions } = props;
    const listButtons = isRootOrganization
      ? [
          {
            name: 'tags',
            Component: TagButton,
            buttonProps: {
              selectedQuestions: tagModal ? selectedQuestions : [],
              onClick: () => setTagModal(true),
              onToggle: () => setTagModal(false),
              handleReload,
            },
          },
          {
            name: 'decimal',
            Component: DecimalButton,
            buttonProps: {
              selectedQuestions: decimalModal ? selectedQuestions : [],
              numericSelectedQuestions,
              initiativeId,
              isHidden: false,
              handleClick: () => setDecimalModal(true),
              handleClose: handleReload,
            },
          },
        ]
      : [];
    return <ActionsToolbar {...props} buttons={listButtons} />;
  };

  const renderNoQuestionsAlert = () => {
    if (hasPackFilter || hasTagFilter) {
      return <BasicAlert type='secondary'>{NO_QUESTIONS_MESSAGE.FILTER}</BasicAlert>
    }
    if(viewType === ViewType.InScope) {
      return <BasicAlert type='secondary'>{NO_QUESTIONS_MESSAGE.IN_SCOPE}</BasicAlert>
    }
    return null;
  }

  return (
    <>
      <QuestionFilter
        viewType={viewType}
        toggleViewType={(viewType: ViewType) => setViewType(viewType)}
        showFilters={showFilters}
        filters={filters}
        handleQuestionFilter={handleQuestionFilter}
        onSearch={onSearch}
        rootInitiativeId={rootOrg._id}
      />
      {[loadUtrError, loadTagError].map((error) =>
        error ? <BasicAlert type='danger'>{error.message}</BasicAlert> : null
      )}
      <LoadingPlaceholder height={200} isLoading={isLoading}>
        {isSuccess && filteredQuestions.length > 0 ? (
          <QuestionTable
            data={filteredQuestions}
            utrTagMap={data.utrTagMap}
            rootInitiativeUtrs={data.rootInitiativeUtrs}
            toggleSelectAll={toggleSelectAll}
            selectedQuestions={selectedQuestions}
            toggleSelectQuestion={toggleSelectQuestion}
            isSelectAll={isSelectAll}
            pageSize={pageSize}
            isRootOrganization={isRootOrganization}
          />
        ) : (
          renderNoQuestionsAlert()
        )}
        <div className='question--list__sticky-toolbar'>
          {renderActionToolbar({
            initiativeId,
            questionCount: filteredQuestions.length,
            selectedQuestions,
            toggleSelectAll,
            handleClose: handleUnselectAll,
          })}
        </div>
      </LoadingPlaceholder>
    </>
  );
};
