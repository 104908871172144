import { SurveyModelMinimalUtrv } from '../../../model/surveyData';
import { UtrvStatus } from '../../../constants/status';
import { multiDateRenderer, MultiDateRendererParams } from './table/multiDateRenderer';
import { heading2, heading3, spacer } from '../document-structure';
import { ScopeGroupHistoricalData } from './sgx-metric-report-generator';
import { VisibilityStatus } from '../../../types/download';
import { generateSubGroups, generateReportData, sortData } from '../utils';
import { getGroup } from '@g17eco/core';

interface GenerateScopeGroupsParams {
  nonSGXScopeGroupData: ScopeGroupHistoricalData[];
  targetMap: Map<string, SurveyModelMinimalUtrv>;
  visibilityStatus: VisibilityStatus;
  headerText: string;
  displayUserInput?: boolean;
}

export function generateScopeGroups({ nonSGXScopeGroupData, targetMap, headerText, visibilityStatus, displayUserInput = false }: GenerateScopeGroupsParams) {
  const scopeGroupTables = nonSGXScopeGroupData.map((scopeGroup) => {
    // Ensure 3 max
    const historicalData = scopeGroup.questionData.slice(0, 3).map(historicalSurveyData => {
      return {
        effectiveDate: historicalSurveyData.effectiveDate,
        unitConfig: historicalSurveyData.unitConfig,
        reportData: historicalSurveyData.reportData.filter(utrv => {
          return utrv.status !== UtrvStatus.Created;
        })
      };
    })


    const [latestHistorical] = historicalData;
    if (!latestHistorical) {
      return []
    }

    const dataASC = historicalData.slice().reverse();

    // Data already filtered by type at this point, we are modifying the underlying array. Seems OK
    const scopeGroupCode = scopeGroup.code;
    
    const subGroups = getGroup('standards-and-frameworks', scopeGroupCode)?.subgroups || [];

    const multiDateRendererProps = {
      dataASC,
      targetMap,
      preferredAltCodes: [scopeGroupCode],
      visibilityStatus,
      displayUserInput: displayUserInput,
    };

    const data = !!subGroups.length
      ? generateSubGroups({
          subGroups,
          data: latestHistorical.reportData,
          scopeGroupCode,
          ...multiDateRendererProps,
        })
      : generateReportData({
          data: sortData(latestHistorical.reportData, scopeGroupCode),
          ...multiDateRendererProps,
          scopeGroupCode,
        });

    return data.length > 0 ? [heading3(scopeGroup.name), ...data, spacer()] : [];
  }).flat();

  if (scopeGroupTables.length === 0) {
    return [];
  }

  return [heading2(headerText), ...scopeGroupTables];
}


type RenderParams = Pick<MultiDateRendererParams, 'questionData' | 'targetMap' | 'preferredAltCodes' | 'visibilityStatus'>
interface DataGeneratorParams extends RenderParams {
  headerText: string;
  utrCodes: string[];
  displayUserInput?: boolean;
}


export function generateSGXQuestions(params: DataGeneratorParams) {

  const { questionData, targetMap, preferredAltCodes, utrCodes, visibilityStatus, headerText, displayUserInput = false } = params
  const [latestHistorical] = questionData;
  if (!latestHistorical) {
    return []
  }

  const historicalData = latestHistorical.reportData
    .filter(question => utrCodes.includes(question.universalTracker.code))
    .sort((a, b) => {
      return utrCodes.indexOf(a.universalTracker.code) - utrCodes.indexOf(b.universalTracker.code);
    });

  const dataASC = questionData.slice().reverse();
  const scopeGroupTables = historicalData.map(lastSurveyUtrvs => {
    return multiDateRenderer({
      currentData: lastSurveyUtrvs,
      questionData: dataASC,
      targetMap,
      preferredAltCodes,
      visibilityStatus,
      showEmptyTables: true,
      displayUserInput,
    })
  }).flat()

  if (scopeGroupTables.length === 0) {
    return [];
  }

  return [heading2(headerText), ...scopeGroupTables];
}
