import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { RadioInput } from '../../components/form/RadioInput';
import {
  InsightDashboard,
  SurveyFilter,
  UtrvFilter,
  ToggleFilter,
  PrivacyFilter,
  InsightDashboardFilterKeys,
  InsightDashboardFilters,
} from '../../types/insight-custom-dashboard';
import IconButton from '../../components/button/IconButton';
import { useToggle } from '../../hooks/useToggle';
import { DashboardDivider } from '../../components/dashboard';

interface Props {
  dashboard: InsightDashboard;
  handleSave: (dashboard: Partial<InsightDashboard>, keepEditing?: boolean) => void;
  hideOptions?: InsightDashboardFilterKeys[];
}

const utrvFilterOptions = [
  { code: UtrvFilter.Verified, name: 'All verified data' },
  { code: UtrvFilter.Assured, name: 'Only assured data' },
];

const surveyFilterOptions = [
  { code: SurveyFilter.All, name: 'All surveys' },
  { code: SurveyFilter.Completed, name: 'Only completed surveys' },
];

const utrvPrivacyOptions = [
  { code: PrivacyFilter.All, name: 'All answers' },
  { code: PrivacyFilter.Public, name: 'Only public answers' },
];

enum ToggleOptionCode {
  Show = 'show',
  Hide = 'hide',
}

const getToggleOptions = (
  names: { [key in ToggleOptionCode]: string } = {
    [ToggleOptionCode.Show]: 'Yes',
    [ToggleOptionCode.Hide]: 'No',
  }
) => {
  return [
    { code: ToggleOptionCode.Show, name: names[ToggleOptionCode.Show], value: { enabled: true } },
    { code: ToggleOptionCode.Hide, name: names[ToggleOptionCode.Hide], value: { enabled: false } },
  ];
};

const toggleOptions = getToggleOptions();

const baselinesTargetsOptions = getToggleOptions({
  [ToggleOptionCode.Show]: 'Targets and baselines',
  [ToggleOptionCode.Hide]: 'No Targets and baselines',
});

const getToggleOptionCode = ({ enabled = false }: ToggleFilter = { enabled: false }) => {
  return enabled ? ToggleOptionCode.Show : ToggleOptionCode.Hide;
};

export const FiltersEditing = ({ dashboard, hideOptions = [], handleSave }: Props) => {
  const { title, filters } = dashboard;
  const [filtersInput, setFiltersInput] = useState(filters);
  const [isEditingFilters, toggleIsEditingFilters, setIsEditingFilters] = useToggle(false);

  const handleChange =
    (key: keyof InsightDashboard['filters']) =>
    ({ value }: { value: UtrvFilter | SurveyFilter | ToggleFilter }) =>
      setFiltersInput((filters) => ({ ...filters, [key]: value }));

  const handleToggle =
    (key: keyof InsightDashboard['filters'], options: ReturnType<typeof getToggleOptions> = toggleOptions) =>
    ({ value }: { value: ToggleOptionCode }) => {
      const selectedOption = options.find((option) => option.code === value);
      const selectedValue = selectedOption?.value || { enabled: false };
      handleChange(key)({ value: selectedValue });
    };

  const toggle = () => {
    setFiltersInput(filters);
    toggleIsEditingFilters();
  };

  const handleClickSave = () => {
    toggleIsEditingFilters();
    handleSave({ filters: filtersInput }, true);
  };

  return (
    <>
      <IconButton
        tooltip='Select the data being pulled'
        color='transparent'
        onClick={() => setIsEditingFilters(true)}
        icon='fa-light fa-gear'
      />
      <Modal isOpen={isEditingFilters} toggle={toggle} backdrop='static' returnFocusAfterClose={false}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <h5>Allow dashboard to show</h5>
          <RadioInput
            groupCode='utrv-filter'
            value={filtersInput.utrv}
            options={utrvFilterOptions}
            onChange={handleChange(InsightDashboardFilters.Utrv)}
            className='d-flex'
            formGroupClassName='mr-5'
          />
          {hideOptions.includes(InsightDashboardFilters.Privacy) ? null : (
            <>
              <h5>Privacy settings</h5>
              <RadioInput
                groupCode='privacy-filter'
                value={filtersInput.privacy}
                options={utrvPrivacyOptions}
                onChange={handleChange('privacy')}
                className='d-flex'
                formGroupClassName='mr-5'
              />
            </>
          )}
          {hideOptions.includes(InsightDashboardFilters.Survey) ? null : (
            <>
              <h5>From</h5>
              <RadioInput
                groupCode='survey-filter'
                value={filtersInput.survey}
                options={surveyFilterOptions}
                onChange={handleChange(InsightDashboardFilters.Survey)}
                className='d-flex'
                formGroupClassName='mr-5'
              />
            </>
          )}
          {hideOptions.includes(InsightDashboardFilters.BaselinesTargets) ? null : (
            <>
              <h5>With</h5>
              <RadioInput
                groupCode='baselines-targets-filter'
                value={getToggleOptionCode(filtersInput.baselinesTargets)}
                options={baselinesTargetsOptions}
                onChange={handleToggle(InsightDashboardFilters.BaselinesTargets, baselinesTargetsOptions)}
                className='d-flex'
                formGroupClassName='mr-5'
              />
            </>
          )}
          <DashboardDivider />
          <h5 className='mt-3'>Company details header</h5>
          <RadioInput
            groupCode='company-header-toggle'
            value={getToggleOptionCode(filtersInput.initiativeInfo)}
            options={toggleOptions}
            onChange={handleToggle(InsightDashboardFilters.InitiativeInfo)}
            className='d-flex'
            formGroupClassName='mr-5'
          />
          <DashboardDivider />
          <h5 className='mt-3'>SDG contribution Chart</h5>
          <RadioInput
            groupCode='company-sdgcontribution-toggle'
            value={getToggleOptionCode(filtersInput.sdgContribution)}
            options={toggleOptions}
            onChange={handleToggle(InsightDashboardFilters.SdgContribution)}
            className='d-flex'
            formGroupClassName='mr-5'
          />
          {hideOptions.includes(InsightDashboardFilters.ShareWithSubsidiaries) ? null : (
            <>
              <DashboardDivider />
              <h5 className='mt-3'>Share dashboard with subsidiaries</h5>
              <RadioInput
                groupCode='share-with-subsidiaries-toggle'
                value={getToggleOptionCode(filtersInput.shareWithSubsidiaries)}
                options={toggleOptions}
                onChange={handleToggle(InsightDashboardFilters.ShareWithSubsidiaries)}
                className='d-flex'
                formGroupClassName='mr-5'
              />
            </>
          )}
        </ModalBody>
        <ModalFooter className='pt-0'>
          <Button color='link-secondary' onClick={toggle}>
            Cancel
          </Button>
          <Button color='primary' onClick={handleClickSave}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
