import { HistoricalUtrs } from '../../../../../api/insights';
import { ChartSubType, GridDashboardChartItem } from '../../../../../types/insight-custom-dashboard';
import { SingleValue } from './SingleValue';
import { PieChart } from './PieChart';
import { LineChart } from './LineChart';
import { ColumnChart } from './ColumnChart';
import { BarChart } from './BarChart';
import { Table } from './Table';
import { SurveyActionData } from '../../../../../model/surveyData';

interface ChartWidgetProps {
  readOnly: boolean;
  utrsData: HistoricalUtrs[];
  item: Pick<GridDashboardChartItem, 'subType' | 'variables' | 'calculation' | 'unitText' | 'note'>;
  handleSetTarget?: () => void;
  initiativeId: string;
  survey?: Pick<SurveyActionData, '_id' | 'fragmentUniversalTrackerValues'>;
  altUnitText: string;
}

export const MultiUtrsChartWidget = (props: ChartWidgetProps) => {
  const { utrsData, item, initiativeId, survey, handleSetTarget, readOnly, altUnitText } = props;
  const { subType = ChartSubType.Line, variables, calculation, unitText, note } = item;

  switch (subType) {
    case ChartSubType.SingleValue:
    case ChartSubType.SparkLine:
      return (
        <SingleValue
          readOnly={readOnly}
          utrsData={utrsData}
          variables={variables}
          calculation={calculation}
          unitText={unitText}
          note={note}
          handleSetTarget={handleSetTarget}
          hasSparkLine={subType === ChartSubType.SparkLine}
          altUnitText={altUnitText}
        />
      );
    case ChartSubType.Pie:
    case ChartSubType.PieWithLegend:
      return (
        <PieChart
          utrsData={utrsData}
          variables={variables}
          calculation={calculation}
          hasLegend={subType === ChartSubType.PieWithLegend}
        />
      );
    case ChartSubType.Line:
      return <LineChart utrsData={utrsData} variables={variables} calculation={calculation} unitText={unitText} />;
    case ChartSubType.Column:
      return <ColumnChart utrsData={utrsData} variables={variables} calculation={calculation} unitText={unitText} />;
    case ChartSubType.Stacked:
      return (
        <ColumnChart
          utrsData={utrsData}
          variables={variables}
          calculation={calculation}
          unitText={unitText}
          isStacked
        />
      );
    case ChartSubType.Bar:
      return <BarChart utrsData={utrsData} variables={variables} calculation={calculation} note={note} />;
    case ChartSubType.Table:
      // TODO: Refactor table to be subType DoubleTable of type Table
      return (
        <Table
          utrsData={utrsData}
          variables={variables}
          calculation={calculation}
          initiativeId={initiativeId}
          survey={survey}
        />
      );
    default:
      return null;
  }
};
