import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input } from 'reactstrap';
import { MetricGroup } from '../../types/metricGroup';
import { DashboardDivider } from '../dashboard';
import LoadingPlaceholder from '../LoaderContainer/LoadingPlaceholder';
import { CustomMetricContext } from './CustomMetricContainer';
import { EditMetricModal, EditMetricModalProps } from './EditMetricModal';
import { MetricConfig } from './MetricGroupForm';
import MetricTable, { MetricTableProps } from './MetricTable';
import MetricForm, { MetricFormProps } from './SearchMetricsTable';
import { useSearchDebounceWithFlexMap } from '../../hooks/useSearchDebounceWithFlexMap';
import { QUESTION } from '../../constants/terminology';

interface QuestionsAssignmentTableProps {
  metricGroup?: MetricGroup;
  isAssigned: boolean;
  canEdit: boolean;
  editQuestionUrl: string;
  assignQuestionsUrl: string;
  handleReload: () => Promise<void>;
  metricConfig: MetricConfig;
}

export const QuestionsAssignmentTable = (props: QuestionsAssignmentTableProps) => {
  const {
    metricGroup,
    isAssigned,
    canEdit,
    editQuestionUrl,
    assignQuestionsUrl,
    handleReload,
    metricConfig,
  } = props;
  const history = useHistory();
  const [editUtr, setEditUtr] = useState<string | undefined>();
  const [createMetric, setCreateMetric] = useState(false);

  const { blueprintQuestions } = useContext(CustomMetricContext);

  const { onSearch, matchedQuestions } = useSearchDebounceWithFlexMap(metricGroup?.universalTracker);

  const handleEditUtr = (utrId: string) => {
    setEditUtr(utrId);
    history.push(editQuestionUrl);
  };

  const handleCancelEditUtr = () => {
    setEditUtr('');
    setCreateMetric(false);
    history.push(assignQuestionsUrl);
  };

  const metricTableProps: MetricTableProps = {
    questions: matchedQuestions,
    metricGroup,
    handleReload,
    handleEditUtr,
    metricConfig,
    helperText: `No ${QUESTION.PLURAL} have been assigned to this custom survey pack.`,
  };

  const metricFormProps: MetricFormProps = {
    metricGroup,
    questions: blueprintQuestions,
    metricConfig,
    handleReload,
    handleCancel: handleCancelEditUtr,
    handleCreateNewMetric: () => setCreateMetric(true),
  };

  const editMetricModalProps: EditMetricModalProps = {
    isOpen: Boolean(editUtr || createMetric),
    editUtrId: editUtr,
    questions: blueprintQuestions,
    metricGroup,
    handleReload,
    handleCancel: handleCancelEditUtr,
    metricConfig,
    isAddingToGroup: true,
  };

  return (
    <>
      <div className='position-relative'>
        <LoadingPlaceholder
          count={1}
          height={37}
          className='background-ThemeBgCanvas'
          isLoading={!blueprintQuestions}
        />
        <LoadingPlaceholder
          count={5}
          height={37}
          className='mt-1 background-ThemeTextWhite'
          isLoading={!blueprintQuestions}
        >
          <div className='position-relative'>
            <Input
              type='text'
              className='metric-table__search position-absolute'
              bsSize='sm'
              placeholder='Search'
              onChange={onSearch}
            />
            <MetricTable {...metricTableProps} isReadOnly={!canEdit || isAssigned} />
          </div>
        </LoadingPlaceholder>
        <DashboardDivider />
        <EditMetricModal {...editMetricModalProps} />
        {canEdit && !isAssigned ? <MetricForm {...metricFormProps} /> : null}
      </div>
    </>
  );
};
