import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Industry, IndustryLevels } from '../../types/initiative';
import IndustrySectorSelector, { getDefaultIcb2019 } from '../industry-sector-selector';
import { EditOptionProps } from '../../types/initiative';
import { useDispatch } from 'react-redux';
import { loadIndustrySectors } from '../../actions/common';
import { useAppSelector } from '../../reducers';
import { IndustrySector } from '../../types/common';
import { saveReportingLevelDetails } from '../../actions/initiative';

interface IndustrySectorDisplayProps {
  industry: string;
  industrySectors: IndustrySector[];
}

enum IndustryDropdownLevels {
  PrimarySector = '1',
  SubSector = '2',
  Industry = '3',
  SubIndustry = '4'
};

const IndustrySectorDisplay = ({ industry, industrySectors }: IndustrySectorDisplayProps) => {
  return (
    <div>
      {Object.values(IndustryDropdownLevels).map((level) => {
        const codeLength = Number(level) * 2;
        const currentSector = industry.substring(0, codeLength);
        const parentSector = industry.substring(0, codeLength - 2);
        const filteredIndustrySectors = industrySectors.filter(
          (sector) => sector.code.length === codeLength && sector.code.substring(0, codeLength - 2) === parentSector
        );
        const options = filteredIndustrySectors.map((industry) => ({ value: industry.code, label: industry.name }));
        const selectedOption = options.find((item) => item.value === currentSector) || null;
        return (
          <div className={'text-ThemeTextDark mt-1'} key={level}>
            {selectedOption?.label}
          </div>
        );
      })}
    </div>
  );
};

export const IndustryRow = ({ label, initiative }: EditOptionProps) => {
  const industrySectors = useAppSelector((state) => state.industrySectors.data) || [];
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [industry, setIndustry] = useState<Industry | undefined>(initiative.industry);
  const defaultIndustrySector = getDefaultIcb2019(industry?.icb2019);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadIndustrySectors());
  }, [dispatch]);

  const handleIndustrySector = (icb2019: IndustryLevels) => {
    setIndustry({ ...industry, icb2019 } as Industry);
  };
  const toggleShowInput = () => setIsEditing(!isEditing);
  const handleSubmit = () => {
    const formData = { industry: industry };
    dispatch(saveReportingLevelDetails(initiative._id, formData))
      .then(() => {
        toggleShowInput();
      })
      .catch((e: Error) => console.log(e));
  };
  const handleCancel = () => {
    setIndustry(initiative.industry);
    toggleShowInput();
  };

  return (
    <Row className='ml-0 mb-3 mr-0 industry-row__wrapper'>
      <Col className='col-md-4 col-12 align-self-start text-label-uppercase mt-1'>{label}</Col>
      <Col className='col-md-6 col-10 d-flex text-right'>
        {isEditing ? (
          <IndustrySectorSelector industry={defaultIndustrySector} handleIndustrySector={handleIndustrySector} />
        ) : (
          <div className={`w-100 ${defaultIndustrySector ? '' : 'text-ThemeTextPlaceholder'}`}>
            {defaultIndustrySector !== '' ? (
              <IndustrySectorDisplay industry={defaultIndustrySector} industrySectors={industrySectors} />
            ) : (
              'not set'
            )}
          </div>
        )}
      </Col>
      <Col className='col-2 d-flex align-self-end'>
        {isEditing ? (
          <>
            <Button color='link' className='mb-1' onClick={handleSubmit}>
              Save
            </Button>
            <Button color='link-secondary' className='mb-1' onClick={handleCancel}>
              Cancel
            </Button>
          </>
        ) : (
          <Button color='link' onClick={toggleShowInput}>
            Edit
          </Button>
        )}
      </Col>
    </Row>
  );
};
