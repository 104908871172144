import { formDataHeaders } from '@services/G17Client';
import { g17ecoApi, transformResponse } from './g17ecoApi';
import { ApiResponse } from '@g17eco/types/api';
import { UserRoles } from '@constants/user';

export interface UserRowData {
  email: string;
  initiativeCode: string;
  initiativeName: string;
  permissions: UserRoles[];
  initiativeId?: string;
  message?: string;
  validPermissions: UserRoles[];
}

export interface ValidatedResult {
  validatedData: UserRowData[];
  isUserLimitExceeded: boolean;
}

interface Params {
  data: { file: File };
  initiativeId: string;
}

const TAGS = ['bulk-onboarding-import'];

const getUrl = (initiativeId: string) => `/initiatives/${initiativeId}/users`;

export const bulkOnboardingImportApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      validateImportFile: builder.mutation<ValidatedResult, Params>({
        transformResponse,
        query: ({ initiativeId, data }) => ({
          url: `${getUrl(initiativeId)}/import/validate`,
          headers: formDataHeaders,
          method: 'post',
          data: data,
        }),
        invalidatesTags: TAGS,
      }),
      bulkOnboardEmails: builder.mutation<ApiResponse, Params>({
        query: ({ initiativeId, data }) => ({
          url: `${getUrl(initiativeId)}/import`,
          headers: formDataHeaders,
          method: 'post',
          data: data,
        }),
        invalidatesTags: TAGS,
      }),
    }),
  });

export const { useValidateImportFileMutation, useBulkOnboardEmailsMutation } = bulkOnboardingImportApi;
