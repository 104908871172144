import { TabContent, TabPane } from 'reactstrap';
import { UtrvType } from '../../constants/status';
import { ShowAs } from '../../reducers/universal-tracker-modal';

export interface Tab {
  navId: ShowAs | UtrvType;
  disabled?: boolean;
  name: string;
  component: JSX.Element;
}

interface Props {
  tabs: Tab[];
  activeTab: Tab['navId'];
}

export const ContentTabs = ({ tabs, activeTab }: Props) => {
  return (
    <div className='px-sm mt-1'>
      <TabContent activeTab={activeTab}>
        {tabs.map((btn, i) => (
          <TabPane key={i} tabId={btn.navId}>
            {activeTab === btn.navId ? btn.component : null}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};
