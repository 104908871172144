import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { SurveyTypeOptions, getSurveyTypeOptions } from './utils';
import { SurveyType } from '@g17eco/types/survey';

export interface SurveyTypeDropdownProps<T extends SurveyTypeOptions = SurveyType.Default | SurveyType.Aggregation> {
  surveyType: T;
  setSurveyType?: (surveyType: T) => void;
  hasAllOption?: boolean;
  disabled?: boolean;
  className?: string;
}

export const SurveyTypeDropdown = <T extends SurveyTypeOptions = SurveyType.Default | SurveyType.Aggregation>({
  surveyType,
  setSurveyType = () => {},
  hasAllOption = false,
  disabled = false,
  className = 'ml-3',
}: SurveyTypeDropdownProps<T>) => {
  const options = getSurveyTypeOptions<T>(hasAllOption);
  return (
    <UncontrolledDropdown disabled={disabled} className={className}>
      <DropdownToggle disabled={disabled} className='text-truncate' color='transparent' outline caret>
        <span className='px-2'>{options.find(({ value }) => value === surveyType)?.label}</span>
      </DropdownToggle>
      <DropdownMenu end>
        {options.map(({ value, label }) => (
          <DropdownItem key={value} active={value === surveyType} onClick={() => setSurveyType(value)}>
            {label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
