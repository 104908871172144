import { useEffect, useState } from 'react';
import { Button, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  DecimalInputProps,
  DecimalInputType,
  InitiativeUniversalTracker,
} from '../../types/initiativeUniversalTracker';
import { validateMinMax } from '../../utils/universalTrackerValue';
import Loader from '../loader';
import { BulkActionUtr } from '../survey-question-list/partials/BulkActionToolbar';
import { TableColumn } from '../survey/form/input/table/InputInterface';
import { DecimalType, ErrorDecimalType } from './DecimalButton';
import { DecimalInput } from './DecimalInput';
import { DecimalTableInput } from './DecimalTableInput';
import { OverrideConfigAlert } from './OverrideConfigAlert';
import {
  DECIMAL_MAX,
  DECIMAL_MIN,
  getInitialState,
  invalidInputMessage,
  isValidUpdateData,
  isDecimalMultipleUpdate,
} from './utils';
import { useToggle } from '../../hooks/useToggle';
import { QUESTION } from '../../constants/terminology';

export interface DecimalModalProps {
  isOpen: boolean;
  isLoading: boolean;
  inputType: DecimalInputType;
  tableColumns: TableColumn[];
  selectedQuestions: BulkActionUtr[];
  questionsWithValidation: InitiativeUniversalTracker[];
  handleClose: (props?: { reloadSurvey: boolean }) => Promise<void | undefined>;
  handleUpdate: (decimal: DecimalType) => void;
  defaultDecimal: DecimalType;
}

const defaultState = { value: undefined };

export const DecimalModal = ({
  isOpen,
  handleClose,
  isLoading,
  inputType,
  selectedQuestions,
  questionsWithValidation,
  tableColumns,
  handleUpdate,
  defaultDecimal,
}: DecimalModalProps) => {
  const [decimal, setDecimal] = useState<DecimalType>(defaultState);
  const [error, setError] = useState<ErrorDecimalType>(defaultState);
  const [isEnforced, toggleEnforced, setEnforced] = useToggle(false);

  const isMultipleUpdate = isDecimalMultipleUpdate({ selectedQuestions, questionsWithValidation });

  const shouldUpdateDecimal = isValidUpdateData({ decimal, defaultDecimal, error, isEnforced, isMultipleUpdate });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setDecimal(defaultDecimal);
    setEnforced(!!questionsWithValidation.length);
    setError(defaultState);
  }, [defaultDecimal, questionsWithValidation, setEnforced, isOpen, isLoading]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setError((prev) => ({
      ...prev,
      [name]: validateMinMax(value, DECIMAL_MIN, DECIMAL_MAX).errored ? invalidInputMessage : '',
    }));
    setDecimal((prev) => ({
      ...prev,
      [name]: value === '' ? undefined : parseInt(value),
    }));
  };

  const onToggleSwitch = () => {
    toggleEnforced();
    setDecimal(getInitialState({ inputType, tableColumns, valueValidation: undefined }));
    setError(defaultState);
  };

  const onClickUpdate = () => {
    if (!shouldUpdateDecimal) {
      return;
    }
    handleUpdate(decimal);
  };

  const inputProps = {
    decimal,
    error,
    disabled: !isEnforced,
    onChange,
  };

  return (
    <Modal isOpen={isOpen} toggle={() => handleClose()} backdrop='static'>
      <ModalHeader toggle={() => handleClose()}>Set decimal</ModalHeader>
      <ModalBody>
        {isLoading ? <Loader /> : null}
        <div>
          This is a global setting. Future/unanswered {QUESTION.PLURAL} will follow the rule set below. Answered {QUESTION.PLURAL} will
          not be impacted.
        </div>
        <OverrideConfigAlert
          showOverrideConfigAlert={isMultipleUpdate}
          questionCount={questionsWithValidation.length}
        />
        <FormGroup switch className='d-flex align-items-center mt-3' id='enforce-decimal'>
          <Input type='switch' className='mr-2' onChange={onToggleSwitch} checked={isEnforced} />
          <label htmlFor='enforce-decimal'>Enforce decimal place</label>
        </FormGroup>
        <DecimalModalBody inputType={inputType} tableColumns={tableColumns} inputProps={inputProps} />
      </ModalBody>
      <ModalFooter>
        <Button color='primary' disabled={!shouldUpdateDecimal || isLoading} onClick={onClickUpdate}>
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const DecimalModalBody = ({
  inputType,
  tableColumns,
  inputProps,
}: Pick<DecimalModalProps, 'inputType' | 'tableColumns'> & {
  inputProps: DecimalInputProps;
}) => {
  switch (inputType) {
    case DecimalInputType.Table:
      return <DecimalTableInput {...inputProps} tableColumns={tableColumns} />;
    case DecimalInputType.SingleInput:
      return <DecimalInput {...inputProps} className='mt-3' />;
    default:
      return <div className='mt-3'>Not supported type</div>;
  }
};
