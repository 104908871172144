import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { generateUrl } from '../../routes/util';
import { InitiativeData } from '../../types/initiative';
import { Menu } from '../menu/Menu';

export enum CompanySettingsPages {
  Details = 'details',
  DefaultSurveyConfig = 'default-survey-config',
}

const NAV_TABS: { code: string; label: string }[] = [
  {
    code: CompanySettingsPages.Details,
    label: 'Company details',
  },
  {
    code: CompanySettingsPages.DefaultSurveyConfig,
    label: 'Default survey configuration',
  },
];

interface NavigationProps {
  initiative: InitiativeData;
}

export const Navigation = ({ initiative }: NavigationProps) => {

  const params = useParams<{ page: string }>();
  const history = useHistory();

  const onClick = (code: string) => {
    history.push({
      pathname: generateUrl(ROUTES.COMPANY_SETTINGS, { initiativeId: initiative._id, page: code }),
      search: history.location.search,
    });
  }

  return (
    <Menu items={
      NAV_TABS.map((tab) => ({
        active: tab.code === params.page,
        label: tab.label,
        onClick: () => onClick(tab.code)
      }))
    } />
  );
};
