import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetDataShareSurveyQuery } from '../../api/g17ecoApi';
import { ROUTES } from '../../constants/routes';
import { ReduxStateLoadable, useAppSelector } from '../../reducers';
import { NotShared } from '../../routes/portfolio-tracker/PortfolioTrackerCompanyRoute';
import { PortfolioTrackerQuestionView } from '../../routes/portfolio-tracker/PortfolioTrackerQuestionView';
import { generateUrl } from '../../routes/util';
import G17Client from '../../services/G17Client';
import { DataShareLookup, RequesterType } from '../../types/dataShare';
import { SurveyListItem } from '../../types/survey';
import { useSurveyGroups } from '../../utils/survey';
import { BasicAlert } from '../alert/BasicAlert';
import Dashboard, { DashboardSection } from '../dashboard';
import { SurveyListDropdown } from '../initiative/SurveyListDropdown';
import Loader from '../loader';
import SurveyQuestionList from '../survey-question-list/survey-question-list';
import { SurveyQuestionListToolbar } from '../survey/survey-question-list-toolbar';
import { ExpandAllToggle } from '../survey/survey-question-list-toolbar/partials/ExpandAllToggle';
import { ScopeViewDropdown } from '../survey/survey-question-list-toolbar/partials/ScopeViewDropdown';
import { Spacer } from '../survey/survey-question-list-toolbar/partials/Spacer';

interface RouteParams {
  questionId?: string;
  index?: string;
}

interface DataShareSurveysOverviewProps {
  initiativeId: string;
  portfolioId: string;
}

export const DataShareSurveyOverview = (props: DataShareSurveysOverviewProps) => {
  const { portfolioId, initiativeId } = props;
  const [item, setItem] = useState<SurveyListItem | undefined>();
  const [dataShare, setDataShare] = useState<ReduxStateLoadable<DataShareLookup>>({
    loaded: false,
    data: undefined,
    errored: false,
  });
  const history = useHistory();
  const { questionId, index } = useParams<RouteParams>();
  const surveyData = useGetDataShareSurveyQuery(
    {
      initiativeId,
      requesterId: portfolioId,
      requesterType: RequesterType.Portfolio,
      surveyId: item?._id || '',
    },
    { skip: !item?._id }
  );
  const overviewMode = useAppSelector((state) => state.surveySettings.overviewMode);
  const surveyGroups = useSurveyGroups(overviewMode, surveyData.data?.surveyData);

  useEffect(() => {
    G17Client.getRequesterDataShare({ requesterId: portfolioId, requesterType: RequesterType.Portfolio, initiativeId })
      .then((data) => {
        setDataShare((s) => ({ ...s, loaded: true, data }));
        setItem(data.list[0]);
      })
      .catch((e: Error) => {
        setDataShare((s) => ({
          ...s,
          loaded: false,
          data: undefined,
          errored: true,
          errorMessage: e.message,
        }));
      });
  }, [initiativeId, portfolioId]);

  const goToQuestion = useCallback(
    (id: string, index?: number) => {
      const url = generateUrl(ROUTES.PORTFOLIO_TRACKER_SURVEY_OVERVIEW, {
        portfolioId,
        companyId: initiativeId,
        questionId: id,
        index: String(index ?? ''),
      });
      history.push(url);
    },
    [history, initiativeId, portfolioId]
  );

  if (questionId) {
    return (
      <PortfolioTrackerQuestionView
        portfolioId={portfolioId}
        companyId={initiativeId}
        questionId={questionId}
        surveyGroups={surveyGroups}
        index={index}
      />
    );
  }

  if (dataShare.errorMessage) {
    return (
      <Dashboard className='mt-0'>
        <DashboardSection>
          <BasicAlert type={'danger'}>{dataShare.errorMessage}</BasicAlert>
        </DashboardSection>
      </Dashboard>
    );
  }

  if (!dataShare.loaded || surveyData.isLoading || surveyData.isFetching) {
    return (
      <Dashboard className='mt-0'>
        <Loader relative={true} />
      </Dashboard>
    );
  }

  if (!item) {
    return <NotShared title='Surveys'>Company does not have any completed surveys</NotShared>;
  }

  return (
    <Dashboard className='mt-0'>
      <DashboardSection buttons={[
        <SurveyListDropdown
          surveyList={dataShare.data.list}
          selectedItem={item}
          handleDropdownSelect={(item) => setItem(item)}
        />
      ]}>
        <SurveyQuestionList
          goToQuestion={goToQuestion}
          toolbar={(props) => (
            <SurveyQuestionListToolbar
              {...props}
              surveyScope={surveyData.data?.surveyData?.scope}
              components={[
                ScopeViewDropdown,
                Spacer,
                surveyGroups.length > 1 ? ExpandAllToggle : null,
              ]}
            />
          )}
          surveyId={item._id}
          initiativeId={initiativeId}
          surveyGroups={surveyGroups}
        />
      </DashboardSection>
    </Dashboard>
  );
};
