import { DataPeriods } from '@g17eco/types/universalTracker';
import { getPeriodName } from '@utils/universalTracker';

export type PeriodOption = 'all' | DataPeriods;

export const getDefaultPeriod = (availablePeriods: DataPeriods[]) => {
  if (availablePeriods.length === 0) {
    return undefined;
  }
  switch (true) {
    case availablePeriods.includes(DataPeriods.Yearly):
      return DataPeriods.Yearly;
    case availablePeriods.includes(DataPeriods.Quarterly):
      return DataPeriods.Quarterly;
    case availablePeriods.includes(DataPeriods.Monthly):
      return DataPeriods.Monthly;
    default:
      return undefined;
  }
};

export const getPeriodOptionLabel = (period: PeriodOption) => {
  return period === 'all' ? 'All durations types' : getPeriodName(period);
};

export const getSurveyPeriodOptions = <T extends PeriodOption>(hasAllOption: boolean): T[] => {
  const defaultOptions = [DataPeriods.Yearly, DataPeriods.Quarterly, DataPeriods.Monthly];

  return (hasAllOption ? ['all', ...defaultOptions] : defaultOptions) as T[];
}
