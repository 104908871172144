import Select, { GroupHeadingProps, MultiValue } from 'react-select';
import { SelectedGoal, SelectedTarget } from '.';
import variables from '../../css/variables.module.scss';

interface TargetSelectProps {
  selectedGoals: SelectedGoal[];
  selectedTargets: SelectedTarget[];
  handleOnChange: (selection: MultiValue<SelectedTarget>) => void;
}

const GroupHeading = (props: GroupHeadingProps<SelectedTarget, true, SelectedGoal>) => {
  const { data } = props;
  return (
    <div className={`sdg-target-header sdg-bg-${data.value}-5 text-ThemeTextWhite`}>
      Goal {data.value}: {data.label}
    </div>
  );
};

export const TargetSelect = (props: TargetSelectProps) => {
  const { selectedGoals, selectedTargets, handleOnChange } = props;
  return (
    <Select
      options={selectedGoals}
      isMulti={true}
      value={selectedTargets}
      components={{ GroupHeading }}
      placeholder='Disappearing list of targets based on above selection'
      onChange={handleOnChange}
      menuPlacement='auto'
      closeMenuOnSelect={false}
      styles={{
        menuList: (styles) => ({
          ...styles,
          maxHeight: 350,
        }),
        group: (base) => {
          return {
            ...base,
            padding: 0,
          };
        },
        multiValueLabel: (base, { data }) => ({
          ...base,
          backgroundColor: variables[`sdg${data.goal.code}`],
          color: variables.white,
        }),
        multiValueRemove: (base, { data }) => ({
          ...base,
          backgroundColor: variables[`sdg${data.goal.code}`],
          color: variables.white,
        }),
      }}
    />
  );
}
