import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { DashboardRow } from '../../../components/dashboard';
import { ROUTES } from '../../../constants/routes';
import { InitiativeCompany } from '../../../types/initiative';
import { generateUrl } from '../../util';
import './PortfolioTrackerCompanySummary.scss'

export interface PortfolioTrackerCompanySummaryProps {
  portfolioId: string;
  company: InitiativeCompany;
  summaryPage?: string;
  questionId?: string;
  hasSidebar?: boolean;
}

export const PortfolioTrackerCompanySummary = (props: PortfolioTrackerCompanySummaryProps) => {
  const { portfolioId, company } = props;
  const history = useHistory();
  const companyId = company._id;

  const goToPortfolio = () => history.push(generateUrl(ROUTES.PORTFOLIO_TRACKER_PORTFOLIO, { portfolioId, companyId }));

  return (
    <>
      <DashboardRow>
        <Button color='link' size='sm' onClick={goToPortfolio}>
          <i className='fa fa-chevron-left mr-2' />
          Back to Portfolios
        </Button>
      </DashboardRow>
      <DashboardRow>
        <div className='flex-fill d-flex flex-column whiteBoxContainer company-information'>
          <div className='company-information__title'>{company.name}</div>
          {company.sectorText && <div className='company-information__bio mt-1'>Sector: {company.sectorText}</div>}
          {company.industryText && <div className='company-information__bio mt-1'>Industry: {company.industryText}</div>}
          {company.description && <div className='company-information__desc mt-3'>{company.description}</div>}
        </div>
      </DashboardRow>
    </>
  );
};
