import { FeatureCode } from '@g17eco/core';
import SimpleTooltip from '@components/simple-tooltip';
import './styles.scss';

export enum ExtraFeature {
  AutoAggregatedSurvey = 'autoAggregatedSurvey',
  MaterialityAssessmentTool = 'materialityAssessmentTool',
  StaffReports = 'staffReports',
  PreferredStandards = 'preferredStandards',
  CompanySponsorship = 'companySponsorship',
  CustomMetricImports = 'customMetricImports'
}

type Feature = FeatureCode | ExtraFeature;
interface FeatureStabilityProps {
  feature: Feature;
  className?: string;
  callback?: () => void;
}

const InternalFeature: Feature[] = [
  ExtraFeature.StaffReports,
  ExtraFeature.PreferredStandards,
  ExtraFeature.CompanySponsorship,
  FeatureCode.CustomDashboards,
  ExtraFeature.CustomMetricImports
];
const ExperimentalFeature: Feature[] = [ExtraFeature.MaterialityAssessmentTool];
const BetaFeature: Feature[] = [ExtraFeature.AutoAggregatedSurvey];
const OnOffFeature: Feature[] = [];

export const FeatureStability = ({ feature, className = 'ml-2', callback }: FeatureStabilityProps) => {
  const renderIcon = () => {
    switch (true) {
      case InternalFeature.includes(feature):
        return (
          <SimpleTooltip text='Internal Feature'>
            <i className='fa-light fa-user-lock'></i>
          </SimpleTooltip>
        );
      case ExperimentalFeature.includes(feature):
        return (
          <SimpleTooltip text='Experimental Feature'>
            <i className='fa-light fa-flask-gear'></i>
          </SimpleTooltip>
        );
      case BetaFeature.includes(feature):
        return (
          <SimpleTooltip text='In Beta'>
            <i className='fa-light fa-flask'></i>
          </SimpleTooltip>
        );
      case OnOffFeature.includes(feature):
        return (
          <SimpleTooltip text='On/Off Feature'>
            <i className='fa-light fa-plug-circle-plus'></i>
          </SimpleTooltip>
        );
      default:
        return null;
    }
  };

  return <div className={`d-inline-block feature-icon ${className}`}>{renderIcon()}</div>;
};
