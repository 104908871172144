import { useHistory, useParams } from 'react-router-dom';
import { naturalSort } from '../../utils';
import { DATE, formatDate } from '../../utils/date';
import Table, { TableColumn } from '../table/Table';
import { NavCode, TemplateHistory } from '../survey-templates/types';
import './styles.scss';
import { useBulkSurveysScopeUpdateMutation, useGetHistoryQuery } from '../../api/survey-templates-history';
import { TemplateHistoryOptions } from './TemplateHistoryOptions';
import { BulkSurveysModal } from '../survey-templates-notifications/BulkSurveysModal';
import { generateUrl } from '../../routes/util';
import { ROUTES } from '../../constants/routes';
import Loader from '../loader';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import { JobStatus } from '../../types/background-jobs';

const getStatusText = (row: TemplateHistory) => {
  if (row.deletedDate) {
    return 'Deleted';
  }
  if (row.completedDate) {
    return 'Completed';
  }
  return '';
}

export const TemplateHistoryTable = () => {
  const { initiativeId, templateId, historyId } = useParams<{ initiativeId: string, templateId: string, historyId?: string }>();
  const query = useGetHistoryQuery(templateId, { skip: !templateId });
  const history = useHistory();
  const { addSiteAlert } = useSiteAlert();
  const [refreshSurveysScope, { isLoading }] = useBulkSurveysScopeUpdateMutation();

  const handleRefreshSurveysScope = async (refreshHistoryId: string) => {
    try {
      await refreshSurveysScope({ templateId, historyId: refreshHistoryId });
      addSiteAlert({
        content: 'Refresh scope successfully',
        timeout: 5000
      })
    } catch (e) {
      addSiteAlert({
        content: e.message,
        color: SiteAlertColors.Danger
      })
    }
  }

  const columns: TableColumn<TemplateHistory>[] = [
    {
      id: 'created',
      Header: 'Date used',
      accessor: (row: TemplateHistory) => formatDate(row.created, DATE.DEFAULT_SPACES_WITH_TIME),
      sortType: (a, b) => naturalSort(a.original.created, b.original.created),
    },
    {
      id: 'lastUpdated',
      Header: 'Last updated',
      accessor: (row: TemplateHistory) =>
        row.lastUpdated ? formatDate(row.lastUpdated, DATE.DEFAULT_SPACES_WITH_TIME) : '',
      sortType: (a, b) => naturalSort(a.original.lastUpdated ?? '', b.original.lastUpdated ?? ''),
    },
    {
      id: 'surveys',
      Header: 'Surveys',
      accessor: (row: TemplateHistory) => row.results.length,
    },
    {
      id: 'successes',
      Header: 'Successes',
      accessor: (row: TemplateHistory) => row.results.filter((result) => result.status === JobStatus.Completed).length,
    },
    {
      id: 'failures',
      Header: 'Failures',
      accessor: (row: TemplateHistory) => row.results.filter((result) => result.status === JobStatus.Error).length,
    },
    {
      id: 'settings',
      Header: '',
      accessor: (row: TemplateHistory) => (
        <TemplateHistoryOptions
          templateHistory={row}
          isLoading={isLoading}
          refreshSurveysScope={handleRefreshSurveysScope}
          disabled={Boolean(row.deletedDate)}
        />
      ),
      disableSortBy: true,
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: (row: TemplateHistory) => getStatusText(row),
    },
  ];

  const handleClose = () => {
    query.refetch();
    
    history.push(
      `${generateUrl(ROUTES.SURVEY_TEMPLATES_VIEW, {
        initiativeId,
        templateId,
        page: NavCode.History,
      })}`
    );
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      <Table
        className='mt-3'
        defaultSortIcon={null}
        sortBy={[{ id: 'lastEdit', desc: false }]}
        showRowCount={false}
        responsive={true}
        pageSize={10}
        columns={columns}
        data={query.data ?? []}
      />
      <BulkSurveysModal isOpen={!!historyId} templateId={templateId} historyId={historyId} toggle={handleClose} />
    </>

  );
};
