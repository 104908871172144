import React, { ReactNode } from 'react';
import { HistoricalUtrs, HistoricalUtrsQueryByCodeParams } from '../../../api/insights';
import { InsightDashboard, InsightDashboardFilterKeys } from '../../../types/insight-custom-dashboard';
import { useAppSelector } from '../../../reducers';
import { isStaff } from '../../../selectors/user';

interface CustomDashboardProps {
  children: ReactNode;
  getHistoricalUtrsByCodes: ({
    initiativeId,
    utrCodes: [utrCode],
    queryParams,
  }: HistoricalUtrsQueryByCodeParams) => Promise<HistoricalUtrs[]>;
  duplicateDashboard: () => Promise<InsightDashboard>;
  hideFilterOptions?: InsightDashboardFilterKeys[];
  hideShareButton?: boolean;
  hideQuestionReference?: boolean;
  readOnly?: boolean;
}

export interface InsightDashboardContextProps
  extends Pick<
    CustomDashboardProps,
    | 'getHistoricalUtrsByCodes'
    | 'duplicateDashboard'
    | 'hideFilterOptions'
    | 'hideShareButton'
    | 'hideQuestionReference'
    | 'readOnly'
  > {
    userIsStaff: boolean;
  }

const initialState: InsightDashboardContextProps = {
  getHistoricalUtrsByCodes: (props: HistoricalUtrsQueryByCodeParams) => Promise.resolve([]),
  duplicateDashboard: () => Promise.resolve({} as InsightDashboard),
  hideFilterOptions: [],
  hideShareButton: false,
  hideQuestionReference: false,
  readOnly: false,
  userIsStaff: false,
};

export const CustomDashboardContext = React.createContext<InsightDashboardContextProps>(initialState);

export const CustomDashboardWrapper = ({
  getHistoricalUtrsByCodes,
  duplicateDashboard,
  hideFilterOptions,
  hideQuestionReference,
  hideShareButton,
  readOnly,
  children,
}: CustomDashboardProps) => {
  // used to put the multiple utrs under staff flag for now 
  const userIsStaff = useAppSelector(isStaff);
  return (
    <CustomDashboardContext.Provider
      value={{
        getHistoricalUtrsByCodes,
        duplicateDashboard,
        hideFilterOptions,
        hideQuestionReference,
        hideShareButton,
        readOnly,
        userIsStaff,
      }}
    >
      {children}
    </CustomDashboardContext.Provider>
  );
};
