import { ReactNode } from 'react';
import { GridDashboardItem } from '../../types/insight-custom-dashboard';
import { DashboardColumn } from '../../components/dashboard';
import { ChartWidget } from './items/charts/ChartWidget';
import { HistoricalUtrs } from '../../api/insights';
import { TextWidget } from './items/texts/TextWidget';
import { isChartType, isSDGTrackerType, isSingleUtrChart, isTableType, isTextOrHeadlineType } from './utils';
import IconButton from '../../components/button/IconButton';
import { UtrvType } from '../../constants/status';
import { Tab } from '../../components/utr-modal/ContentTabs';
import { MultiUtrsChartWidget } from './items/charts/multi-utrs-chart/MultiUtrsChartWidget';
import { QuestionReference } from './question-reference/QuestionReference';
import { SurveyActionData } from '../../model/surveyData';
import { TableWidget } from './items/tables/TableWidget';
import { MultiUtrsChartFooter } from './items/charts/common/MultiUtrsChartFooter';
import { NoData } from './items/charts/common/NoData';
import SimpleTooltip from '@components/simple-tooltip';

const PADDING = 3;

export interface UtrvHistoryModalProps {
  item: GridDashboardItem;
  utrData?: HistoricalUtrs;
  activeTab?: Tab['navId'];
}

export interface GridItemProps {
  item: GridDashboardItem;
  utrsData: HistoricalUtrs[];
  isEditing: boolean;
  onItemRemove?: (item: GridDashboardItem) => void;
  handleClickEdit?: (item: GridDashboardItem) => void;
  handleOpenUtrvHistoryModal: (params: UtrvHistoryModalProps) => void;
  readOnly?: boolean;
  hideQuestionReference?: boolean;
  initiativeId: string;
  survey?: Pick<SurveyActionData, '_id' | 'fragmentUniversalTrackerValues'>;
  actionBtn?: React.ElementType<{ item: GridDashboardItem }>;
}

interface GridItemWrapperProps extends Pick<GridItemProps, 'item' | 'isEditing'> {
  openHistoryModal?: () => void;
  children: ReactNode;
}

const getItemTitle = ({ item, openHistoryModal }: Pick<GridItemWrapperProps, 'item' | 'openHistoryModal'>) => {
  if (!('title' in item) || !item.title) {
    return undefined;
  }
  if (openHistoryModal) {
    return (
      <SimpleTooltip text={item.title}>
        <div style={{ cursor: 'pointer' }} className='d-inline-block dashboard-title' onClick={() => openHistoryModal()}>
          <p className='m-0'>{item.title}</p>
        </div>
      </SimpleTooltip>
    );
  }
  return (
    <SimpleTooltip text={item.title}>
      <div className='d-inline-block dashboard-title'>
        <p className='m-0'>{item.title}</p>
      </div>
    </SimpleTooltip>
  );
};

const GridItemWrapper = ({ item, children, isEditing, openHistoryModal }: GridItemWrapperProps) => {
  const title = getItemTitle({ item, openHistoryModal });
  return (
    <DashboardColumn
      title={title}
      padding={PADDING}
      headerIcon={'icon' in item && item.icon ? <img alt={item.icon} src={item.icon} width={40} height={40} /> : null}
      className={`h-100 ${isEditing ? 'border-0' : ''}`}
    >
      <div className='h-100 d-flex flex-column justify-content-between position-relative'>{children}</div>
    </DashboardColumn>
  );
};

export const GridItem = (props: GridItemProps) => {
  const { isEditing } = props;
  const widget = getWidget(props);

  if (!widget) {
    return null;
  }

  return isEditing ? <EditingWrapper {...props}>{widget}</EditingWrapper> : widget;
};

const getWidget = (props: GridItemProps) => {
  const {
    item,
    utrsData,
    isEditing,
    handleOpenUtrvHistoryModal,
    readOnly = false,
    hideQuestionReference = false,
    initiativeId,
    survey,
    actionBtn: ActionBtn,
  } = props;

  const isChart = isChartType(item);
  const isSDGTracker = isSDGTrackerType(item);

  if (isTextOrHeadlineType(item)) {
    return <TextWidget type={item.type} text={item.text} isEditing={isEditing} />;
  }

  if (isChart || isSDGTracker) {
    const { variables, calculation } = item;
    const variableUtrCodes = Object.values(variables).map(({ code }) => code);
    const filteredUtrsData = utrsData.filter((data) => variableUtrCodes.includes(data.utr.code));
    const noData = filteredUtrsData.every(({ utrvs }) => !utrvs.length);
    const altUnitText = isSDGTracker ? 'contribution' : '';
    const actionButton = ActionBtn ? <ActionBtn item={item} /> : null;
    const handleSetTarget = !isEditing
      ? () => handleOpenUtrvHistoryModal({ item, utrData: filteredUtrsData[0], activeTab: UtrvType.Target })
      : undefined;

    const getQuestionReference = (className: string = '') =>
      !hideQuestionReference && !isSDGTracker ? (
        <QuestionReference
          initiativeId={initiativeId}
          survey={survey}
          utrsCodes={variableUtrCodes}
          utrsData={filteredUtrsData}
          btnClass={className}
        />
      ) : null;

    if (noData) {
      return (
        <GridItemWrapper isEditing={false} item={item}>
          <NoData actionButton={actionButton} questionReference={getQuestionReference()} />
        </GridItemWrapper>
      );
    }

    if (calculation) {
      // It is a single utr single value chart
      const openHistoryModal = isSingleUtrChart(item.subType, calculation)
        ? () => handleOpenUtrvHistoryModal({ item, utrData: filteredUtrsData[0] })
        : undefined;
      return (
        <GridItemWrapper openHistoryModal={openHistoryModal} isEditing={false} item={item}>
          <MultiUtrsChartWidget
            readOnly={readOnly}
            item={item}
            utrsData={filteredUtrsData}
            initiativeId={initiativeId}
            survey={survey}
            handleSetTarget={handleSetTarget}
            altUnitText={altUnitText}
          />
          <MultiUtrsChartFooter
            item={item}
            actionButton={actionButton}
            questionReference={getQuestionReference('text-left')}
          />
        </GridItemWrapper>
      );
    }

    // this is used to support old charts, new charts should take data from calculation and variables
    return (
      <GridItemWrapper
        {...props}
        openHistoryModal={() => handleOpenUtrvHistoryModal({ item, utrData: filteredUtrsData[0] })}
      >
        <ChartWidget
          subType={item.subType}
          utrData={filteredUtrsData[0]}
          selectedColumnCode={Object.values(variables)[0].valueListCode}
          handleSetTarget={handleSetTarget}
          readOnly={readOnly}
          unitText={item.unitText}
          initiativeId={initiativeId}
          survey={survey}
          type={item.type}
          altUnitText={altUnitText}
          hideQuestionReference={hideQuestionReference}
        />
      </GridItemWrapper>
    );
  }

  if (isTableType(item)) {
    const { variables, calculation, title, config } = item;
    const utrsCodes = Object.values(variables).map(({ code }) => code);
    const filteredUtrsData = utrsData.filter((data) => utrsCodes.includes(data.utr.code));

    const openUtrvHistoryModal = (utrCode: string) => {
      const utrData = filteredUtrsData.find(({ utr }) => utr.code === utrCode);

      if (!utrData) {
        return;
      }
      handleOpenUtrvHistoryModal({ item, utrData });
    };

    return (
      <TableWidget
        title={title}
        utrsData={filteredUtrsData}
        variables={variables}
        calculation={calculation}
        onClickUtrValueLabel={openUtrvHistoryModal}
        config={config}
      />
    );
  }

  return null;
};

const EditingWrapper = ({
  children,
  item,
  onItemRemove,
  handleClickEdit,
}: Omit<GridItemProps, 'utrsData'> & { children: ReactNode }) => {
  return (
    <div className='position-relative w-100 h-100 grid-item__editing-wrapper border-dash'>
      <div className='position-absolute shadow-sm rounded background-ThemeBgExtralight editing-action-buttons'>
        <IconButton
          outline={false}
          color='transparent'
          icon='fa-light fa-pencil text-ThemeIconPrimary'
          onClick={() => handleClickEdit?.(item)}
        />
        <IconButton
          outline={false}
          color='transparent'
          icon='fa-light fa-trash-can text-ThemeDangerMedium'
          onClick={() => onItemRemove?.(item)}
        />
      </div>
      {children}
    </div>
  );
};
