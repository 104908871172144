import { Badge } from 'reactstrap';
import { SurveyModelMinimalUtrv } from '../../../model/surveyData';
import { assuranceStatusColumns } from '../overview/constants';

interface QuestionStatusBadgeProps {
  utrv?: SurveyModelMinimalUtrv;
}

export const QuestionStatusBadge = ({ utrv }: QuestionStatusBadgeProps) => {
  if (!utrv || !utrv.assuranceStatus) {
    return null;
  }
  return (
    <div className='ml-2 d-inline-block'>
      <Badge
        color='primary'
        className={'questionContainer__status-badge'}
        pill
      >
        {assuranceStatusColumns[utrv.assuranceStatus]?.text}
      </Badge>
    </div>
  );
};
