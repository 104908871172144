import { useParams } from 'react-router-dom';
import { useGetTemplateByIdQuery } from '../../api/survey-templates';
import { useGetHistoryQuery } from '../../api/survey-templates-history';
import { NotFound } from '../../routes/not-found/NotFound';
import Dashboard, { DashboardSection } from '../dashboard';
import { SubsidiarySelectionTable } from '../downloads/partials/SubsidiarySelectionTable';
import Loader from '../loader';
import { TemplateDashboardHeader } from '../survey-templates/partials/TemplateDashboardHeader';
import { TemplateHistoryHeader } from './TemplateHistoryHeader';
import { useGetSelectedHistory } from './hooks/useGetSelectedHistory';
import { TemplateHistoryNav } from './TemplateHistoryNav';

export const TemplateHistorySubsidiary = () => {
  const { initiativeId = '', templateId = '' } = useParams<{
    initiativeId: string;
    templateId: string;
    historyId: string;
  }>();
  const historyQuery = useGetHistoryQuery(templateId, { skip: !templateId });
  const templateQuery = useGetTemplateByIdQuery(templateId, { skip: !templateId });
  const selectedHistory = useGetSelectedHistory(historyQuery.data);

  if (historyQuery.isFetching || historyQuery.isLoading || templateQuery.isFetching || templateQuery.isLoading) {
    return <Loader />;
  }

  if (!selectedHistory || !templateQuery.data) {
    return <NotFound />;
  }

  return (
    <Dashboard>
      <TemplateHistoryHeader />
      {selectedHistory ? (
        <DashboardSection header={<TemplateDashboardHeader title={`Survey templates: ${templateQuery.data.name}`} />}>
          <TemplateHistoryNav />
          <SubsidiarySelectionTable
            isDisabled
            initiativeId={initiativeId}
            selectedIds={selectedHistory.results.map((result) => result.initiativeId)}
          />
        </DashboardSection>
      ) : (
        <Loader />
      )}
    </Dashboard>
  );
};
