import { useEffect } from 'react';
import { DashboardSectionTitle } from '../../components/dashboard';
import { SdgContributionGraph } from '../../components/impact-performance/SdgContributionGraph';
import { RatingsCarousel } from '../../components/ratings-standards';
import { useAppDispatch } from '../../reducers';
import { loadInsightsByPeriod, loadInsightsBySurveyId } from '../../slice/insightsSlice';
import { MainDownloadCode } from '../../config/app-config';
import { useGetScorecardByInitiativeIdQuery, useGetScorecardBySurveyIdQuery } from '../../api/data-share';
import { RequesterType } from '../../types/dataShare';
import { getDefaultScorecardState } from '../../reducers/scorecard';

interface PortfolioCompanySummaryProps {
  portfolioId: string;
  initiativeId: string;
  surveyId: string;
  selectedPack: MainDownloadCode;
  hasFullScopeAccess: boolean;
}

const getScorecardState = ({ data, isSuccess, isLoading }: { data: any; isSuccess: boolean; isLoading: boolean }) => {
  const state = getDefaultScorecardState();
  if (isSuccess) {
    return {
      ...state,
      loaded: true,
      error: false,
      data: undefined,
      main: {
        data,
        loaded: true,
        loading: false,
      },
    };
  }
  if (isLoading) {
    return state;
  }
  return { ...state, errored: true };
};

export const PortfolioCompanySummary = (props: PortfolioCompanySummaryProps) => {
  const { initiativeId, portfolioId, surveyId, selectedPack, hasFullScopeAccess } = props;

  const dispatch = useAppDispatch();

  const {
    data: scSurvey,
    isSuccess: isScSurveySuccess,
    isFetching: isLoadingScSurvey,
  } = useGetScorecardBySurveyIdQuery(
    {
      initiativeId,
      surveyId,
      requesterId: portfolioId,
      requesterType: RequesterType.Portfolio,
    },
    { skip: !surveyId || !hasFullScopeAccess }
  );

  const {
    data: scInitiative,
    isSuccess: isScInitiativeSuccess,
    isFetching: isLoadingScInitiative,
  } = useGetScorecardByInitiativeIdQuery(
    {
      initiativeId,
      requesterId: portfolioId,
      requesterType: RequesterType.Portfolio,
    },
    { skip: !!surveyId || !hasFullScopeAccess }
  );

  const scorecardState = getScorecardState({
    data: surveyId ? scSurvey : scInitiative,
    isSuccess: surveyId ? isScSurveySuccess : isScInitiativeSuccess,
    isLoading: surveyId ? isLoadingScSurvey : isLoadingScInitiative,
  });

  useEffect(() => {
    if (surveyId) {
      dispatch(loadInsightsBySurveyId(initiativeId, 'insights', surveyId, portfolioId));
    } else {
      // Reload insights, to clear stale data when we don't have surveyId
      dispatch(loadInsightsByPeriod(initiativeId, selectedPack, undefined, portfolioId));
    }
  }, [dispatch, initiativeId, surveyId, portfolioId, selectedPack]);

  const commonProps = {
    initiativeId,
    surveyId,
    readOnly: true,
    showNoItems: true,
  };

  return (
    <div>
      <SdgContributionGraph
        canAccess={false}
        showAITools={false}
        scorecardState={scorecardState}
        readOnly
        surveyId={surveyId}
        initiativeId={initiativeId}
      />
      <DashboardSectionTitle
        title='Media, Ratings and Certifications'
        headingStyle={2}
        className='text-ThemeHeadingLight'
      />
      <RatingsCarousel {...commonProps} options={{ showInfoButton: false }} />
    </div>
  );
};
