import { Button, Input, ModalBody, ModalFooter } from 'reactstrap';
import { ChartSubType, InsightDashboardItemType } from '@g17eco/types/insight-custom-dashboard';
import SimpleTooltip from '@components/simple-tooltip';
import Select, { OptionProps, components } from 'react-select';
import { ChartData } from '../../../utils';
import { ChartAddingModalProps } from './types';
import { RadioInput } from '@components/form/RadioInput';
import { QUESTION } from '@constants/terminology';

const MAX_LENGTH_CHART_TITLE = 50;
const TITLE_TOOLTIP = 'If left blank, title will default to data-point selected.';
const METRICS_TOOLTIP = 'How many metrics do you want to monitor on this chart?';

const chartTypeOptions: {
  value: InsightDashboardItemType.Chart | InsightDashboardItemType.SDGTracker;
  label: string;
}[] = [
  { value: InsightDashboardItemType.Chart, label: 'Survey metrics' },
  { value: InsightDashboardItemType.SDGTracker, label: 'SDG Contribution' },
];

enum MetricCountType {
  Single = 'single',
  Multiple = 'multiple',
}

const metricCountOptions = Object.entries(MetricCountType).map(([key, value]) => ({ code: value, name: key }));

type Props = Pick<ChartAddingModalProps, 'toggle'> & {
  chartData: ChartData;
  changeChartData: (chartData: Partial<ChartData>) => void;
  handleClickSelectDataPoint: () => void;
  isMultiple: boolean;
  setIsMultiple: (isMultiple: boolean) => void;
  userIsStaff: boolean;
};

type SelectOption = {
  value: ChartSubType;
  label: string;
  icon: string;
  isDisabled?: boolean;
};

const Option = (props: OptionProps<SelectOption, false>) => {
  return (
    <components.Option {...props}>
      <div className='d-flex align-items-center gap-2'>
        <i className={`fal ${props.data.icon} text-ThemeIconSecondary`} />
        <div>{props.data.label}</div>
      </div>
    </components.Option>
  );
};

export const SelectChartType = ({
  chartData,
  changeChartData,
  toggle,
  handleClickSelectDataPoint,
  isMultiple,
  setIsMultiple,
  userIsStaff,
}: Props) => {
  const { title, type, subType } = chartData;
  const chartSubTypeOptions: SelectOption[] = [
    { value: ChartSubType.SingleValue, label: 'Single metric', icon: 'fa-input-numeric', isDisabled: isMultiple },
    {
      value: ChartSubType.SparkLine,
      label: 'Single metric with sparkline',
      icon: 'fa-chart-line',
      isDisabled: isMultiple,
    },
    { value: ChartSubType.Line, label: 'Timeline', icon: 'fa-chart-line-up' },
    { value: ChartSubType.Column, label: 'Vertical bar diagram', icon: 'fa-chart-simple', isDisabled: !isMultiple },
    { value: ChartSubType.Bar, label: 'Horizontal bar diagram', icon: 'fa-chart-simple-horizontal', isDisabled: !isMultiple },
    { value: ChartSubType.Pie, label: 'Pie chart', icon: 'fa-chart-pie', isDisabled: !isMultiple },
  ];

  const changeMetricCounts = ({ value }: { value: MetricCountType }) => {
    const subTypeOption = chartSubTypeOptions.find((option) => option.value === subType);
    if (subTypeOption?.isDisabled === false) {
      // if not disabled then must be disabled after changing metric counts
      changeChartData({ subType: undefined, metrics: [] });
    } else {
      changeChartData({ metrics: [] });
    }
    setIsMultiple(value === MetricCountType.Multiple);
  };

  return (
    <>
      <ModalBody>
        <p className=''>Chart selection / Data-point</p>
        <p className='mt-3 text-medium'>
          Title (optional){' '}
          <SimpleTooltip text={TITLE_TOOLTIP} className='text-ThemeIconSecondary'>
            <i className='fal fa-info-circle ml-2' />
          </SimpleTooltip>
        </p>
        <Input
          type='text'
          className='text-md'
          maxLength={MAX_LENGTH_CHART_TITLE}
          value={title}
          onChange={(e) => changeChartData({ title: e.target.value })}
          placeholder='Enter chart title'
        />
        {userIsStaff ? (
          <>
            <p className='mt-3 text-medium'>
              Number of {QUESTION.PLURAL}{' '}
              <SimpleTooltip text={METRICS_TOOLTIP} className='text-ThemeIconSecondary'>
                <i className='fal fa-info-circle ml-2' />
              </SimpleTooltip>
            </p>
            <RadioInput
              groupCode='metric-counts'
              value={isMultiple ? 'multiple' : 'single'}
              options={metricCountOptions}
              onChange={changeMetricCounts}
              className='d-flex'
              formGroupClassName='mr-5'
            />
          </>
        ) : null}
        <p className='mt-3 text-medium'>Source</p>
        <Select
          placeholder={'Select chart source'}
          isClearable={false}
          options={chartTypeOptions}
          value={chartTypeOptions.find((option) => option.value === type) ?? null}
          onChange={(option) => changeChartData({ type: option?.value })}
        />
        <p className='mt-3 text-medium'>Chart type</p>
        <Select
          key={String(subType)}
          placeholder={'Select chart type'}
          isClearable={false}
          components={{ Option }}
          options={chartSubTypeOptions}
          value={chartSubTypeOptions.find((option) => option.value === subType) ?? null}
          onChange={(option) => changeChartData({ subType: option?.value })}
        />
      </ModalBody>
      <ModalFooter className='w-100 d-flex justify-content-end'>
        <div>
          <Button color='link-secondary' onClick={toggle} className='mr-3'>
            Cancel
          </Button>
          <Button color='primary' disabled={!(type && subType)} onClick={handleClickSelectDataPoint}>
            Select data-point
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
