import { useContext, useState } from 'react';
import { deleteMetric } from '../../actions/initiative';
import { useGetInitiativeCustomMetricsQuery } from '../../api/custom-metrics';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import { BasicAlert } from '../alert/BasicAlert';
import { DashboardDivider, DashboardSection, DashboardSectionTitle } from '../dashboard';
import LoadingPlaceholder from '../LoaderContainer/LoadingPlaceholder';
import { CustomMetricContext } from './CustomMetricContainer';
import { CustomMetricManageTable } from './CustomMetricManageTable';
import { DeleteModal } from '../confirm-modal/DeleteModal';
import { EditMetricModal, EditMetricModalProps } from './EditMetricModal';
import { MetricConfig } from './MetricGroupForm';
import SimpleTooltip from '../simple-tooltip';
import { Button } from 'reactstrap';
import { useAppSelector } from '../../reducers';
import { FeaturePermissions } from '../../services/permissions/FeaturePermissions';
import { QUESTION } from '../../constants/terminology';
import { AddonButton } from '../../apps/company-tracker/components/payment-details/AddonButton';
import { FeatureCode } from '@g17eco/core';
import { TrialModal } from '../../apps/company-tracker/components/payment-details/TrialModal';
import {
  CustomMetricUpgradeModal
} from '../../apps/company-tracker/components/custom-metrics/CustomMetricUpgradeModal';
import { isStaff } from '../../selectors/user';
import { CustomMetricImportExport } from './CustomMetricImportExport';
import { ExtraFeature, FeatureStability } from '../../molecules/feature-stability';

interface CustomMetricManageProps {
  initiativeId: string;
  metricConfig: MetricConfig;
  handleReload: () => Promise<void>;
}

const deleteModalProps = {
  title: 'custom metric',
  content: (
    <>
      You have chosen to <span className='text-ThemeDangerDark'>delete</span> the custom metric. It will be deleted
      entirely, including from any metric groups it is in, and cannot be recovered.
    </>
  ),
};

export const CustomMetricManage = ({ initiativeId, metricConfig, handleReload }: CustomMetricManageProps) => {
  const {
    data: customMetrics,
    isFetching,
    error,
    refetch,
  } = useGetInitiativeCustomMetricsQuery(initiativeId, {
    skip: !initiativeId,
  });
  const isUserStaff = useAppSelector(isStaff);
  const { addSiteAlert, addSiteError } = useSiteAlert();
  const { blueprintQuestions } = useContext(CustomMetricContext);
  const [deleteUtrId, setDeleteUtrId] = useState<string>('');
  const [editUtrId, setEditUtrId] = useState<string>('');
  const metricLimit = useAppSelector(FeaturePermissions.getLimitCustomMetric);
  const [isCreating, setIsCreating] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const globalData = useAppSelector(state => state.globalData);

  if (!globalData.loaded) {
    return <LoadingPlaceholder height={300} isLoading={true} />
  }

  const hasHitQuestionLimit = metricConfig.count && metricLimit && metricConfig.count >= metricLimit;

  const handleOpenEditUtr = (utrId: string) => {
    setEditUtrId(utrId);
  };

  const handleCancelEditUtr = () => {
    setEditUtrId('');
    setIsCreating(false);
  };

  const handleOpenDeleteUtr = (utrId: string) => {
    setDeleteUtrId(utrId);
  };

  const handleCancelDeleteUtr = () => {
    setDeleteUtrId('');
  };

  const handleReloadCustomMetrics = async () => {
    await handleReload();
    refetch();
  };

  const handleDeleteUtr = () => {
    if (!deleteUtrId) {
      return;
    }
    deleteMetric(initiativeId, deleteUtrId)
      .then(() => {
        addSiteAlert({
          content: (
            <>
              <strong>Success!</strong> Custom metric has been removed
            </>
          ),
          color: SiteAlertColors.Primary,
        });
      })
      .catch((e) => addSiteError(e))
      .finally(() => {
        handleCancelDeleteUtr();
        handleReloadCustomMetrics();
      });
  };

  const editMetricModalProps: EditMetricModalProps = {
    isOpen: Boolean(editUtrId) || isCreating,
    editUtrId: editUtrId,
    questions: blueprintQuestions,
    handleReload: handleReloadCustomMetrics,
    handleCancel: handleCancelEditUtr,
    metricConfig,
  };

  const getCustomMetricManageTableProps = (hasUniversalTrackerValues: boolean = false) => ({
    handleOpenEditUtr,
    handleOpenDeleteUtr,
    questions: hasUniversalTrackerValues ? customMetrics?.used : customMetrics?.created,
    hasUniversalTrackerValues,
    total: (customMetrics?.used.length || 0) + (customMetrics?.created.length || 0),
  });

  const handleGoToImportExport = () => setIsImporting(true);

  if (isImporting) {
    const onComplete = async () => {
      setIsImporting(false);
      handleReloadCustomMetrics();
    }
    return <CustomMetricImportExport
      initiativeId={initiativeId}
      onCancel={() => setIsImporting(false)}
      onComplete={onComplete} />;
  }

  return (
    <>
      <DashboardSectionTitle
        title='Manage custom metrics'
        buttons={[
          <AddonButton
            color={'primary'}
            outline={true}
            rootOrg={globalData.organization}
            appConfig={globalData.data.appConfig}
            featureCode={FeatureCode.CustomMetrics}
            TrialModalComponent={({ toggle }) => (
              <TrialModal toggle={toggle} initiativeId={globalData.organization._id}>
                During the free trial, the option to buy additional custom metrics is not available.
                To buy more custom metrics, first add credit card details or another payment method.
              </TrialModal>
            )}
            UpgradeModal={CustomMetricUpgradeModal}
          >
            Buy +10 metrics
          </AddonButton>,
          isUserStaff ? <Button onClick={handleGoToImportExport}>Import/Export <FeatureStability feature={ExtraFeature.CustomMetricImports} /></Button> : <></>
        ]}
      />
      <div className='col-12 px-3 mb-3'>
        <div className='d-flex justify-content-end align-items-center'>
          <div>Can’t find your {QUESTION.SINGULAR}:</div>
          <SimpleTooltip
            text={hasHitQuestionLimit ? 'Limit reached. Please upgrade if more than custom metrics are needed' : ''}
          >
            <Button color={'secondary'} outline disabled={!!hasHitQuestionLimit} className='ml-3' onClick={() => setIsCreating(prev => !prev)}>
              Create a custom {QUESTION.SINGULAR}
            </Button>
          </SimpleTooltip>
        </div>
      </div>
      {error ? (
        <BasicAlert type='danger'>{error.message}</BasicAlert>
      ) : (
        <DashboardSection>
          <LoadingPlaceholder count={2} height={37} className='mt-1 background-ThemeTextWhite' isLoading={isFetching}>
            <div className='h5 flex-fill mt-0'>Never used</div>
            <CustomMetricManageTable {...getCustomMetricManageTableProps()} />
            <DashboardDivider />
            <div className='h5 flex-fill mt-3'>Has been used previously</div>
            <CustomMetricManageTable {...getCustomMetricManageTableProps(true)} />
          </LoadingPlaceholder>
        </DashboardSection>
      )}

      <EditMetricModal {...editMetricModalProps} />
      <DeleteModal
        {...deleteModalProps}
        isOpen={Boolean(deleteUtrId)}
        handleDelete={handleDeleteUtr}
        handleCancel={handleCancelDeleteUtr}
      />
    </>
  );
};
