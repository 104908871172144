import { useHistory } from 'react-router-dom';
import {
  useCreatePortfolioInsightDashboardMutation,
  useGetInsightDashboardsByPortfolioQuery,
} from '../../../../../api/portfolio-insight-dashboards';
import { ROUTES } from '../../../../../constants/routes';
import { useAppSelector } from '../../../../../reducers';
import { isStaff } from '../../../../../selectors/user';
import { generateUrl } from '../../../../util';
import { AddDashboardOption, InsightDashboardOption, getDashboardOption } from '../../utils/sidebar';
import { InsightsSidebar } from './InsightsSidebar';
import { useSiteAlert } from '../../../../../hooks/useSiteAlert';
import Loader from '@components/loader';

interface PortfolioInsightsSidebarProps {
  portfolioId: string;
  dashboardId?: string;
}

export const PortfolioInsightsSidebar = ({ portfolioId, dashboardId }: PortfolioInsightsSidebarProps) => {
  const history = useHistory();

  const isUserStaff = useAppSelector(isStaff);
  const addDashboardOption = isUserStaff ? getDashboardOption(true) : undefined;
  const { addSiteError } = useSiteAlert();

  const { data: dashboards, isFetching } = useGetInsightDashboardsByPortfolioQuery(portfolioId, { skip: !isUserStaff });
  const [createInsightDashboard, { isLoading }] = useCreatePortfolioInsightDashboardMutation();

  const dashboardOptions: InsightDashboardOption[] = (dashboards || []).map((dashboard) => ({
    value: dashboard._id,
    label: dashboard.title,
    isCustom: true,
  }));

  const options: (InsightDashboardOption | AddDashboardOption)[] = [
    {
      label: 'SDG Insights',
      value: '',
    },
    ...dashboardOptions,
    ...(addDashboardOption ? [addDashboardOption] : []),
  ];

  const handleAddDashboard = async () => {
    createInsightDashboard({ initiativeId: portfolioId, title: 'Custom Dashboard' })
      .unwrap()
      .then((dashboard) => {
        handleNavigateCustom(dashboard._id);
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  const handleNavigate = () => {
    const url = generateUrl(ROUTES.PORTFOLIO_TRACKER_INSIGHTS, { portfolioId });
    history.push(url);
  };

  const handleNavigateCustom = (dashboardId: string) => {
    const url = generateUrl(ROUTES.PORTFOLIO_TRACKER_INSIGHT_DASHBOARDS, { portfolioId, dashboardId });
    history.push(url);
  };

  const handleClickOption = (item: InsightDashboardOption | AddDashboardOption) => () => {
    if (item.disabled) {
      return;
    }
    if (isUserStaff && item.value === addDashboardOption?.value) {
      return handleAddDashboard();
    }
    if (isUserStaff && item.isCustom) {
      return handleNavigateCustom(item.value);
    }
    return handleNavigate();
  };

  const currentPage = dashboardId ?? '';

  return (
    <>
      {isLoading || isFetching ? <Loader /> : null}
      <InsightsSidebar options={options} currentPage={currentPage} handleClickOption={handleClickOption} />
    </>
  );
};
