import { GridDashboardChartItem } from '../../../../../types/insight-custom-dashboard';
import { FormulaVariables, tryCalculation } from '../../../../../utils/formula';

interface Props {
  note: GridDashboardChartItem['note'];
  variablesWithValues: FormulaVariables;
  className?: string;
}

export const Note = ({ note, variablesWithValues, className = 'text-ThemeTextPlaceholder' }: Props) => {
  const haveNote = note && Object.values(note).some((field) => !!field);

  if (!haveNote) {
    return null;
  }

  const { prefix = '', value = '', valueUnit = '', postfix = '' } = note;

  return (
    <div className={`text-sm ${className}`}>
      {prefix}
      <span className='text-ThemeAccentExtradark'>
        {value ? ` ${tryCalculation({ formula: value, variables: variablesWithValues, fallback: '' })}` : ''}
        {value && valueUnit ? ` ${valueUnit}` : ''}
      </span>
      {postfix ? ` ${postfix}` : ''}
    </div>
  );
};
