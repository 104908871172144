import Select from 'react-select';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Tree } from '../../../../types/tree';
import { InitiativePlain } from '../../../../types/initiative';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveReportingLevelDetails } from '../../../../actions/initiative';
import { useSiteAlert } from '../../../../hooks/useSiteAlert';
import { getBranchInitiativeNameText, getSubsidiaryOptions } from '../../utils';

interface MoveSubsidiaryModalProps {
  isOpen: boolean;
  toggle: () => void;
  initiativeId: string | undefined;
  initiativeTree: Tree;
  initiativeTreeList: InitiativePlain[];
  handleUpdate: () => void;
}

interface FormState {
  parentId: undefined | string;
  hasAgreed: boolean;
}

const initialState = {
  parentId: undefined,
  hasAgreed: false,
};

export const MoveSubsidiaryModal = ({
  isOpen,
  initiativeId,
  initiativeTree,
  initiativeTreeList,
  toggle,
  handleUpdate,
}: MoveSubsidiaryModalProps) => {
  const [form, setForm] = useState<FormState>(initialState);
  const dispatch = useDispatch();
  const { addSiteAlert, addSiteError } = useSiteAlert();

  if (!initiativeId) {
    return null;
  }

  const options = getSubsidiaryOptions({ fullTreeList: initiativeTreeList, excludedBranchRootId: initiativeId });

  const canSubmit = Object.values(form).every((value) => value);

  const onClickSubmit = () => {
    if (!canSubmit) {
      return;
    }

    dispatch(saveReportingLevelDetails(initiativeId, { parentId: form.parentId }))
      .then(() => {
        addSiteAlert({
          content: 'Move subsidiary successfully!',
        });
      })
      .catch((e) => {
        addSiteError(e);
      })
      .finally(() => {
        onCloseModal();
        handleUpdate();
      });
  };

  const onCloseModal = () => {
    setForm(initialState);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={onCloseModal} backdrop='static' className='initiative-modal'>
      <ModalHeader toggle={onCloseModal}>Move subsidiary</ModalHeader>
      <ModalBody className='pb-0'>
        <div className='text-strong mb-2'>Current parent</div>
        <div>{getBranchInitiativeNameText({ initiativeTree, initiativeId })}</div>
        <div className='text-strong mt-4 mb-2'>New parent</div>
        <Select
          placeholder={'Select new parent subsidiary'}
          isClearable={true}
          options={options}
          value={options.find((op) => op.value === form?.parentId)}
          onChange={(op) => setForm((prev) => ({ ...prev, parentId: op?.value || undefined }))}
        />
        <div className='mt-1'>
          {getBranchInitiativeNameText({ initiativeTree, initiativeId: form.parentId, showInitiativeId: true })}
        </div>
        <FormGroup check className='mt-4'>
          <Input
            type='checkbox'
            className='mt-4'
            onClick={() => setForm((prev) => ({ ...prev, hasAgreed: !prev.hasAgreed }))}
          />
          <Label check>
            I accept that by moving this subsidiary, any of it’s children will move with it, or my user permissions /
            auto-aggregation may change. Combined survey data will not be impacted.
          </Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button className='mr-2' color='link-secondary' onClick={onCloseModal}>
          Cancel
        </Button>
        <Button color='primary' disabled={!canSubmit} onClick={onClickSubmit}>
          Move subsidiary
        </Button>
      </ModalFooter>
    </Modal>
  );
};


