import { useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  DefaultSurveyConfigResponse,
  useGetDefaultSurveyConfigQuery,
  useUpdateDefaultSurveyConfigMutation,
} from '../../api/default-survey-config';
import { useAppSelector } from '../../reducers';
import { getRootOrg, currentInitiative, checkIsBottomOfInitiativeTree } from '../../selectors/initiative';
import { getCheckOptions } from '../../utils/survey';
import { InfoIcon } from '../common/InfoIcon';
import { updateFn } from '../form/common';
import Loader from '../loader';
import { SurveyQuestionForm as QuestionRequirements } from '../survey-configuration/partials/SurveyQuestionForm';
import SurveyUnitsAndCurrency from '../survey-configuration/partials/SurveyUnitsAndCurrency';
import { Container } from './Container';
import './style.scss';
import { UnitConfig } from '../../model/surveyData';
import { FeaturePermissions } from '../../services/permissions/FeaturePermissions';
export const DefaultSurveyConfigContainer = () => {
  const rootOrg = useAppSelector(getRootOrg);
  const initiative = useAppSelector(currentInitiative);
  const isBottomOfInitiativeTree = useAppSelector(checkIsBottomOfInitiativeTree);
  const { data: defaultSurveyConfig, isLoading } = useGetDefaultSurveyConfigQuery({
    initiativeId: initiative?._id || '',
  });

  if (!rootOrg || !initiative || isLoading || !defaultSurveyConfig) {
    return <Loader />;
  }

  const props = {
    defaultSurveyConfig,
    isBottomOfInitiativeTree,
    isRootOrg: rootOrg._id === initiative._id,
    initiativeId: initiative._id,
  };
  return <DefaultSurveyConfig {...props} />;
};

interface DefaultSurveyConfigProps {
  defaultSurveyConfig: DefaultSurveyConfigResponse;
  isBottomOfInitiativeTree: boolean;
  isRootOrg: boolean;
  initiativeId: string;
}

const DefaultSurveyConfig = ({
  defaultSurveyConfig,
  isBottomOfInitiativeTree,
  isRootOrg,
  initiativeId,
}: DefaultSurveyConfigProps) => {
  const isEnforced = !!defaultSurveyConfig.isEnforced;

  const [isLoading, setIsLoading] = useState(false);
  const [isEnforcementConfirming, setIsEnforcementConfirming] = useState<boolean>(false);
  const canAccessVerification = useAppSelector(FeaturePermissions.canAccessVerification);

  const [config, setConfig] = useState(defaultSurveyConfig);
  const { subsidiariesEnforced, verificationRequired, evidenceRequired, noteRequired, isPrivate, unitConfig } = config;
  const requirements = {
    verificationRequired: canAccessVerification && verificationRequired,
    evidenceRequired,
    noteRequired,
    isPrivate,
  };

  const [updateDefaultSurveyConfig] = useUpdateDefaultSurveyConfigMutation();

  if (isLoading) {
    return <Loader />;
  }

  const onClickEnforce = () => {
    setConfig((cur) => ({ ...cur, subsidiariesEnforced: true }));
    setIsEnforcementConfirming(false);
  };

  const questionRequirementsProps = {
    disabled: false,
    isCheckboxDisabled: isEnforced,
    checkOptions: getCheckOptions(canAccessVerification),
    form: requirements,
    updateForm: ((update) => setConfig((cur) => ({ ...cur, [update.code]: update.value }))) as updateFn,
  };

  const surveyUnitsAndCurrencyProps = {
    unitConfig,
    setUnitConfig: (value: UnitConfig) => setConfig((cur) => ({ ...cur, unitConfig: value })),
    isDisabled: isEnforced,
  };

  const onClickCancel = () => {
    setConfig(defaultSurveyConfig);
  };
  const onClickUpdate = async () => {
    setIsLoading(true);
    await updateDefaultSurveyConfig({
      initiativeId,
      config: {
        ...requirements,
        subsidiariesEnforced,
        unitConfig,
      },
    });
    setIsLoading(false);
  };

  return (
    <Container>
      <>
        <h5 className='default-survey-config__header'>Default survey configuration</h5>
        <p>
          Changing your default survey configuration settings will update the default settings you see when creating any
          new surveys for this reporting level.
        </p>
        <FormGroup switch className='d-flex align-items-center pl-0'>
          <Input
            type='switch'
            checked={subsidiariesEnforced}
            id='enforce-config'
            className='text-md m-0'
            onChange={() =>
              subsidiariesEnforced
                ? setConfig((cur) => ({ ...cur, subsidiariesEnforced: false }))
                : setIsEnforcementConfirming(true)
            }
            disabled={isEnforced || isBottomOfInitiativeTree}
          />
          <Label className='text-sm ml-1' check>
            Enforce configuration on all subsidiaries
          </Label>
          <InfoIcon
            text={
              'Enforcing configuration on all subsidiaries means every subsidiaries in your company will have the following settings assigned by default when they create a new survey.'
            }
            className='text-sm'
          />
        </FormGroup>
        <QuestionRequirements {...questionRequirementsProps} />
        <SurveyUnitsAndCurrency {...surveyUnitsAndCurrencyProps} />
        <div className='float-end'>
          <Button color='link-secondary' onClick={onClickCancel} className='mr-3' disabled={isEnforced}>
            Cancel
          </Button>
          <Button color='primary' className='px-5' onClick={onClickUpdate} disabled={isEnforced}>
            Update
          </Button>
        </div>
        <Modal isOpen={isEnforcementConfirming} toggle={() => setIsEnforcementConfirming(false)} backdrop='static'>
          <ModalHeader toggle={() => setIsEnforcementConfirming(false)}>Manage Assurance</ModalHeader>
          <ModalBody>
            Enforcing this configuration will overwrite the default survey rules on all subsidiaries (impacts new
            surveys only).
          </ModalBody>
          <ModalFooter>
            <Button color='link' onClick={() => setIsEnforcementConfirming(false)}>
              Cancel
            </Button>
            <Button color='primary' onClick={() => onClickEnforce()}>
              Enforce
            </Button>
          </ModalFooter>
        </Modal>
      </>
    </Container>
  );
};
