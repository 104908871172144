import { useSiteAlert } from '../../hooks/useSiteAlert';
import G17Client from '../../services/G17Client';
import IconButton from '../button/IconButton';

interface Props {
  initiativeId: string;
  utrId: string;
}

export const DownloadUtrvHistoryButton = ({ initiativeId, utrId }: Props) => {
  const { addSiteError } = useSiteAlert();
  const downloadUtrvHistory = async () => {
    if (!initiativeId) {
      return;
    }
    return G17Client.downloadUtrvHistory(initiativeId, utrId).catch((e) => {
      addSiteError(e);
    });
  };

  return <IconButton onClick={downloadUtrvHistory} outline={false} color='transparent' icon='fal fa-file-excel' />;
};
