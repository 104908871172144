import { SurveyModelMinimalUtrv } from '../../../model/surveyData';
import { UtrvStatus } from '../../../constants/status';
import { heading2, spacer } from '../document-structure';
import { ScopeGroupHistoricalData } from './sustainability-report-generator';
import { VisibilityStatus } from '../../../types/download';
import { getGroup } from '@g17eco/core';
import { generateReportData, generateSubGroups, sortData } from '../utils';

interface GenerateScopeGroupsParams {
  scopeGroupData: ScopeGroupHistoricalData[];
  targetMap: Map<string, SurveyModelMinimalUtrv>;
  visibilityStatus: VisibilityStatus;
  displayUserInput?: boolean;
}

export function generateScopeGroups({ scopeGroupData, targetMap, visibilityStatus, displayUserInput = false }: GenerateScopeGroupsParams) {
  const scopeGroupTables = scopeGroupData.map((scopeGroup) => {
    // Ensure 3 max
    const historicalData = scopeGroup.questionData.slice(0, 3).map(historicalSurveyData => {
      return {
        effectiveDate: historicalSurveyData.effectiveDate,
        unitConfig: historicalSurveyData.unitConfig,
        reportData: historicalSurveyData.reportData.filter(utrv => {
          return utrv.status !== UtrvStatus.Created;
        })
      };
    })


    const [latestHistorical] = historicalData;
    if (!latestHistorical) {
      return []
    }

    const dataASC = historicalData.slice().reverse();

    // Data already filtered by type at this point, we are modifying the underlying array. Seems OK
    const scopeGroupCode = scopeGroup.code;

    const subGroups = getGroup('standards-and-frameworks', scopeGroupCode)?.subgroups || [];

    const multiDateRendererProps = {
      dataASC,
      targetMap,
      preferredAltCodes: [scopeGroupCode],
      visibilityStatus,
      displayUserInput: displayUserInput,
    };

    const data = !!subGroups.length
      ? generateSubGroups({
          subGroups,
          data: latestHistorical.reportData,
          scopeGroupCode,
          ...multiDateRendererProps,
        })
      : generateReportData({
          data: sortData(latestHistorical.reportData, scopeGroupCode),
          ...multiDateRendererProps,
          scopeGroupCode
        });
    
    return data.length > 0 ? [heading2(scopeGroup.name), ...data, spacer()] : [];
  }).flat();

  if (scopeGroupTables.length === 0) {
    return [];
  }

  return scopeGroupTables;
}
