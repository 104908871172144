import { DecimalType, ErrorDecimalType } from '../components/utr-decimal/DecimalButton';
import { ValueValidation } from './universalTracker';

export interface InitiativeUniversalTracker {
  _id: string;
  initiativeId: string;
  universalTrackerId: string;
  valueValidation: ValueValidation;
}

export interface DecimalInputProps {
  decimal: DecimalType;
  error: ErrorDecimalType;
  disabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export enum DecimalInputType {
  SingleInput = 'single-input',
  Table = 'table',
}
