interface Props {
  questionReference?: JSX.Element | null;
  actionButton?: JSX.Element | null;
}

export const NoData = (props: Props) => {
  const { questionReference = null, actionButton } = props;
  return (
    <div className='w-100 h-100 d-flex flex-column justify-content-between'>
      {!questionReference ? (
        <p className='text-sm text-medium'>No available data</p>
      ) : (
        <div className='flex-grow-1 d-flex flex-column justify-content-center align-items-center'>
          <span className='text-ThemeTextPlaceholder'>To see data, answer:</span>
          {questionReference}
        </div>
      )}
      {actionButton ? <div className='text-right'>{actionButton}</div> : null}
    </div>
  );
};
