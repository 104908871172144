import Chart from 'react-google-charts';
import { HistoricalUtrs } from '../../../../../api/insights';
import {
  CalculationType,
  ChartDataLoaded,
  GridDashboardChartItem
} from '../../../../../types/insight-custom-dashboard';
import { tryCalculation } from '@utils/formula';
import { getChartOptions } from '../../../../summary/insights/partials/GenericChart';
import { chartStyleNames } from '../../../../summary/insights/utils/chartStyles';
import { ChartType } from '../../../../summary/insights/utils/constants';
import { TakenFromText } from '../common/TakenFromText';
import { getLatestActualUtrvs, getVariablesWithValues } from './utils';
import { processCalculation } from '@services/aggregation/stageAggregation';
import { useEffect, useState } from 'react';
import { getLatestActualHistoricalUtrs } from '@services/aggregation/utrData';

type Props = Pick<GridDashboardChartItem, 'calculation' | 'variables'> & {
  utrsData: HistoricalUtrs[];
  hasLegend: boolean;
};

type ChartColumn = string | number;
type ChartDataResponse = ChartDataLoaded<ChartColumn>;

const getChartData = async ({ utrsData, variables, calculation }: Required<Omit<Props, 'hasLegend'>>): Promise<ChartDataResponse> => {

  if (calculation.type === CalculationType.Stages) {
    const { effectiveDate, period, latestUtrsData } = getLatestActualHistoricalUtrs(utrsData);
    const chartData: ChartColumn[][] = [
      ['Key', 'Value'],
      ...await Promise.all(calculation.values.map(async (value) => [
        value.name,
        await processCalculation({
          variables,
          utrsData: latestUtrsData,
          stages: value.stages,
          fallback: 0
        }),
      ])),
    ];
    return { chartData, effectiveDate, period };
  }

  const { latestActualUtrvs = [], effectiveDate, period } = getLatestActualUtrvs(utrsData);
  const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs: latestActualUtrvs });
  const chartData = [
    ['Key', 'Value'],
    ...calculation.values.map((value) => [
      value.name,
      tryCalculation({ formula: value.formula ?? '', variables: variablesWithValues, fallback: 0 }),
    ]),
  ];
  return { chartData, effectiveDate, period };
};

export const PieChart = ({ utrsData, variables, calculation, hasLegend }: Props) => {
  const [data, setData] = useState<ChartDataResponse|undefined>();

  useEffect(() => {
    if (calculation) {
      getChartData({ utrsData, variables, calculation })
        .then(data => setData(data))
    }
  }, [calculation, utrsData, variables]);

  if (!utrsData.length || !variables || !calculation || !data) {
    return null;
  }

  const { chartData, effectiveDate = '', period } = data;

  return (
    <>
      <div className='w-100 h-100'>
        <Chart
          chartType={ChartType.PieChart}
          data={chartData}
          width={'100%'}
          height={'100%'}
          options={getChartOptions({
            chartType: ChartType.PieChart,
            options: hasLegend ? chartStyleNames.chartStylePieLegend : undefined,
          })}
        />
      </div>
      <TakenFromText effectiveDate={effectiveDate} period={period} />
    </>
  );
};
