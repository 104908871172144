import { useMemo } from 'react';
import { RequestScope } from '../../../actions/api';
import { Scope, ScopeGroups } from '../../../model/surveyData';
import { MetricGroup } from '../../../types/metricGroup';
import { CustomSelectOption } from '../../select-packs';
import { getRequestScopeExtended } from '../../../utils/requestScope';
import { mergeScopes } from '../../../utils/dataShare';

interface InitiativeRequestScopes {
  metricGroups: MetricGroup[];
  initiativeCombinedScope: Scope | undefined;
  requestScopes: RequestScope[];
}

export const useInitiativeRequestScopes = ({
  metricGroups,
  initiativeCombinedScope,
  requestScopes,
}: InitiativeRequestScopes) => {
  const initiativeRequestScopes = useMemo(() => {
    if (!initiativeCombinedScope) {
      return [];
    }

    const groupOptions = metricGroups.reduce((acc, cur) => {
      acc[cur._id] = { name: cur.groupName, src: cur.groupData?.icon };
      return acc;
    }, {} as CustomSelectOption);

    // filter out packs in initiative combined scope and current data share
    const allScopes = getRequestScopeExtended(groupOptions);
    const mergedScope = mergeScopes(initiativeCombinedScope, requestScopes);
    return allScopes.filter((scope) => {
      return mergedScope[scope.scopeType as ScopeGroups].has(scope.code);
    });
  }, [metricGroups, initiativeCombinedScope, requestScopes]);

  return initiativeRequestScopes;
};
