import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import CONFIG from '../../../../config';

export const deleteAllNotifications = async (userKey: string, userExternalId: string | undefined) => {
  const option: AxiosRequestConfig = {
    method: 'DELETE',
    baseURL: CONFIG.magicBellNotificationURL,
    headers: {
      'X-MAGICBELL-API-KEY': CONFIG.magicBellApiKey,
      'X-MAGICBELL-USER-HMAC': userKey,
      'X-MAGICBELL-USER-EXTERNAL-ID': String(userExternalId),
    },
  };
  try {
    return await axios(option);
  } catch (err) {
    const error = err as Error | AxiosError;
    throw error;
  }
};
