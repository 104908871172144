import { frameworks, standards } from '@g17eco/core';
import { useCallback, useMemo } from 'react';
import Select, { createFilter, MultiValue, SingleValue, StylesConfig } from 'react-select';
import { SelectOption } from '../../types/select';
import { RequestScope } from '../../actions/api';
import { getRequestScopeExtended } from '../../utils/requestScope';

export const getStyles = (): StylesConfig<any> => {
  const ravenGrey = 'var(--theme-TextDark)';
  return {
    container: (provided) => ({
      ...provided,
      flexGrow: 1,
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 32,
      height: 32,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
    }),
    input: (provided) => ({
      ...provided,
      color: ravenGrey,
      width: 120,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: ravenGrey,
      padding: '4px 0px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: ravenGrey,
      padding: '4px 10px',
    }),
  };
};

export type CustomSelectOption = Record<string, { name: string; src?: string; hidden?: boolean }>;
export interface SelectPackOption extends SelectOption<string> {
  scopeType: string
}

interface SelectPacksProps {
  selectedScopes?: string[];
  groupOptions?: CustomSelectOption;
  handleChangePacks: (scopes: RequestScope[]) => void;
  isDisabled?: boolean;
  displayIcon?: boolean;
  placeholder?: string;
}

export const SelectPacks = (props: SelectPacksProps) => {
  const { selectedScopes = [], groupOptions, handleChangePacks, isDisabled, displayIcon = true, placeholder } = props;

  const allRequestScopes = useMemo(() => getRequestScopeExtended(groupOptions), [groupOptions]);

  const onChangePack = useCallback(
    (selectedOption: RequestScope) => {
      const scopeId = selectedOption?.code;
      const newSelectedScopes = selectedScopes.includes(scopeId)
        ? selectedScopes.filter((code) => code !== scopeId)
        : [...selectedScopes, scopeId];
      handleChangePacks(allRequestScopes.filter((item) => newSelectedScopes.includes(item.code)));
    },
    [handleChangePacks, allRequestScopes, selectedScopes]
  );

  const filterOption = createFilter<SelectOption>({
    stringify: (option) => `${(option.data as SelectOption).searchString ?? option.label}`,
  });

  const getPackOptions = useCallback(
    (packs: CustomSelectOption, scopeType: string) => {
      return Object.keys(packs).reduce((acc, key: string) => {
        if (packs[key].hidden) {
          return acc;
        }
        const option = {
          value: key,
          searchString: `${key} ${packs[key]?.name ?? ''}`,
          scopeType,
          label: (
            <div className='d-flex align-items-center'>
              <input
                type={'checkbox'}
                className='mr-2'
                onChange={() => onChangePack({ scopeType, code: key })}
                checked={selectedScopes.includes(key)}
              />
              {displayIcon && packs[key].src ? (
                <img src={packs[key].src} alt={packs[key].name} height='26px' className='mr-1' />
              ) : null}
              <span>{packs[key].name}</span>
            </div>
          ),
        };
        return [...acc, option];
      }, [] as SelectPackOption[]);
    },
    [onChangePack, selectedScopes, displayIcon]
  );

  const isMultiValue = (selectedOption: MultiValue<SelectPackOption> | SingleValue<SelectPackOption>): selectedOption is MultiValue<SelectPackOption> => {
    return Array.isArray(selectedOption)
  }

  const onChange = (selectedOption: MultiValue<SelectPackOption> | SingleValue<SelectPackOption>) => {
    if (!selectedOption) {
      return
    }

    if (!isMultiValue(selectedOption)) {
      onChangePack({ scopeType: selectedOption.scopeType, code: selectedOption.value });
      return
    }
    // we know this is array, but can still be empty (backspace cause clear...)
    const [firstItem] = selectedOption;
    if (firstItem) {
      onChangePack({ scopeType: firstItem.scopeType, code: firstItem.value });
    }
  };

  const packs = useMemo(
    () => [
      ...getPackOptions(standards, 'standards'),
      ...getPackOptions(frameworks, 'frameworks'),
      ...(groupOptions ? getPackOptions(groupOptions, 'custom') : []),
    ],
    [getPackOptions, groupOptions]
  );

  return (
    <Select<SelectPackOption, true>
      placeholder={`${placeholder} (${selectedScopes.length})`}
      styles={getStyles()}
      isMulti
      closeMenuOnSelect={false}
      filterOption={filterOption}
      value={[]}
      options={packs}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  );
};
