import { GridLayoutDashboard } from '../../custom-dashboard/GridLayoutDashboard';
import { GridDashboardItem, InsightDashboard } from '../../../types/insight-custom-dashboard';
import { DeleteFromOverviewBtn } from './DeleteFromOverviewBtn';
import { useSelector } from 'react-redux';
import { selectMostRecentSurvey } from '../../../slice/initiativeSurveyListSlice';
import { useGetSurveyByIdQuery } from '../../../api/surveys';
import Loader from '../../../components/loader';
import { CompanyProfile } from '../../../components/company-profile';
import { DashboardSectionTitle } from '../../../components/dashboard';
import { InsightsOverview } from '../insights/overview';
import TargetActual from '../../../components/target-actual';
import { UniversalTrackersTable } from '../../../components/universal-trackers-table';
import MediaSlider from '../../../components/documents/media-slider';
import { RatingsCarousel, StandardsCarousel } from '../../../components/ratings-standards';
import { useAppDispatch, useAppSelector } from '../../../reducers';
import { useCallback, useEffect, useState } from 'react';
import { loadScorecardsByInitiativeId, loadScorecardsBySurveyId } from '../../../actions/scorecard';
import { TABS } from '../../../components/target-actual/TargetActualModal';
import { getSummaryUtrTable } from '../../../selectors';
import { SUMMARY_DASHBOARD_UTR_TABLE } from '../../../constants/usage-slots';
import { getRootOrg } from '../../../selectors/initiative';
import { getMainDownloadCode } from '../../../config/app-config';
import { loadUniversalTrackerValues } from '../../../actions/universalTracker';
import { useToggle } from '../../../hooks/useToggle';
import { ReportSwitcherContainer } from '../../../components/initiative/ReportSwitcherContainer';
import { InitiativeData } from '../../../types/initiative';
import { useAppSettings } from '@hooks/app/useAppSettings';

interface Props {
  initiative: InitiativeData | undefined;
  dashboard: InsightDashboard;
  initiativeId: string;
}

export const Overview = ({ initiative, dashboard, initiativeId }: Props) => {
  const mostRecentSurvey = useSelector(selectMostRecentSurvey);
  const { data: latestSurvey, isFetching } = useGetSurveyByIdQuery(mostRecentSurvey?._id ?? '', {
    skip: !mostRecentSurvey?._id,
  });

  const surveyId = mostRecentSurvey?._id; // TODO: Set surveyId from dropdown / route
  const utrTable = useAppSelector(getSummaryUtrTable);
  const { canEditInsightOverview } = useAppSettings();
  const readOnly = !canEditInsightOverview;
  const utTableUsage = SUMMARY_DASHBOARD_UTR_TABLE;
  const rootInitiative = useAppSelector(getRootOrg);
  const mainDownloadCode = getMainDownloadCode(rootInitiative?.appConfigCode, rootInitiative?.permissionGroup);
  const [sdgCode, setSDGCode] = useState('');
  const [sdgChartSurveyId, setSdgChartSurveyId] = useState<string>();

  const handleSDGChange = useCallback(
    (sdgCodes) => {
      setSDGCode(Array.isArray(sdgCodes) ? sdgCodes[0] : sdgCodes);
    },
    [setSDGCode]
  );

  const handleSurveyChange = (value: string) => {
    setSdgChartSurveyId(value === 'current' ? surveyId : value);
  };

  const reportSwitcher = (
    <div className='d-flex justify-content-end'>
      <ReportSwitcherContainer
        initiativeId={initiativeId}
        selectedSurveyId={sdgChartSurveyId ?? surveyId}
        onChange={handleSurveyChange}
      />
    </div>
  );

  const insightsOverviewProps = {
    readOnly,
    initiativeId,
    selectedPack: mainDownloadCode,
    utTableUsage,
    surveyId: sdgChartSurveyId,
    reportSwitcher,
    sdgCode,
    handleSDGChange,
    utrTable: readOnly ? utrTable : undefined, // prevent duplicating UniversalTrackersTable for non-CTL.
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (surveyId) {
      dispatch(loadScorecardsBySurveyId(initiativeId, surveyId));
    } else {
      dispatch(loadScorecardsByInitiativeId(initiativeId));
    }
  }, [dispatch, initiativeId, surveyId]);

  useEffect(() => {
    dispatch(loadUniversalTrackerValues(true, surveyId ?? ''));

    // preset sdg contributors chart with latest survey
    setSdgChartSurveyId(surveyId);
  }, [dispatch, surveyId]);

  const [openTargetActualModal, toggleTargetActualModal] = useToggle(false);
  const [activeTab, setActiveTab] = useState(TABS.CustomTargets);

  return (
    <>
      {isFetching ? <Loader /> : null}
      <CompanyProfile initiative={initiative} readOnly={readOnly} />
      <DashboardSectionTitle title='Highlights' headingStyle={2} className='text-ThemeHeadingLight' />
      <GridLayoutDashboard
        isEditing={false}
        gridItems={dashboard.items}
        utrsData={dashboard.utrsData}
        readOnly
        initiativeId={initiativeId}
        actionBtn={({ item }: { item: GridDashboardItem }) => (
          <DeleteFromOverviewBtn initiativeId={initiativeId} item={item} />
        )}
        survey={latestSurvey}
      />
      <InsightsOverview {...insightsOverviewProps} />
      {readOnly ? null : (
        <>
          <TargetActual
            openTargetActualModal={openTargetActualModal}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            toggleTargetActualModal={toggleTargetActualModal}
            surveyId={surveyId}
            readOnly={readOnly}
          />
          {utrTable ? (
            <UniversalTrackersTable
              readOnly={readOnly}
              header={'Targets and Trends'}
              usage={utTableUsage}
              universalTrackers={utrTable}
            />
          ) : null}
          <DashboardSectionTitle
            title='Media, Ratings and Certifications'
            headingStyle={2}
            className='text-ThemeHeadingLight'
          />
          <MediaSlider readOnly={readOnly} initiativeId={initiativeId} />
          <RatingsCarousel
            surveyId={surveyId}
            readOnly={readOnly}
            initiativeId={initiativeId}
            options={{ showInfoButton: true }}
          />
          <StandardsCarousel
            surveyId={surveyId}
            readOnly={readOnly}
            initiativeId={initiativeId}
            options={{ showDownloadButton: true, showInfoButton: true }}
          />
        </>
      )}
    </>
  );
};
