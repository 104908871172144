import { ChartData, QuestionData } from '../../../../utils';
import { View } from '../types';
import { MetricsTable } from './MetricsTable';
import { Button } from 'reactstrap';
import { QUESTION } from '@constants/terminology';
import { QuestionSelectingFilter } from '../../../partials/question-selecting-filter/QuestionSelectingFilter';
import { SDGTargetDropdown } from '../sdg-trackers/SDGTargetDropdown';
import { InsightDashboardItemType } from '../../../../../../types/insight-custom-dashboard';
import { UniversalTrackerBlueprintMin } from '../../../../../../types/universalTracker';
import { UtrVariable } from '../../../../../summary/insights/utils/constants';
import { getMetricUnitDesc } from '@components/utr-modal/components/chart';
import UniversalTracker from '../../../../../../model/UniversalTracker';
import { DEFAULT_METRIC_UNIT } from '@constants/utr';

const METRIC_LIMIT = 5;

interface ViewProps {
  initiativeId: string;
  view: View;
  chartData: ChartData;
  questionsMap: Map<string, UniversalTrackerBlueprintMin>;
  openAddMetric: () => void;
  questionData: QuestionData;
  setQuestionData: (questionData: QuestionData) => void;
  changeChartData: (chartData: Partial<ChartData>) => void;
}

const hasMetrics = (metrics: UtrVariable[] | undefined): metrics is UtrVariable[] => {
  return metrics !== undefined && metrics.length > 0;
};

const getLockedUnit = ({
  metrics,
  questionsMap,
}: {
  metrics: UtrVariable[] | undefined;
  questionsMap: Map<string, UniversalTrackerBlueprintMin>;
}) => {
  // ! since unit can be either undefined, empty string or string,
  // ! default to empty string will treat undefined unit and empty string unit to be the same
  if (!metrics || metrics.length === 0) {
    return DEFAULT_METRIC_UNIT;
  }
  // first metric unit will be used as locked unit
  const [firstMetric] = metrics;
  const question = questionsMap.get(firstMetric.code);
  return question ? getMetricUnitDesc(new UniversalTracker(question), firstMetric.valueListCode) : DEFAULT_METRIC_UNIT;
};

export const MetricsContent = ({
  initiativeId,
  view,
  chartData,
  questionsMap,
  openAddMetric,
  questionData,
  setQuestionData,
  changeChartData,
}: ViewProps) => {
  const { metrics } = chartData;
  const currentMetricCount = metrics.length;
  // Locked unit is calculated from utr unit and sdg number scale
  const lockedUnit = getLockedUnit({ metrics, questionsMap });
  const isUTRChart = chartData.type === InsightDashboardItemType.Chart;

  const handleRemove = (utr: UtrVariable) => {
    if (!chartData.metrics) {
      return;
    }
    const newMetrics = chartData.metrics.filter(
      (metric) => {
        if (utr.valueListCode) {
          return metric.valueListCode !== utr.valueListCode || metric.code !== utr.code;
        }
        return metric.code !== utr.code;
      }
    );
    changeChartData({ ...chartData, metrics: newMetrics });
  };

  switch (view) {
    case View.CombineMetrics:
      return (
        <>
          <div className='mt-3 text-medium'>Which metrics would you like to track</div>
          {hasMetrics(metrics) ? (
            <MetricsTable
              metrics={metrics}
              questionsMap={questionsMap}
              handleRemove={handleRemove}
              chartType={chartData.type ?? InsightDashboardItemType.Chart}
            />
          ) : null}
          {currentMetricCount < METRIC_LIMIT ? (
            <Button color='link' className='mt-2 p-0' onClick={openAddMetric}>
              <i className='fal fa-plus me-2' />
              Track a {isUTRChart ? QUESTION.SINGULAR : 'SDG'}
            </Button>
          ) : null}
          {currentMetricCount === 0 ? (
            <p className='text-xs text-ThemeIconSecondary'>Selectable metric will be locked once first unit selected</p>
          ) : null}
          {currentMetricCount === METRIC_LIMIT ? (
            <p className='text-sm text-ThemeIconSecondary fst-italic'>Charts limited to five metrics.</p>
          ) : null}
        </>
      );
    case View.TrackMetric:
      if (isUTRChart) {
        return (
          <>
            <p className='mt-3 text-medium'>Select data-point from survey scope</p>
            <QuestionSelectingFilter
              lockedUnit={lockedUnit}
              questionData={questionData}
              initiativeId={initiativeId}
              handleSelect={(utr) => setQuestionData(utr)}
            />
          </>
        );
      }
      return <SDGTargetDropdown questionData={questionData} changeQuestionData={(utr) => setQuestionData(utr)} />;
    default:
      return null;
  }
};
