import { ReactElement } from 'react';
import { useAppSelector } from '../../reducers';
import { AdminBreadcrumbs } from '../../routes/admin-dashboard/AdminBreadcrumbs';
import { currentInitiative } from '../../selectors/initiative';
import Dashboard, { DashboardRow, DashboardSection } from '../dashboard';
import Loader from '../loader';
import { Navigation } from './Navigation';
import './style.scss';

interface ContainerProps {
  children: ReactElement;
}

export const Container = ({ children }: ContainerProps) => {
  const initiative = useAppSelector(currentInitiative);

  if (!initiative) {
    return <Loader />;
  }

  return (
    <Dashboard className='company-settings__wrapper'>
      <DashboardRow>
        <AdminBreadcrumbs breadcrumbs={[{ label: 'Company Settings' }]} initiativeId={initiative._id} />
      </DashboardRow>
      <DashboardRow>
        <h3 className='pl-2'>Company settings</h3>
      </DashboardRow>
      <DashboardSection>
        <Navigation initiative={initiative} />
        <div className='mt-4'>{children}</div>
      </DashboardSection>
    </Dashboard>
  );
};
