import { Route, Switch, useParams } from 'react-router-dom';
import { sidebarPath } from '../../components/survey-settings-toolbar/breadcrumbPath';
import { TemplateHistoryConfiguration } from '../../components/template-history/TemplateHistoryConfiguration';
import { TemplateBulkSurveysCreate } from '../../components/survey-templates/TemplateBulkSurveysCreate';
import { TemplateConfiguration } from '../../components/survey-templates/TemplateConfiguration';
import {
  TemplateContainer,
  TemplateContext,
  TemplateContextLoadedProps,
} from '../../components/survey-templates/TemplateContainer';
import { TemplateDashboard } from '../../components/survey-templates/TemplateDashboard';
import { TemplateHistory } from '../../components/template-history/TemplateHistory';
import { TemplateScope } from '../../components/survey-templates/TemplateScope';
import { NavCode } from '../../components/survey-templates/types';
import { rootAppPath } from '../../routes/company-tracker/utils';
import { TemplateNav } from '../../components/survey-templates/partials/TemplateNav';
import { TemplateHistoryNav } from '../../components/template-history/TemplateHistoryNav';
import { useContext } from 'react';
import { SurveySettings, SurveyType } from '../../types/survey';
import { generateUrl } from '../util';
import { ROUTES } from '../../constants/routes';
import { TemplateHistorySubsidiary } from '../../components/template-history/TemplateHistorySubsidiary';
import { TemplateHistoryHeader } from '../../components/template-history/TemplateHistoryHeader';
import { useAppSelector } from '../../reducers';
import { isUserManagerByInitiativeId } from '../../selectors/user';
import NotAuthorised from '../not-authorised';
import { AggregatedConfiguration } from '../../components/survey-templates/aggregated/AggregatedConfiguration';
import { AggregatedBulkSurveysCreate } from '../../components/survey-templates/aggregated/AggregatedBulkSurveysCreate';
import { TemplateHistoryAggregatedConfiguration } from '../../components/template-history/TemplateHistoryAggregatedConfiguration';

const TemplateSettingPages = () => {
  const { page } = useParams<{ page?: string }>();
  const { templateData } = useContext(TemplateContext) as TemplateContextLoadedProps;
  const isAggregated = templateData.type === SurveyType.Aggregation;

  switch (page) {
    case NavCode.Configuration:
      return isAggregated ? <AggregatedConfiguration /> : <TemplateConfiguration />;
    case NavCode.Scope:
      return isAggregated ? <TemplateDashboard /> : <TemplateScope renderHeader={() => <TemplateNav />} />;
    case NavCode.BulkSurveysCreate:
      return isAggregated ? <AggregatedBulkSurveysCreate /> : <TemplateBulkSurveysCreate />;
    case NavCode.History:
      return <TemplateHistory />;
    default:
      return <TemplateDashboard />;
  }
};

const HistorySettingPages = () => {
  const { initiativeId, templateId, historyId, page } = useParams<{
    initiativeId: string;
    templateId: string;
    historyId: string;
    page?: string;
  }>();
  const templateContext = useContext(TemplateContext) as TemplateContextLoadedProps;
  const isAggregated = templateContext.templateData.type === SurveyType.Aggregation;

  const handleChangeSettings = (key: keyof SurveySettings, setting: any) => {
    const historyRoute = generateUrl(ROUTES.SURVEY_TEMPLATES_HISTORY, {
      initiativeId,
      templateId,
      historyId,
      page,
    });
    return templateContext.handleChangeSettings(key, setting, historyRoute);
  };
  switch (page) {
    case NavCode.Configuration:
      return isAggregated ? <TemplateHistoryAggregatedConfiguration /> : <TemplateHistoryConfiguration />;
    case NavCode.Scope:
      return (
        <TemplateScope
          isDisabled
          renderBackButton={() => <TemplateHistoryHeader />}
          renderHeader={() => <TemplateHistoryNav />}
          handleChangeSettings={handleChangeSettings}
          title={`Survey templates: ${templateContext.templateData.name}`}
        />
      );
    case NavCode.Subsidiary:
      return <TemplateHistorySubsidiary />;
    default:
      return <TemplateHistory />;
  }
};

export const SurveyTemplatesRoute = () => {
  const { initiativeId } = useParams<{
    initiativeId: string;
  }>();
  const isManager = useAppSelector((state) => isUserManagerByInitiativeId(state, initiativeId));
  if (!isManager) {
    return <NotAuthorised />;
  }

  return (
    <Switch>
      <Route path={`/${rootAppPath}/survey-templates/:initiativeId/template/create`}>
        <TemplateConfiguration />
      </Route>
      <Route path={`/${rootAppPath}/survey-templates/:initiativeId/aggregated/create`}>
        <AggregatedConfiguration />
      </Route>
      <Route
        path={`/${rootAppPath}/survey-templates/:initiativeId/template/:templateId/history/:historyId/:page?/${sidebarPath}`}
      >
        <TemplateContainer>
          <HistorySettingPages />
        </TemplateContainer>
      </Route>
      <Route path={`/${rootAppPath}/survey-templates/:initiativeId/template/:templateId/:page?/${sidebarPath}`}>
        <TemplateContainer>
          <TemplateSettingPages />
        </TemplateContainer>
      </Route>
      <Route>
        <TemplateDashboard />
      </Route>
    </Switch>
  );
};
