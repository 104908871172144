import { UtrModalBody } from '../../../components/utr-modal/UtrModalBody';
import { CurrentUserData } from '../../../reducers/current-user';
import UniversalTrackerModalService from '../../../model/UniversalTrackerModalService';
import { UtrvHistoryModalTemplate } from '../../../components/utr-modal/UtrvHistoryModalTemplate';
import { Tab } from '../../../components/utr-modal/ContentTabs';
import { SurveyFiltersProps } from '@components/utr-modal/SurveyFilters';

type Props = SurveyFiltersProps & {
  canAccessCustomDashboards: boolean;
  reloadUtrvs: () => void;
  toggle: () => void;
  currentUser?: CurrentUserData;
  modalService: UniversalTrackerModalService;
  activeTabId?: Tab['navId'];
  showTargetBaselineAdding: boolean;
  disableTargetBaselineAdding: boolean;
};

export const UtrvHistoryModal = ({
  canAccessCustomDashboards,
  reloadUtrvs,
  toggle,
  currentUser,
  modalService,
  activeTabId,
  showTargetBaselineAdding,
  disableTargetBaselineAdding,
  initialFilters,
  onChangeFilters,
}: Props) => {
  return (
    <UtrvHistoryModalTemplate toggle={toggle}>
      <UtrModalBody
        modalService={modalService}
        canAccessCustomDashboards={canAccessCustomDashboards}
        reloadUtrvs={reloadUtrvs}
        currentUser={currentUser}
        activeTabId={activeTabId}
        showTargetBaselineAdding={showTargetBaselineAdding}
        disableTargetBaselineAdding={disableTargetBaselineAdding}
        initialFilters={initialFilters}
        onChangeFilters={onChangeFilters}
      />
    </UtrvHistoryModalTemplate>
  );
};
