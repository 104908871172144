import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select, { createFilter, SingleValue } from 'react-select';
import { Input, Label } from 'reactstrap';
import { RequestScope } from '../../../../actions/api';
import { loadCustomMetricGroupsByInitiativeId } from '../../../../actions/initiative';
import { SURVEY_PACKS } from '../../../../constants/standards-frameworks';
import { useAppSelector } from '../../../../reducers';
import { ClassificationsLabels, SubmissionInsightsRowValues } from '../../../../types/portfolio';
import { TIME_RANGE_OPTIONS } from '../../../../utils/date';
import IconButton from '../../../button/IconButton';
import { PeriodDropdown } from '../../../period-dropdown';
import { DateRangeType } from '../../../period-dropdown/DateRangePicker';
import { UrlParams } from '../PackUsage';
import { ACTION, DEFAULT_PERIOD, MetricView, SubmissionInsightsContext } from './SubmissionInsightsContainer';
import { getClassificationsTextArray, getDisplayColumn, getDynamicRowValues, getViewOptions } from './utils';
import { getStyles } from '../../../select-packs';
import { exportToExcel } from '../../../downloads/util/exportToExcel';

type Option = {
  value: string;
  searchString: string | null;
  label: string | JSX.Element;
  scopeType?: string | 'standards' | 'frameworks' | 'sdg' | 'custom';
};

const viewOptions = getViewOptions();

export const SubmissionInsightsFilter = () => {
  const { filters, tableData, dispatch } = useContext(SubmissionInsightsContext);
  const reactDispatch = useDispatch();
  const [period, setPeriod] = useState(DEFAULT_PERIOD);
  const { portfolioId } = useParams<UrlParams>();
  const { data: metricGroups } = useAppSelector((state) => state.customMetricGroups);
  useEffect(() => {
    if (!portfolioId) {
      return
    }
    reactDispatch(loadCustomMetricGroupsByInitiativeId(portfolioId))
  }, [reactDispatch, portfolioId]);

  const packOptions = useMemo(() => {

    const customGroupOptions =  metricGroups.map(el => {
      return {
        label: <span>
          {el.groupData?.icon ? <img src={el.groupData.icon} alt={el.groupName} height='26px' className='mr-2'/> : null}
          <span>{el.groupName}</span>
        </span>,
        searchString: el.groupName,
        value: el._id,
        scopeType: 'custom',
      }
    });

    return [...SURVEY_PACKS, ...customGroupOptions]
  }, [metricGroups])

  const handleChangeFilters = (key: string, option: DateRangeType | RequestScope | string | boolean | undefined) => {
    if (option !== undefined) {
      dispatch({
        type: ACTION.SET_FILTERS,
        payload: {
          [key]: option,
        },
      });
    }
  };

  const handleChangeScope = (key: string, option: SingleValue<Option>) => {
    if (option !== null) {
      const scopeGroup: RequestScope = {
        code: option.value,
        scopeType: option.scopeType || '',
      };
      handleChangeFilters(key, scopeGroup);
    }
  };

  const downloadReport = () => {
    const rowHeaders = [filters.view.toString()]
      .concat(Object.values(ClassificationsLabels))
      .concat(tableData.utrs.map((utr) => getDisplayColumn({ utr, alternativeCode: filters.scopeGroup?.code })));
    const rowValues = tableData.utrValues.map((rowValue: SubmissionInsightsRowValues) => {
      return [
        rowValue.name,
        ...getClassificationsTextArray(rowValue),
        ...getDynamicRowValues(rowValue, tableData.utrs, filters.metricView),
      ];
    });

    exportToExcel({
      headers: rowHeaders,
      values: rowValues,
      fileName: 'benchmarking-submission-insights',
      sheetName: 'submission-insights',
    });
  };

  const filterOption = createFilter<Option>({
    stringify: ({ data }) => `${data.searchString ?? data.label}`,
  });

  return (
    <div className='d-flex justify-content-between align-items-center gap-3 mt-5'>
      <Select<Option>
        styles={getStyles()}
        options={viewOptions}
        onChange={(option) => handleChangeFilters('view', option?.value)}
        value={viewOptions.find((view) => view.value === filters.view)}
        defaultValue={viewOptions[0]}
        className='filter__select'
      />
      <Select<Option>
        placeholder='Survey pack'
        styles={getStyles()}
        options={packOptions}
        value={packOptions.find((v) => v.value === filters.scopeGroup?.code) ?? null}
        onChange={(option) => handleChangeScope('scopeGroup', option)}
        filterOption={filterOption}
        className='filter__select'
      />
      <Label check>
        <Input
          type='radio'
          name='status'
          className='mr-2'
          onChange={(option) => handleChangeFilters('metricView', option.target.value)}
          checked={filters.metricView === MetricView.Average}
          value={MetricView.Average}
        />
        Average
      </Label>
      <Label check>
        <Input
          type='radio'
          name='status'
          className='mr-2'
          onChange={(option) => handleChangeFilters('metricView', option.target.value)}
          checked={filters.metricView === MetricView.Total}
          value={MetricView.Total}
        />
        Sum
      </Label>
      <PeriodDropdown
        period={period}
        onChangePeriod={(value) => setPeriod(value)}
        dateRange={filters.dateRange}
        onChangeDateRange={(range) => handleChangeFilters('dateRange', range)}
        timeRangeOptions={TIME_RANGE_OPTIONS}
      />
      <IconButton
        onClick={downloadReport}
        outline={false}
        color='transparent'
        className='text-ThemeAccentDark'
        icon='fal fa-file-excel'
      />
    </div>
  );
};
