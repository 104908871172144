import Select, { SingleValue, StylesConfig } from 'react-select';
import { getStyles, Option } from '../../multiple-select';

interface FilterSelectOption {
  label: string | JSX.Element;
  value: string;
}

interface FilterSelectOption {
  label: string | JSX.Element;
  value: string;
}

export const clearOption = {
  label: (
    <>
      <i className='fa fa-times text-ThemeDangerMedium mr-2' />
      Remove Filter
    </>
  ),
  value: '',
};

export const getMenuStyles = (active: boolean): StylesConfig<Option> => {
  const border = active ? 'var(--theme-TextDark)' : 'var(--theme-NeutralsLight)';
  return {
    menu: (provided) => ({
      ...provided,
      zIndex: 3,
      marginTop: 0,
      minWidth: '300px',
      whiteSpace: 'normal',
      border: `solid 1px ${border}`,
      boxShadow: 'none',
    }),
  };
};

export const FilterSelect = ({
  placeholder = 'Select',
  options,
  value,
  onSelectChange,
}: {
  placeholder: string;
  options: FilterSelectOption[];
  value: string | undefined;
  onSelectChange: (value: string) => void;
}) => (
  <div className='mr-3'>
    <Select
      placeholder={placeholder}
      styles={{ ...getStyles(!!value), ...getMenuStyles(!!value) }}
      options={!!value ? [clearOption, ...options] : options}
      value={options.find((option) => option.value === value) || null}
      isMulti={false}
      onChange={(option: SingleValue<Option>) => onSelectChange(option?.value || '')}
    />
  </div>
);
