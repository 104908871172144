import { random } from 'mathjs';
import { ChildrenTree, Tree } from '../../types/tree';
import { HierarchyNode, SelectionTreeNode, StepPathNode, TreeNode } from './types';
import { ARCHIVED_BG, ARCHIVED_COLOUR, BUTTON_WIDTH, LEVELCOLOURS, MAX_LENGTH, MINIMUM_DISTANCE } from './constants';
import { InitiativePlain, InitiativeTags } from '../../types/initiative';
import { findAllChildrenById, findCurrentBranchInTree } from '../../utils/initiative';

export const getLevelColor = (depth: number) => {
  const style = 'border-color: ';
  if (LEVELCOLOURS[depth]) {
    return style + LEVELCOLOURS[depth];
  }
  return style + LEVELCOLOURS[random(0, LEVELCOLOURS.length)];
};

// flextree functionality to get variable node size
export const getNodeSize = (node: HierarchyNode): [number, number] => {
  // Minimum 5, to ensure we have enough space between the nodes
  const nodeNameLength = Math.max(Math.min(node.data.name.length, MAX_LENGTH), 5);
  if (nodeNameLength > 20) {
    return [70, nodeNameLength * 15];
  }
  if (nodeNameLength > 10) {
    return [70, nodeNameLength * 20];
  }
  return [70, nodeNameLength * 50];
};

export const getNodeHtmlWidth = (d: TreeNode) => {
  // styled wrapper not the svg node width
  const namelength = d.data.name.length;
  let factor = 4;
  if (namelength > 7 && namelength <= 15) {
    factor = 3;
  } else if (namelength > 15) {
    factor = 2.5;
  }
  return `${Math.max(Math.min(namelength / 2 + factor, 20), 8)}rem`;
};

export const expandedNodePositionShift = (parent: Partial<Pick<TreeNode, 'children' | 'data'>>) => {
  const length = parent.data?.name?.length ?? 3;
  // For small nodes, we want to expand at least by node width
  const adjustment = length <= 20 ? BUTTON_WIDTH : 40;
  return parent.children?.length ? adjustment : Math.min(length, 50);
};

export const getExpandButtonDistance = (d: TreeNode) => {
  const firstChildNode = d.children ? d.children[0] : d._children ? d._children[0] : d;
  const source = { x: firstChildNode.x, y: firstChildNode.y };
  const target = { x: d.x, y: d.y };
  const extra = expandedNodePositionShift(d);
  return 0.5 * (source.y - target.y) + MINIMUM_DISTANCE + extra - BUTTON_WIDTH / 2;
};

export const drawConnectingLineToExpandButton = (nodeEnter: SelectionTreeNode) => {
  nodeEnter
    .append('svg:foreignObject')
    .attr('height', 2)
    .attr('width', (d) => {
      return getExpandButtonDistance(d);
    })
    .style('display', (d) => {
      return d.children || d._children ? 'inline-block' : 'none';
    })
    .attr('class', 'expand-line');
};

export const drawStepPath = (child: StepPathNode, parent: StepPathNode) => {
  const deltaY = parent.y - child.y;
  const extra = expandedNodePositionShift(parent);
  return `M${child.y}, ${child.x} H${child.y + 50 + extra + deltaY / 2} V${parent.x} H${parent.y}`;
};

export const getCTLightPreviewOrgMap = (company: ChildrenTree): ChildrenTree => {
  return {
    code: '',
    disabled: true,
    name: company.name,
    id: company.id,
    children: [
      {
        id: '',
        code: '',
        disabled: false,
        name: 'Business Unit 1',
        children: [
          {
            id: '',
            code: '',
            disabled: false,
            name: 'Americas',
            children: [],
          },
          {
            id: '',
            code: '',
            disabled: false,
            name: 'ASEAN',
            children: [
              {
                id: '',
                code: '',
                disabled: false,
                name: 'Subsidiary 1',
                children: [
                  {
                    id: '',
                    code: '',
                    disabled: false,
                    name: 'Site/Branch/Asset 1',
                    children: [],
                  },
                  {
                    id: '',
                    code: '',
                    disabled: false,
                    name: 'Site/Branch/Asset 2',
                    children: [],
                  },
                  {
                    id: '',
                    code: '',
                    disabled: false,
                    name: 'Site/Branch/Asset 3',
                    children: [],
                  },
                ],
              },
              {
                id: '',
                code: '',
                disabled: false,
                name: 'Subsidiary 2',
                children: [],
              },
              {
                id: '',
                code: '',
                disabled: false,
                name: 'Subsidiary 3',
                children: [],
              },
            ],
          },
          {
            id: '',
            code: '',
            disabled: false,
            name: 'APAC',
            children: [],
          },
        ],
      },
      {
        id: '',
        code: '',
        disabled: false,
        name: 'Business Unit 2',
        children: [],
      },
      {
        id: '',
        code: '',
        disabled: false,
        name: 'Business Unit 3',
        children: [],
      },
    ],
  };
};

export const getSubsidiaryOptions = ({
  fullTreeList,
  excludedBranchRootId,
}: {
  fullTreeList: InitiativePlain[];
  excludedBranchRootId?: string;
}) => {

  if (!excludedBranchRootId) {
    return fullTreeList.map((initiative) => ({
      label: initiative.name,
      value: initiative._id,
    }));
  }

  const subsidiaryIds = findAllChildrenById(fullTreeList, excludedBranchRootId).map((initiative) => initiative._id);
  const currentInitiativeAndChildrenIds = new Set([...subsidiaryIds, excludedBranchRootId]);

  return fullTreeList
    .filter((initiative) => !currentInitiativeAndChildrenIds.has(initiative._id))
    .map((initiative) => ({
      label: initiative.name,
      value: initiative._id,
    }));
};

export const isOrganisation = (tags: InitiativeTags[]) => {
  return tags.includes(InitiativeTags.Organization);
};

export const getBranchInitiativeNameText = ({
  initiativeTree,
  initiativeId,
  showInitiativeId = false,
}: {
  initiativeTree: Tree;
  initiativeId: string | undefined;
  showInitiativeId?: boolean;
}) => {
  if (!initiativeId) {
    return '';
  }
  const currentBranch = showInitiativeId
    ? findCurrentBranchInTree(initiativeTree, initiativeId).branch
    : findCurrentBranchInTree(initiativeTree, initiativeId).branch.filter(
        (initiative) => initiative.id !== initiativeId
      );

  return currentBranch
    .map((initiative) => initiative.name)
    .reverse()
    .join(' / ');
};

export const getArchivedNodeStyles = () => {
  return `border-color: ${ARCHIVED_COLOUR};background-color: ${ARCHIVED_BG};`;
}
