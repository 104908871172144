import { FormGroup, Input, Label } from 'reactstrap'

interface ToggleProps {
  checked: boolean;
  onChange: () => void;
  label: string;
  className?: { form?: string; input?: string; label?: string };
  disabled?: boolean;
}

export const Toggle = ({ checked, onChange, label, className = {}, disabled = false }: ToggleProps) => {
  const {
    form: formClassName = 'd-flex align-items-center pl-0',
    input: inputClassName = 'm-0',
    label: labelClassName = 'text-sm ml-1',
  } = className;
  return (
    <FormGroup switch className={formClassName}>
      <Input
        type='switch'
        role='switch'
        checked={checked}
        className={inputClassName}
        onChange={onChange}
        disabled={disabled}
      />
      <Label className={labelClassName} check>
        {label}
      </Label>
    </FormGroup>
  );
};
