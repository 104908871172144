import { standards } from '@g17eco/core';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import LoadingPlaceholder from '../../LoaderContainer/LoadingPlaceholder';
import { QuestionProps } from './questionInterfaces';
import { getTooltip } from './questionUtil';

const defaultHeight = 90;

interface Props extends Pick<QuestionProps, 'utr' | 'glossaryState' | 'alternativeCode'> {
  selectedLanguage?: string;
  startAltTextAnimation: boolean;
}

const SingleInstructions = (props: Props & { isHidden: boolean, children: JSX.Element }) => {
  const { utr, glossaryState, alternativeCode, isHidden } = props;
  const textHeight = document.querySelector('.instruction-text')?.scrollHeight || defaultHeight;
  const isTruncated = textHeight > defaultHeight;

  const height = useMemo(() => {
    const isTruncatedText = textHeight > defaultHeight;
    switch (true) {
      case isTruncatedText && isHidden:
        return `${defaultHeight}px`;
      case isTruncatedText === false:
        return '100%';
      default:
        return `${textHeight}px`;
    }
  }, [isHidden, textHeight]);


  if (!utr) {
    return <LoadingPlaceholder height={40} />;
  }

  const instructionsText =
    alternativeCode === 'gri'
      ? 'See complete Reporting Guidance and Reporting Recommendation here'
      : `View ${standards[alternativeCode]?.shortName || ''} Instructions`;
  const instructionsLink = utr.getInstructionsLink(alternativeCode);

  const instructions: string = utr.getInstructions(alternativeCode);
  const getInstructionClass = () => {
    if (!isTruncated) {
      return '';
    }
    if (isHidden) {
      return 'is-truncated collapsed';
    }
    return 'is-truncated expanded';
  };

  return (
    <>
      <div
        className={`instruction-text ${getInstructionClass()}`}
        style={{ height }}
      >
        {getTooltip(instructions, glossaryState)}
        {instructionsLink ? (
          <div className='instruction-link'>
            <Button
              color='link'
              size='sm'
              className='px-0 mt-2'
              onClick={() => window.open(instructionsLink, '_blank')}
            >
              <u>{instructionsText}</u>
            </Button>
          </div>
        ) : null}
      </div>
      {isTruncated ? props.children : null}
    </>
  );
}

export const Instructions = (props: Props) => {
  const { utr, alternativeCode, selectedLanguage, startAltTextAnimation } = props;

  const [isHidden, setIsHidden] = useState(true);
  useEffect(() => {
    setIsHidden(true);
  }, [utr]);
  const toggleHiding = () => setIsHidden((prev) => !prev);

  const showInstructionLink = !isHidden && utr?.getInstructionsLink(alternativeCode);

  const HiddenBtn = () => (
    <Button color='link-secondary' onClick={toggleHiding} className={`text-xs ${showInstructionLink ? 'd-block mt-2' : ''}`}>
      {isHidden ? <><i className='fa fa-angle-down mr-1' />Read more</> : <><i className='fa fa-angle-up mr-2' />Read less</>}
    </Button>
  );

  return (
    <div className='animated-container'>
      <div className={`text-animated ${startAltTextAnimation ? 'disappear-right' : ''}`}>
        <SingleInstructions {...props} alternativeCode={alternativeCode} isHidden={isHidden}>
          <HiddenBtn />
        </SingleInstructions>
      </div>
      <div className={`text-animated ${startAltTextAnimation ? '' : 'disappear-left'}`}>
        <SingleInstructions {...props} alternativeCode={selectedLanguage ?? alternativeCode} isHidden={isHidden}>
          <HiddenBtn />
        </SingleInstructions>
      </div>
    </div>
  );
};
