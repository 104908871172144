import { QUESTION } from '../../constants/terminology';
import { Alternative, LanguageAlternative } from '../../types/universalTracker';
import { isDefined } from '../../utils';
import { Field, Form } from './EditMetricModal';

export const validateTypeCode = (form: Form) => {
  const NO_SPACES_REGEX = /^\S+$/g;
  const value = form.typeCode;
  if (value === '') {
    return { valid: undefined, message: '' };
  }

  if (!Boolean(value)) {
    // Empty value is valid, as it's not required...
    return { valid: true, message: '' };
  }

  if (value && typeof value === 'string' && value.length < 15 && NO_SPACES_REGEX.test(value)) {
    return {
      valid: true,
      message: '',
    };
  }

  return { valid: false, message: `${QUESTION.CAPITALIZED_SINGULAR} code is max 15 characters and cannot include spaces` };
}

export const hasMinAndMax = (
  valueValidation: Form['valueValidation']
): valueValidation is { min: number; max: number } => {
  return typeof valueValidation?.min === 'number' && typeof valueValidation?.max === 'number';
};

export const DEFAULT_PERCENTAGE_RESTRICTIONS = {
  min: 0,
  max: 100,
};

export const UNSET_PERCENTAGE_RESTRICTIONS = {
  min: undefined,
  max: undefined
}

export const PAGE_SIZE = 20;

export const isValidMinMaxData = (valueValidation: Form['valueValidation']) => {
  if (valueValidation && 'min' in valueValidation && 'max' in valueValidation) {
    const min = valueValidation.min;
    const max = valueValidation.max;
    if (!isDefined(min) && !isDefined(max)) {
      return true;
    }    
    if (isDefined(min) && isDefined(max)) {
      if (!Number.isInteger(min) || !Number.isInteger(max)) {
        return false;
      }
      if (min >= max) {
        return false;
      }
      return true;
    }
    // either min or max not defined
    return false;
  }
  return true;
}

export const hasInvalidAlternativeFields = ({
  formFields,
  alternatives,
}: {
  formFields: Field[];
  alternatives: { [key: string]: Alternative | LanguageAlternative } | undefined;
}) => {
  return Object.keys(alternatives ?? {}).some((k) => {
    const alternativeData = alternatives?.[k];
    if (!alternativeData) {
      return true; // True means it has failed validation
    }
    return formFields.some((field) => field.required && !alternativeData[field.code as keyof Alternative]);
  });
};

export const hasPassedValidation = ({ formFields, formData }: { formFields: Field[]; formData: Form }) => {
  return formFields.every((field) => {
    if (field.isValid) {
      const { valid } = field.isValid(formData);
      if (valid === undefined && !field.required) {
        // Empty, and not required
        return true;
      }
      return valid;
    }
    return true;
  });
};