import { SurveyStats } from '../../../../api/initiative-stats';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select, { SingleValue } from 'react-select';
import './surveys-editing.scss';
import { useState } from 'react';
import { useToggleCompleteSurveys } from '../../../../hooks/useToggleCompleteSurveys';
import Loader from '../../../../components/loader';
import { SurveysTable } from './SurveysTable';
import { QUESTION } from '../../../../constants/terminology';

export enum Action {
  Complete = 'complete',
  Incomplete = 'incomplete',
}

enum CompleteSurveyStatus {
  AllVerified = 'all-verified',
  AllAnswered = 'all-answered',
  All = 'all',
}

enum IncompleteSurveyStatus {
  NotAllVerified = 'not-all-verified',
  OnlyRejected = 'only-rejected',
  OnlyUnanswered = 'only-unanswered',
  All = 'all',
}

interface FilterOption {
  value: CompleteSurveyStatus | IncompleteSurveyStatus;
  label: string;
}

const COMPLETE_FILTER_OPTIONS: FilterOption[] = [
  { value: CompleteSurveyStatus.AllVerified, label: `All ${QUESTION.PLURAL} are verified` },
  { value: CompleteSurveyStatus.AllAnswered, label: `All ${QUESTION.PLURAL} are answered` },
  { value: CompleteSurveyStatus.All, label: 'All surveys' },
];

const INCOMPLETE_FILTER_OPTIONS: FilterOption[] = [
  { value: IncompleteSurveyStatus.NotAllVerified, label: `Not all ${QUESTION.PLURAL} are verified` },
  { value: IncompleteSurveyStatus.OnlyRejected, label: `Has rejected ${QUESTION.PLURAL} only` },
  { value: IncompleteSurveyStatus.OnlyUnanswered, label: `Has unanswered ${QUESTION.PLURAL} only` },
  { value: IncompleteSurveyStatus.All, label: 'All surveys' },
];

interface Props {
  surveys: SurveyStats[];
  action: Action;
  handleClose: () => void;
  handleReload: () => void;
}

export const SurveysEditing = (props: Props) => {
  const { surveys, action, handleClose, handleReload } = props;
  const isCompleting = Action.Complete === action;
  const filterOptions = isCompleting ? COMPLETE_FILTER_OPTIONS : INCOMPLETE_FILTER_OPTIONS;
  const defaultFilterOption =
    filterOptions.find(({ value }) => filterSurveys(surveys, value).length) || filterOptions[0];

  const [filter, setFilter] = useState(defaultFilterOption.value);

  const title = `Mark surveys as ${isCompleting ? 'completed' : 'in progress'}`;
  const onChangeFilter = (option: SingleValue<FilterOption>) => {
    if (!option) return;
    setFilter(option.value);
  };

  const filteredSurveys = filterSurveys(surveys, filter);

  const [selectedSurveys, setSelectedSurveys] = useState<string[]>([]);
  const { isSaving, toggleCompleteSurveys } = useToggleCompleteSurveys({
    handleReload,
    surveys: surveys.filter(({ _id }) => selectedSurveys.includes(_id)),
    isCompleting,
  });

  const onClickMarkBtn = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await toggleCompleteSurveys();
    handleClose();
  };

  if (isSaving) return <Loader />;

  return (
    <Modal isOpen={true} toggle={handleClose} backdrop='static' className='surveys-editing'>
      <ModalHeader toggle={handleClose}>{title}</ModalHeader>
      <ModalBody>
        <div className='surveys-editing__filter'>
          <span className='mr-3'>Survey progress</span>
          <Select
            className='surveys-editing__filter_select'
            classNamePrefix='select'
            defaultValue={defaultFilterOption}
            isClearable={false}
            isSearchable={false}
            name='color'
            options={filterOptions}
            onChange={onChangeFilter}
            styles={selectStyles}
          />
        </div>
        <SurveysTable
          surveys={filteredSurveys}
          selectedSurveys={selectedSurveys}
          setSelectedSurveys={setSelectedSurveys}
        />
      </ModalBody>
      <ModalFooter className='text-right'>
        <Button color='link' size='sm' className='mr-2' onClick={handleClose}>
          Cancel
        </Button>
        <Button size='md' className='btn-sm surveys-editing__mark-btn' onClick={(e) => onClickMarkBtn(e)}>
          Mark as {isCompleting ? 'completed' : 'in progress'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const filterSurveys = (surveys: SurveyStats[], filter: CompleteSurveyStatus | IncompleteSurveyStatus) => {
  return surveys.filter(({ status: { created, updated, rejected } }) => {
    switch (filter) {
      case CompleteSurveyStatus.AllVerified:
        return !created && !updated && !rejected;
      case CompleteSurveyStatus.AllAnswered:
        return !created && !rejected;
      case IncompleteSurveyStatus.NotAllVerified:
        return created || updated || rejected;
      case IncompleteSurveyStatus.OnlyRejected:
        return !created && !updated && rejected;
      case IncompleteSurveyStatus.OnlyUnanswered:
        return created && !updated && !rejected;
      default:
        return true;
    }
  });
};

const selectStyles = {
  singleValue: (provided: any) => ({
    ...provided,
    color: '#6C757D',
    margin: '0',
  }),
  indicatorSeparator: (provided: any) => ({ ...provided, display: 'none' }),
  control: (provided: any) => ({ ...provided, width: '250px', minHeight: 'initial', padding: '6px 16px' }),
  dropdownIndicator: (provided: any) => ({ ...provided, padding: '0' }),
  valueContainer: (provided: any) => ({ ...provided, padding: '0' }),
};
