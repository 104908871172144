import { useState } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DATE, setFormat } from '../../utils/date';
import DateRangePicker, { DateRangeType, getDateRange } from './DateRangePicker';
import './styles.scss';

interface TimeRangeOption {
  code: string | number;
  name: string;
  showRange?: boolean;
}

interface PeriodDropdownProps {
  period: string | number;
  onChangePeriod: (value: string | number) => void;
  dateRange: DateRangeType;
  onChangeDateRange: (dateRange: DateRangeType) => void;
  timeRangeOptions: TimeRangeOption[];
  datePickerClassName?: string;
}

export const PeriodDropdown = ({ period, onChangePeriod, dateRange = {}, onChangeDateRange, timeRangeOptions, datePickerClassName }: PeriodDropdownProps) => {
  const [showDatePicker, setShowDateRangePicker] = useState(false);

  const selectedOption = timeRangeOptions.find((o) => o.code === period) ?? timeRangeOptions[0];

  const handleSelectPeriod = (option: { code: string | number; name: string }) => {
    onChangePeriod(option.code);
    if (option.code === 'custom') {
      setShowDateRangePicker(true);
    } else {
      onChangeDateRange(getDateRange(Number(option.code)));
      setShowDateRangePicker(false);
    }
  };

  const handleSubmitDateRange = (range: DateRangeType) => {
    onChangeDateRange(range);
    setShowDateRangePicker(false);
  };

  const handleCloseCalendar = () => {
    setShowDateRangePicker(false);
  };

  return (
    <div className='select-period'>
      <UncontrolledDropdown className={'d-inline-block'}>
        <DropdownToggle className={'text-truncate'} style={{ maxWidth: '350px' }} color='transparent' caret>
          <span className='px-2'>
            {dateRange.startDate && dateRange.endDate && selectedOption.showRange
              ? `${setFormat(DATE.DEFAULT_SPACES, dateRange.startDate)} - ${setFormat(
                  DATE.DEFAULT_SPACES,
                  dateRange.endDate
                )}`
              : selectedOption?.name}
          </span>
        </DropdownToggle>
        <DropdownMenu>
          {timeRangeOptions.map((option) => (
            <DropdownItem key={option.code} onClick={() => handleSelectPeriod(option)}>
              {option.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {showDatePicker ? (
        <DateRangePicker
          dateRange={dateRange}
          callbackClose={handleCloseCalendar}
          callbackSubmit={handleSubmitDateRange}
          className={datePickerClassName}
        />
      ) : null}
    </div>
  );
};
