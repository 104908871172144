import { SelectOption } from '../../types/select';

export const clearDropdownOption = (text: string): SelectOption => ({
  value: null,
  label: (
    <div className='text-muted'>
      <i className={'fa fa-times faded text-chateauGrey mr-2'} />
      {text}
    </div>
  ),
});