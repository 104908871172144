import {
  useGetInitiativeUniversalTrackersQuery,
  useUpdateInitiativeUniversalTrackerMutation,
} from '../../api/initiative-universal-trackers';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { isDefined } from '../../utils';
import { isNumericTableColumnType } from '../../utils/universalTracker';
import { BulkActionUtr } from '../survey-question-list/partials/BulkActionToolbar';
import { BulkButton } from '../survey-question-list/partials/ToggledButton';
import { DecimalModal } from './DecimalModal';
import { getInitialState, getInputType, isSingleTableQuestion } from './utils';

export interface DecimalType {
  [key: string]: number | undefined;
}

export interface ErrorDecimalType {
  [key: string]: string | undefined;
}

interface Props {
  isHidden: boolean;
  selectedQuestions: BulkActionUtr[];
  numericSelectedQuestions: BulkActionUtr[];
  initiativeId: string;
  handleClick: () => void;
  handleClose: (props?: { reloadSurvey: boolean }) => Promise<void | undefined>;
}

export const DecimalButton = (props: Props) => {
  const { isHidden, handleClick, handleClose, selectedQuestions, initiativeId, numericSelectedQuestions } = props;
  const { addSiteError } = useSiteAlert();

  const { data: initiativeUtrs = [], isFetching: isInitiativeUtrsLoading } = useGetInitiativeUniversalTrackersQuery(
    initiativeId,
    {
      skip: !initiativeId || isHidden || selectedQuestions.length === 0,
    }
  );

  const [updateInitiativeUtrsDecimal, { isLoading: isUpdating }] = useUpdateInitiativeUniversalTrackerMutation();

  const questionsWithValidation = (() => {
    const utrIds = selectedQuestions.map(q => q._id);
    return initiativeUtrs.filter(
      (utr) =>
        utrIds.includes(utr.universalTrackerId) &&
        (isDefined(utr.valueValidation.decimal) ||
          utr.valueValidation.table?.columns.some((col) => isDefined(col.validation?.decimal)))
    );
  })();

  const tableColumns = isSingleTableQuestion(numericSelectedQuestions)
    ? numericSelectedQuestions[0].valueValidation?.table?.columns?.filter((c) => isNumericTableColumnType(c.type)) || []
    : [];

  const inputType = getInputType(numericSelectedQuestions);

  const defaultDecimal = getInitialState({
    inputType,
    tableColumns,
    valueValidation: questionsWithValidation[0]?.valueValidation,
  });

  const handleUpdate = (decimal: DecimalType) => {
    const data = {
      decimal,
      initiativeId,
      utrIds: numericSelectedQuestions.map((q) => q._id),
    };
    updateInitiativeUtrsDecimal(data)
      .unwrap()
      .then(() => {
        handleClose({ reloadSurvey: true });
      })
      .catch((err) => {
        addSiteError(err);
        handleClose();
      });
  };

  if (isHidden) {
    return null;
  }

  return (
    <>
      <BulkButton tooltip={'Set decimal'} onClick={handleClick} disabled={!numericSelectedQuestions.length}>
        Set decimal
      </BulkButton>
      <DecimalModal
        isOpen={selectedQuestions.length > 0}
        isLoading={[isInitiativeUtrsLoading, isUpdating].some((loading) => loading)}
        handleClose={handleClose}
        inputType={inputType}
        selectedQuestions={selectedQuestions}
        tableColumns={tableColumns}
        handleUpdate={handleUpdate}
        questionsWithValidation={questionsWithValidation}
        defaultDecimal={defaultDecimal}
      />
    </>
  );
};
