import {
  Document,
  Header,
  VerticalPositionRelativeFrom,
  VerticalPositionAlign,
  TableRow,
  TableCell,
  Table,
  WidthType,
  TableLayoutType,
  ImageRun,
} from 'docx';
import { declarativeStyles, numberingStyles } from './styles'
import {
  heading,
  heading2,
  commentBox,
  paragraphWithBoldText,
  paragraph,
  imageWrapper,
  bulletList,
  arrayToTable,
  spacer,
  wwgLogo,
  arrayToTableRow,
  cellToTableCell,
  Appendix,
  DEFAULTS,
} from './document-structure';
import * as base64Logos from './base64Logo';
import { formatDate } from '../../utils/date';
import { TEXT } from './constants';
import { SurveyInitiative } from '../../types/survey';
import { getSDGShortTitle } from '../../constants/sdg-data';
import { renderQuestionValue, renderTable } from './questionView';
import { UtrValueTypes } from '../../utils/universalTracker';
import { ReportData } from '../../types/reportData';
import { standards } from '@g17eco/core';
import { SDGReport, SDGReportGoal } from './reportApi';
import { getLatestNote } from './group-builder';
import { QUESTION } from '../../constants/terminology';


export const SDGReportGenerator = async (survey: SurveyInitiative, data: SDGReport): Promise<Document> => {

  Appendix.init();

  const sdgLogo = new ImageRun({
    data: Uint8Array.from(atob(base64Logos['sdgLogoBase64']), c => c.charCodeAt(0)),
    transformation: {
      width: 180,
      height: 102
    }
  });

  const getGoalImage = (goalCode: string) => {
    // @ts-ignore
    const logo: any = base64Logos[`sdgGoal${goalCode}Base64`];
    return new ImageRun({
      data: Uint8Array.from(atob(logo), c => c.charCodeAt(0)),
      transformation: {
        width: 100,
        height: 100,
      },
      floating: {
        horizontalPosition: {
          offset: 5600000
        },
        verticalPosition: {
          relative: VerticalPositionRelativeFrom.LINE,
          align: VerticalPositionAlign.TOP
        },
        margins: {
          top: 201440,
          bottom: 201440,
        },
      },
    }
    )
  }

  const periodCovered = formatDate(survey.effectiveDate, 'MMMM YYYY');
  const materialSDGs = Object.keys(data.materiality)
    .filter(k => [100].includes(data.materiality[Number(k)]))
    .map(k => Number(k));
  const alignedSDGs = Object.keys(data.materiality)
    .filter(k => [33, 66].includes(data.materiality[Number(k)]))
    .map(k => Number(k));

  const highMaterialityGoals = data.goals.filter(d => materialSDGs.includes(Number(d.goal)));

  const getValueStr = (m: ReportData) => {
    if (m.universalTracker.valueType === UtrValueTypes.table) {
      const table = renderTable({ question: m, returnEmpty: false, unitConfig: survey.unitConfig });
      if (!table) {
        return;
      }

      const note = getLatestNote(m)

      return Appendix.add(m.universalTracker.name, table, note ? paragraph(`Additional notes: ${note}`) : undefined);
    }

    return renderQuestionValue(m, survey.unitConfig);
  }

  const sdgTargetTable = (d: SDGReportGoal) => {

    const headerRow = [
      'SDG Target',
      'Metric Name',
      'Sources',
      'Answer',
      'Target Contribution',
    ];
    const totalWidth = 9000;
    const colWidth = Math.floor(totalWidth / headerRow.length);

    return new Table({
      rows: [
        arrayToTableRow(headerRow, { style: 'bold', shading: DEFAULTS.TABLE_HEADER_SHADING }),
        ...(d.contribution.targets ?? []).map(t => {
          const targetCode = t.sdgCode;
          const metrics = d.metricsIncluded
            .filter(m => ['verified', 'updated'].includes(m.status))
            .filter(m => m.universalTracker.tags?.sdg.includes(targetCode));

          const firstCol = new TableCell({
            children: [
              paragraph(targetCode)
            ],
            rowSpan: metrics.length
          });
          const lastCol = new TableCell({
            children: [
              paragraph(`${t.actual ? Math.round(t.actual) : 0}%`)
            ],
            rowSpan: metrics.length
          })

          return metrics.map((m, i) => {
            const companyResponse = getValueStr(m);

            const sources = Object.keys(m.universalTracker.alternatives ?? {}).map(k => {
              return `${k} ${m.universalTracker.alternatives?.[k].typeCode}`.toUpperCase();
            });
            const validStandards = Object.keys(standards);
            if (validStandards.includes(m.universalTracker.type)) {
              sources.push(`${m.universalTracker.type} ${m.universalTracker.typeCode}`.toUpperCase());
            }

            const cells: TableCell[] = [
              cellToTableCell(`${m.universalTracker.name}`),
              cellToTableCell(`${sources.join(', ')}`),
              cellToTableCell(companyResponse ?? ''),
            ];

            if (i === 0) {
              cells.unshift(firstCol);
              cells.push(lastCol);
            }
            return new TableRow({ children: cells });
          })
        }).flat()
      ],
      columnWidths: Array.from({ length: headerRow.length }, (_) => colWidth),
      width: {
        size: totalWidth,
        type: WidthType.DXA,
      },
      layout: TableLayoutType.AUTOFIT
    });
  }

  const sdgGoalReports = highMaterialityGoals.map(d => {
    const code = d.goal;

    return [
      // pagebreak(),
      imageWrapper(getGoalImage(code)),
      heading2(`SDG ${code} Overview`),
      paragraph(`SDG ${code}: ${getSDGShortTitle(code)}`),

      paragraph(`Metrics Included in SDG ${code}`, { style: 'bold' }),

      ...d.metricsIncluded
        .filter(m => ['verified', 'updated'].includes(m.status))
        .map(m => bulletList(m.universalTracker.name)),

      spacer(),
      paragraphWithBoldText(`SDG ${code} Contribution: `, `${!isNaN(d.contribution.actual) ? Math.round(d.contribution.actual) : 0}%`),
      paragraph('Target Breakdown Contribution', { style: 'bold' }),

      sdgTargetTable(d),
      // ...d.contribution.targets?.map(t => bulletList(`${t.sdgCode}: ${!isNaN(t.actual) ? Math.round(t.actual) : 0}%`)) ?? [],
      spacer(),

      paragraphWithBoldText(TEXT.CONTEXTUAL_COMMENTS_TITLE, TEXT.CONTEXTUAL_COMMENTS_BODY),
      commentBox(''),

      spacer(),
      paragraph(`Most Frequent Standards and Frameworks Included in SDG ${code}`, { style: 'bold' }),
      ...d.standardsOrFrameworks?.map(txt => bulletList(txt.toUpperCase())) ?? [],

      spacer(),
      paragraph(`Breakdown of ${QUESTION.PLURAL} answered in SDG ${code}`, { style: 'bold' }),
      arrayToTable([
        [
          `Total Qs in SDG ${code}`,
          'Number of Qs Answered',
          '% of Qs marked Not Applicable',
          '% of Qs marked Not Reporting',
          '% of Qs Verified',
          '% of Qs with Evidence'
        ],
        [
          `${d.statusSummary.total}`,
          `${d.statusSummary.completed}`,
          `${Math.round(100 * d.statusSummary.na / d.statusSummary.total)}%`,
          `${Math.round(100 * d.statusSummary.nr / d.statusSummary.total)}%`,
          `${Math.round(100 * d.statusSummary.verified / d.statusSummary.total)}%`,
          `${Math.round(100 * d.statusSummary.evidenced / d.statusSummary.total)}%`
        ]
      ]),
      spacer(),

    ];
  });

  const overallSummary = highMaterialityGoals.map(d => {
    const code = d.goal;

    return [
      code,
      `${d.contribution.actual ? Math.round(d.contribution.actual) : 0}%`,
      `${d.contribution.target ? Math.round(d.contribution.target) : 0}%`,
    ]
  });

  const document = new Document({
    styles: {
      paragraphStyles: declarativeStyles
    },
    numbering: {
      config: numberingStyles
    },
    sections: [
      {
        headers: {
          default: new Header({
            children: [
              imageWrapper(sdgLogo),
              imageWrapper(wwgLogo)
            ]
          })
        },

        children: [
          heading('SDG Report'),

          paragraphWithBoldText('Company Name: ', survey.initiative.name),
          paragraphWithBoldText('Company Sector: ', survey.initiative.industryText),
          paragraphWithBoldText('Report Date: ', periodCovered),
          paragraphWithBoldText('High Material SDGs: ', materialSDGs.join(', ')),
          alignedSDGs.length > 0 ? paragraphWithBoldText('Other SDGs aligned to: ', alignedSDGs.join(', ')) : spacer(0),
          spacer(),

          ...sdgGoalReports.flat(),

          spacer(),
          heading2('Overall SDG Summary'),

          arrayToTable([
            [
              'Material SDG',
              'SDG Actual Contribution',
              'SDG Custom Target Contribution',
            ],
            ...overallSummary
          ]),
          paragraphWithBoldText('Total SDG Contribution: ', `${data.contribution.actual ? Math.round(data.contribution.actual) : 0}%`),

          ...Appendix.render()
        ]
      }
    ],
  });

  return document;
}
