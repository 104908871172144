import { FeatureCode } from '@g17eco/core';
import { FeatureStability } from '@g17eco/molecules';

export interface InsightDashboardOption {
  value: string;
  label: string;
  isCustom?: boolean;
  disabled?: boolean;
  tooltip?: string;
  isSharedByParent?: boolean;
  isStaffOnly?: boolean;
}

export type AddDashboardOption = Omit<InsightDashboardOption, 'label'> & { label: JSX.Element };

const getStaffOption = (label: string) => <span><FeatureStability feature={FeatureCode.CustomDashboards} className='mr-1'/> {label}</span>
// demo options
export const staffOnlyOptions: AddDashboardOption[] = [
  { value: 'overview-staff-only', label: getStaffOption('Overview'), isStaffOnly: true },
  { value: 'environmental-staff-only', label: getStaffOption('Environmental'), isStaffOnly: true },
  { value: 'social-staff-only', label: getStaffOption('Social'), isStaffOnly: true },
  { value: 'governance-staff-only', label: getStaffOption('Governance'), isStaffOnly: true },
];

export const TOOLTIP_MESSAGE = {
  NOT_AVAILABLE_PLAN: 'Not available on your current plan',
  IS_SHARED_BY_PARENT:
    'Dashboard template will be made available to subsidiaries, but will be populated with their data. Any changes to this master dashboard will be pushed to subsidiaries automatically',
};

export const isDemoOption = (key: string) => {
  return staffOnlyOptions.some((option) => option.value === key);
};

export const getDashboardOption = (canAccessCustomDashboards: boolean): AddDashboardOption => {
  return {
    value: 'add-dashboard',
    label: (
      <>
        <i className='fa-light fa-plus mr-2' />
        Add dashboard
      </>
    ),
    disabled: !canAccessCustomDashboards,
    tooltip: !canAccessCustomDashboards ? TOOLTIP_MESSAGE.NOT_AVAILABLE_PLAN : undefined,
  };
};
