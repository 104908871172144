import GridLayout, { Layout } from 'react-grid-layout';
import { GridDashboardItem } from '../../types/insight-custom-dashboard';
import { GridItem, GridItemProps, UtrvHistoryModalProps } from './GridItem';
import './styles.scss';
import { COLUMNS, GRID_WIDTH, ITEM_GAP, ROW_HEIGHT, getLayoutByItems } from './utils';

type GridLayoutDashboardProps = Pick<
  GridItemProps,
  | 'utrsData'
  | 'isEditing'
  | 'onItemRemove'
  | 'handleClickEdit'
  | 'readOnly'
  | 'hideQuestionReference'
  | 'initiativeId'
  | 'survey'
  | 'actionBtn'
> & {
  handleOpenUtrvHistoryModal?: (params: UtrvHistoryModalProps) => void;
  gridItems: GridDashboardItem[];
  onLayoutChange?: (layout: Layout[]) => void;
  onItemResize?: (newItemSize: Layout) => void;
};

export const GridLayoutDashboard = (props: GridLayoutDashboardProps) => {
  const {
    isEditing,
    gridItems,
    utrsData,
    onLayoutChange,
    onItemRemove,
    onItemResize,
    handleClickEdit,
    handleOpenUtrvHistoryModal = () => {},
    readOnly = false,
    hideQuestionReference = false,
    initiativeId,
    actionBtn,
    survey,
  } = props;

  return (
    <GridLayout
      className='layout position-relative mb-5'
      cols={COLUMNS}
      width={GRID_WIDTH}
      rowHeight={ROW_HEIGHT}
      margin={[ITEM_GAP, ITEM_GAP]}
      onResize={(currentLayout, oldItem, newItem) => onItemResize?.(newItem)}
      layout={getLayoutByItems(gridItems)}
      isDraggable={isEditing}
      isResizable={isEditing}
      onLayoutChange={onLayoutChange}
    >
      {gridItems.map((item) => (
        <div key={item._id}>
          <GridItem
            key={item._id}
            utrsData={utrsData}
            item={item}
            isEditing={isEditing}
            onItemRemove={onItemRemove}
            handleClickEdit={handleClickEdit}
            handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
            readOnly={readOnly}
            hideQuestionReference={hideQuestionReference}
            initiativeId={initiativeId}
            survey={survey}
            actionBtn={actionBtn}
          />
        </div>
      ))}
    </GridLayout>
  );
};
