import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ROUTES } from '../../constants/routes';
import { generateUrl } from '../../routes/util';
import { QUESTION } from '../../constants/terminology';

interface AddAssignedMetricsModalProps {
  initiativeId: string;
  isOpen: boolean;
  handleClose: () => void;
  addAssignedMetrics: () => Promise<void>;
}

export const AddAssignedMetricsModal = (props: AddAssignedMetricsModalProps) => {
  const { initiativeId, isOpen, handleClose, addAssignedMetrics } = props;
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      await addAssignedMetrics();
      setIsSaving(false);
      handleClose();
    } catch (e) {
      setIsSaving(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} backdrop='static' className='add-assigned-metrics'>
      <ModalHeader toggle={handleClose}>Additional {QUESTION.PLURAL}</ModalHeader>
      <ModalBody>
        <div className='text-md'>
          The bank you have selected has some {QUESTION.PLURAL} that they would like their client companies to answer. The
          {QUESTION.PLURAL} are in a survey pack that is now available in your survey settings, under the{' '}
          <a
            href={generateUrl(ROUTES.CUSTOM_METRICS, { initiativeId })}
            target={'_blank'}
            className='text-decoration-underline text-ThemeTextMedium'
            rel='noreferrer'
          >
            survey scope section here
          </a>
          . You can add the survey pack to current or future surveys. When you have completed the survey pack, the data
          will be sent to the bank. These sharing settings can be managed in the{' '}
          <a
            href={generateUrl(ROUTES.DATA_SHARE_INITIATIVE, { initiativeId })}
            target={'_blank'}
            className='text-decoration-underline text-ThemeTextMedium'
            rel='noreferrer'
          >
            Data sharing section in the Admin section here
          </a>
          .
        </div>
      </ModalBody>
      <ModalFooter className='pt-0'>
        <Button color='link' className='mr-4' onClick={handleClose}>
          Okay, ignore for now
        </Button>
        <Button disabled={isSaving} color='primary' onClick={() => handleSubmit()}>
          Add {QUESTION.PLURAL} to all current surveys
        </Button>
      </ModalFooter>
    </Modal>
  );
};
