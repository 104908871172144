import Select, { ActionMeta, SingleValue } from 'react-select';
import { QuestionData, QuestionFilterType } from '../../../utils';
import { QuestionWarning } from './QuestionWarning';
import { useQuestionFilters } from '../../../hooks/useQuestionFilters';
import { getStyles } from '../../../../../components/select-packs';
import { canAddTarget } from '../../../../../utils/universalTracker';
import { QUESTION } from '../../../../../constants/terminology';
import { useGetInitiativeBlueprintQuestionsQuery } from '../../../../../api/admin-dashboard';
import { Option, QuestionInputOption, simpleNumericTypes } from './utils';
import { emptyUtrData } from '../../charts/chart-adding-modal/utils';
import { getMetricUnitDesc } from '@components/utr-modal/components/chart';
import UniversalTracker from '../../../../../model/UniversalTracker';
import { DEFAULT_METRIC_UNIT } from '@constants/utr';
import { useState } from 'react';

interface QuestionSelectingFilterProps {
  initiativeId: string;
  handleSelect: (data: QuestionData) => void;
  questionData?: QuestionData;
  lockedUnit?: string | null;
}

const dropdownStyles = getStyles();

export const QuestionSelectingFilter = ({
  initiativeId,
  handleSelect,
  questionData,
  lockedUnit = DEFAULT_METRIC_UNIT,
}: QuestionSelectingFilterProps) => {
  const { data: blueprintQuestions = [] } = useGetInitiativeBlueprintQuestionsQuery({ initiativeId });
  const {
    packOptions,
    subPacks,
    filteredQuestions,
    questionInputs,
    filters,
    filterOption,
    selectedQuestionCode,
    setSelectedQuestionCode,
    handleChangeFilters,
  } = useQuestionFilters({ initiativeId, questionData, blueprintQuestions });
  const selectedQuestion = blueprintQuestions.find((q) => q.code === selectedQuestionCode);
  const isSupportedType = selectedQuestion && canAddTarget(selectedQuestion);
  const [isMatchedUnit, setIsMatchedUnit] = useState(true);

  const onChangePack = (option: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    setSelectedQuestionCode('');
    handleChangeFilters(QuestionFilterType.Pack, actionMeta, option?.value);
    handleSelect({ ...emptyUtrData, groupCode: option?.value });
  };

  const onChangeSubPack = (option: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    setSelectedQuestionCode('');
    handleChangeFilters(QuestionFilterType.Subpack, actionMeta, option?.value);
    handleSelect({ ...emptyUtrData, groupCode: filters.pack, subGroupCode: option?.value });
  };

  const onChangeQuestion = (option: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
    const newQuestion = blueprintQuestions.find((q) => q.code === option?.value);
    if (!newQuestion) {
      return;
    }

    setSelectedQuestionCode(newQuestion.code);
    handleChangeFilters(QuestionFilterType.Question, actionMeta, newQuestion.code);
    const isSimpleNumericValue = simpleNumericTypes.includes(newQuestion.valueType);

    const isMatched =
      lockedUnit === DEFAULT_METRIC_UNIT || getMetricUnitDesc(new UniversalTracker(newQuestion)) === lockedUnit;

    // not simple numeric value means matched unit logic will be taken care by the valueListCode, not here
    setIsMatchedUnit(!isSimpleNumericValue ? true : isMatched);

    if (isSimpleNumericValue && option?.value && isMatched) {
      handleSelect({
        ...emptyUtrData,
        code: newQuestion.code,
        groupCode: filters.pack,
        subGroupCode: filters.subPack,
      });
      return;
    }
    handleSelect({ ...emptyUtrData, groupCode: filters.pack, subGroupCode: filters.subPack });
  };

  const onChangeQuestionInput = (
    option: SingleValue<QuestionInputOption>,
    actionMeta: ActionMeta<QuestionInputOption>
  ) => {
    handleChangeFilters(QuestionFilterType.Input, actionMeta, option?.value);
    if (!option) {
      handleSelect({
        valueListCode: '',
      });
      return;
    }
    const newQuestion = blueprintQuestions.find((q) => q.code === filters.question);
    if (!newQuestion) {
      return;
    }

    const isMatched =
      lockedUnit === DEFAULT_METRIC_UNIT ||
      getMetricUnitDesc(new UniversalTracker(newQuestion), option.value) === lockedUnit;

    setIsMatchedUnit(isMatched);
    
    if (selectedQuestion && filters.question && isMatched) {
      handleSelect({
        valueListCode: option.value,
        code: filters.question,
        groupCode: filters.pack,
        subGroupCode: filters.subPack,
      });
    }
  };

  return (
    <>
      <Select<Option>
        placeholder='Select survey pack'
        styles={dropdownStyles}
        options={packOptions}
        value={packOptions.find((v) => v.value === filters.pack) ?? null}
        onChange={onChangePack}
        filterOption={filterOption}
      />
      <Select<Option>
        isDisabled={subPacks.length === 0}
        className='mt-3'
        placeholder='Select sub-pack (optional)'
        styles={dropdownStyles}
        options={subPacks}
        value={subPacks.find((v) => v.value === filters.subPack) ?? null}
        onChange={onChangeSubPack}
        filterOption={filterOption}
        isClearable
      />
      <Select<Option>
        isDisabled={filteredQuestions.length === 0}
        className='mt-3'
        placeholder={`Select ${QUESTION.SINGULAR}`}
        styles={dropdownStyles}
        options={filteredQuestions}
        value={filteredQuestions.find((v) => v.value === filters.question) ?? null}
        onChange={onChangeQuestion}
        filterOption={filterOption}
      />
      <QuestionWarning selectedQuestion={selectedQuestion} isMatchedUnit={isMatchedUnit} />
      <Select<QuestionInputOption>
        isDisabled={questionInputs.length === 0 || !isSupportedType}
        className='mt-3'
        placeholder='Select input'
        styles={dropdownStyles}
        options={questionInputs}
        isOptionDisabled={(option) => option.disabled}
        value={questionInputs.find((v) => v.value === filters.input) ?? null}
        onChange={onChangeQuestionInput}
        filterOption={filterOption}
      />
    </>
  );
};
