import { Layout } from 'react-grid-layout';
import { UtrVariables } from '../routes/summary/insights/utils/constants';
import { HistoricalUtrs } from '../api/insights';
import { ScorecardResponse } from './scorecard';
import { ValueStages } from '@services/aggregation/ValueStage';
import { SurveyType } from './survey';

export enum InsightDashboardItemType {
  Chart = 'chart',
  Headline = 'headline',
  Text = 'text',
  SDGTracker = 'sdg-tracker',
  Table = 'table',
}

export enum InsightDashboardTempItemType {
  TempChart = 'temp-chart',
}

export type InsightDashboardItemChartType = InsightDashboardItemType.Chart | InsightDashboardItemType.SDGTracker;

export type InsightDashboardItemTextType = InsightDashboardItemType.Headline | InsightDashboardItemType.Text;

export enum CalculationType {
  Sum = 'sum',
  Percentage = 'percentage',
  Formula = 'formula',
  Stages = 'stages',
}

export enum ValueRole {
  Annotation = 'annotation',
  Style = 'style',
}

export enum TableWidgetColumn {
  Name = 'name',
  Unit = 'unit',
}

type Tooltip = { formula: string };
export interface CalculationValue {
  name: string;
  formula?: string;
  role?: ValueRole;
  stages?: ValueStages;
  decimalPlaces?: number;
  suffix?: string;
  options?: { style: string; tooltip: Tooltip };
}

export interface Calculation {
  type: CalculationType;
  values: CalculationValue[];
  headers?: ({ name: string } | { role: ValueRole })[];
}

export interface Note {
  prefix?: string;
  value?: string;
  valueUnit?: string;
  postfix?: string;
}

interface DashboardItemConfig {
  hiddenColumns?: string[];
}

type RequireSome<T, K extends keyof T> = Partial<T> & Required<Pick<T, K>>;

export type GridDashboardItemBase =
  | GridDashboardTextItemBase
  | GridDashboardTableItemBase
  | GridDashboardChartItemBase
  | GridDashboardSDGChartItemBase;

export type GridDashboardItem =
  | GridDashboardTextItem
  | GridDashboardTableItem
  | GridDashboardChartItem
  | GridDashboardSDGChartItem;

export type GridDashboardItemCreate = GridDashboardItemBase | GridDashboardItem;

export interface GridDashboardTempItem {
  type: InsightDashboardTempItemType;
}

export interface GridDashboardTempChartItem extends GridDashboardTempItem {
  type: InsightDashboardTempItemType.TempChart;
}

export interface ChartDataLoaded<T = unknown> {
  chartData: T[][];
  effectiveDate?: string;
  period?: string;
}

export type DashboardGridSize = Pick<Layout, 'x' | 'y' | 'w' | 'h' | 'minW' | 'minH' | 'maxW' | 'maxH'>;

export interface GridDashboardTextItem {
  _id: string;
  gridSize: DashboardGridSize;
  type: InsightDashboardItemType.Text | InsightDashboardItemType.Headline;
  text: string;
}

export type GridDashboardTextItemBase = RequireSome<Omit<GridDashboardTextItem, '_id'>, 'type'>;

export interface GridDashboardTableItem {
  _id: string;
  gridSize: DashboardGridSize;
  type: InsightDashboardItemType.Table;
  subType: TableSubType;
  variables: UtrVariables;
  calculation: Calculation;
  title?: string;
  config?: DashboardItemConfig;
}

export type GridDashboardTableItemBase = RequireSome<Omit<GridDashboardTableItem, '_id'>, 'type'>;

export interface GridDashboardChartItem {
  _id: string;
  gridSize: DashboardGridSize;
  type: InsightDashboardItemType.Chart;
  subType: ChartSubType;
  variables: UtrVariables;
  calculation?: Calculation;
  icon?: string;
  title?: string;
  note?: Note;
  unitText?: string;
}

export type GridDashboardChartItemBase = RequireSome<Omit<GridDashboardChartItem, '_id'>, 'type'>;

export interface GridDashboardSDGChartItem extends Omit<GridDashboardChartItem, 'type'> {
  type: InsightDashboardItemType.SDGTracker;
}

export type GridDashboardSDGChartItemBase = RequireSome<Omit<GridDashboardSDGChartItem, '_id'>, 'type'>;

export type OverviewDashboardItem = GridDashboardItem & {
  isDefault?: boolean;
  note?: Note;
  subType?: SubType;
};

export enum UtrvFilter {
  Verified = 'verified',
  Assured = 'assured',
}

export enum SurveyFilter {
  All = 'all',
  Completed = 'completed',
}

export enum PrivacyFilter {
  All = 'all',
  Public = 'public',
}

export type InsightDashboardFilterKeys = keyof InsightDashboard['filters'];

export interface ToggleFilter {
  enabled: boolean;
}

export interface SharingItem {
  enabled: boolean;
  token: string;
}

export interface InsightDashboard<ITEM = GridDashboardItem> {
  _id: string;
  initiativeId: string;
  title: string;
  filters: {
    utrv: UtrvFilter;
    survey: SurveyFilter;
    privacy: PrivacyFilter;
    initiativeInfo?: ToggleFilter;
    sdgContribution?: ToggleFilter;
    baselinesTargets?: ToggleFilter;
    shareWithSubsidiaries?: ToggleFilter;
  };
  items: ITEM[];
  utrsData: HistoricalUtrs[];
  scorecard?: ScorecardResponse;
  share?: SharingItem[];
  type: InsightDashboardType;
}

export type OverviewDashboard = InsightDashboard<OverviewDashboardItem>;

export enum ChartSubType {
  Line = 'line',
  SingleValue = 'singleValue',
  SparkLine = 'sparkLine',
  Pie = 'pie',
  PieWithLegend = 'pieWithLegend',
  Column = 'column',
  Bar = 'bar',
  Table = 'table',
  Stacked = 'stacked',
}

// extendable for future types
export type SubType = ChartSubType | TableSubType;

export enum TableSubType {
  Table = 'table',
  DoubleTable = 'doubleTable',
}

export enum InsightDashboardType {
  Custom = 'custom',
  Overview = 'overview',
  Environmental = 'environmental',
  Social = 'social',
  Governance = 'governance',
  SgxEnvironmental = 'sgx_environmental',
  SgxSocial = 'sgx_social',
  SgxGovernance = 'sgx_governance',
}

export const SummaryPages = [
  InsightDashboardType.Overview,
  InsightDashboardType.Environmental,
  InsightDashboardType.Social,
  InsightDashboardType.Governance,
  InsightDashboardType.SgxEnvironmental,
  InsightDashboardType.SgxSocial,
  InsightDashboardType.SgxGovernance,
];

export enum InsightDashboardFilters {
  Utrv = 'utrv',
  Survey = 'survey',
  Privacy = 'privacy',
  InitiativeInfo = 'initiativeInfo',
  SdgContribution = 'sdgContribution',
  BaselinesTargets = 'baselinesTargets',
  ShareWithSubsidiaries = 'shareWithSubsidiaries',
}

export type DashboardSurveyType = SurveyType.Default | SurveyType.Aggregation;
