import { useEffect, useState } from 'react';
import { SurveySettings } from '../../../types/survey';
import { DisableUtrsProps, generateDisabledUtrs } from '../../../components/survey/utils/getDisableUtrs';
import { useAppSelector } from '../../../reducers';
import { getCurrentUser } from '../../../selectors/user';
import { SurveyActionData } from '../../../model/surveyData';
import { generateSurveyQuestionsFlexSearchMap } from '../../../selectors/blueprint';
import { useDispatch } from 'react-redux';
import { loadBlueprintMapping } from '../../../actions/blueprints';
import { loadScorecardsByInitiativeId } from '../../../actions/scorecard';
import { loadDelegationUsers } from '../../../slice/surveyDelegationUsers';
import { useGetCustomTagsQuery } from '../../../api/metric-groups';

const initialFilters = {
  filterByStatus: [],
  filterByDelegationStatus: [],
  filterByMateriality: [],
  filterByGoal: [],
  filterByStandard: [],
  filterByFramework: [],
  filterByTag: [],
  searchText: '',
};

type Props = Pick<DisableUtrsProps, 'surveyGroups' | 'materiality'> & { survey?: SurveyActionData };

export const useFilterQuestions = ({ surveyGroups, survey, materiality }: Props) => {
  const blueprintState = useAppSelector((state) => state.blueprint);
  const user = useAppSelector(getCurrentUser);
  const dispatch = useDispatch();
  const { data: tags = [] } = useGetCustomTagsQuery(survey?.initiativeId ?? '', { skip: !survey?.initiativeId });

  const searchIndex = generateSurveyQuestionsFlexSearchMap(survey);

  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    if (!survey?._id || !survey?.initiativeId || !survey?.sourceName) {
      return;
    }

    dispatch(loadBlueprintMapping(survey.sourceName));
    dispatch(loadScorecardsByInitiativeId(survey.initiativeId));
    dispatch(loadDelegationUsers(survey._id));
  }, [survey?.sourceName, survey?.initiativeId, survey?._id, dispatch]);

  const changeFilter = (key: keyof SurveySettings, setting: any) => {
    setFilters((filters) => {
      return { ...filters, [key]: setting };
    });
  };

  const {
    filterByGoal,
    searchText,
    filterByStatus,
    filterByStandard,
    filterByFramework,
    filterByMateriality,
    filterByDelegationStatus,
    filterByTag,
  } = filters;

  const disabledUTRs = generateDisabledUtrs({
    surveyGroups,
    blueprint: blueprintState.data,
    materiality,
    filterByGoal,
    searchText,
    filterByStatus,
    filterByStandard,
    filterByFramework,
    filterByMateriality,
    filterByDelegationStatus,
    filterByTag,
    searchIndex,
    user,
    tags,
  });

  return { filters, changeFilter, disabledUTRs };
};
