import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'reactstrap';
import {
  SelectedTagIdsType,
  useCreateCustomTagMutation,
  useDeleteCustomTagsMutation,
  useGetCustomTagsQuery,
  useUpdateCustomTagsMutation,
} from '../../api/metric-groups';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import { Tag } from '../../types/metricGroup';
import { BasicAlertParams } from '../alert/BannerAlert';
import { BulkActionUtr } from '../survey-question-list/partials/BulkActionToolbar';
import { AddTagList } from './AddTagList';
import { ManageTags } from './ManageTags';
import './style.scss';

export interface TagModalProps {
  selectedQuestions: BulkActionUtr[];
  onToggle: () => void;
  handleReload: (props?: { reloadSurvey?: boolean }) => Promise<void | undefined>;
}

export interface Message {
  content: string;
  type: BasicAlertParams['type'];
}

export const TagsModal = (props: TagModalProps) => {
  const { onToggle, handleReload, selectedQuestions } = props;
  const { initiativeId = '' } = useParams<{ initiativeId: string }>();

  const { addSiteError } = useSiteAlert();
  const { data: tags = [], error, isFetching } = useGetCustomTagsQuery(initiativeId, { skip: !initiativeId });
  const [updateCustomTags, { isLoading: isUpdating }] = useUpdateCustomTagsMutation();
  const [createCustomTag] = useCreateCustomTagMutation();
  const [deleteCustomTags] = useDeleteCustomTagsMutation();

  const [isManageTag, setManageTag] = useState(false);
  const [message, setMessage] = useState<Message | undefined>();

  const selectedUtrIds = useMemo(() => {
    return selectedQuestions.map((question) => question._id);
  }, [selectedQuestions]);

  const addMessage = (content: string, type?: BasicAlertParams['type']) => {
    setMessage({ content, type: type || SiteAlertColors.Success });
    setTimeout(() => {
      setMessage(undefined);
    }, 2000);
  };

  const handleAddTag = async (tag: Tag['groupName']) => {
    createCustomTag({ initiativeId, groupName: tag })
      .then(() => {
        const content = `${tag} has been added`;
        addMessage(content);
      })
      .catch((e) => {
        addMessage(e.message, SiteAlertColors.Danger);
      });
  };

  const handleDeleteTags = (ids: Tag['_id'][]) => {
    deleteCustomTags({ initiativeId, ids })
      .then(() => {
        const content = 'Tags have been deleted successfully!';
        addMessage(content);
      })
      .catch((e) => {
        addMessage(e.message, SiteAlertColors.Danger);
      });
  };

  const handleAddUtrsToTags = (selectedTagIds: SelectedTagIdsType) => {
    updateCustomTags({ initiativeId, updatedTagIds: selectedTagIds, utrIds: selectedUtrIds })
      .unwrap()
      .then(() => {
        handleReload({ reloadSurvey: true });
      })
      .catch((e) => {
        addSiteError(e.message);
        handleReload();
      });
  };

  const onClose = () => {
    setManageTag(false);
    onToggle();
  };

  const renderModal = () => {
    if (isManageTag) {
      return (
        <ManageTags
          tags={tags}
          initiativeId={initiativeId}
          message={message}
          handleAddTag={handleAddTag}
          handleDeleteTags={handleDeleteTags}
          toggleManageTag={() => setManageTag(false)}
          onClose={onClose}
        />
      );
    }
    return (
      <AddTagList
        tags={tags}
        isLoading={isFetching || isUpdating}
        tagListError={error}
        selectedUtrIds={selectedUtrIds}
        onClose={onClose}
        openManageTags={() => setManageTag(true)}
        handleAddUtrsToTags={handleAddUtrsToTags}
      />
    );
  };

  return (
    <>
      <Modal isOpen={selectedQuestions.length > 0} toggle={onClose} backdrop='static' size='sm' className='custom-tag__container'>
        {renderModal()}
      </Modal>
    </>
  );
};
