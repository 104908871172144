import React from 'react'
import './routeErrorBoundary.scss';
import * as Sentry from '@sentry/browser';
import { handleRouteError } from '../../logger';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Explosion from '../../images/explosion.svg';
import config from '../../config';

class ContentErrorBoundary extends React.Component<RouteComponentProps> {

  state = {
    hasError: false,
    error: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (this.state.hasError && prevProps.location.key !== this.props.location.key) {
      // Reset error on location change
      this.setState({ hasError: false, error: undefined });
    }
  }

  handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    window.location.reload();
  }

  handleReport() {
    Sentry.showReportDialog();
    window.open(config.reportURL, '_blank', '');
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    handleRouteError(error, errorInfo as any);
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }
    return (
      <div className='error-boundary-container d-flex flex-md-row flex-column'>
        <div className='section col-12 col-md-6'>
          <div>
            <h1 className='my-3'>Oh no!<br />It looks like there is an issue</h1>
            <p>
              Our development team has received an error message, and will investigate. If it is urgent, please report
              the issue or use the chat bot at the bottom of the page.
            </p>
          </div>
          <div className='text-center text-md-left'>
            <button type='button' className='btn btn-outline-secondary mt-2' onClick={this.handleClick}>
              Retry
            </button>
            <button type='button' className='ml-3 btn btn-secondary mt-2' onClick={this.handleReport}>
              Report issue
            </button>
          </div>
        </div>
        <div className='section col-12 col-md-6'>
          <img draggable={false} className='logo' src={Explosion} alt='G17Eco' />
        </div>
      </div>
    );
  }
}

export default withRouter(ContentErrorBoundary);