import { MetricActionType } from '../components/survey/overview/constants';
import { SurveyActionData } from '../model/surveyData';
import { ApiResponse } from '../types/api';
import { g17ecoApi, transformResponse } from './g17ecoApi';

interface SurveyScopeUpdateMutationParams {
  action: MetricActionType;
  surveyId: string;
}

export const SURVEY_DATA_TAG = 'survey';

export const surveyScopeUpdateApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['surveyScopeUpdate', SURVEY_DATA_TAG],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      handleScopeUpdate: builder.mutation<ApiResponse, SurveyScopeUpdateMutationParams>({
        invalidatesTags: ['surveyScopeUpdate'],
        query: ({ action, surveyId }) => ({
          url: `/surveys/${surveyId}/blueprint/${action}`,
          method: 'patch',
        }),
      }),
      getSurveyById: builder.query<SurveyActionData, string>({
        transformResponse,
        query: (surveyId) => ({
          url: `/surveys/${surveyId}`,
          method: 'get',
        }),
        providesTags: [SURVEY_DATA_TAG],
      }),
    }),
  });

export const { useHandleScopeUpdateMutation, useGetSurveyByIdQuery } = surveyScopeUpdateApi;
