import { useGetSummaryInsightDashboardQuery } from '../../../api/insight-dashboards';
import { GridLayoutDashboard } from '../../custom-dashboard/GridLayoutDashboard';
import { GridDashboardItem, OverviewDashboard } from '../../../types/insight-custom-dashboard';
import { AddToOverviewBtn } from './AddToOverviewBtn';
import { useSelector } from 'react-redux';
import { selectMostRecentSurvey } from '../../../slice/initiativeSurveyListSlice';
import { useGetSurveyByIdQuery } from '../../../api/surveys';
import { DashboardSection } from '../../../components/dashboard';
import { SdgChart } from '../../../components/impact-performance/SdgChart';
import { CompanyProfile } from '../../../components/company-profile';
import { InitiativeData } from '../../../types/initiative';
import { SurveyType } from '@g17eco/types/survey';

interface Props {
  initiative: InitiativeData | undefined;
  dashboard: OverviewDashboard;
  initiativeId: string;
}

export const Summary = ({ initiative, dashboard, initiativeId }: Props) => {
  const { data: overview, isFetching: isFetchingOverview } = useGetSummaryInsightDashboardQuery(
    { dashboardId: 'overview', initiativeId, queryParams: { surveyType: SurveyType.Default } },
    { skip: !initiativeId }
  );

  const mostRecentSurvey = useSelector(selectMostRecentSurvey);
  const { data: latestSurvey, isFetching: isFetchingLatestSurvey } = useGetSurveyByIdQuery(
    mostRecentSurvey?._id ?? '',
    {
      skip: !mostRecentSurvey?._id,
    }
  );

  return (
    <>
      {dashboard.filters.initiativeInfo?.enabled ? <CompanyProfile initiative={initiative} readOnly={false} /> : null}
      {dashboard.filters.sdgContribution?.enabled && dashboard.scorecard ? (
        <DashboardSection>
          <SdgChart initiativeId={initiativeId} scorecard={dashboard.scorecard} />
        </DashboardSection>
      ) : null}
      <GridLayoutDashboard
        isEditing={false}
        gridItems={dashboard.items}
        utrsData={dashboard.utrsData}
        readOnly
        initiativeId={initiativeId}
        actionBtn={({ item }: { item: GridDashboardItem }) => (
          <AddToOverviewBtn
            initiativeId={initiativeId}
            overviewItems={overview?.items ?? []}
            item={item}
            disabled={isFetchingOverview || isFetchingLatestSurvey}
          />
        )}
        survey={latestSurvey}
      />
    </>
  );
};
