import { useState } from 'react';
import Dashboard from '../../../components/dashboard';
import { PortfolioInsightsSidebar } from '../../summary/insights/partials/sidebar/PortfolioInsightsSidebar';

import { useHistory, useParams } from 'react-router-dom';
import { HistoricalUtrs, HistoricalUtrsQueryByCodeParams } from '../../../api/insights';
import {
  useDeletePortfolioInsightDashboardMutation,
  useDuplicatePortfolioInsightDashboardMutation,
  useGetInsightDashboardByPortfolioIdQuery,
  useLazyGetPortfolioHistoricalUtrsByCodesQuery,
  useUpdatePortfolioInsightDashboardMutation,
} from '../../../api/portfolio-insight-dashboards';
import { BasicAlert } from '../../../components/alert/BasicAlert';
import Loader from '../../../components/loader';
import { UtrvHistoryModalTemplate } from '../../../components/utr-modal/UtrvHistoryModalTemplate';
import { ROUTES } from '../../../constants/routes';
import UniversalTrackerModalService from '../../../model/UniversalTrackerModalService';
import { GridDashboardItem, InsightDashboard, InsightDashboardFilters } from '../../../types/insight-custom-dashboard';
import { CustomDashboard } from '../../custom-dashboard/CustomDashboard';
import { CustomDashboardEditing } from '../../custom-dashboard/CustomDashboardEditing';
import { CustomDashboardWrapper } from '../../custom-dashboard/context/CustomDashboardWrapper';
import { UtrModalBody } from '../../custom-dashboard/shared-dashboard/UtrModalBody';
import { hasUtrvHistoryModal } from '../../custom-dashboard/utils';
import { generateUrl } from '../../util';
import { useAppSelector } from '../../../reducers';
import { currentPortfolio } from '../../../selectors/portfolio';
import { SafeInitiativeFields } from '../../../types/initiative';
import LoadingPlaceholder from '@components/LoaderContainer/LoadingPlaceholder';

export const PortfolioCustomDashboardRoute = () => {
  const history = useHistory();
  const { portfolioId = '', dashboardId = '' } = useParams<{ portfolioId?: string; dashboardId?: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [selectingUtrData, setSelectingUtrData] = useState<UniversalTrackerModalService | undefined>(undefined);
  const portfolio = useAppSelector(currentPortfolio);

  const [updateDashboard, { isLoading: isUpdating, error: updateError }] =
    useUpdatePortfolioInsightDashboardMutation();
  const [deleteDashboard, { isLoading: isDeleting, error: deleteError }] =
    useDeletePortfolioInsightDashboardMutation();
  const [duplicateDashboard] = useDuplicatePortfolioInsightDashboardMutation();
  const [getHistoricalUtrsByCodes, { isLoading: isLoadingUtrsData }] = useLazyGetPortfolioHistoricalUtrsByCodesQuery();
  const {
    data: dashboard,
    isFetching,
    error,
  } = useGetInsightDashboardByPortfolioIdQuery({ initiativeId: portfolioId, dashboardId });

  if (!dashboard || !portfolio) {
    return (
      <Dashboard className={'insights-dashboard'} hasSidebar={true}>
        <PortfolioInsightsSidebar portfolioId={portfolioId} dashboardId={dashboardId} />
        <LoadingPlaceholder height={600} />
      </Dashboard>
    );
  }

  const initiative: SafeInitiativeFields = {
    _id: portfolio._id,
    name: portfolio.name,
    profile: portfolio.profile || '',
    description: portfolio.description,
    missionStatement: portfolio.missionStatement,
    industryText: '',
    sectorText: '',
  };

  const handleClickEdit = () => setIsEditing(true);

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async (changes: Partial<InsightDashboard>, keepEditing?: boolean) => {
    await updateDashboard({ ...dashboard, ...changes, initiativeId: portfolioId });

    if (!keepEditing) {
      setIsEditing(false);
    }
  };

  const handleDelete = async () => {
    await deleteDashboard({ dashboardId, initiativeId: portfolioId });
    setIsEditing(false);
    const url = generateUrl(ROUTES.PORTFOLIO_TRACKER_INSIGHTS, { portfolioId });
    history.push(url);
  };

  const handleDuplicateDashboard = async () => {
    return duplicateDashboard({ dashboardId, initiativeId: portfolioId }).unwrap();
  };

  const handleOpenUtrvHistoryModal = ({ item, utrData }: { item: GridDashboardItem; utrData?: HistoricalUtrs }) => {
    if (!hasUtrvHistoryModal(item) || !utrData || !utrData.utrvs.length) {
      return;
    }

    const modalService = new UniversalTrackerModalService(utrData.utr);
    modalService.setUniversalTrackerValues(utrData.utrvs);
    modalService.setInitiativeId(portfolioId);
    setSelectingUtrData(modalService);
  };

  const handleGetHistoricalUtrsByCodes = ({ utrCodes: [utrCode], queryParams }: HistoricalUtrsQueryByCodeParams) => {
    return getHistoricalUtrsByCodes({ initiativeId: portfolioId, utrCodes: [utrCode], queryParams }).unwrap();
  };

  const renderCustomDashboard = () => {
    return (
      <CustomDashboardWrapper
        getHistoricalUtrsByCodes={handleGetHistoricalUtrsByCodes}
        duplicateDashboard={handleDuplicateDashboard}
        hideFilterOptions={[
          InsightDashboardFilters.Survey,
          InsightDashboardFilters.Privacy,
          InsightDashboardFilters.BaselinesTargets,
          InsightDashboardFilters.ShareWithSubsidiaries,
        ]}
        hideShareButton={true}
        hideQuestionReference={true}
        readOnly={true}
      >
        {isFetching || isUpdating || isDeleting || isLoadingUtrsData ? <Loader /> : null}
        {selectingUtrData ? (
          <UtrvHistoryModalTemplate toggle={() => setSelectingUtrData(undefined)}>
            <UtrModalBody
              utr={selectingUtrData.getUniversalTracker()}
              utrvs={selectingUtrData.getUniversalTrackerValues()}
            />
          </UtrvHistoryModalTemplate>
        ) : null}
        {isEditing ? (
          <CustomDashboardEditing
            dashboard={dashboard}
            handleCancel={handleCancel}
            handleSave={handleSave}
            handleDelete={handleDelete}
            handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
            survey={undefined}
            availablePeriods={[]}
            period={undefined}
            initiative={initiative}
          />
        ) : (
          <CustomDashboard
            dashboard={dashboard}
            handleClickEdit={handleClickEdit}
            handleSave={handleSave}
            canManage={true}
            isCurrentLevelDashboard={true}
            handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
            survey={undefined}
            availablePeriods={[]}
            initiative={initiative}
          />
        )}
      </CustomDashboardWrapper>
    );
  };

  return (
    <Dashboard className={'insights-dashboard'} hasSidebar={true}>
      <PortfolioInsightsSidebar portfolioId={portfolioId} dashboardId={dashboardId} />
      {error || updateError || deleteError ? (
        <>
          {[error, updateError, deleteError].map((error) =>
            error ? <BasicAlert type={'danger'}>{error.message}</BasicAlert> : null
          )}
        </>
      ) : null}
      {renderCustomDashboard()}
    </Dashboard>
  );
};
