import { ArchiveProgress, ArchiveStep, UserChanges } from './ArchiveSubsidiaryModal';
import Loader from '../../../../components/loader';
import Table, { TableColumn } from '../../../../components/table/Table';
import { getUserFullName } from '../../../../constants/user';
import { Button, FormGroup, Input, ModalBody, ModalFooter } from 'reactstrap';
import './UserManagementStep.scss';
import { useState } from 'react';
import Select from 'react-select';
import { InitiativePlain } from '../../../../types/initiative';
import { getSubsidiaryOptions } from '../../utils';
import { OrgMapUser, useGetCurrentAndDescendantUsersQuery } from '../../../../api/organisation-map';
import { MinReportingLevel } from '../../types';

interface UserManagementStepProps {
  selectedInitiative: MinReportingLevel;
  initiativeTreeList: InitiativePlain[];
  handleChange: (changes: Partial<ArchiveProgress>) => void;
  handleToggle: () => void;
}

export const UserManagementStep = (props: UserManagementStepProps) => {
  const { selectedInitiative, handleChange, handleToggle, initiativeTreeList } = props;

  const { data: orgMapUsers = [], isLoading } = useGetCurrentAndDescendantUsersQuery({
    initiativeId: selectedInitiative.initiativeId,
  });
  const options = getSubsidiaryOptions({
    fullTreeList: initiativeTreeList,
    excludedBranchRootId: selectedInitiative.initiativeId,
  });

  const [userChanges, setUserChanges] = useState<UserChanges>({
    reassignedInitiative: undefined,
    reassignedUsers: [],
    removedUsers: [],
  });

  const handleUserChanges = (key: keyof UserChanges, user: OrgMapUser) => {
    let newRemovedUsers: OrgMapUser[] = [];
    let newReassignedUsers: OrgMapUser[] = [];
    if (key === 'removedUsers') {
      newRemovedUsers = [...userChanges.removedUsers, user];
      newReassignedUsers = userChanges.reassignedUsers.filter((reassignedUser) => reassignedUser._id !== user._id);
    }
    if (key === 'reassignedUsers') {
      newRemovedUsers = userChanges.removedUsers.filter((removedUser) => removedUser._id !== user._id);
      newReassignedUsers = [...userChanges.reassignedUsers, user];
    }
    setUserChanges((prev) => ({
      ...prev,
      reassignedUsers: newReassignedUsers,
      removedUsers: newRemovedUsers,
    }));
  };

  const handleInitiativeChange = (option: { label: string; value: string } | null) => {
    if (option) {
      setUserChanges((prev) => ({
        ...prev,
        reassignedInitiative: {
          initiativeId: option.value,
          name: option.label,
        },
      }));
    }
  };

  const isRemovedChecked = (userId: string) => {
    return userChanges.removedUsers.some((removedUser) => removedUser._id === userId);
  };

  const isAssignedChecked = (userId: string) => {
    return userChanges.reassignedUsers.some((reassignedUser) => reassignedUser._id === userId);
  };

  const handleNext = () => {
    handleChange({
      userChanges: userChanges,
      step: ArchiveStep.Confirmation,
    });
  };

  const columns: TableColumn[] = [
    {
      Header: '',
      resizable: false,
      id: 'fullName',
      cellProps: {
        className: 'full-name__column text-truncate',
      },
      accessor: (user: OrgMapUser) => getUserFullName(user),
      disableSortBy: true,
    },
    {
      Header: 'Remove',
      resizable: false,
      id: 'remove',
      disableSortBy: true,
      accessor: (user: OrgMapUser) => (
        <FormGroup check key={user._id} className='py-2'>
          <Input
            type='radio'
            id={user._id}
            onChange={() => handleUserChanges('removedUsers', user)}
            checked={isRemovedChecked(user._id)}
          />
        </FormGroup>
      ),
    },
    {
      Header: 'Reassign',
      resizable: false,
      id: 'reassign',
      disableSortBy: true,
      accessor: (user: OrgMapUser) => (
        <FormGroup check key={user._id} className='py-2'>
          <Input
            type='radio'
            id={user._id}
            onChange={() => handleUserChanges('reassignedUsers', user)}
            checked={isAssignedChecked(user._id)}
          />
        </FormGroup>
      ),
    },
  ];

  const isDisabled =
    userChanges.removedUsers.length + userChanges.reassignedUsers.length < orgMapUsers.length ||
    (userChanges.reassignedUsers.length > 0 && !userChanges.reassignedInitiative);

  return (
    <>
      <ModalBody>
        <p>
          The following users are only part of this business unit. Please choose to remove them from your organisation,
          or reassign them as a restricted user to another business unit.
        </p>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className='user-management__list'>
              <Table data={orgMapUsers} defaultSortIcon={null} columns={columns} />
            </div>
            {userChanges.reassignedUsers.length > 0 ? (
              <div className='d-flex align-items-center justify-content-end'>
                <div className='mr-2'>Reassign to:</div>
                <Select
                  className='user-management__select'
                  placeholder={'Select your Reporting Level'}
                  options={options}
                  onChange={(option) => handleInitiativeChange(option)}
                />
              </div>
            ) : null}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color='transparent' onClick={handleToggle}>
          Cancel
        </Button>
        <Button color='primary' disabled={isDisabled} onClick={handleNext}>
          Next
        </Button>
      </ModalFooter>
    </>
  );
};
