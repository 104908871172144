import { useRouteMatch } from 'react-router-dom';
import { GridLayoutDashboard } from '../GridLayoutDashboard';
import { GRID_WIDTH, hasUtrvHistoryModal } from '../utils';
import { useGetSharableInsightDashboardByIdQuery } from '../../../api/insight-dashboards';
import { NotFound } from '../../not-found/NotFound';
import { QueryWrapper } from '../../../components/query/QueryWrapper';
import G17EcoLogo from '../../../images/g17Eco.svg';
import './styles.scss';
import { CompanyProfile } from '../../../components/company-profile';
import UniversalTrackerModalService from '../../../model/UniversalTrackerModalService';
import { useState } from 'react';
import { GridDashboardItem } from '../../../types/insight-custom-dashboard';
import { HistoricalUtrs } from '../../../api/insights';
import { UtrvHistoryModalTemplate } from '../../../components/utr-modal/UtrvHistoryModalTemplate';
import { UtrModalBody } from './UtrModalBody';
import { SdgChart } from '../../../components/impact-performance/SdgChart';

export const SharedDashboard = () => {
  const { params } = useRouteMatch<{ dashboardId?: string; token?: string }>();
  const { dashboardId = '', token = '' } = params;
  const query = useGetSharableInsightDashboardByIdQuery({ dashboardId, token }, { skip: !dashboardId || !token });

  const [selectingUtrData, setSelectingUtrData] = useState<UniversalTrackerModalService | undefined>(undefined);

  const handleOpenUtrvHistoryModal = ({
    item,
    utrData,
    initiativeId,
  }: {
    item: GridDashboardItem;
    utrData: HistoricalUtrs | undefined;
    initiativeId: string;
  }) => {
    if (!hasUtrvHistoryModal(item) || !utrData || !utrData.utrvs.length) {
      return;
    }

    const modalService = new UniversalTrackerModalService(utrData.utr);
    modalService.setUniversalTrackerValues(utrData.utrvs);
    modalService.setInitiativeId(initiativeId);
    setSelectingUtrData(modalService);
  };

  return (
    <div className='main-container'>
      <QueryWrapper
        query={query}
        onError={() => <NotFound />}
        onSuccess={({ dashboard, initiative }) => (
          <>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='p-0 mt-3 d-flex flex-column' style={{ maxWidth: `${GRID_WIDTH}px` }}>
                  {dashboard.filters.initiativeInfo?.enabled ? (
                    <CompanyProfile initiative={initiative} readOnly />
                  ) : null}
                  <h3 className='text-ThemeHeadingLight dashboard__title'>{dashboard.title}</h3>
                  {dashboard.filters.sdgContribution?.enabled && dashboard.scorecard ? (
                    <SdgChart initiativeId={dashboard.initiativeId} scorecard={dashboard.scorecard} />
                  ) : null}
                  <GridLayoutDashboard
                    isEditing={false}
                    gridItems={dashboard.items}
                    utrsData={dashboard.utrsData}
                    handleOpenUtrvHistoryModal={({ item, utrData }) =>
                      handleOpenUtrvHistoryModal({ item, utrData, initiativeId: dashboard.initiativeId })
                    }
                    readOnly
                    hideQuestionReference
                    initiativeId={dashboard.initiativeId}
                  />
                  {selectingUtrData ? (
                    <UtrvHistoryModalTemplate toggle={() => setSelectingUtrData(undefined)}>
                      <UtrModalBody
                        utr={selectingUtrData.getUniversalTracker()}
                        utrvs={selectingUtrData.getUniversalTrackerValues()}
                      />
                    </UtrvHistoryModalTemplate>
                  ) : null}
                </div>
              </div>
            </div>
            <footer className='position-relative m-0'>
              <div className='d-flex flex-column justify-content-center align-items-center text-ThemeTextPlaceholder'>
                <div className='powerByText text-ThemeTextMedium'>Powered by</div>
                <div>
                  <img src={G17EcoLogo} height={35} width={135} alt='G17Eco' />
                </div>
              </div>
            </footer>
          </>
        )}
      />
    </div>
  );
};
