import { UtrValueTypes } from '../../../../../utils/universalTracker';

export enum FilterMessage {
  Text = 'Text based answers are not currently supported',
  Table = 'Table metrics with multiple rows are not currently supported',
  Date = 'Date based answers are not currently supported',
  Generic = 'These type of answers are not currently supported',
  MisMatchedUnit = 'The selected metric unit does not match the locked metric unit',
}

export interface Option {
  value: string;
  searchString?: string;
  label: string | JSX.Element;
}

export interface QuestionInputOption extends Option {
  disabled: boolean;
}

export const simpleNumericTypes = [UtrValueTypes.number, UtrValueTypes.percentage];

export enum MetaAction {
  Select = 'select-option',
  Clear = 'clear',
}
