import { DownloadSettingPropType } from './DownloadSettingModal';
import { RadioInput } from '../common/RadioInput';
import { TotalsPropType } from './partials/Totals';
import { getAssuredOption, privacyOptions, statusOptions } from './util/downloadReportHandler';
import { QUESTION } from '../../constants/terminology';

interface DownloadQuestionSettingProps
  extends Pick<DownloadSettingPropType, 'code' | 'isDisabled' | 'downloadSettings' | 'handleChange'>,
    Pick<TotalsPropType, 'totals'> {
  isPortfolioTracker?: boolean;
}

export const DownloadQuestionSettings = ({
  code,
  isDisabled = false,
  totals,
  isPortfolioTracker = false,
  downloadSettings,
  handleChange,
}: DownloadQuestionSettingProps) => {
  if (!downloadSettings || code === 'sdg') {
    return null;
  }

  return (
    <>
      {downloadSettings.status || downloadSettings.assuranceStatus ? (
        <>
          <h5>{QUESTION.CAPITALIZED_SINGULAR} Status </h5>
          <div className='d-flex flex-column'>
            <RadioInput
              groupCode='status'
              value={downloadSettings.status}
              options={statusOptions}
              onChange={handleChange}
              formGroupClassName='mr-5'
              disabled={isDisabled}
            />
            {totals && totals?.sentToAssurer > 0 ? (
              <RadioInput
                groupCode='assuranceStatus'
                value={downloadSettings.assuranceStatus}
                options={getAssuredOption(totals && totals.assured === 0)}
                onChange={handleChange}
                formGroupClassName='mr-5'
                disabled={isDisabled}
              />
            ) : null}
          </div>
        </>
      ) : null}
      {!isPortfolioTracker && downloadSettings.visibility.length > 0 ? (
        <>
          <h5 className='mt-2'>Privacy settings</h5>
          <RadioInput
            groupCode='privacy'
            value={downloadSettings.visibility}
            options={privacyOptions}
            onChange={handleChange}
            formGroupClassName='mr-5'
            disabled={isDisabled}
          />
        </>
      ) : null}
    </>
  );
};
