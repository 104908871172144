import { useParams } from 'react-router-dom';
import {
  useGetSummaryInsightDashboardQuery,
  useUpdateSummaryInsightDashboardMutation,
} from '../../../api/insight-dashboards';
import Dashboard, { DashboardRow } from '../../../components/dashboard';
import { CompanyInsightsSidebar } from '../insights/partials/sidebar/CompanyInsightsSidebar';
import Loader from '../../../components/loader';
import { Overview } from './Overview';
import { Summary } from './Summary';
import {
  DashboardSurveyType,
  InsightDashboard,
  InsightDashboardFilters,
  InsightDashboardType,
  SummaryPages,
} from '@g17eco/types/insight-custom-dashboard';
import { RootState, useAppSelector } from '../../../reducers';
import { canManageCurrentLevel } from '../../../selectors/user';
import { FiltersEditing } from '../../custom-dashboard/FiltersEditing';
import { SharingButton } from '../../custom-dashboard/shared-dashboard/SharingButton';
import { isESGDashboard } from '../../custom-dashboard/shared-dashboard/utils';
import { useGetAvailablePeriodsQuery } from '../../../api/initiatives';
import { useEffect, useState } from 'react';
import { DataPeriods } from '../../../types/universalTracker';
import LoadingPlaceholder from '@components/LoaderContainer/LoadingPlaceholder';
import { getRootOrg } from '../../../selectors/initiative';
import { MainDownloadCode, getMainDownloadCode } from '../../../config/app-config';
import { Button, ButtonGroup } from 'reactstrap';
import { layoutOptions } from '../insights/utils/layout';
import { SurveyType } from '@g17eco/types/survey';
import { SurveyTypeDropdown } from '@components/survey-type-dropdown/SurveyTypeDropdown';
import { SurveyPeriodDropdown } from '@components/survey-period-dropdown';
import { getDefaultPeriod } from '@components/survey-period-dropdown/utils';

export const StaticDashboardRoute = () => {
  const rootInitiative = useAppSelector(getRootOrg);
  const mainDownloadCode = getMainDownloadCode(rootInitiative?.appConfigCode, rootInitiative?.permissionGroup);
  // No summaryPage means we are rendering SUMMARY without explicit page, fallback to overview
  const { initiativeId = '', summaryPage = 'overview' } = useParams<{ initiativeId?: string; summaryPage?: string }>();
  const canManage = useAppSelector(canManageCurrentLevel);
  const initiative = useAppSelector((state: RootState) => state.initiative.data);
  const [period, setPeriod] = useState<DataPeriods | undefined>();
  const [surveyType, setSurveyType] = useState<DashboardSurveyType>(SurveyType.Default);
  const [downloadCode, setDownloadCode] = useState<MainDownloadCode>(mainDownloadCode);
  const summaryPageStaffOnly = summaryPage.split('-')[0] as InsightDashboardType;

  const isESGDashboardPage = isESGDashboard(summaryPageStaffOnly);

  const { data: availablePeriods = [] } = useGetAvailablePeriodsQuery({ initiativeId, surveyType }, {
    skip: !initiativeId || !isESGDashboardPage,
  });
  const { data: dashboard, isFetching } = useGetSummaryInsightDashboardQuery(
    { dashboardId: summaryPageStaffOnly, initiativeId, queryParams: { period, mainDownloadCode: downloadCode, surveyType } },
    {
      skip: !initiativeId || !SummaryPages.includes(summaryPageStaffOnly) || (isESGDashboardPage && !period),
    }
  );

  const [updateSummaryDashboard] = useUpdateSummaryInsightDashboardMutation();

  useEffect(() => {
    if (isESGDashboardPage) {
      setPeriod(getDefaultPeriod(availablePeriods));
    }
  }, [availablePeriods, isESGDashboardPage]);

  if (!dashboard || !initiativeId) {
    return (
      <Dashboard className='profile-dashboard insights-dashboard' hasSidebar>
        <CompanyInsightsSidebar alignButtonRow={false} />
        <LoadingPlaceholder height={600} />
      </Dashboard>
    );
  }

  const handleSave = async (changes: Partial<InsightDashboard>) => {
    await updateSummaryDashboard({ ...changes, initiativeId, dashboardId: dashboard.type });
  };

  const layoutButtons = (
    <ButtonGroup>
      {layoutOptions.map((option) => {
        const isActive = downloadCode === option.code;
        return (
          <Button
            key={option.code}
            outline={!isActive}
            active={isActive}
            color={'primary'}
            onClick={() => setDownloadCode(option.code)}
          >
            {option.name}
          </Button>
        );
      })}
    </ButtonGroup>
  );

  return (
    <>
      {isFetching ? <Loader /> : null}
      <Dashboard className='profile-dashboard insights-dashboard' hasSidebar>
        <CompanyInsightsSidebar alignButtonRow={false} />
        {mainDownloadCode === MainDownloadCode.SGX_Metrics && isESGDashboardPage ? (
          <DashboardRow className='d-flex w-100 justify-content-end align-items-start'>{layoutButtons}</DashboardRow>
        ) : null}
        <DashboardRow className='d-flex w-100 justify-content-end align-items-start'>
          {canManage && isESGDashboardPage ? (
            <>
              {isFetching ? null : (
                <FiltersEditing
                  key={dashboard.type}
                  handleSave={handleSave}
                  dashboard={dashboard}
                  hideOptions={[
                    InsightDashboardFilters.BaselinesTargets,
                    InsightDashboardFilters.ShareWithSubsidiaries,
                  ]}
                />
              )}
              <SharingButton dashboard={dashboard} />
              <SurveyTypeDropdown
                surveyType={surveyType}
                setSurveyType={(newSurveyType) => setSurveyType(newSurveyType)}
              />
              <SurveyPeriodDropdown
                className='ml-3'
                period={period}
                availablePeriods={availablePeriods}
                setPeriod={(newPeriod) => setPeriod(newPeriod)}
              />
            </>
          ) : null}
        </DashboardRow>
        {dashboard.type === InsightDashboardType.Overview ? (
          <Overview initiativeId={initiativeId} initiative={initiative} dashboard={dashboard} />
        ) : (
          <Summary initiativeId={initiativeId} initiative={initiative} dashboard={dashboard} />
        )}
      </Dashboard>
    </>
  );
};
