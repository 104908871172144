import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import SurveyDuplicateForm from './SurveyDuplicateForm'

interface SurveyDuplicateProps {
  handleClose: () => void,
  display: boolean
  survey: any
}


const SurveyDuplicateModal = ({ display, handleClose, survey }: SurveyDuplicateProps) => {

  if (!display) {
    return null
  }

  return <Modal isOpen={display} toggle={handleClose} backdrop='static'>
    <ModalHeader toggle={handleClose}>
      <div>
        <i className='fas fa-copy mr-2' />
        Duplicate Existing Report Survey
      </div>

      <div className='subtitle-container text-ThemeTextPlaceholder'>
        This is a simple way to create a new survey by copying all the settings, scope, delegation
        and data from an existing survey.
      </div>
    </ModalHeader>
    <ModalBody>
      <SurveyDuplicateForm
        toggle={handleClose}
        survey={survey}
      />
    </ModalBody>
  </Modal>
}

export default SurveyDuplicateModal;
