import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { runConfetti } from '../../utils/confetti';

interface Props {
  isOpen: boolean;
  toggle: (hasCompleted?: boolean) => Promise<void> | void;
}

export const CongratulationsModal = ({ isOpen, toggle }: Props) => {
  const handleComplete = () => {
    runConfetti();
    return toggle(true);
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} backdrop='static'>
      <ModalHeader toggle={() => toggle()}>🎉 You did it! 🎉</ModalHeader>
      <ModalBody>
        <p>Congratulations on finishing the survey!</p>
        <p className='mb-0'>
          Now, all that's left is to click the ‘Complete' button to make sure your responses are locked in, and shared
          with relevant dashboards, and data can be viewed by organisations that you’ve given permissions to.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' className='mr-3' onClick={() => toggle()}>
          Cancel - not ready yet
        </Button>
        <Button color='primary' onClick={handleComplete}>
          Complete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
