import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import { useBulkSurveysCreateMutation } from '../../api/survey-templates';
import { ROUTES } from '../../constants/routes';
import { generateUrl } from '../../routes/util';
import Dashboard, { DashboardSection } from '../dashboard';
import { SubsidiarySelectionTable } from '../downloads/partials/SubsidiarySelectionTable';
import { updateFn } from '../form/common';
import {
  OnChangeReportingProps,
  SurveyReportingSettings,
} from '../survey-configuration/partials/SurveyReportingSettings';
import { createEffectiveDate } from '../survey-configuration/SurveyConfiguration';
import { useGoBack } from './hooks/useGoBack';
import { TemplateDashboardHeader } from './partials/TemplateDashboardHeader';
import { TemplateSettingsHeader } from './partials/TemplateSettingsHeader';
import { TemplateContext, TemplateContextLoadedProps } from './TemplateContainer';
import { BulkSurveysFormData } from './types';
import { hasEmptyFields, hasInvalidTags, requiredFields } from './utils';
import { useAppDispatch } from '../../reducers';
import { addSiteAlert, SiteAlertColors } from '../../slice/siteAlertsSlice';
import { MergeTagsButton } from './partials/MergeTagsButton';
import { BasicAlert } from '../alert/BasicAlert';
import Loader from '../loader';

export const TemplateBulkSurveysCreate = () => {
  const history = useHistory();
  const { initiativeId = '', templateId } = useParams<{ initiativeId: string; templateId: string }>();
  const goBack = useGoBack(initiativeId);
  const dispatch = useAppDispatch();
  const { templateData } = useContext(TemplateContext) as TemplateContextLoadedProps;
  const [bulkSurveysCreate, { isLoading }] = useBulkSurveysCreateMutation();
  const initialState: BulkSurveysFormData = {
    ...templateData,
    surveyName: '',
    reportingLevels: [initiativeId],
  };
  const [form, setForm] = useState<BulkSurveysFormData>(initialState);
  const updateForm: updateFn = (update) => setForm({ ...form, [update.code]: update.value });

  const handleChange = ({ option, code }: OnChangeReportingProps) => {
    return updateForm({ code, value: option?.value });
  };

  const handleSetSelectedIds = (ids: string[]) => {
    return updateForm({ code: 'reportingLevels', value: ids ?? [initiativeId] });
  }

  const setSurveyName = (value: string) => updateForm({ code: 'surveyName', value });

  const handleSubmit = () => {
    const effectiveDate = createEffectiveDate(form) ?? new Date();
    bulkSurveysCreate({ ...form, effectiveDate }).unwrap()
      .then((response) => {
        history.push(generateUrl(ROUTES.SURVEY_TEMPLATES_HISTORY, { initiativeId, templateId, historyId: response.data._id }));
      })
      .catch((e) => dispatch(addSiteAlert({
        content: e.message,
        color: SiteAlertColors.Danger,
      })))
  }

  const isDisabled =
    !form.reportingLevels ||
    form.reportingLevels.length === 0 ||
    hasEmptyFields(form, requiredFields) ||
    hasInvalidTags(form.surveyName);

  return (
    <Dashboard>
      <TemplateSettingsHeader />
      <DashboardSection header={<TemplateDashboardHeader title='Bulk create surveys' />}>
        {isLoading ? (
          <Loader relative />
        ) : (
          <>
            <BasicAlert type={'danger'}>{hasInvalidTags(form.surveyName) ? 'Merge tag does not exist' : ''}</BasicAlert>
            <h5>Template name</h5>
            <div>{templateData.name}</div>
            <div className='mt-4 position-relative'>
              <h5>Survey name</h5>
              <Input
                placeholder={'e.g. December 2022 annual report'}
                value={form.surveyName}
                onChange={(el) => setSurveyName(el.target.value)}
              />
              <MergeTagsButton surveyName={form.surveyName} updateName={setSurveyName} />
            </div>
            <SurveyReportingSettings form={form} onChange={handleChange} />
            <div className='mt-4'>
              <h5>Create surveys for</h5>
            </div>
            <SubsidiarySelectionTable
              initiativeId={initiativeId}
              handleSetSelectedIds={handleSetSelectedIds}
            />
            <div className='mt-4 text-right'>
              <Button color='link' onClick={goBack} className='mr-3'>
                Cancel
              </Button>
              <Button color='primary' className='submit-button' disabled={isDisabled} onClick={handleSubmit}>
                Create surveys
              </Button>
            </div>
          </>
        )}
      </DashboardSection>
    </Dashboard>
  );
};
