import { useParams } from 'react-router-dom';
import { useGetTemplateByIdQuery } from '../../api/survey-templates';
import { BasicAlert } from '../alert/BasicAlert';
import Dashboard, { DashboardSection } from '../dashboard';
import Loader from '../loader';
import { TemplateDashboardHeader } from '../survey-templates/partials/TemplateDashboardHeader';
import { TemplateSettingsHeader } from '../survey-templates/partials/TemplateSettingsHeader';
import { TemplateHistoryTable } from './TemplateHistoryTable';

export const TemplateHistory = () => {
  const { templateId = '' } = useParams<{ templateId: string }>();
  const { data: currentTemplate, error } = useGetTemplateByIdQuery(templateId, { skip: !templateId });

  return (
    <Dashboard>
      <TemplateSettingsHeader />
      {!currentTemplate ? (
        <div style={{ minHeight: '150px' }}>
          <Loader />
        </div>
      ) : (
        <DashboardSection header={<TemplateDashboardHeader title={`Survey templates: ${currentTemplate.name}`} />}>
          <BasicAlert type={error ? 'danger' : 'success'}>{error?.message}</BasicAlert>
          <TemplateHistoryTable />
        </DashboardSection>
      )}
    </Dashboard>
  );
};
