import { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ROUTES } from '../../../../constants/routes';
import { generateUrl } from '../../../../routes/util';
import { SubmissionInsightsRowValues } from '../../../../types/portfolio';
import { naturalSort } from '../../../../utils';
import NumberFormat from '../../../../utils/number-format';
import { BasicAlert } from '../../../alert/BasicAlert';
import Loader from '../../../loader';
import Table, { TableColumn, TableVariant } from '../../../table/Table';
import { SubmissionInsightsContext } from './SubmissionInsightsContainer';
import { getCellUtrv, getDisplayColumn, hasDataShare } from './utils';
import { QUESTION } from '../../../../constants/terminology';

export const SubmissionInsightsTable = () => {
  const { tableData, filters, isLoading, error, companies } = useContext(SubmissionInsightsContext);
  const { portfolioId } = useParams<{portfolioId: string}>();
  const history = useHistory();
  const handleClickCompany = (initiativeId: string) => {
    history.push(generateUrl(ROUTES.PORTFOLIO_TRACKER_COMPANY, { portfolioId: portfolioId, companyId: initiativeId }));
  }

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <BasicAlert type={'danger'}>{error}</BasicAlert>;
  }

  const dynamicColumns: TableColumn<SubmissionInsightsRowValues>[] = tableData.utrs.map((utr) => {
    return {
      id: utr._id,
      sortTooltip: `${utr.typeCode}: ${getDisplayColumn({ utr, alternativeCode: filters.scopeGroup?.code })}`,
      Header: () => getDisplayColumn({ utr, alternativeCode: filters.scopeGroup?.code }),
      accessor: (row: SubmissionInsightsRowValues) => {
        const utrv = getCellUtrv(row.utrvs, utr);
        return <NumberFormat value={utrv ? utrv[filters.metricView] : ''} emptyValue='-' />;
      },
      sortType: (a, b) => {
        const valueA = getCellUtrv(a.original.utrvs, utr);
        const valueB = getCellUtrv(b.original.utrvs, utr);
        return naturalSort(
          valueA ? String(valueA[filters.metricView]) : '',
          valueB ? String(valueB[filters.metricView]) : ''
        );
      },
      headerClassName: 'truncateTwoline',
    } as TableColumn<SubmissionInsightsRowValues>;
  });

  const columns: TableColumn<SubmissionInsightsRowValues>[] = [
    {
      accessor: 'name',
      Header: () => <span className='text-capitalize'>{filters.view}</span>,
      Cell: (c) => {
        const { initiativeId, name } = c.row.original;
        return initiativeId ? (
          <Button className='btn btn-link' onClick={() => handleClickCompany(initiativeId)}>
            {name}
          </Button>
        ) : (
          name
        );
      },
      sortType: (a, b) => naturalSort(a.original.name, b.original.name),
      sticky: 'left',
    },
    ...dynamicColumns,
  ];

  const renderTable = () => {
    switch (true) {
      case companies.length === 0 || !hasDataShare(companies):
        return (
          <BasicAlert type='secondary' className='mt-2'>
            No data from companies with approved data-share available.
          </BasicAlert>
        );
      case dynamicColumns.length === 0:
        return (
          <BasicAlert type='secondary' className='mt-2'>
            This pack contains no numeric {QUESTION.PLURAL}. Please select a survey pack that contains one or more numeric
            {QUESTION.SINGULAR}.
          </BasicAlert>
        );
      default:
        return (
          <Table columns={columns} data={tableData.utrValues} pageSize={20} variant={TableVariant.StickyColumns} />
        );
    }
  };

  return (
    <div className='latest-survey-table'>
      <div className='table-wrapper mt-3'>
        {renderTable()}
      </div>
    </div>
  );
};
