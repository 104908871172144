import { Progress } from 'reactstrap';
import { AnimatedProgressProvider } from './AnimatedProgressProvider';
import { AnimationVariant } from '.';
import NumberFormat from '@utils/number-format';

interface AnimatedProgressBarProps {
  valueEnd: number | undefined;
  variant: AnimationVariant;
  children: React.ReactNode;
}

export const AnimatedProgressBar = ({ valueEnd, variant, children }: AnimatedProgressBarProps) => {
  return (
    <AnimatedProgressProvider valueStart={0} valueEnd={valueEnd ?? 0} duration={2}>
      {(value: number) => {
        const roundedValue = Math.round(value);
        return (
          <div className='d-flex'>
            <Progress multi className='animated-progress flex-grow-1'>
              <div className={`d-flex animated-bar ${variant}`}>{children}</div>
            </Progress>
            <NumberFormat value={roundedValue} decimalPlaces={0} emptyValue={' '} suffix='%' className='flex-grow-0 strong pl-2' />
          </div>
        );
      }}
    </AnimatedProgressProvider>
  );
};
