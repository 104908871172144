import { BulkActionUtr } from '../survey-question-list/partials/BulkActionToolbar';
import { BulkButton } from '../survey-question-list/partials/ToggledButton';
import { TagsModal } from './TagsModal';

interface TagButtonProps {
  selectedQuestions: BulkActionUtr[];
  onToggle: () => void;
  handleReload: (props?: { reloadSurvey?: boolean }) => Promise<void | undefined>;
  onClick: () => void;
}

export const TagButton = (props: TagButtonProps) => {
  const { onClick, ...tagModalProps } = props;

  return (
    <>
      <BulkButton tooltip='Add tag' onClick={onClick}>
        Add tag
      </BulkButton>
      <TagsModal {...tagModalProps} />
    </>
  );
};
