import React from 'react';
import { Card, CardTitle, CardText, Col } from 'reactstrap';
import SimpleTooltip from '../simple-tooltip';

interface AdminSettingsCardProps {
  icon: string;
  text: string;
  onClickHandler?: React.MouseEventHandler<HTMLElement>;
  disabledText?: string;
}

export const AdminSettingsCard = ({
  icon = '',
  text = '',
  onClickHandler = () => {},
  disabledText = '',
}: AdminSettingsCardProps) => {
  return (
    <Col className='settings-card-container' sm='4'>
      <SimpleTooltip text={disabledText}>
        <Card
          className={`settings-card ${disabledText ? 'disabled' : 'active'}`}
          body
          onClick={disabledText ? undefined : onClickHandler}
        >
          <CardTitle className='settings-card-icon'>
            <i className={icon} />
          </CardTitle>
          <CardText className='settings-card-text'>{text}</CardText>
        </Card>
      </SimpleTooltip>
    </Col>
  );
};
