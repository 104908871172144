import { GenericChartOptions } from './constants'

export const chartStyleHorizontalBar: GenericChartOptions = {
    bar: {
        groupWidth: '100%',
        width: '100%'
    },
    legend: {
        position: 'none'
    },
    chartArea: {
        top: 20,
        bottom: 40,
        left: 0,
        right: 0,
    },
    hAxis: {
        gridlines: {
            color: 'transparent'
        },
        textPosition: 'none',
        minValue: 0,
    },
}

export const chartStylePieLegend: GenericChartOptions = {
    legend: {
        position: 'left'
    },
    chartArea: {
        top: 10,
        bottom: 10,
        left: 20,
        right: 10,
    },
}

export const chartStyleStacked: GenericChartOptions = {
    isStacked: true
}

export const chartStyleNames: {[key: string]: string} = {
    chartStyleHorizontalBar: 'chartStyleHorizontalBar',
    chartStylePieLegend: 'chartStylePieLegend',
    chartStyleStacked: 'chartStyleStacked'
}

export const chartStyleMap: {[key: string]: GenericChartOptions} = {
    chartStyleHorizontalBar,
    chartStylePieLegend,
    chartStyleStacked
}