import { Button, ModalBody } from 'reactstrap';
import { InsightDashboardItemType } from '@g17eco/types/insight-custom-dashboard';
import { QuestionSelectingFilter } from '../../partials/question-selecting-filter/QuestionSelectingFilter';
import { QuestionData, transformVariables } from '../../../utils';
import { SelectDataPointFooter } from './SelectDataPointFooter';
import { ChartAddingModalProps, SelectDataPointProps } from './types';
import { QUESTION } from '@constants/terminology';
import { useState } from 'react';
import { getUtrTitle, isValidUtrVariable } from './utils';

export const SelectUtr = ({
  initiativeId,
  item,
  chartData,
  changeChartData,
  backToEntryPoint,
  handleSubmitChart,
  togglePreviewModal,
  questionsMap,
  isCustomTitle,
}: Pick<ChartAddingModalProps, 'initiativeId'> &
  SelectDataPointProps) => {
  const [questionData, setQuestionData] = useState<QuestionData | undefined>(chartData.metrics?.[0]);

  const handleCreate = async () => {
    if (!isValidUtrVariable(questionData)) {
      return;
    }

    const variables = transformVariables([questionData]);
    const title = isCustomTitle ? chartData.title : getUtrTitle({ code: questionData.code, questionsMap });

    const { subType } = chartData;

    return handleSubmitChart({
      ...item,
      type: InsightDashboardItemType.Chart,
      title,
      variables,
      subType,
      icon: '',
      // unset calculation as single utr chart must not have calculation otherwise it will render multi utrs chart instead
      calculation: undefined,
    });
  };

  const handleSelect = (utr: QuestionData) => {
    setQuestionData(utr);
    if (isValidUtrVariable(utr)) {
      changeChartData({ ...chartData, metrics: [utr] });
    }
  };

  return (
    <>
      <ModalBody>
        <p>
          <Button color='link' onClick={backToEntryPoint} className='p-0'>
            Chart selection
          </Button>{' '}
          / Survey {QUESTION.PLURAL}
        </p>
        <p className='mt-3 text-medium'>Select data-point from survey scope</p>
        <QuestionSelectingFilter questionData={questionData} initiativeId={initiativeId} handleSelect={handleSelect} />
      </ModalBody>
      <SelectDataPointFooter {...{ chartData, handleBack: backToEntryPoint, handleCreate, togglePreviewModal, item }} />
    </>
  );
};
