import Select, { StylesConfig } from 'react-select';
import { ViewValues } from '../../survey-overview-sidebar/viewOptions';
import { QUESTION } from '../../../constants/terminology';

interface ScopeTypeOption {
  value: ViewValues;
  label: string;
}

const reactSelectStyles: StylesConfig<ScopeTypeOption, false> = {
  container: (provided) => ({
    ...provided,
    maxHeight: '26px',
    minWidth: '270px',
  }),
  control: (provided) => ({
    ...provided,
    maxHeight: '26px',
    minHeight: 'unset',
  }),
  singleValue: (provided) => ({ ...provided, color: '#a1a3a4' }),
  valueContainer: (provided) => ({ ...provided, position: 'unset' }),
  indicatorsContainer: (provided) => ({ ...provided, maxHeight: '26px', }),
  indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
  menu: (provider) => ({ ...provider, marginTop: '1px', }),
}

interface SurveyCardsFilterProps {
  options: ScopeTypeOption[];
  selectedView: ViewValues;
  setSelectedView: (value: ViewValues) => void;
}

export const SurveyCardsFilter = ({ options, selectedView, setSelectedView }: SurveyCardsFilterProps) => {
  const value = options.find(option => option.value === selectedView);
  return (
    <div className='col-4' data-testid='survey-scopes-filter'>
      <Select<ScopeTypeOption>
        styles={reactSelectStyles}
        placeholder={`Select View ${QUESTION.CAPITALIZED_PLURAL} By`}
        options={options}
        value={value ?? null}
        onChange={(option) => setSelectedView(option?.value ?? selectedView)}
        isSearchable={false}
        isClearable={false}
      />
    </div>
  );
};
