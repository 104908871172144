import { useHistory } from 'react-router-dom';
import { generateUrl, generateUrlWithQuery } from '../../util';
import { Button } from 'reactstrap';
import { ROUTES } from '../../../constants/routes';
import { ActionModal } from './ActionModal';
import React, { useState } from 'react';
import { UniversalTrackerPlain } from '../../../types/universalTracker';
import { getUtrFrameworkNames, getUtrStandardNames } from '../../../constants/standards-frameworks';
import { QUESTION } from '../../../constants/terminology';
import { UniversalTrackerModalServiceUtrv } from '../../../reducers/universal-tracker-modal';

type LinkText = string | React.JSX.Element;

interface Props {
  text: LinkText;
  initiativeId?: string;
  surveyId?: string;
  utrv?: Pick<UniversalTrackerModalServiceUtrv, '_id' | 'compositeData' | 'disaggregation'>;
  btnClass?: string;
  utr?: Pick<UniversalTrackerPlain, 'type' | 'name' | 'alternatives' | 'tags'>;
}

const defaultModal = { isOpen: false, modalText: '', actionText: '', actionUrl: '' };

export const UtrvLink = (props: Props) => {
  const { initiativeId, surveyId, text, utrv, btnClass = '', utr } = props;

  const history = useHistory();
  const [modal, setModal] = useState(defaultModal);

  if (!initiativeId) {
    return <>{text}</>;
  }

  const handleClick = () => {
    if (!utrv) {
      // if have latest survey with current period, open survey scope modal
      if (surveyId) {
        const utrFrameworks = getUtrFrameworkNames(utr);
        const utrStandards = getUtrStandardNames(utr);

        setModal({
          isOpen: true,
          modalText: `The selected survey does not have this ${QUESTION.SINGULAR} in Scope`,
          actionText: 'Update survey scope',
          actionUrl: generateUrlWithQuery(
            ROUTES.COMPANY_TRACKER_SURVEY,
            { initiativeId, surveyId, page: 'scope' },
            { search: [...utrFrameworks, ...utrStandards].join(',') }
          ),
        });
        return;
      }
      // if no survey, open survey create modal
      setModal({
        isOpen: true,
        modalText: 'There are no surveys for the required period',
        actionText: 'Create a survey',
        actionUrl: `${generateUrl(ROUTES.COMPANY_TRACKER_LIST, { initiativeId })}/create`,
      });
      return;
    }

    // at this point, we have utrv then redirect to question detail
    // if data from children, redirect to children
    if ('disaggregation' in utrv && utrv.disaggregation) {
      // use the first one as it is the latest
      const dissaggregationUtrv = utrv.disaggregation[0];
      if (!dissaggregationUtrv.compositeData?.surveyId) {
        return;
      }
      const rootUrl = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, {
        initiativeId,
        surveyId: dissaggregationUtrv.compositeData.surveyId,
        page: 'question',
      });
      const url = `${rootUrl}/${dissaggregationUtrv._id}`;
      history.push(url);
      return;
    }
    if (!utrv.compositeData?.surveyId) {
      return;
    }
    const rootUrl = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, {
      initiativeId,
      surveyId: utrv.compositeData.surveyId,
      page: 'question',
    });
    const url = `${rootUrl}/${utrv._id}`;
    history.push(url);
  };

  const toggleModal = () => setModal({ ...defaultModal, isOpen: false });

  return (
    <>
      <ActionModal {...{ ...modal, toggle: toggleModal }} />
      <Button onClick={handleClick} color='link' className={`text-truncate p-0 ${btnClass}`}>
        {text}
      </Button>
    </>
  );
};
