import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { RootState, useAppSelector } from '../../reducers';
import { getRootOrg } from '../../selectors/initiative';
import Dashboard, { DashboardSection } from '../dashboard';
import Loader from '../loader';
import { AdminBreadcrumbs } from '../../routes/admin-dashboard/AdminBreadcrumbs';
import { generateUrl } from '../../routes/util';
import { ROUTES } from '../../constants/routes';
import { BulkImportJobsList } from './partials/BulkImportJobsList';
import { useToggle } from '../../hooks/useToggle';
import { ImportFileModal } from './partials/ImportFileModal';
import {
  useDeleteBulkImportJobMutation,
  useGetBulkImportJobsQuery,
  useRunBulkImportJobMutation,
} from '../../api/bulk-import';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import './styles.scss';

export const BulkImporting = () => {
  const rootOrg = useAppSelector(getRootOrg);

  const { addSiteError } = useSiteAlert();
  const initiativeId = useSelector((state: RootState) => state.initiative.data?._id ?? '');
  const [openUpload, toggleUpload] = useToggle(false);

  const { data: bulkImportJobs = [], isLoading: loadingJobsList } = useGetBulkImportJobsQuery(initiativeId, {
    skip: !initiativeId,
    // Automatically refresh the list every 30s
    pollingInterval: 30000
  });
  const [runBulkImportJob, { isLoading: loadingRunJob }] = useRunBulkImportJobMutation();
  const [deleteBulkImportJob, { isLoading: loadingDeleteJob }] = useDeleteBulkImportJobMutation();

  if (!rootOrg || !initiativeId) {
    return <Loader />;
  }

  const handleRunJob = async (jobId: string) => {
    try {
      await runBulkImportJob({ initiativeId, jobId });
    } catch (e) {
      addSiteError(e);
    }
  };

  const handleDeleteJob = async (jobId: string) => {
    try {
      await deleteBulkImportJob({ initiativeId, jobId });
    } catch (e) {
      addSiteError(e);
    }
  };

  return (
    <Dashboard className='bulk-importing__wrapper'>
      <div className='pl-2 mb-4'>
        <AdminBreadcrumbs
          breadcrumbs={[
            {
              label: 'Admin Dashboard',
              url: generateUrl(ROUTES.ADMIN_DASHBOARD, { initiativeId }),
            },
            { label: 'Bulk import survey data' },
          ]}
          initiativeId={initiativeId}
        />
      </div>
      <DashboardSection
        title='Bulk import survey data'
        paddingInternal={0}
        titleClass='mt-0'
        buttons={[
          <Button type='button' color='secondary' onClick={() => toggleUpload()}>
            Upload new file
          </Button>,
        ]}
      >
        <BulkImportJobsList
          bulkImportJobs={bulkImportJobs}
          isLoading={loadingJobsList || loadingRunJob || loadingDeleteJob}
          runBulkImportJob={handleRunJob}
          deleteBulkImportJob={handleDeleteJob}
        />
        <ImportFileModal
          isOpen={openUpload}
          toggle={toggleUpload}
          initiativeId={initiativeId}
        />
      </DashboardSection>
    </Dashboard>
  );
};
