import { InitiativeUniversalTracker } from '../types/initiativeUniversalTracker';
import { g17ecoApi, transformResponse } from './g17ecoApi';

interface UpdateDecimalParams {
  initiativeId: string;
  decimal: { [key: string]: number | undefined };
  utrIds: string[];
}

const TAGS = ['initiative-universal-trackers'];

const getUrl = ({ initiativeId }: { initiativeId: string }) =>
  `/initiative-universal-trackers/initiative/${initiativeId}`;

export const initiativeUniversalTrackersApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: TAGS,
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInitiativeUniversalTrackers: builder.query<InitiativeUniversalTracker[], string>({
        transformResponse,
        query: (initiativeId) => ({
          url: getUrl({ initiativeId }),
          method: 'get',
        }),
        providesTags: TAGS,
      }),
      updateInitiativeUniversalTracker: builder.mutation<InitiativeUniversalTracker, UpdateDecimalParams>({
        transformResponse,
        query: ({ initiativeId, ...data }) => ({
          url: getUrl({ initiativeId }),
          method: 'post',
          data,
        }),
        invalidatesTags: TAGS,
      }),
    }),
  });

export const { useGetInitiativeUniversalTrackersQuery, useUpdateInitiativeUniversalTrackerMutation } =
  initiativeUniversalTrackersApi;
