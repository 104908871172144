import { UniversalTrackerBlueprintMin, UtrValueType } from '@g17eco/types/universalTracker';
import {
  Calculation,
  CalculationType,
  ChartSubType,
  InsightDashboardItemChartType,
  InsightDashboardItemType,
  ValueRole,
} from '../../../../../types/insight-custom-dashboard';
import { UtrVariable, UtrVariables } from '../../../../summary/insights/utils/constants';
import { CHART_COLORS, ChartData, QuestionData, isDashboardItem, isTempChartType } from '../../../utils';
import { ChartAddingModalProps, ModalChartItem, PreviewChartData } from './types';
import { getSDGTitle } from '@constants/sdg-data';
import { getExtendedGoalCode } from './sdg-trackers/utils';

export const getChartData = (item: ChartAddingModalProps['item']): ChartData => {
  if (isTempChartType(item)) {
    return { metrics: [] };
  }

  const commonFields = {
    type: item.type,
    metrics: [],
  };

  const isEditing = isDashboardItem(item);
  if (isEditing) {
    return {
      ...commonFields,
      metrics: Object.values(item.variables),
      subType: item.subType,
      title: item.title ?? '',
    };
  }

  return commonFields;
};

export const emptyUtrData = {
  code: undefined,
  valueListCode: undefined,
  groupCode: undefined,
  subGroupCode: undefined,
};

export function isPreviewChartData(chartData: ChartData): chartData is PreviewChartData {
  const { type, subType, metrics } = chartData;
  return Boolean(type && subType && metrics?.every(({ code }) => code));
}

export const isValidUtrVariable = (questionData?: QuestionData): questionData is UtrVariable => {
  if (!questionData) {
    return false;
  }
  return Boolean(questionData.code);
};

type UtrLookup = Pick<UniversalTrackerBlueprintMin, 'code' | 'valueType' | 'name' | 'valueValidation'>;

const getName = ({ utrs, value }: { utrs: UtrLookup[]; value: UtrVariable }): string => {
  const utr = utrs.find((utr) => utr.code === value.code);
  if (!utr) {
    return value.code;
  }
  if (!value.valueListCode) {
    return utr.name;
  }
  switch (utr.valueType) {
    case UtrValueType.ValueList:
    case UtrValueType.NumericValueList:
    case UtrValueType.TextValueList:
      return utr.valueValidation?.valueList?.list?.find((vl) => vl.code === value.valueListCode)?.name ?? utr.name;
    case UtrValueType.Table:
      const column = utr.valueValidation?.table?.columns.find((c) => c.code === value.valueListCode);
      if (!column) {
        return utr.name;
      }
      return column.shortName ?? column.name;
    default:
      return utr.name;
  }
};

export const getChartCalculation = ({
  variables,
  subType,
  utrs,
}: {
  variables: UtrVariables;
  subType: ChartSubType;
  utrs: UtrLookup[];
}): Calculation => {
  switch (subType) {
    case ChartSubType.Bar:
      return {
        type: CalculationType.Formula,
        values: Object.entries(variables).map(([key, value], index) => {
          const name = getName({ utrs, value });
          return {
            name: name,
            formula: `{${key}}`,
            options: {
              tooltip: {
                formula: `${name} | {${key}}`,
              },
              style: CHART_COLORS[index],
            },
          };
        }),
        headers: [
          {
            name: '',
          },
          {
            name: '',
          },
          {
            role: ValueRole.Annotation,
          },
          {
            role: ValueRole.Style,
          },
        ],
      };
    case ChartSubType.Line:
    case ChartSubType.Column:
    default:
      return {
        type: CalculationType.Formula,
        values: Object.entries(variables).map(([key, value]) => ({
          name: getName({ utrs, value }),
          formula: `{${key}}`,
        })),
      };
  }
};

export const getUtrTitle = ({
  code,
  questionsMap,
}: {
  code: string;
  questionsMap: Map<string, UniversalTrackerBlueprintMin>;
}) => {
  const question = questionsMap.get(code);
  return question ? question.valueLabel : '';
};

export const getChartTitleByType = ({
  chartType,
  code,
  questionsMap,
}: {
  chartType: InsightDashboardItemChartType;
  code: string;
  questionsMap: Map<string, UniversalTrackerBlueprintMin>;
}) => {
  if (chartType === InsightDashboardItemType.Chart) {
    return getUtrTitle({ code, questionsMap });
  }

  const goalCode = getExtendedGoalCode(code);
  return goalCode ? getSDGTitle({ goalCode }) : '';
};

export const checkIsCustomTitle = ({
  item,
  chartData,
  questionsMap,
}: {
  item: ModalChartItem;
  chartData: ChartData;
  questionsMap: Map<string, UniversalTrackerBlueprintMin>;
}) => {
  if (!isDashboardItem(item)) { // is adding.
    return Boolean(chartData.title);
  }

  const metrics = Object.values(item.variables);
  if (!metrics.length) {
    return false;
  }

  const [firstMetric] = metrics;

  const defaultTitle =
    item.type === InsightDashboardItemType.Chart
      ? getUtrTitle({ code: firstMetric.code, questionsMap })
      : getSDGTitle({ goalCode: getExtendedGoalCode(firstMetric.code) ?? '' });

  return chartData.title !== defaultTitle;
};
