import React from 'react'
import Chart from 'react-google-charts';
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';

const WIDTH = 600;
const HEIGHT = 400;

interface EmployeeCategory {
  loaded: boolean;
  width: number;
  height: number;
  name: string;
  chart: string;
  malePay: number;
  femalePay: number;
  payRatio: number;
};

function camelCase(str: string) {
  return str.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
}

export interface GenderPayGapDataSource extends DataSource {
  employeeCategories?: EmployeeCategory[]
}

const GenderPayGap = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const [isUpdated, setUpdated] = React.useState(false);
  const [reportData, setReportData] = React.useState<GenderPayGapDataSource>({
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    employeeCategories: []
  });

  const addReportData = (data: EmployeeCategory) => {
    const d = { ...reportData };
    d.employeeCategories?.push(data);
    setReportData(d);
  }

  const utr = questionData.find(d => d.universalTracker.code === 'gri/2020/405-2/a');
  const data = utr?.valueData?.table ?? [];
  const reportDataCount = React.useMemo(() => reportData.employeeCategories?.length ?? undefined, [reportData]);
  const rowCount = data.length;

  React.useEffect(() => {
    const noData = reportDataCount === 0;
    const dataUpdated = rowCount === reportDataCount;
    const shouldUpdate = noData || dataUpdated;

    if (shouldUpdate && !isUpdated) {
      setUpdated(true);
      updateData('genderPayGap', reportData);
    }
  }, [reportData, isUpdated, reportDataCount, rowCount, updateData]);

  return <>
    {data.map(row => {
      const employee_category = row.find(r => r.code === 'employee_category')?.value ?? 'Other';
      const male_pay = row.find(r => r.code === 'denominator_male') ?? { value: 0 };
      const female_pay = row.find(r => r.code === 'numerator_female') ?? { value: 0 };
      const ratio = 100 * Number(female_pay.value) / Number(male_pay.value);
      const chartData = [
        ['Role', 'Pay', { role: 'style' }],
        ['Men', Number(male_pay.value), '#A6CFE5'],
        ['Women', Number(female_pay.value), '#D6F5DF'],
      ];
      return <Chart
        key={`genderpaygap_${employee_category}`}
        chartType='BarChart'
        data={chartData}
        width={WIDTH}
        height={HEIGHT}
        options={{
          ...CHART_DEFAULTS.OPTIONS,
          chartArea: {
            top: 40,
            bottom: 20,
            left: 100,
            right: 20,
          },
          legend: {
            position: 'none'
          },
        }}
        chartEvents={[
          {
            eventName: 'ready',
            callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
              const name = camelCase(String(employee_category).replace('_', ' '));
              const existing = reportData.employeeCategories?.find(d => d.name === name);
              if (existing) {
                return;
              }
              addReportData({
                loaded: true,
                width: WIDTH,
                height: HEIGHT,
                name: name,
                chart: (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1],
                malePay: Number(male_pay.value),
                femalePay: Number(female_pay.value),
                payRatio: ratio
              })
            }
          }
        ]}
      />
    })}
  </>;
})

//   const utrv = questionData.find(d => d.universalTracker.code === 'gri/2020/405-2/a');
// const data = utrv?.valueData?.table?.reduce((acc, row, ix) => {
//   for (let i = 0; i <= 2; i++) {
//     acc[i] = {
//       code: row[i].code,
//       value: ((acc[i].value * ix) + row[i].value) / (ix + 1) // Running average
//     }
//   }
//   return acc;
// }, [{ code: '', value: 0 }, { code: '', value: 0 }, { code: '', value: 0 }])

// const femalePay = data?.[1].value ?? 0;
// const malePay = data?.[0].value ?? 0

// const reportData: GenderPayGapDataSource = {
//   loaded: true,
//   width: WIDTH,
//   height: HEIGHT,
//   malePay: malePay,
//   femalePay: femalePay,
//   femaleRatio: 100 * femalePay / malePay,
//   employeeCategory: 'Management'
// }

// const chartData = [
//   ['Role', 'Pay', { role: 'style' }],
//   ['Men', malePay, '#A6CFE5'],
//   ['Women', femalePay, '#D6F5DF'],
// ];

// return <Chart
//   chartType="BarChart"
//   data={chartData}
//   width={WIDTH}
//   height={HEIGHT}
//   options={{
//     ...CHART_DEFAULTS.OPTIONS,
//     chartArea: {
//       top: 40,
//       bottom: 20,
//       left: 100,
//       right: 20,
//     },
//     legend: {
//       position: 'none'
//     },
//   }}
//   chartEvents={[
//     {
//       eventName: 'ready',
//       callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
//         reportData.chart = (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1];
//         updateData('genderPayGap', reportData);
//       }
//     }
//   ]}
// />
// })

export default GenderPayGap;
