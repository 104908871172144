import { Input } from 'reactstrap';
import { CustomMetric } from '../../api/custom-metrics';
import { CustomMetricTable } from './CustomMetricTable';
import { useSearchDebounceWithFlexMap } from '../../hooks/useSearchDebounceWithFlexMap';

interface CustomMetricManageTableProps {
  questions: CustomMetric[] | undefined;
  hasUniversalTrackerValues?: boolean;
  handleOpenEditUtr: (utrId: string) => void;
  handleOpenDeleteUtr: (utrId: string) => void;
  total: number;
}

export const CustomMetricManageTable = (props: CustomMetricManageTableProps) => {
  const { questions, hasUniversalTrackerValues = false, handleOpenEditUtr, handleOpenDeleteUtr, total } = props;
  const { onSearch, matchedQuestions } = useSearchDebounceWithFlexMap(questions);

  return (
    <div className='position-relative'>
      <Input
        type='text'
        className='metric-table__search position-absolute'
        bsSize='sm'
        placeholder='Search'
        onChange={onSearch}
      />
      <CustomMetricTable
        handleOpenEditUtr={handleOpenEditUtr}
        handleOpenDeleteUtr={handleOpenDeleteUtr}
        questions={matchedQuestions}
        hasUniversalTrackerValues={hasUniversalTrackerValues}
        total={total}
      />
    </div>
  );
};
