import { GridDashboardTableItem } from '../../../../types/insight-custom-dashboard';
import { generateId } from '../../../../utils';
import { extractVariables } from '../../../../utils/formula';
import { UtrVariable, UtrVariables } from '../../../summary/insights/utils/constants';
import { TableData, TableRowDataInfo } from './types';
import { HistoricalUtrs } from '../../../../api/insights';
import { UtrvType } from '../../../../constants/status';
import { isNumericString } from '../../../../utils/string';

export const MAX_LENGTH_TABLE_TITLE = 200;

// values are taken from css styles (pixels)
export const TABLE_HEADER_HEIGHT = 50;
export const TABLE_ROW_HEIGHT = 60;

export const updateRow = (data: TableRowDataInfo[], row: Partial<TableRowDataInfo>) => {
  let found = false;
  const newData = data.map((r) => {
    if (r.id === row.id) {
      found = true;
      return { ...r, ...row };
    }
    return r;
  });
  return found ? newData : data;
};

export const removeRow = (data: TableRowDataInfo[], id: string) => {
  return data.filter((r) => r.id !== id);
};

export const getTableData = (item: GridDashboardTableItem | Pick<GridDashboardTableItem, 'type'>): TableData => {
  const tableData: TableData = {
    rowData: [],
    editRowId: '',
  };

  if (!('variables' in item && 'calculation' in item) || !item.variables || !item.calculation?.values) {
    return tableData;
  }

  return item.calculation.values.reduce((acc, value) => {
    // get formula and extract keys (a, b, c, ...)
    // create utrVariable from extracted keys
    const rowData: TableRowDataInfo = {
      id: generateId(),
      data: {
        value,
        variables: [],
      },
    };
    if (value.formula) {
      rowData.data.variables = extractVariables(value.formula).reduce((acc, variableLetter) => {
        if (item.variables?.[variableLetter]) {
          acc.push(item.variables[variableLetter]);
        }
        return acc;
      }, [] as UtrVariable[]);
    }

    acc.rowData.push(rowData);
    return acc;
  }, tableData);
};

export const hasValidVariables = (rowData: TableRowDataInfo[]) => {
  for (const row of rowData) {
    for (const variable of row.data.variables) {
      if (!variable.code) {
        return false;
      }
    }
  }
  return true;
}

export const getUniqueUtrVariables = (rowData: TableRowDataInfo[]) => {
  return rowData.reduce((acc, row) => {
    const newVariables = row.data.variables.filter(({ code, valueListCode = '' }) => {
      if (acc.length === 0) {
        return true;
      }
      return acc.every(({ code: existingCode, valueListCode: existingValueListCode = '' }) =>
          existingCode !== code || existingValueListCode !== valueListCode
      );
    });
    return acc.concat(newVariables);
  }, [] as UtrVariable[]);
};

export const getCalculationValues = ({
  rowData,
  variables,
}: {
  rowData: TableRowDataInfo[];
  variables: UtrVariables;
}) => {
  return rowData.map((row) => {
    const key = Object.keys(variables).find(
      (key) =>
        variables[key].valueListCode === row.data.variables[0].valueListCode &&
        variables[key].code === row.data.variables[0].code
    );
    return {
      name: row.data.value.name,
      formula: `{${key}}`,
    };
  });
};

export const getActualUtrsData = (utrsData: HistoricalUtrs[]): HistoricalUtrs[] => {
  // TODO: might need to filter by period later.
  return utrsData.map((utrData) => {
    return { ...utrData, utrvs: utrData.utrvs.filter((utrv) => utrv.type === UtrvType.Actual) };
  });
};

export const getDisplayUnitText = ({
  utrCode,
  utrsData,
}: {
  utrCode: string;
  utrsData: HistoricalUtrs[];
}) => {
  const utrData = utrsData.find((data) => data.utr.code === utrCode);
  return utrData?.utr.unit || '';
};

export const getDisplayNumericValue = (calculationValue: any) => {
  return typeof calculationValue === 'number' || isNumericString(calculationValue)
    ? calculationValue.toLocaleString('en-GB', {
        maximumFractionDigits: 2,
      })
    : calculationValue;
};
