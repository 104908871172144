import Select from 'react-select';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { getCurrencyOptions } from '../../../utils/units';
import { BaseStepCompProps } from '../../onboarding-esgenome/types';
import { getStyles } from './CompanyInfoForm';

export const ReportingCurrencyForm = (props: BaseStepCompProps) => {
  const { updateForm, formData, handlePrevious, handleNext } = props;
  const currencyOptions = getCurrencyOptions();

  return (
    <div className='d-lg-flex justify-content-between onboarding-form'>
      <aside className='title-container'>
        <div className='h1 mb-3'>Reporting currency</div>
        <p>Please select or confirm the currency that you report in.</p>
      </aside>
      <section className='form-container mt-5'>
        <Form className='mt-3 mb-5'>
          <FormGroup>
            <Label for='currency'>Reporting currency</Label>
            <Select
              id={'currencySelect'}
              placeholder={'e.g. US Dollars'}
              options={currencyOptions}
              styles={getStyles()}
              value={currencyOptions.find((c) => c.value === formData.unitConfig?.currency) ?? null}
              onChange={(option) => updateForm('unitConfig', { ...formData.unitConfig, currency: option?.value ?? '' })}
            />
          </FormGroup>
        </Form>
        <div className='mt-3 d-flex'>
          <Button outline className='mr-auto' onClick={handlePrevious}>
            <i className='far fa-angle-left mr-2' />
            Back
          </Button>
          <Button data-testid='sgx-currency-next-btn' color='primary' className='ml-1' onClick={handleNext}>
            Next <i className='far fa-angle-right ml-2' />
          </Button>
        </div>
      </section>
    </div>
  );
};
