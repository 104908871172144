import Chart from 'react-google-charts';
import { HistoricalUtrs } from '../../../../../api/insights';
import {
  CalculationType,
  ChartDataLoaded,
  GridDashboardChartItem,
  ValueRole
} from '../../../../../types/insight-custom-dashboard';
import { tryCalculation } from '@utils/formula';
import { getChartOptions } from '../../../../summary/insights/partials/GenericChart';
import { ChartType } from '../../../../summary/insights/utils/constants';
import { getActualUtrvsGroupByDate, getVariablesWithValues, getVAxisTitle } from './utils';
import { chartStyleNames } from '../../../../summary/insights/utils/chartStyles';
import { useEffect, useState } from 'react';
import { generateGroupedStageData, getActualUtrsDataGrouped } from '@services/aggregation/utrData';
import { DATE } from '@utils/date';

type Props = Pick<GridDashboardChartItem, 'calculation' | 'variables' | 'unitText'> & {
  utrsData: HistoricalUtrs[];
  isStacked?: boolean;
};

type DataProps = Required<Pick<Props, 'calculation' | 'variables' | 'utrsData'>>;

const getChartData = async (props: DataProps) => {
  const { utrsData, variables, calculation } = props;

  const fallback = 0;

  let chartData: (string | number | { role: ValueRole })[][] = [];
  switch (calculation.type) {
    case CalculationType.Stages: {
      chartData = await Promise.all(getActualUtrsDataGrouped(utrsData, DATE.MONTH_YEAR_SHORT).map(async ({ date, utrsData }) => {
        return generateGroupedStageData({
          fallback,
          date,
          calculation,
          variables,
          utrsData
        });
      }));
      break;
    }
    case CalculationType.Formula: {
      const utrvsGroupByDate = getActualUtrvsGroupByDate(utrsData);
      chartData = Object.keys(utrvsGroupByDate).map((date) => {
        const utrvs = utrvsGroupByDate[date];
        const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs });
        return [
          date,
          ...calculation.values.map((value) => {
            return tryCalculation({
              formula: value.formula ?? '',
              variables: variablesWithValues,
              fallback,
            });
          }),
        ];
      });
      break;
    }
  }

  const linesNames = calculation.values.map((value) => (value.role ? { role: value.role } : value.name));
  chartData.unshift(['Time', ...linesNames]);

  return { chartData };
};

export const ColumnChart = ({ utrsData, variables, calculation, unitText, isStacked }: Props) => {

  const [data, setData] = useState<ChartDataLoaded | undefined>();

  useEffect(() => {
    if (calculation) {
      getChartData({ utrsData, variables, calculation })
        .then(data => setData(data))
    }
  }, [calculation, utrsData, variables]);

  if (!calculation || !data?.chartData.length) {
    return null;
  }

  const vAxisTitle = getVAxisTitle({ unitText, calculation, utrsData });

  return (
    <div className='w-100 h-100'>
      <Chart
        chartType={ChartType.ColumnChart}
        data={data.chartData}
        width={'100%'}
        height={'100%'}
        options={getChartOptions({
          chartType: ChartType.ColumnChart,
          vAxisTitle,
          options: isStacked ? chartStyleNames.chartStyleStacked : undefined,
        })}
      />
    </div>
  );
};
