import {
  ChartSubType,
  GridDashboardItem,
  GridDashboardItemCreate,
  InsightDashboardItemType,
  GridDashboardItemBase,
  GridDashboardChartItemBase,
  GridDashboardSDGChartItemBase,
  GridDashboardTextItemBase,
  GridDashboardTableItemBase,
  GridDashboardChartItem,
  GridDashboardSDGChartItem,
  GridDashboardTextItem,
  GridDashboardTableItem,
  DashboardGridSize,
  InsightDashboardItemChartType,
  InsightDashboardTempItemType,
  GridDashboardTempItem,
  GridDashboardTempChartItem,
  Calculation,
} from '../../types/insight-custom-dashboard';
import { UniversalTrackerBlueprintMin } from '../../types/universalTracker';
import { COLOUR1, COLOUR2, COLOUR3, COLOUR4, COLOUR5, UtrVariable, UtrVariables } from '../summary/insights/utils/constants';
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from './items/tables/utils';

export const DISABLED_TARGET_BASELINE_ADDING_TOOLTIP =
  'Targets and baselines cannot be set whilst Custom Dashboard is in Edit mode. Please save to set targets and baselines';

export const COLUMNS = 12;
export const ROW_HEIGHT = 10;
export const COL_WIDTH = 72;
export const ITEM_GAP = 10;
export const GRID_WIDTH = COLUMNS * COL_WIDTH + (COLUMNS + 1) * ITEM_GAP;
const DEFAULT_HEIGHT = 12;

export enum QuestionFilterType {
  Pack = 'pack',
  Subpack = 'subPack',
  Question = 'question',
  Input = 'input',
}

export const CHART_COLORS = [COLOUR1, COLOUR2, COLOUR3, COLOUR4, COLOUR5];

export type QuestionFilters = {
  [key in QuestionFilterType]?: string;
};

export const getLayoutByItems = (items: GridDashboardItem[]) =>
  items.map((item) => ({ ...item.gridSize, i: item._id }));

export const isChartType = (
  item: GridDashboardItem | GridDashboardItemBase
): item is GridDashboardChartItem | GridDashboardChartItemBase => {
  return !item.type || item.type === InsightDashboardItemType.Chart;
};

export const isSDGTrackerType = (
  item: GridDashboardItem | GridDashboardItemBase
): item is GridDashboardSDGChartItem | GridDashboardSDGChartItemBase => {
  return item.type === InsightDashboardItemType.SDGTracker;
};

export const isTempChartType = (
  item: GridDashboardItem | GridDashboardItemBase | GridDashboardTempItem
): item is GridDashboardTempChartItem => {
  return item.type === InsightDashboardTempItemType.TempChart;
};

export const isTextOrHeadlineType = (
  item: GridDashboardItem | GridDashboardItemBase
): item is GridDashboardTextItem | GridDashboardTextItemBase => {
  return [InsightDashboardItemType.Headline, InsightDashboardItemType.Text].includes(item.type);
};

export const isTableType = (
  item: GridDashboardItem | GridDashboardItemBase
): item is GridDashboardTableItem | GridDashboardTableItemBase => {
  return item.type === InsightDashboardItemType.Table;
};

export const isDashboardItem = (
  item: GridDashboardItem | GridDashboardItemCreate | GridDashboardItemBase | GridDashboardTempItem
): item is GridDashboardItem => {
  return '_id' in item && !!item._id;
};

const TEXT_LIMIT_PER_ROW = {
  [InsightDashboardItemType.Headline]: 60,
  [InsightDashboardItemType.Text]: 130,
};

const PADDING_HEIGHT = {
  [InsightDashboardItemType.Headline]: 0,
  [InsightDashboardItemType.Text]: 32,
};

const LINE_HEIGHT = {
  [InsightDashboardItemType.Headline]: 33.6,
  [InsightDashboardItemType.Text]: 19.5,
};

export const calculateItemHeight = (item: GridDashboardItem | GridDashboardItemCreate, width = COLUMNS) => {
  const { type } = item;

  switch (type) {
    case InsightDashboardItemType.Headline:
    case InsightDashboardItemType.Text:
      const { text = '' } = item;

      const textLimitPerRow = (TEXT_LIMIT_PER_ROW[type] * width) / COLUMNS;
      const textLines = Math.ceil(text.length / textLimitPerRow);

      // Equation: rowsCount * ROW_HEIGHT + (rowsCount - 1) * ITEM_GAP = textLines * LINE_HEIGHT + PADDING_HEIGHT
      const textGridRowsCount = Math.ceil(
        (textLines * LINE_HEIGHT[type] + ITEM_GAP + PADDING_HEIGHT[type]) / (ROW_HEIGHT + ITEM_GAP)
      );
      return { h: textGridRowsCount, minH: textGridRowsCount };
    case InsightDashboardItemType.Table:
      const tableRowsCount = item.calculation?.values.length ?? 0;

      // Equation: rowsCount * ROW_HEIGHT + (rowsCount - 1) * ITEM_GAP = tableRowsCount * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT
      const tableGridRowsCount = Math.ceil(
        (tableRowsCount * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT + ITEM_GAP) / (ROW_HEIGHT + ITEM_GAP)
      );
      return {
        h: tableGridRowsCount,
        minH: tableGridRowsCount,
        maxH: tableGridRowsCount,
      };
    case InsightDashboardItemType.Chart:
    case InsightDashboardItemType.SDGTracker:
      return {
        h: 17,
        minH: DEFAULT_HEIGHT,
      };
    default:
      return {
        h: DEFAULT_HEIGHT,
        minH: DEFAULT_HEIGHT,
      };
  }
};

export const createQuestionOptions = (questions: UniversalTrackerBlueprintMin[]) =>
  questions.map((question) => ({
    label: question.name,
    searchString: `${question.name} ${question.typeCode || ''}`,
    value: question.code,
  }));

export const getResetChildren = (key: keyof QuestionFilters): QuestionFilters => {
  switch (key) {
    case QuestionFilterType.Pack:
      return {
        [QuestionFilterType.Subpack]: '',
        [QuestionFilterType.Question]: '',
        [QuestionFilterType.Input]: '',
      };
    case QuestionFilterType.Subpack:
      return {
        [QuestionFilterType.Question]: '',
        [QuestionFilterType.Input]: '',
      };
    case QuestionFilterType.Question:
      return {
        [QuestionFilterType.Input]: '',
      };
    default:
      return {};
  }
};

export type QuestionData = Partial<UtrVariable>;

export interface ChartData {
  title?: string;
  type?: InsightDashboardItemChartType;
  subType?: ChartSubType;
  metrics: UtrVariable[];
}

export const WidgetProps = {
  [InsightDashboardItemType.Headline]: {
    label: 'text',
    icon: 'fal fa-input-text',
  },
  [InsightDashboardItemType.Table]: {
    label: 'table',
    icon: 'fal fa-table',
  },
  [InsightDashboardTempItemType.TempChart]: {
    label: 'chart',
    icon: 'fal fa-chart-simple',
  },
};

export const getGridSize = (updatingItem: GridDashboardItemCreate, items: GridDashboardItem[]): DashboardGridSize => {
  const defaultGridSize = getDefaultGridSize(updatingItem.type);
  const itemHeights = calculateItemHeight(updatingItem, defaultGridSize.w);

  return { ...defaultGridSize, ...getOrdinate(defaultGridSize.w, items), ...itemHeights };
};

export const getDefaultGridSize = (type: InsightDashboardItemType | 'default') => {
  switch (type) {
    case InsightDashboardItemType.Chart:
    case InsightDashboardItemType.SDGTracker:
      return {
        w: COLUMNS / 2,
        h: 17,
        minW: COLUMNS / 3,
        minH: DEFAULT_HEIGHT,
      };
    case InsightDashboardItemType.Headline:
    case InsightDashboardItemType.Text:
      return {
        w: COLUMNS,
        minW: COLUMNS / 3,
      };
    case InsightDashboardItemType.Table:
      return {
        w: COLUMNS,
        minW: COLUMNS,
      };
    default:
      return {
        x: 0,
        y: 0,
        w: COLUMNS / 3,
        h: DEFAULT_HEIGHT,
        minW: COLUMNS / 3,
        minH: DEFAULT_HEIGHT,
      };
  }
};

export const getOrdinate = (newItemWidth: number, items: GridDashboardItem[]) => {
  if (!items.length) {
    return { x: 0, y: 0 };
  }

  const finalItem = items.reduce((acc, current) => {
    return acc.y > current.gridSize.y ? acc : current.gridSize;
  }, items[0].gridSize);

  const hasRowEnoughSpace = finalItem.x + finalItem.w + newItemWidth <= COLUMNS;
  const x = hasRowEnoughSpace ? finalItem.x + finalItem.w : 0;
  const y = hasRowEnoughSpace ? finalItem.y : finalItem.y + finalItem.h;

  return { x, y };
};

const MAXIMUM_LENGTH = 10;
const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

export const transformVariables = (variables: UtrVariable[]) => {
  if (!variables) {
    return {};
  }

  if (variables.length > MAXIMUM_LENGTH) {
    return {};
  }
  const keys = ALPHABET.split('');

  return variables.reduce((acc, variable, index) => {
    acc[keys[index]] = {
      code: variable.code,
      valueListCode: variable.valueListCode,
      groupCode: variable.groupCode,
      subGroupCode: variable.subGroupCode,
    };
    return acc;
  }, {} as UtrVariables);
};

export const getChartSubTypeOptions = () => {
  return [
    { code: ChartSubType.Line, name: 'Line chart' },
    { code: ChartSubType.SingleValue, name: 'Single value' },
    {
      code: ChartSubType.SparkLine,
      name: 'Single value + spark line',
    },
  ];
};

export const getDisplayDashboardItemType = (type: InsightDashboardItemType) => {
  switch (type) {
    case InsightDashboardItemType.Chart:
      return 'Chart';
    case InsightDashboardItemType.Headline:
      return 'Headline';
    case InsightDashboardItemType.Text:
      return 'Text';
    case InsightDashboardItemType.SDGTracker:
      return 'SDG Tracker';
    case InsightDashboardItemType.Table:
      return 'Table';
    default:
      return '';
  }
};

export const hasUtrvHistoryModal = (item: GridDashboardItem) => {
  return isChartType(item) || isSDGTrackerType(item) || isTableType(item);
};

export const isSingleUtrChart = (subType: ChartSubType, calculation: Calculation) => {
  return (
    (subType === ChartSubType.SingleValue || subType === ChartSubType.SparkLine) && calculation.values.length === 1
  );
};
