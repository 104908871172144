import { Button, ModalFooter } from 'reactstrap';
import { UniversalTrackerBlueprintMin } from '../../../../../../types/universalTracker';
import { ChartData, QuestionData, transformVariables } from '../../../../utils';
import { SelectDataPointProps } from '../types';
import { View } from '../types';
import { SelectDataPointFooter } from '../SelectDataPointFooter';
import { emptyUtrData, getChartCalculation, getChartTitleByType, isValidUtrVariable } from '../utils';
import { GridDashboardItemCreate, InsightDashboardItemType } from '../../../../../../types/insight-custom-dashboard';

interface FooterProps extends SelectDataPointProps {
  view: View;
  closeAddMetric: () => void;
  questionData: QuestionData;
  setQuestionData: (questionData: QuestionData) => void;
}

const getTitle = ({
  chartData: { type, metrics },
  questionsMap,
}: {
  chartData: ChartData;
  questionsMap: Map<string, UniversalTrackerBlueprintMin>;
}) => {
  if (!metrics || metrics.length === 0 || !type) {
    return '';
  }

  // Extract title from the first metric
  const [{ code }] = metrics;

  return getChartTitleByType({chartType: type, code, questionsMap})
};

export const MetricsFooter = (props: FooterProps) => {
  const {
    view,
    chartData,
    item,
    questionsMap,
    questionData,
    setQuestionData,
    closeAddMetric,
    togglePreviewModal,
    backToEntryPoint,
    changeChartData,
    handleSubmitChart,
    isCustomTitle,
  } = props;
  const isUTRChart = chartData.type === InsightDashboardItemType.Chart;

  const handleCreate = async () => {
    if (!chartData.metrics || !chartData.subType) {
      return;
    }
    // If not set, use the title of the first metric
    const title = isCustomTitle ? chartData.title : getTitle({ chartData, questionsMap });
    const variables = transformVariables(chartData.metrics);
    const subType = chartData.subType;

    const calculation = getChartCalculation({ variables, subType, utrs: Array.from(questionsMap.values()) });
    const submitItem = {
      ...item,
      subType,
      title,
      variables,
      calculation,
      icon: undefined,
      type: isUTRChart ? InsightDashboardItemType.Chart : InsightDashboardItemType.SDGTracker,
    } as GridDashboardItemCreate;

    return handleSubmitChart(submitItem);
  };

  const handleClose = () => {
    setQuestionData(emptyUtrData);
    closeAddMetric();
  };

  const handleAdd = () => {
    if (!isValidUtrVariable(questionData)) {
      return;
    }
    const newMetrics = chartData.metrics ? [...chartData.metrics, questionData] : [questionData];

    changeChartData({ ...chartData, metrics: newMetrics });
    handleClose();
  };

  switch (view) {
    case View.CombineMetrics:
      return (
        <SelectDataPointFooter
          {...{ chartData, handleBack: backToEntryPoint, handleCreate, togglePreviewModal, item }}
        />
      );
    case View.TrackMetric:
      return (
        <ModalFooter className='w-100 d-flex'>
          <Button color='link-secondary' onClick={handleClose} className='mr-3'>
            <i className='fa fa-chevron-left mr-2' />
            Cancel
          </Button>
          <Button color='primary' disabled={!questionData.code} onClick={handleAdd}>
            Add metric
          </Button>
        </ModalFooter>
      );
    default:
      return null;
  }
};
