import { DropdownItem } from 'reactstrap';
import { generateErrorToast, generateToast } from '../../../toasts';
import { useContext, useState } from 'react';
import { CustomDashboardContext } from '../../../../routes/custom-dashboard/context/CustomDashboardWrapper';

export const DuplicateDropdownItem = () => {
  const { duplicateDashboard } = useContext(CustomDashboardContext);
  const [isDuplicating, setDuplicating] = useState(false);

  const handleClick = () => {
    setDuplicating(true);
    duplicateDashboard()
      .then(() => {
        generateToast({
          color: 'success',
          message: 'Dashboard has been successfully duplicated',
        });
      })
      .catch((error) => {
        generateErrorToast(error);
      })
      .finally(() => {
        setDuplicating(false);
      });
  };

  return (
    <>
      <DropdownItem onClick={handleClick} disabled={isDuplicating}>
        Duplicate dashboard
      </DropdownItem>
    </>
  );
};
