import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../../reducers';
import { useDispatch } from 'react-redux';
import { loadInitiativeQuestions } from '../../../actions/blueprints';
import { loadCustomMetricGroupsByInitiativeId } from '../../../actions/initiative';
import UniversalTracker from '../../../model/UniversalTracker';
import { getRootFrameworksOptions, getStandardsOptions } from '../../../constants/standards-frameworks';
import { filterMetricGroups, filterStandardAndFramework } from '../../../components/survey/utils/filters';
import { QuestionData, QuestionFilters, createQuestionOptions, getResetChildren } from '../utils';
import { getGroup } from '@g17eco/core';
import {
  UniversalTrackerBlueprintMin,
  UniversalTrackerPlain,
  getTableColumnTypeNames,
} from '../../../types/universalTracker';
import { ActionMeta, createFilter } from 'react-select';
import {
  MetaAction,
  Option,
  QuestionInputOption,
  simpleNumericTypes,
} from '../items/partials/question-selecting-filter/utils';

interface UseQuestionFiltersProps {
  initiativeId: string;
  questionData?: QuestionData;
  blueprintQuestions: UniversalTrackerBlueprintMin[];
}

export const useQuestionFilters = ({ initiativeId, questionData, blueprintQuestions }: UseQuestionFiltersProps) => {
  const [filters, setFilters] = useState<QuestionFilters>(() => {
    if (!questionData) {
      return {};
    }
    const { valueListCode, groupCode, subGroupCode, code } = questionData;
    return {
      pack: groupCode,
      subPack: subGroupCode,
      question: code,
      input: valueListCode,
    };
  });
  const { data: metricGroups } = useAppSelector((state) => state.customMetricGroups);
  const [selectedQuestionCode, setSelectedQuestionCode] = useState<string>(questionData?.code || '');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initiativeId) {
      return;
    }
    dispatch(loadInitiativeQuestions(initiativeId));
    dispatch(loadCustomMetricGroupsByInitiativeId(initiativeId));
  }, [dispatch, initiativeId]);

  const packOptions: Option[] = useMemo(() => {
    const customGroupOptions = metricGroups.map((group) => {
      return {
        label: (
          <span>
            {group.groupData?.icon ? (
              <img src={group.groupData.icon} alt={group.groupName} height='26px' className='mr-2' />
            ) : null}
            <span>{group.groupName}</span>
          </span>
        ),
        searchString: group.groupName,
        value: group._id,
      };
    });
    return [...getStandardsOptions(), ...getRootFrameworksOptions(), ...customGroupOptions];
  }, [metricGroups]);

  const subPacks: Option[] = useMemo(() => {
    if (!filters.pack) {
      return [];
    }
    const subGroups = getGroup('standards-and-frameworks', filters.pack)?.subgroups ?? [];
    return subGroups.map((group) => ({
      label: group.name,
      searchString: group.name,
      value: group.code,
    }));
  }, [filters.pack]);

  const filteredQuestions: Option[] = useMemo(() => {
    if (!blueprintQuestions || !filters.pack) {
      return [];
    }
    const scope = filters.subPack || filters.pack;
    if (!getGroup('standards-and-frameworks', filters.pack)) {
      return createQuestionOptions(
        blueprintQuestions.filter((question) =>
          filterMetricGroups(
            { universalTracker: new UniversalTracker(question as UniversalTrackerPlain) },
            scope,
            metricGroups
          )
        )
      );
    }
    return createQuestionOptions(
      blueprintQuestions.filter((question) =>
        filterStandardAndFramework({ universalTracker: new UniversalTracker(question as UniversalTrackerPlain) }, scope)
      )
    );
  }, [blueprintQuestions, filters.pack, filters.subPack, metricGroups]);

  const questionInputs: QuestionInputOption[] = useMemo(() => {
    const currentQuestion = filteredQuestions.find((question) => question.value === selectedQuestionCode);
    const utrPlain = blueprintQuestions?.find((question) => question.code === currentQuestion?.value);

    if (!utrPlain) {
      return [];
    }
    const tableColumnsOptions = utrPlain.valueValidation?.table?.columns.map((c) => {
      const isAllowed = simpleNumericTypes.includes(c.type);
      const typeString = !isAllowed ? 'Not Supported' : getTableColumnTypeNames(c.type, Boolean(c.listId), c);
      return {
        value: c.code,
        label: `${c.name} (${typeString})`,
        searchString: `${c.name} (${typeString})`,
        disabled: !isAllowed,
      };
    });
    return tableColumnsOptions ?? [];
  }, [blueprintQuestions, filteredQuestions, selectedQuestionCode]);

  const filterOption = createFilter<Option>({
    stringify: ({ data }) => `${data.searchString ?? data.label}`,
  });

  const handleChangeFilters = (key: keyof QuestionFilters, actionMeta: ActionMeta<Option>, option?: string) => {
    const reset = getResetChildren(key);
    switch (actionMeta.action) {
      case MetaAction.Select:
        setFilters({
          ...filters,
          ...reset,
          [key]: option,
        });
        break;
      case MetaAction.Clear:
        setFilters({
          ...filters,
          ...reset,
          [key]: '',
        });
        break;
      default:
        break;
    }
  };

  return {
    packOptions,
    subPacks,
    filteredQuestions,
    questionInputs,
    filters,
    selectedQuestionCode,
    filterOption,
    handleChangeFilters,
    setSelectedQuestionCode,
  };
};
