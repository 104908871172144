import { ScopeTotals } from '../../../types/download';

export interface TotalsPropType {
  totals: ScopeTotals | undefined;
  className?: string;
}

export const Totals = ({ totals, className = 'p-3' }: TotalsPropType) => {
  if (!totals) {
    return null;
  }
  return (
    <div className={`counter ml-auto mt-auto ${className}`}>
      {totals.total > 0 ? (
        <span>
          <span className='verified strong'>{totals.verified}</span> / {totals.total}
        </span>
      ) : (
        <span>- / -</span>
      )}
    </div>
  );
};
