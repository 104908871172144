import { Button, Input } from 'reactstrap';
import { getPeriodName } from '../../../../utils/universalTracker';
import { DATE, formatDate } from '../../../../utils/date';
import { naturalSort } from '../../../../utils';
import { generateUrl } from '../../../util';
import { ROUTES } from '../../../../constants/routes';
import { Link } from 'react-router-dom';
import Table, { TableColumn } from '../../../../components/table/Table';
import { SurveyStats } from '../../../../api/initiative-stats';

type SurveysTableProps = {
  surveys: SurveyStats[];
  selectedSurveys: string[];
  setSelectedSurveys: React.Dispatch<React.SetStateAction<string[]>>;
};

export const SurveysTable = (props: SurveysTableProps) => {
  const { surveys, selectedSurveys, setSelectedSurveys } = props;

  const toggleSelect = (surveyId: string) => {
    if (!selectedSurveys.includes(surveyId)) {
      setSelectedSurveys((prev) => [...prev, surveyId]);
      return;
    }

    setSelectedSurveys((prev) => prev.filter((id) => id !== surveyId));
  };

  const isSelectedAll = selectedSurveys.length === surveys.length;
  const toggleSelectAll = () => {
    setSelectedSurveys(isSelectedAll ? [] : surveys.map(({ _id }) => _id));
  };
  const columns: TableColumn<SurveyStats>[] = [
    {
      Header: 'Type',
      accessor: (row: SurveyStats) => getPeriodName(row.period, false),
      cellProps: {
        style: {
          textAlign: 'center',
        },
      },
    },
    {
      Header: 'Up to',
      accessor: (row) => formatDate(row.effectiveDate, DATE.MONTH),
      sortType: (a, b) => naturalSort(a.original.effectiveDate, b.original.effectiveDate),
      cellProps: {
        style: {
          textAlign: 'center',
        },
      },
    },
    {
      cellProps: {
        style: {
          maxWidth: 250,
        },
      },
      Header: 'Title',
      accessor: (row) => {
        const url = generateUrl(ROUTES.COMPANY_TRACKER_SURVEY, { initiativeId: row.initiativeId, surveyId: row._id });
        return (
          <Link to={url}>
            <span className={'text-truncate'} style={{ display: 'block' }}>
              {row.name ?? row.initiativeName ?? '-'}
            </span>
          </Link>
        );
      },
    },
    {
      id: 'status.created',
      Header: () => <i className='fa fa-user-clock text-secondary' />,
      accessor: (row) => (
        <div style={{ width: '100%', borderLeft: '1px solid #E6E8ED', padding: '3px 0' }}>
          {row.status.created || '-'}
        </div>
      ),
      cellProps: {
        style: {
          textAlign: 'right',
        },
      },
    },
    {
      id: 'status.updated',
      Header: () => <i className='fa fa-user-edit text-primary' />,
      accessor: (row) => row.status.updated || '-',
      cellProps: {
        style: {
          textAlign: 'right',
        },
      },
    },
    {
      id: 'status.rejected',
      Header: () => <i className='fa fa-user-times text-danger' />,
      accessor: (row) => row.status.rejected || '-',
      cellProps: {
        style: {
          textAlign: 'right',
        },
      },
    },
    {
      id: 'status.verified',
      Header: () => <i className='fa fa-user-check text-success' />,
      accessor: (row) => row.status.verified || '-',
      cellProps: {
        style: {
          textAlign: 'right',
        },
      },
    },
    {
      id: 'select',
      Header: () => (
        <Button onClick={toggleSelectAll} color={'link'} className='btn-xs'>
          {isSelectedAll ? 'Deselect' : 'Select'} all
        </Button>
      ),
      cellProps: {
        style: {
          textAlign: 'center',
        },
      },
      accessor: (row) => (
        <div style={{ width: '100%', borderLeft: '1px solid #E6E8ED', padding: '3px 0' }}>
          <Input
            {...{
              type: 'checkbox',
              checked: selectedSurveys.includes(row._id),
              onChange: () => toggleSelect(row._id),
            }}
          />
        </div>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <Table
      defaultSortIcon={null}
      showRowCount={false}
      responsive={false}
      pageSize={100}
      columns={columns}
      data={surveys}
      noData={<>No results</>}
      className='surveys-editing__table'
    />
  );
};
