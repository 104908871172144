import { Cell, Column, useTable } from 'react-table';
import { TableRowDataInfo } from '../types';

type TableColumn = Column<TableRowDataInfo> & { cellProps?: { className?: string } };

interface Props {
  rowData: TableRowDataInfo[];
  editRowId: string;
  columns: TableColumn[]
}

export const MultiRowTable = (props: Props) => {
  const { rowData, editRowId, columns } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: rowData,
  });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const isActive = row.original.id === editRowId;
          const classes = `${isActive ? 'active-edit-row' : ''}`;
          return (
            <tr {...row.getRowProps({ className: classes })}>
              {row.cells.map((cell: Cell<TableRowDataInfo> & { column: { cellProps?: object } }) => {
                return <td {...cell.getCellProps(cell.column.cellProps)}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
