import Select from 'react-select';
import { getCode } from '@components/add-utr-modal/utils';
import Loader from '@components/loader';
import { SuggestionsProps } from '@components/target-actual/TargetActualModal';
import { useGetSDGTargetSuggestionsQuery } from '@api/universal-trackers';
import { BasicAlert } from '@components/alert/BasicAlert';
import { QuestionData } from '@routes/custom-dashboard/utils';
import { emptyUtrData } from '../utils';


interface Props {
  goalCode: string;
  sdgCode?: string;
  changeQuestionData: (questionData: Partial<QuestionData>) => void;
}

export const SDGTargetSuggestions = ({ goalCode, sdgCode, changeQuestionData }: Props) => {
  const { data: suggestions = [], isLoading, error } = useGetSDGTargetSuggestionsQuery(goalCode);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <BasicAlert type='danger'>{error.message}</BasicAlert>;
  }

  const suggestionSdg = suggestions.find((suggestion: SuggestionsProps) => getCode(suggestion.code) === goalCode);

  if (suggestions.length === 0 || !suggestionSdg) {
    return (
      <ul>
        <li>
          <span>There are no SDG {goalCode} targets available to choose from.</span>
        </li>
      </ul>
    );
  }

  const [, sdgDesc] = suggestionSdg.name.split('-');
  const suggestionTargets = suggestions.filter((suggestion) => getCode(suggestion.code) !== goalCode);

  const handleSelect = (code?: string) => {
    changeQuestionData({ ...emptyUtrData, code });
  };

  const options = [
    { value: suggestionSdg.code, label: `${getCode(suggestionSdg.code)}: ${sdgDesc}` },
    ...suggestionTargets.map(({ code, name }) => ({ value: code, label: `${getCode(code)}: ${name}` })),
  ];

  return (
    <Select
      placeholder={'Select SDG Target'}
      isClearable={false}
      options={options}
      value={options.find((option) => option.value === sdgCode)}
      onChange={(option) => handleSelect(option?.value)}
    />
  );
};
