import { hasAtLeastOneRoleLevel, isAssignedToAll } from './ultis';
import { roles } from '../../constants/roles';
import { DelegatedUsersTableProps, RoleLevel, User } from './types';
import SimpleTooltip from '../simple-tooltip';
import { OnboardingStatus } from '../../types/onboarding';
import { ellipsis } from '../../utils';
import G17EcoLogo from '../../images/g17eco-icon.svg';
import { InfoIcon } from '../common/InfoIcon';
import { Button } from 'reactstrap';
import { SurveyUserRoles } from '../../constants/users';
import { QUESTION } from '../../constants/terminology';

export const UserRow = ({
  userRole,
  user,
  utrvs,
  handleRemoveUser,
}: Pick<DelegatedUsersTableProps, 'utrvs' | 'handleRemoveUser'> & { userRole: SurveyUserRoles; user: User }) => {
  return (
    <div className='d-flex align-items-center'>
      <CircleIcon userRole={userRole} user={user} utrvs={utrvs} />
      <UserText user={user} />
      {user.isStaff ? <StaffIcon /> : null}
      <Action userRole={userRole} user={user} handleRemoveUser={handleRemoveUser} />
    </div>
  );
};

const CircleIcon = ({
  userRole,
  user,
  utrvs,
}: Pick<DelegatedUsersTableProps, 'utrvs'> & { userRole: SurveyUserRoles; user: User }) => {
  const isFullCircle = isAssignedToAll(userRole, user, utrvs?.length || 0);

  const icon = isFullCircle ? 'fa-circle' : 'fa-circle-half-stroke';
  const tooltip = `Delegated as a ${roles[userRole].shortName?.toLowerCase()} for ${
    isFullCircle ? 'all' : 'some'
  } of the ${QUESTION.PLURAL} selected.`;

  return (
    <SimpleTooltip text={tooltip}>
      <i className={`text-ThemeIconDark mr-3 fa-solid ${icon}`}></i>
    </SimpleTooltip>
  );
};

const UserText = ({ user }: { user: User }) => {
  if (user.status && [OnboardingStatus.Pending, OnboardingStatus.NotStarted].includes(user.status)) {
    const limit = 30;
    const tooltip = (user.firstName?.length || 0) > limit ? user.firstName : undefined;
    return (
      <span className={'pendingText'}>
        <i title='Invitation sent and waiting confirmation' className='fas fa-paper-plane pending mr-1' />
        <SimpleTooltip text={tooltip}>{ellipsis(user.firstName || '', limit)}</SimpleTooltip> (pending)
      </span>
    );
  }

  if (user.active) {
    return (
      <span>
        {user.firstName} {user.surname}{' '}
      </span>
    );
  }

  return (
    <span className={'user-inactive'}>
      {user.firstName} {user.surname}{' '}
      <SimpleTooltip
        id={`optionTooltip-${user._id}`}
        text={'User is not active'}
        component={<i className='fas fa-info-circle' />}
      />
    </span>
  );
};

const StaffIcon = () => (
  <SimpleTooltip text={'Support user'}>
    <img src={G17EcoLogo} alt='staff-icon' height={12} className='ml-2' />
  </SimpleTooltip>
);

const Action = ({
  userRole,
  user,
  handleRemoveUser,
}: { userRole: SurveyUserRoles; user: User } & Pick<DelegatedUsersTableProps, 'handleRemoveUser'>) => {
  if (hasAtLeastOneRoleLevel([RoleLevel.Initiative], userRole, user)) {
    const text = `User has the ${roles[
      userRole
    ].shortName?.toLowerCase()} permission assigned to them at the platform level and cannot be removed from ${QUESTION.SINGULAR} level delegation.`;
    return (
      <div className='ml-auto mr-2'>
        <InfoIcon text={text} />
      </div>
    );
  }

  if (hasAtLeastOneRoleLevel([RoleLevel.Survey], userRole, user)) {
    const text = `User has the ${roles[
      userRole
    ].shortName?.toLowerCase()} permission assigned to them at the survey level and cannot be removed from ${QUESTION.SINGULAR} level delegation.`;
    return (
      <div className='ml-auto mr-2'>
        <InfoIcon text={text} />
      </div>
    );
  }

  return (
    <Button
      color='link'
      className='mx-1 p-0 ml-auto mr-2'
      onClick={() => handleRemoveUser({ role: userRole, userId: user._id })}
    >
      <i className='fas fa-times text-ThemeDangerMedium' />
    </Button>
  );
};
