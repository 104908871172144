import Table, { TableColumn } from '@components/table/Table';
import IconButton from '@components/button/IconButton';
import { UniversalTrackerBlueprintMin } from '@g17eco/types/universalTracker';
import { UtrVariable } from '../../../../../summary/insights/utils/constants';
import { getChartTitleByType } from '../utils';
import { InsightDashboardItemChartType } from '@g17eco/types/insight-custom-dashboard';

type MetricsTableRow = UtrVariable & Pick<UniversalTrackerBlueprintMin, 'valueLabel'>;

interface Props {
  metrics: UtrVariable[];
  questionsMap: Map<string, UniversalTrackerBlueprintMin>;
  handleRemove: (code: UtrVariable) => void;
  chartType: InsightDashboardItemChartType;
}

export const MetricsTable = ({ metrics, questionsMap, handleRemove, chartType }: Props) => {
  const data: MetricsTableRow[] = metrics.map((metric) => {
    const valueLabel = getChartTitleByType({ chartType, code: metric.code, questionsMap });
    return {
      ...metric,
      valueLabel,
    };
  });

  const tableColumns: TableColumn<MetricsTableRow>[] = [
    {
      accessor: 'valueLabel',
      Header: () => 'Metric name',
      cellProps: {
        className: 'flex-grow-1',
      },
    },
    {
      accessor: 'action-buttons',
      Header: () => '',
      cellProps: {
        className: 'flex-grow-0',
      },
      Cell: ({ row }) => {
        return (
          <IconButton
            className='border-0'
            color='danger'
            onClick={() => handleRemove(row.original)}
            icon='fal fa-trash'
          />
        );
      },
      disableSortBy: true,
    },
  ];

  return <Table responsive={true} showHeader={false} columns={tableColumns} data={data} pageSize={5} />;
};
