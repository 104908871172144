import React from 'react';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { Details, DefaultSurveyConfig } from '../../components/company-settings';
import { CompanySettingsPages } from '../../components/company-settings/Navigation';
import { ROUTES } from '../../constants/routes';

export const getBaseCompanySettingsRoute = (isPortfolioTracker: boolean) =>
  isPortfolioTracker ? ROUTES.PORTFOLIO_TRACKER_COMPANY_SETTINGS : ROUTES.COMPANY_SETTINGS;

const Page = ({ page }: { page?: string }) => {
  switch (page) {
    case CompanySettingsPages.DefaultSurveyConfig:
      return <DefaultSurveyConfig />;
    case CompanySettingsPages.Details:
    default:
      return <Details />;
  }
};

export const CompanySettingsRoute = () => {
  const { path } = useRouteMatch();
  const { page } = useParams<{ page?: string }>();

  return (
    <>
      <Switch>
        <Route path={path}>
          <Page page={page} />
        </Route>
      </Switch>
    </>
  );
};
