
import { ValueListPlain } from '../types/valueList';
import { g17ecoApi, transformResponse } from './g17ecoApi';

export const valueListApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['value-list'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getValueListById: builder.query<ValueListPlain, string>({
        transformResponse,
        query: (listId) => {
          return {
            url: `value-list/${listId}`,
            method: 'get',
          };
        },
        providesTags: (result, error, agrs) => [{ type: 'value-list', id: agrs }],
      }),
    }),
  });

export const { useGetValueListByIdQuery } = valueListApi;
