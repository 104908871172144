import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useGetInsightDashboardsByInitiativeQuery } from '../../api/insight-dashboards';
import { InsightDashboardOption } from '../../routes/summary/insights/utils/sidebar';
import Loader from '../loader';
import { GridDashboardItemCreate, InsightDashboard, InsightDashboardItemType } from '../../types/insight-custom-dashboard';
import { useCreateInsightDashboardItemMutation } from '../../api/insight-dashboards';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { getGridSize } from '../../routes/custom-dashboard/utils';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import UniversalTracker from '../../model/UniversalTracker';

interface AddToDashboardDropdownProps {
  initiativeId?: string;
  alternativeCode?: string;
  selectedColumnCode?: string;
  utr: UniversalTracker;
}

export const AddToDashboardDropdown = ({
  initiativeId = '',
  alternativeCode,
  selectedColumnCode,
  utr,
}: AddToDashboardDropdownProps) => {
  const { data: dashboards, isFetching } = useGetInsightDashboardsByInitiativeQuery(initiativeId, {
    skip: !initiativeId,
  });
  const [createInsightDashboardItem] = useCreateInsightDashboardItemMutation();
  const { addSiteAlert, addSiteError } = useSiteAlert();

  const dashboardOptions: InsightDashboardOption[] = (dashboards || []).map((dashboard) => ({
    value: dashboard._id,
    label: dashboard.title,
    isCustom: true,
  }));

  const onAddItem = async (dashboard: InsightDashboard, page: string) => {
    if (!initiativeId) {
      return;
    }
    const item: GridDashboardItemCreate = {
      type: InsightDashboardItemType.Chart,
      title: utr.getName(),
    }
    return createInsightDashboardItem({
      ...item,
      initiativeId,
      dashboardId: dashboard._id,
      utrVariables: [{ code: utr.getCode(), valueListCode: selectedColumnCode, groupCode: alternativeCode }],
      gridSize: getGridSize(item, dashboard.items),
    })
      .then(() => {
        addSiteAlert({
          content: `Chart added to ${page}`,
          timeout: 2000,
          color: SiteAlertColors.Success,
        });
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  const onAddChart = (option: InsightDashboardOption) => {
    const dashboard = (dashboards || []).find(({ _id }) => _id === option.value);

    if (!dashboard) {
      return;
    }

    onAddItem(dashboard, option.label);
  };

  return (
    <UncontrolledDropdown>
      <DropdownToggle outline>
        <i className='fal fa-plus' />
      </DropdownToggle>
      <DropdownMenu>
        {isFetching ? (
          <Loader />
        ) : (
          <>
            <div className='pl-3' style={{ minWidth: '180px' }}>
              CUSTOM DASHBOARDS
            </div>
            {dashboardOptions.map((option, i) => (
              <DropdownItem key={`op-${i}}`} onClick={() => onAddChart(option)}>
                <span className='border border-ThemeNeutralsDark mr-2 py-1 rounded'>
                  <i className='fal fa-plus text-xs'></i>
                </span>
                {`Add to ${option.label} page`}
              </DropdownItem>
            ))}
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
