import { ApiResponse } from '../types/api';
import { UniversalTrackerPlain } from '../types/universalTracker';
import { g17ecoApi } from './g17ecoApi';

export interface CustomMetric extends UniversalTrackerPlain {
  utrvCount: number;
}
export interface CustomMetricsResponse {
  created: CustomMetric[];
  used: CustomMetric[];
}

const transformResponse = <T>(response: ApiResponse<T>) => response.data;

export const customMetricApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['initiative-custom-metrics'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getInitiativeCustomMetrics: builder.query<CustomMetricsResponse, string>({
        transformResponse,
        query: (initiativeId) => {
          return {
            url: `/initiatives/${initiativeId}/custom-metrics`,
            method: 'get',
          };
        },
        providesTags: ['initiative-custom-metrics'],
      }),
    }),
  });

export const { useGetInitiativeCustomMetricsQuery } = customMetricApi;
