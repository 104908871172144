import Select, { SingleValue } from 'react-select';
import { Col, Row } from 'reactstrap';
import { MonthDay } from '../../types/onboarding';

interface Props {
  value: MonthDay;
  onChange: (value: MonthDay) => void;
}

type Option = SingleValue<{ value: string; label: string }>;

const defaultOption = { value: '-', label: '-' };

const daysPerMonth: { [key: string]: number } = {
  january: 31,
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31,
};

const getDayOptions = (month: string) => {
  if (!month || !Object.keys(daysPerMonth).includes(month)) {
    return [defaultOption];
  }

  return Array.from({ length: daysPerMonth[month] }, (v, i) => ({ value: String(i + 1), label: String(i + 1) }));
};

const monthOptions = [defaultOption, ...Object.keys(daysPerMonth).map((month) => ({ value: month, label: month }))];

const selectStyles = {
  indicatorSeparator: (provided: any) => ({ ...provided, display: 'none' }),
  singleValue: (provided: any) => ({
    ...provided,
    textTransform: 'capitalize',
  }),
  option: (provided: any) => ({ ...provided, textTransform: 'capitalize' }),
};

export const MonthDayPicker = ({ value, onChange }: Props) => {
  const { month, day } = value;

  const dayOptions = getDayOptions(month);

  const onChangeDay = (newValue: Option) => {
    onChange({ ...value, day: newValue?.value || '1' });
  };

  const onChangeMonth = (newValue: Option) => {
    const newMonth = newValue?.value;
    if (!newMonth || defaultOption.value === newMonth) {
      onChange({ month: defaultOption.value, day: defaultOption.value });
      return;
    }

    const days = daysPerMonth[newMonth];
    if (defaultOption.value !== day && Number(day) > days) {
      onChange({ month: newMonth, day: String(days) });
      return;
    }

    if (defaultOption.value === day) {
      onChange({ month: newMonth, day: '1' });
      return;
    }

    onChange({ ...value, month: newMonth });
  };

  const selectedMonth = monthOptions.find(({ value }) => value === month) ?? null;
  const selectedDay = dayOptions.find(({ value }) => value === day) ?? null;

  return (
    <Row className='month-day-picker mt-1'>
      <Col xs={8}>
        <div className='month-day-picker__label'>Month</div>
        <Select value={selectedMonth} options={monthOptions} onChange={onChangeMonth} styles={selectStyles} />
      </Col>
      <Col xs={4}>
        <div className='month-day-picker__label'>Day</div>
        <Select value={selectedDay} options={dayOptions} onChange={onChangeDay} styles={selectStyles} />
      </Col>
    </Row>
  );
};


