import { StakeholderGroup } from '../model/stakeholderGroup';
import { DelegationScope, Scope } from '../model/surveyData';
import { UtrvType } from '../constants/status';
import { UserPermissions } from '../constants/users';
import { AppConfig } from './app';

interface BasicUserRolePermissions {
  permissions: UserPermissions[];
}

export enum OnboardingStatus {
  NotStarted = 'not_started',
  Pending = 'pending',
  Started = 'started',
  Rejected = 'rejected',
  Complete = 'complete',
  Deleted = 'deleted',
}

export enum OnboardingStep {
  Signup = 'Signup',
  VerifyEmail = 'VerifyEmail',
  CompanyInfo = 'CompanyInfo',
  SectorInfo = 'SectorInfo',
  ReportingCurrency = 'ReportingCurrency',
  ReferralInfo = 'ReferralInfo',
  Activation = 'Activation',
  Complete = 'Complete',
  EmailDomainCompanyOnboardOrNot = 'EmailDomainCompanyOnboardOrNot',

  // Referral+Lookup page for listed companies
  SGXReferrerCodeIssuerLookup = 'SGXReferrerCodeIssuerLookup',
  IssuerCompanyOnboardOrNot = 'IssuerCompanyOnboardOrNot',
}

export enum ObType {
  JoinRequest = 'join_request',
}


interface SurveyConfig {
  code: string;
  surveyId?: string;
  sourceName: string;
  effectiveDate: Date;
  utrvType: UtrvType;
  evidenceRequired: boolean;
  verificationRequired: boolean;
  scope?: Scope;
  complete: boolean;
}

interface OnboardingUser extends BasicUserRolePermissions {
  emailTemplate?: string;
  userId?: string;
  sfId?: string;
  firstName?: string;
  surname?: string;
  email: string;
  complete: boolean;
  message?: string;
}

interface SurveyRoles {
  admin: string[],
  viewer: string[]
}

interface OnboardingCommon {
  user: OnboardingUser;
  initiativeId: string;
  type?: ObType
  utrvConfig?: StakeholderGroup;
  surveyStakeholders?: StakeholderGroup;
  assuranceStakeholders?: StakeholderGroup;
  surveyRoles?: SurveyRoles
  surveyIds?: string[];
  delegationScope?: DelegationScope;
  surveyConfig?: SurveyConfig;
  createdBy?: string;
}

export interface OnboardingModelPlain extends OnboardingCommon {
  _id: string;
  token: string;
  status: OnboardingStatus;
  created: string;
  onboardingListId?: string;
  surveyConfig?: SurveyConfig;
}

export interface OnboardingToken extends OnboardingModelPlain {
  initiativeName: string;
  externalIdp?: boolean;
  permissionGroup?: string;
  appConfig?: AppConfig;
}

export type ReferralInfoType = {
  [referralInfoKey: string]: string | null,
}

export interface MonthDay {
  month: string;
  day: string;
}

export const getStepTitle = (step: OnboardingStep): string => {
  switch (step) {
    case OnboardingStep.CompanyInfo:
    case OnboardingStep.SGXReferrerCodeIssuerLookup:
      return 'Company details';
    case OnboardingStep.IssuerCompanyOnboardOrNot:
      return 'Onboarding';
    case OnboardingStep.EmailDomainCompanyOnboardOrNot:
      return 'Company check';
    case OnboardingStep.SectorInfo:
      return 'Sector info';
    case OnboardingStep.ReferralInfo:
      return 'Referral info';
    case OnboardingStep.Signup:
      return 'Sign up';
    case OnboardingStep.VerifyEmail:
      return 'Verify email';
    case OnboardingStep.Activation:
      return 'Activation';
    case OnboardingStep.Complete:
      return 'Finished';
    default:
      return '';
  }
}

export interface ActivationToken {
  _id: string;
  created: string;
  expiryDate: string;
  token: string;
  userId: string;
}
