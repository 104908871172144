import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SubmitButton } from '../button/SubmitButton';
import { useToggle } from '../../hooks/useToggle';
import { RadioInput } from '../form/RadioInput';
import { useState } from 'react';
import { useUpdateAllAggregatedSurveysMutation } from '../../api/aggregated-surveys';
import { useSiteAlert } from '../../hooks/useSiteAlert';

interface Props {
  initiativeId: string;
}

const options = [{ code: 'all', name: 'All levels' }];

export const UpdateCombinedSurveyButton = ({ initiativeId }: Props) => {
  const [open, toggle] = useToggle();
  const [option, setOption] = useState(options[0].code);

  const [updateAllAggregatedSurveys] = useUpdateAllAggregatedSurveysMutation();

  const { addSiteAlert, addSiteError } = useSiteAlert();

  const handleSubmit = async () => {
    return updateAllAggregatedSurveys({ initiativeId })
      .unwrap()
      .then(() => {
        addSiteAlert({
          content: 'Combined surveys are updating. Notification will be sent when the process is completed',
        });
      })
      .catch((e) => {
        addSiteError(e.message);
      })
      .finally(() => {
        toggle();
      });
  };
  return (
    <>
      <div className='flex-grow-1 d-flex justify-content-center'>
        <Button color='secondary' onClick={toggle}>
          Update combined survey data
        </Button>
      </div>
      <Modal isOpen={open} toggle={toggle} backdrop='static'>
        <ModalHeader toggle={toggle}>Update combined survey data</ModalHeader>
        <ModalBody>
          <p>Update data for all available combined surveys.</p>

          <RadioInput
            groupCode='update-combined-survey'
            value={option}
            options={options}
            onChange={({ value }) => setOption(value)}
            className='d-flex'
            formGroupClassName='mr-5'
          />
        </ModalBody>
        <ModalFooter>
          <Button color='link-secondary' className='mr-3' onClick={toggle}>
            Cancel
          </Button>
          <SubmitButton onClick={handleSubmit}>Update</SubmitButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
