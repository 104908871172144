import { useHistory, useParams } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { generateUrl } from '../../../routes/util';
import { ROUTES } from '../../../constants/routes';
import { RouteParams } from '../types';
import { useToggle } from '../../../hooks/useToggle';

enum CreateType {
  SurveyTemplate = 'template',
  AggregatedTemplate = 'aggregated',
}

export const TemplateCreateDropdown = () => {
  const { initiativeId } = useParams<RouteParams>();
  const [isOpen, toggle] = useToggle(false);
  const history = useHistory();

  const goTo = (type: CreateType) => {
    return history.push(`${generateUrl(ROUTES.SURVEY_TEMPLATES, { initiativeId })}/${type}/create`);
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle color='transparent' caret>
        <i className='fa fa-bars mr-2' />
        Create new template
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem size='sm' color='secondary' onClick={() => goTo(CreateType.SurveyTemplate)}>
          <i className='fas fa-plus-square  mr-2' /> Create survey template
        </DropdownItem>
        <DropdownItem onClick={() => goTo(CreateType.AggregatedTemplate)}>
          <i className={'fa fa-object-exclude  icon mr-2'} /> Create combined survey template
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
