import { UniversalTrackerBlueprintMin } from '@g17eco/types/universalTracker';
import { DashboardItemFilters, HistoricalUtrs } from '../../../../../api/insights';
import {
  GridDashboardChartItem,
  GridDashboardChartItemBase,
  GridDashboardItemCreate,
  GridDashboardSDGChartItem,
  GridDashboardSDGChartItemBase,
  GridDashboardTempChartItem,
  InsightDashboardItemType,
} from '../../../../../types/insight-custom-dashboard';
import { UtrVariable } from '../../../../summary/insights/utils/constants';
import { GridItemProps } from '../../../GridItem';
import { ChartData } from '../../../utils';

export type ModalChartItem =
  | GridDashboardTempChartItem
  | GridDashboardChartItem
  | GridDashboardChartItemBase
  | GridDashboardSDGChartItem
  | GridDashboardSDGChartItemBase;

export interface ChartAddingModalProps extends Pick<GridItemProps, 'survey'> {
  initiativeId: string;
  toggle: () => void;
  handleSubmitChart: (updatingItem: GridDashboardItemCreate) => Promise<void>;
  item: ModalChartItem;
  queryParams: DashboardItemFilters;
}

export type SelectDataPointProps = Pick<ChartAddingModalProps, 'item' | 'handleSubmitChart'> & {
  chartData: ChartData;
  changeChartData: (chartData: Partial<ChartData>) => void;
  backToEntryPoint: () => void;
  togglePreviewModal: () => void;
  isCustomTitle: boolean;
  questionsMap: Map<string, UniversalTrackerBlueprintMin>;
};

export type PreviewChartData = Required<Pick<ChartData, 'subType'>> & {
  metrics: UtrVariable[];
  type: InsightDashboardItemType.Chart | InsightDashboardItemType.SDGTracker;
};

export interface HistoricalUtrsState {
  historicalUtrs: HistoricalUtrs[];
  isLoading: boolean;
  errorMessage: string;
}

export enum View {
  CombineMetrics,
  TrackMetric,
}
