import { Tag } from '../../types/metricGroup';
import { ellipsis } from '../../utils';
import { BasicAlert } from '../alert/BasicAlert';
import { Checkbox, CheckboxState } from '../common/Checkbox';
import { BulkActionToolbarSelectedUtrv } from '../survey-question-list/partials/BulkActionToolbar';
import { getTagStatus } from './utils';

interface TagListProps {
  tags: Tag[];
  selectedUtrIds?: BulkActionToolbarSelectedUtrv['universalTrackerId'][];
  search?: string;
  handleSelect: (tag: Tag) => void;
}

const MIN_LENGTH_TO_SCROLL = 9;

export const TagList = (props: TagListProps) => {
  const { tags, selectedUtrIds, search, handleSelect } = props;

  if (!tags.length) {
    const emptyMessage = !search ? 'No tags have been created' : 'No tag match your criteria';
    return (
      <BasicAlert type='secondary' className='mt-3'>
        {emptyMessage}
      </BasicAlert>
    );
  }

  const padding = tags.length > MIN_LENGTH_TO_SCROLL ? 'pr-2' : '';

  const getCheckboxStatus = (tag: Tag) => {
    if (!selectedUtrIds) {
      return CheckboxState.Unchecked;
    }
    return getTagStatus(tag, selectedUtrIds);
  };

  return (
    <div className={`tag-list__wrapper d-flex flex-column gap-3 ${padding} mt-3 overflow-auto`}>
      {tags.map((tag: Tag) => (
        <div key={tag._id} className='d-flex align-items-center justify-content-between'>
          <div>
            <i className='fal fa-tag text-ThemeIconSecondary mr-2'></i>
            {ellipsis(tag.groupName, 25)}
          </div>
          <Checkbox status={getCheckboxStatus(tag)} onChange={() => handleSelect(tag)} />
        </div>
      ))}
    </div>
  );
};
