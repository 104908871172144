import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { useRemoveOverviewDashboardItemMutation } from '../../../api/insight-dashboards';
import IconButton from '../../../components/button/IconButton';
import { useSiteAlert } from '../../../hooks/useSiteAlert';
import { OverviewDashboardItem, InsightDashboardItemType } from '../../../types/insight-custom-dashboard';

interface Props {
  initiativeId: string;
  item: OverviewDashboardItem;
}

export const DeleteFromOverviewBtn = ({ initiativeId, item }: Props) => {
  const { addSiteAlert, addSiteError } = useSiteAlert();
  const [removeOverviewItem, { isLoading }] = useRemoveOverviewDashboardItemMutation();
  const handleClick = () => {
    removeOverviewItem({ initiativeId, itemId: item._id })
      .unwrap()
      .then(() => {
        addSiteAlert({
          content: 'Chart is removed from Overview page',
        });
      })
      .catch((e) => {
        addSiteError(e);
      });
  };

  if (item.type !== InsightDashboardItemType.Chart || item.isDefault) {
    return null;
  }

  const btnId = `add-to-overview-btn-${item._id}`;

  return (
    <>
      <IconButton icon='fa-close' onClick={handleClick} disabled={isLoading} id={btnId} />
      <UncontrolledPopover placement='left' target={btnId} trigger='hover' hideArrow>
        <PopoverBody className='p-2'>Remove</PopoverBody>
      </UncontrolledPopover>
    </>
  );
};
