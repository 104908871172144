import { useParams } from 'react-router-dom';
import Dashboard, { DashboardRow, DashboardSection, DashboardSectionTitle } from '../../components/dashboard';
import { ROUTES } from '../../constants/routes';
import { generateUrl } from '../util';
import { AdminBreadcrumbs } from './AdminBreadcrumbs';
import { QuestionManagement } from './components/questions/QuestionManagement';
import { QUESTION } from '../../constants/terminology';

export const AdminQuestionRoute = () => {
  const { initiativeId } = useParams<{ initiativeId: string }>();

  return (
    <div className='admin-dashboard'>
      <Dashboard>
        <DashboardRow>
          <AdminBreadcrumbs
            initiativeId={initiativeId}
            breadcrumbs={[
              {
                label: 'Admin Dashboard',
                url: generateUrl(ROUTES.ADMIN_DASHBOARD, { initiativeId }),
              },
              {
                label: QUESTION.CAPITALIZED_PLURAL,
              },
            ]}
          />
        </DashboardRow>
        <DashboardSectionTitle title={QUESTION.CAPITALIZED_PLURAL} />
        <DashboardSection>
          <QuestionManagement showFilters initiativeId={initiativeId} />
        </DashboardSection>
      </Dashboard>
    </div>
  );
};
