import { ApiResponse } from '../types/api';
import { g17ecoApi } from './g17ecoApi';
import { SuggestionsProps, TargetValuesProps } from '../components/target-actual/TargetActualModal';

const transformResponse = <T>(response: ApiResponse<T>) => response.data;

const SDG_TRACKER_TAGS = ['sdg-tracker'];

export const universalTrackersApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: [...SDG_TRACKER_TAGS],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSDGTargetSuggestions: builder.query<SuggestionsProps[], TargetValuesProps['goalCode']>({
        transformResponse,
        query: (goalCode) => ({
          url: `universal-trackers/search?sdgGoal=${goalCode}`,
          method: 'get',
        }),
        providesTags: SDG_TRACKER_TAGS,
      }),
    }),
  });

export const { useGetSDGTargetSuggestionsQuery } = universalTrackersApi;
