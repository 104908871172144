import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useToggle } from '../../../hooks/useToggle';
import { LockAction } from './questionInterfaces';
import { QUESTION } from '../../../constants/terminology';
import SimpleTooltip from '../../simple-tooltip';

interface QuestionLockModalProps {
  handleLockAction: (action: LockAction) => void;
  isLocked: boolean;
  isDisabled: boolean;
}

const getDisabledButtonTooltipText = (isLocked: boolean) => {
  const action = isLocked ? 'unlocked' : 'locked';
  return `Question cannot be ${action} whilst survey is completed`;
}

export const QuestionLockButton = ({ handleLockAction, isLocked, isDisabled }: QuestionLockModalProps) => {
  const [isOpen, toggle] = useToggle(false);
  return (
    <>
      <SimpleTooltip text={isDisabled ? getDisabledButtonTooltipText(isLocked) : ''}>
        <Button color='link' onClick={toggle} disabled={isDisabled}>
          {isLocked ? `Unlock ${QUESTION.SINGULAR}` : `Lock ${QUESTION.SINGULAR}`}
        </Button>
      </SimpleTooltip>
      <Modal isOpen={isOpen} toggle={toggle} backdrop='static'>
        <ModalHeader className='text-ThemeWarningMedium' toggle={toggle}>
          <span className='text-ThemeWarningExtradark'>Restatement of data</span>
        </ModalHeader>
        <ModalBody>
          You are unlocking a {QUESTION.SINGULAR} that has been assured.
          <br /><br />
          If you restate any of the core information, then you should include an explanation in the Further explanation
          section.
          <br />
          The {QUESTION.SINGULAR} will be marked as 'Restated', not 'Assured'.
          <br />
          You can still view the Assured data in the {QUESTION.SINGULAR}'s Provenance section.
        </ModalBody>
        <ModalFooter className='pt-0'>
          <Button color='transparent' onClick={toggle}>
            Cancel
          </Button>
          <Button color='warning' onClick={() => handleLockAction(isLocked ? LockAction.Open : LockAction.Close)}>
            Proceed
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
