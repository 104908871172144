import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Sort, SortType } from '../../utils/sort';
import SimpleTooltip from '../simple-tooltip';

interface SortButtonProps {
  currentSort: SortType;
  handleSort: (sortType: SortType) => void;
}

export default function SortButton(props: SortButtonProps) {
  const { currentSort, handleSort } = props;
  const [isOpen, toggleOpen] = useState(false);
  const handleSortClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, sortType: SortType) => {
    e.preventDefault();
    handleSort(sortType);
  };
  const sorts = [
    {
      code: Sort.TitleAsc,
      icon: 'fa-sort-alpha-down',
      tooltip: 'Sort by Title, Ascending',
    },
    {
      code: Sort.TitleDesc,
      icon: 'fa-sort-alpha-down-alt',
      tooltip: 'Sort by Title, Descending',
    },
    {
      code: Sort.UnitCountAsc,
      icon: 'fa-sort-amount-down-alt',
      tooltip: 'Sort by counts, Ascending',
    },
    {
      code: Sort.UnitCountDesc,
      icon: 'fa-sort-amount-down',
      tooltip: 'Sort by counts, Descending',
    },
  ];
  const selectedSort = sorts.find(s => s.code === currentSort) ?? sorts[0];
  return (<Dropdown isOpen={isOpen} toggle={() => toggleOpen(!isOpen)}>
    <SimpleTooltip text={'Click to choose sorting'}>
      <DropdownToggle outline color='secondary' className={isOpen ? 'active' : ''}>
        <i className={`fas ${selectedSort.icon}`} />
      </DropdownToggle>
    </SimpleTooltip>
    <DropdownMenu>
      {sorts.map(sort => (<SimpleTooltip text={sort.tooltip} key={`survey-setting-breadcrumb-${sort.icon}`}>
        <DropdownItem onClick={e => handleSortClick(e, sort.code)}>
          <i className={`fa ${sort.icon} text-ThemeAccentExtradark`} />
        </DropdownItem>
      </SimpleTooltip>))}
    </DropdownMenu>
  </Dropdown>);
};
