import { useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { WidgetProps, isDashboardItem, transformVariables } from '../../../utils';
import {
  CalculationType,
  GridDashboardItemCreate,
  GridDashboardTableItem,
  GridDashboardTableItemBase,
  InsightDashboardItemType,
  TableSubType,
  TableWidgetColumn,
} from '../../../../../types/insight-custom-dashboard';
import { TableData } from '../types';
import { MultiRowTableInput } from './MultiRowTableInput';
import {
  MAX_LENGTH_TABLE_TITLE,
  getCalculationValues,
  getTableData,
  getUniqueUtrVariables,
  hasValidVariables,
} from '../utils';
import { SubmitButton } from '../../../../../components/button/SubmitButton';

interface Props {
  toggle: () => void;
  initiativeId: string;
  handleSubmitChart: (updatingItem: GridDashboardItemCreate) => Promise<void>;
  item: GridDashboardTableItem | GridDashboardTableItemBase;
}

export const TableAddingModal = ({ toggle, initiativeId, handleSubmitChart, item }: Props) => {
  const [tableData, setTableData] = useState<TableData>(getTableData(item));
  const [title, setTitle] = useState<string>('title' in item && item.title ? item.title : '');
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    'config' in item && item.config?.hiddenColumns ? item.config.hiddenColumns : []
  );

  const isDisabled = tableData.rowData.length === 0 || !hasValidVariables(tableData.rowData);
  const label = WidgetProps[InsightDashboardItemType.Table].label;
  const action = isDashboardItem(item) ? 'Edit' : 'Add';
  const checked = !hiddenColumns.includes(TableWidgetColumn.Unit);

  const toggleDisplayUnits = () => {
    if (hiddenColumns.includes(TableWidgetColumn.Unit)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== TableWidgetColumn.Unit));
    } else {
      setHiddenColumns([...hiddenColumns, TableWidgetColumn.Unit]);
    }
  };

  const handleSubmit = async () => {
    const variables = transformVariables(getUniqueUtrVariables(tableData.rowData));
    const calculation = {
      type: CalculationType.Formula,
      values: getCalculationValues({ rowData: tableData.rowData, variables }),
    };
    const config = {
      hiddenColumns,
    };
    return handleSubmitChart({ ...item, subType: TableSubType.Table, title, variables, calculation, config });
  };

  return (
    <>
      <Modal isOpen toggle={toggle} backdrop='static' className='modal-md table-adding__modal'>
        <ModalHeader toggle={toggle}>
          {action} {label}
        </ModalHeader>
        <ModalBody>
          <p className='text-medium mb-1'>Table title</p>
          <Input
            type='text'
            className='text-md'
            maxLength={MAX_LENGTH_TABLE_TITLE}
            value={title ?? ''}
            onChange={(e) => setTitle(e.target.value)}
            placeholder='Table title (optional)'
          />
          <FormGroup switch className='d-flex align-items-center mt-4 text-muted gap-2'>
            <Input type='switch' role='switch' checked={checked} onChange={toggleDisplayUnits} />
            <Label className='text-md text-ThemeTextDark' check>
              Display units
            </Label>
          </FormGroup>
          <MultiRowTableInput initiativeId={initiativeId} tableData={tableData} setTableData={setTableData} />
        </ModalBody>
        <ModalFooter className='w-100 d-flex justify-content-end'>
          <Button className='mr-2' color='transparent' onClick={toggle}>
            Cancel
          </Button>
          <SubmitButton disabled={isDisabled} color='primary' onClick={handleSubmit}>
            {`${action} ${label}`}
          </SubmitButton>
        </ModalFooter>
      </Modal>
    </>
  );
};
