import { UncontrolledDashboardSectionCollapsible } from '../dashboard';
import { QuestionInformation } from '../survey/question/QuestionInformation';
import { TrackerInformation } from './components/TrackerInformation';
import UniversalTracker from '../../model/UniversalTracker';
import { QUESTION } from '../../constants/terminology';

interface Props {
  utr: UniversalTracker;
  alternativeCode?: string;
}
export const CollapsibleQuestionInfo = ({ utr, alternativeCode }: Props) => {
  return (
    <UncontrolledDashboardSectionCollapsible className='question-info' title={`${QUESTION.CAPITALIZED_SINGULAR} Information`}>
      <h3>{utr.getName()}</h3>
      <hr />
      <QuestionInformation utr={utr} alternativeCode={alternativeCode ?? ''} />
      <TrackerInformation utr={utr} />
    </UncontrolledDashboardSectionCollapsible>
  );
};
