import { useEffect, useState } from 'react';
import Select, { MultiValue, StylesConfig } from 'react-select';
import { Input } from 'reactstrap';
import { SelectOption } from '../../types/select';
import { Checkbox, CheckboxState } from '../common/Checkbox';
import { getFilterOption } from './ultis';

export type Option = Pick<SelectOption<string>, 'label' | 'searchString' | 'value'> & {
  isDisabled?: boolean;
};

interface Props {
  placeholder?: string;
  options: Option[];
  onChange: (values: Option['value'][]) => void;
  values: Option['value'][];
}

const getToggleAllOption = (values: Option['value'][], options: Option[]) => {
  const label = `${values.length ? 'Deselect' : 'Select'} all`;
  const status = !values.length
    ? CheckboxState.Unchecked
    : values.length === options.filter(({ isDisabled }) => !isDisabled).length
    ? CheckboxState.Checked
    : CheckboxState.Indeterminate;

  return {
    value: 'select-all',
    searchString: label,
    label: (
      <div
        className='d-flex align-items-center border-bottom pb-2'
        style={{ borderBottomColor: 'var(--theme-NeutralsLight)' }}
      >
        <Checkbox status={status} onChange={() => {}} className='mt-0 mr-2 flex-shrink-0' />
        <span className='text-sm' style={{ color: getColor(!!values.length) }}>
          {label}
        </span>
      </div>
    ),
  };
};

export const MultipleSelect = ({
  placeholder = 'Select',
  options: optionsProp,
  onChange: onChangeProp,
  values: valuesProp,
}: Props) => {
  const [values, setValues] = useState<string[]>([]);

  useEffect(() => {
    setValues(valuesProp);
  }, [valuesProp]);

  const getLabel = ({ value, label, isDisabled }: Option) => {
    const checked = values.includes(value);
    return (
      <div className='d-flex align-items-center'>
        {isDisabled ? null : (
          <Input type='checkbox' checked={checked} onChange={() => {}} className='mt-0 mr-2 flex-shrink-0' />
        )}
        <span className='text-sm' style={{ color: getColor(checked) }}>
          {label}
        </span>
      </div>
    );
  };

  const nonDisableOptions = optionsProp.filter(({ isDisabled }) => !isDisabled);

  const toggleAllOption = getToggleAllOption(values, nonDisableOptions);

  const options = [
    toggleAllOption,
    ...optionsProp.map(({ value, searchString, label, isDisabled }) => ({
      value,
      searchString,
      label: getLabel({ value, label, isDisabled }),
      isDisabled,
    })),
  ];

  const toggleValue = (value: Option['value']) => {
    const existed = values.some((v) => v === value);
    const newValues = existed ? values.filter((v) => v !== value) : [...values, value];
    setValues(newValues);
    onChangeProp(newValues);
  };

  const toggleAll = () => {
    const newValues = values.length ? [] : nonDisableOptions.map(({ value }) => value);
    setValues(newValues);
    onChangeProp(newValues);
  };

  const onChange = (selectedOption: MultiValue<Option>) => {
    const value = selectedOption[0]?.value;
    if (!value) {
      return;
    }

    return value === toggleAllOption.value ? toggleAll() : toggleValue(value);
  };

  return (
    <Select
      placeholder={`${placeholder}${values.length ? ` (${values.length})` : ''}`}
      styles={getStyles(!!values.length)}
      isMulti
      closeMenuOnSelect={false}
      filterOption={getFilterOption<Option>()}
      value={[]}
      options={options}
      onChange={onChange}
    />
  );
};

const getColor = (active: boolean) => (active ? 'var(--theme-TextDark)' : 'var(--theme-NeutralsExtradark)');

export const getStyles = (active: boolean): StylesConfig<Option> => {
  const border = 'var(--theme-NeutralsLight)';
  const color = active ? 'var(--theme-TextDark)' : 'var(--theme-TextMedium)';
  const indicatorColor = getColor(active);
  return {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 35,
      height: 35,
      borderColor: border,
      boxShadow: 'none',
      color,
      '&:hover': {
        borderColor: border,
      },
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      minWidth: '300px',
      whiteSpace: 'normal',
      border: 'solid 1px var(--bs-dropdown-border-color)',
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)',
    }),
    placeholder: (provided) => ({
      ...provided,
      color,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0px',
      color: indicatorColor,
      '&:hover': {
        color: indicatorColor,
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingRight: '0px',
    }),
  };
};
