import {
  InsightDashboardItemTextType,
} from '../../../../types/insight-custom-dashboard';
import { TextData } from './types';

export const getTextData = (item: { type: InsightDashboardItemTextType; text?: string }): TextData => {
  return {
    type: item.type,
    text: item.text ? item.text : '',
  };
};
