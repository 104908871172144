import { Modal } from 'reactstrap';
import { GridDashboardItemCreate, GridDashboardTempItem } from '../../types/insight-custom-dashboard';
import { TableAddingModal } from './items/tables/table-adding-modal/TableAddingModal';
import { TextWidgetAdding } from './items/texts/TextWidgetAdding';
import { isTempChartType, isChartType, isSDGTrackerType, isTableType, isTextOrHeadlineType } from './utils';
import { DashboardItemFilters } from '../../api/insights';
import { ChartAddingModal } from './items/charts/chart-adding-modal/ChartAddingModal';
import { GridItemProps } from './GridItem';

interface Props extends Pick<GridItemProps, 'survey'> {
  editingItem: GridDashboardItemCreate | undefined | GridDashboardTempItem;
  updateItem: (updatingItem: GridDashboardItemCreate) => Promise<void>;
  toggleModal: () => void;
  initiativeId: string;
  queryParams: DashboardItemFilters;
}

const ModalContent = ({ editingItem, toggleModal, updateItem, initiativeId, queryParams, survey }: Props) => {
  if (!editingItem) {
    return null;
  }

  if (isTempChartType(editingItem) || isChartType(editingItem) || isSDGTrackerType(editingItem)) {
    return (
      <ChartAddingModal
        initiativeId={initiativeId}
        toggle={toggleModal}
        item={editingItem}
        handleSubmitChart={updateItem}
        queryParams={queryParams}
        survey={survey}
      />
    );
  }

  if (isTextOrHeadlineType(editingItem)) {
    return <TextWidgetAdding toggle={toggleModal} handleSubmitChart={updateItem} item={editingItem} />;
  }

  if (isTableType(editingItem)) {
    return (
      <TableAddingModal
        toggle={toggleModal}
        initiativeId={initiativeId}
        handleSubmitChart={updateItem}
        item={editingItem}
      />
    );
  }

  return null;
};

export const CustomDashboardModalContainer = (props: Props) => {
  const { editingItem } = props;

  return (
    <Modal isOpen={!!editingItem} toggle={props.toggleModal} backdrop='static' className='modal-md'>
      <ModalContent {...props} />
    </Modal>
  );
};
