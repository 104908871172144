import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface DeleteMetricModalProps {
  title: string;
  content: string | JSX.Element;
  isOpen: boolean;
  handleDelete: () => void;
  handleCancel: () => void;
}

export const DeleteModal = ({ title, content, isOpen, handleDelete, handleCancel }: DeleteMetricModalProps) => {
  return (
    <Modal isOpen={isOpen} toggle={handleCancel} backdrop='static' size='md'>
      <ModalHeader toggle={handleCancel}>
        <span className='text-ThemeDangerDark'>Delete {title}</span>
      </ModalHeader>
      <ModalBody>{content}</ModalBody>
      <ModalFooter className='pt-0'>
        <div className='text-right'>
          <Button color='link-secondary' onClick={handleCancel} className='mr-3'>
            Cancel
          </Button>
          <Button color='danger' className='ml-3' onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
