import { Col, Row } from 'reactstrap';
import { useAppSelector } from '../../reducers';
import { getRootOrg, currentInitiative } from '../../selectors/initiative';
import { currentPortfolio } from '../../selectors/portfolio';
import { InitiativeData, RootInitiativeData } from '../../types/initiative';
import Loader from '../loader';
import { ProfileLogo } from '../profile/ProfileLogo';
import { Container } from './Container';
import { companyEditOptions, EditOption } from './EditOption';
import { PortfolioDetails } from './PortfolioDetails';
import './style.scss';

interface InitiativeDetailsProps {
  rootOrg: RootInitiativeData;
  initiative: InitiativeData;
}

const InitiativeDetails = ({ rootOrg, initiative }: InitiativeDetailsProps) => {
  return (
    <Container>
      <Row>
        <Col className='col-md-8 col-12'>
          {companyEditOptions.map((option) => (
            <EditOption key={option.label} {...option} rootOrg={rootOrg} initiative={initiative} />
          ))}
        </Col>
        <Col className='col-md-4 col-12 d-flex justify-content-center'>
          <ProfileLogo defaultPicture={initiative?.profile} initiativeId={initiative._id} />
        </Col>
      </Row>
    </Container>
  );
};

export const Details = () => {
  const rootOrg = useAppSelector(getRootOrg);
  const initiative = useAppSelector(currentInitiative);
  const portfolio = useAppSelector(currentPortfolio);

  if (rootOrg && initiative) {
    return <InitiativeDetails rootOrg={rootOrg} initiative={initiative} />;
  }

  if (portfolio) {
    return <PortfolioDetails portfolio={portfolio} />;
  }

  return <Loader />;
};
