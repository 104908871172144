import { FormGroup, Input, Label } from 'reactstrap';
import SimpleTooltip from '../simple-tooltip';
import { Tab } from './ContentTabs';

interface Props {
  tabs: Tab[];
  activeTab: Tab['navId'];
  handleChangeTab: (tab: Tab['navId']) => void;
}

export const ViewRadioButtons = ({ tabs, activeTab, handleChangeTab }: Props) => {
  return (
    <div className='d-flex'>
      Show data as:
      {tabs.map((button) => (
        <FormGroup className='ml-2' key={`btn-${button.navId}`} check>
          <Input
            id={button.navId}
            type='radio'
            disabled={button.disabled ?? false}
            checked={button.navId === activeTab}
            onChange={() => handleChangeTab(button.navId)}
          />
          <Label check for={button.navId}>
            <SimpleTooltip text={button.disabled ? `This metric does not support ${button.name}` : undefined}>
              {button.name}
            </SimpleTooltip>
          </Label>
        </FormGroup>
      ))}
    </div>
  );
};
