import { FilterOption } from '../../../../../../components/settings-sidebar';
import { getSDGOptions } from '../../../../../../components/survey/survey-question-list-toolbar/partials/FilterQuestions';
import { SelectOption } from '../../../../../../types/select';
import { useState } from 'react';
import { SDGTargetSuggestions } from './SDGTargetSuggestions';
import { QuestionData } from '../../../../utils';
import { getGoalCodeFromSDG } from './utils';
import { emptyUtrData } from '../utils';

interface Props {
  questionData: QuestionData;
  changeQuestionData: (questionData: QuestionData) => void;
}

const sdgOptions = getSDGOptions();
const sdgGoals = sdgOptions.filter((option) => option.value !== null) as SelectOption<string>[];

export const SDGTargetDropdown = ({ questionData, changeQuestionData }: Props) => {
  const [goalCode, setGoalCode] = useState<string | undefined>(getGoalCodeFromSDG(questionData.code));

  const handleChangeSDGTarget = async (goalCode: string | undefined) => {
    setGoalCode(goalCode);
    changeQuestionData(emptyUtrData);
  };

  return (
    <>
      <p className='text-medium'>Select SDG</p>
      <FilterOption
        isClearable={false}
        placeholder='Select SDG'
        options={sdgGoals}
        value={goalCode ? sdgGoals.find((o) => o.value === goalCode) : null}
        onChange={(option) => handleChangeSDGTarget(option?.value)}
      />
      {goalCode ? (
        <>
          <p className='my-3 text-medium'>Select SDG Target</p>
          <SDGTargetSuggestions
            key={goalCode}
            goalCode={goalCode}
            sdgCode={questionData.code}
            changeQuestionData={changeQuestionData}
          />
        </>
      ) : null}
    </>
  );
};
