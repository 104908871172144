import { UniversalTrackerPlain, UtrValueType, ValueValidation } from '@g17eco/types/universalTracker';
import { Form } from '../EditMetricModal';
import { FieldProps, FormGenerator } from '../../form/FormGenerator';
import { InputType } from 'reactstrap/types/lib/Input';
import { useMemo } from 'react';
import { PercentageSettingsFields } from './PercentageSettingsFields';

interface Props {
  formData: Form;
  isEditMode: boolean;
  onChange: (e: React.ChangeEvent<{ name: string; value: any }>) => void;
  initialData: UniversalTrackerPlain | undefined;
  setValueValidation: (value: ValueValidation) => void;
}

const getDefaultSettingsFields = (isEditMode = false): FieldProps[] => [
  {
    code: 'unitType',
    type: 'select' as InputType,
    disabled: isEditMode,
    label: `Value Unit${
      isEditMode
        ? ' - cannot be edited'
        : ' (Note: this is for outputs, the input unit is determined at the survey level)'
    }`,
    options: [
      { value: '', label: 'None' },
      { value: 'time', label: 'Time' },
      { value: 'area', label: 'Area' },
      { value: 'length', label: 'Length' },
      { value: 'mass', label: 'Mass' },
      { value: 'volume', label: 'Volume' },
      { value: 'energy', label: 'Energy' },
      { value: 'co2Emissions', label: 'CO2 Emissions' },
      { value: 'currency', label: 'Currency' },
    ],
    classes: {
      label: 'fw-bold',
    },
  },
];

export const ValueTypeSettingsContainer = (props: Props) => {
  const { formData, onChange, isEditMode, initialData, setValueValidation } = props;
  const defaultFields = useMemo(() => getDefaultSettingsFields(isEditMode), [isEditMode]);

  switch (formData.valueType) {
    case UtrValueType.Percentage:
      return (
        <PercentageSettingsFields
          initialData={initialData}
          formData={formData}
          setValueValidation={setValueValidation}
        />
      );
    default:
      return <FormGenerator fields={defaultFields} form={formData} updateForm={onChange} />;
  }
};
