import { createApi } from '@reduxjs/toolkit/query/react'
import { ApiResponse } from '../types/api';
import { axiosBaseQuery } from './axiosQuery';
import { ConnectionUtrv } from '../types/universalTrackerValue';
import { Connection } from '../types/universalTracker';

interface ConnectionsData {
  connections: Connection[];
  utrvs: ConnectionUtrv[];
}

// Define a service using a base URL and expected endpoints
export const utrvApi = createApi({
  reducerPath: 'relatedApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['utrv-related'],
  endpoints: (builder) => ({
    getConnections: builder.query<ConnectionsData, string>({
      transformResponse: (response: ApiResponse<ConnectionsData>) => response.data,
      query: (utrvId) => ({
        url: `/universal-tracker-values/${utrvId}/connections`,
        method: 'get'
      }),
    }),
  }),
})

export const { useGetConnectionsQuery } = utrvApi
