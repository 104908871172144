import { BasicAlert } from '../../../../../components/alert/BasicAlert';
import { UniversalTrackerBlueprintMin } from '../../../../../types/universalTracker';
import { UtrValueTypes, canAddTarget } from '../../../../../utils/universalTracker';
import { FilterMessage } from './utils';

interface QuestionWarningProps {
  selectedQuestion?: UniversalTrackerBlueprintMin;
  isMatchedUnit: boolean;
}

const QuestionMessage = ({ message }: { message: FilterMessage }) => (
  <BasicAlert className='mt-3' type={'danger'}>
    {message}
  </BasicAlert>
);

export const QuestionWarning = ({ selectedQuestion, isMatchedUnit }: QuestionWarningProps) => {
  if (!selectedQuestion) {
    return null;
  }

  if (canAddTarget(selectedQuestion)) {
    if (!isMatchedUnit) {
      return <QuestionMessage message={FilterMessage.MisMatchedUnit} />;
    }
    return null;
  }
  const { valueType } = selectedQuestion;

  switch (true) {
    case [UtrValueTypes.table, UtrValueTypes.numericValueList].includes(valueType):
      return <QuestionMessage message={FilterMessage.Table} />;
    case [UtrValueTypes.text, UtrValueTypes.textValueList].includes(valueType):
      return <QuestionMessage message={FilterMessage.Text} />;
    case [UtrValueTypes.date].includes(valueType):
      return <QuestionMessage message={FilterMessage.Date} />;
    default:
      return <QuestionMessage message={FilterMessage.Generic} />;
  }
};
