import { QUESTION } from '../../constants/terminology';
import { UniversalTrackerPlain } from '../../types/universalTracker';
import Table, { TableColumn } from '../table/Table';
import { CTAButtons } from './partials/CTAButtons';
import { NameCell } from './partials/NameCell';

interface CustomMetricTableProps {
  questions: UniversalTrackerPlain[];
  hasUniversalTrackerValues: boolean;
  handleOpenEditUtr: (utrId: string) => void;
  handleOpenDeleteUtr: (utrId: string) => void;
  total: number;
}

export const CustomMetricTable = ({
  questions,
  hasUniversalTrackerValues,
  handleOpenEditUtr,
  handleOpenDeleteUtr,
  total,
}: CustomMetricTableProps) => {

  const columns: TableColumn[] = [
    {
      accessor: 'name',
      Header: () => `${QUESTION.CAPITALIZED_SINGULAR} name`,
      minWidth: 250,
      cellProps: {
        className: 'nameCol flex-grow-1 order-1',
      },
      Cell: ({ row }) => <NameCell isInGroup={true} row={row} />,
    },
    {
      accessor: '_id',
      disableSortBy: true,
      Header: () => 'Added to survey',
      cellProps: {
        className: 'typeCol text-center order-2',
      },
      Cell: () => (
        <h4 className='m-0'>
          {hasUniversalTrackerValues ? (
            <i className='fal fa-check text-ThemeSuccessMedium' />
          ) : (
            <i className='fal fa-dash text-ThemeNeutralsExtradark' />
          )}
        </h4>
      ),
    },
    {
      id: 'buttons',
      accessor: '_id',
      Header: () => '',
      disableSortBy: true,
      cellProps: {
        className: 'buttonsCol flex-shrink-1 order-3',
      },
      Cell: ({ row }: { row: { original: UniversalTrackerPlain } }) => {
        return (
          <CTAButtons
            handleOpenEditUtr={handleOpenEditUtr}
            universalTracker={row.original}
            handleOpenDeleteUtr={handleOpenDeleteUtr}
            isDisabled={hasUniversalTrackerValues}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className='metric-table-container'>
        <Table responsive={true} columns={columns} data={questions} pageSize={10} total={total} unit='questions' />
      </div>
      {!questions.length ? (
        <div className='metric-group-form--text-helper'>No custom {QUESTION.PLURAL} in this company</div>
      ) : null}
    </>
  );
};
