import { standards } from '@g17eco/core';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { VerifiedBadge } from '../../components/assurer/VerifiedBadge';
import Dashboard, { DashboardSection } from '../../components/dashboard';
import { NavigationButtons } from '../../components/question/NavigationButtons';
import { NotApplicableBadge } from '../../components/question/NotApplicableBadge';
import { QuestionInput } from '../../components/question/QuestionInput';
import { DisplayCheckBox } from '../../components/survey/question/questionInterfaces';
import { ROUTES } from '../../constants/routes';
import { UtrvStatus } from '../../constants/status';
import { useQuestionIds } from '../../hooks/useQuestionIds';
import { getQuestionId } from '../../selectors/survey';
import { ScopeQuestionGroup } from '../../types/survey';
import { nl2br } from '../../utils';
import { isCheckboxType } from '../../utils/universalTracker';
import { getNaType, getValueData } from '../../utils/universalTrackerValue';
import { generateUrl } from '../util';
import { QUESTION } from '../../constants/terminology';

interface PortfolioTrackerQuestionViewProps {
  portfolioId: string;
  companyId: string;
  surveyGroups: ScopeQuestionGroup[];
  questionId: string;
  index?: string;
}

export const PortfolioTrackerQuestionView = (props: PortfolioTrackerQuestionViewProps) => {
  const { surveyGroups, questionId, index, portfolioId, companyId } = props;
  const history = useHistory();
  const { questionListIds, utr, utrv, altCode } = useQuestionIds(surveyGroups, index, questionId);

  const goToQuestion = ({ id, index }: { id: any, index?: number }) => {
    const url = generateUrl(ROUTES.PORTFOLIO_TRACKER_SURVEY_OVERVIEW, {
      portfolioId,
      companyId,
      questionId: id,
      index: String(index ?? ''),
    })
    history.push(url);
  };

  const goNext = () => goToQuestion(getQuestionId(questionListIds, questionId, index ?? '', true));
  const goPrevious = () => goToQuestion(getQuestionId(questionListIds, questionId, index ?? '', false));

  const onBackClick = () => history.push(generateUrl(ROUTES.PORTFOLIO_TRACKER_SURVEY_OVERVIEW, { portfolioId, companyId }));
  const backButton = (
    <Button color='link' size='sm' onClick={onBackClick}>
      <i className='fa fa-arrow-circle-left mr-2' />
      Back to {QUESTION.CAPITALIZED_SINGULAR} List
    </Button>
  );
  const prevNextButton = <NavigationButtons goNext={goNext} goPrevious={goPrevious} isDisabled={questionListIds.length <= 1} />;

  if (!utr || !utrv) {
    return (
      <Dashboard className='assuranceContainer mt-2'>
        <DashboardSection title={backButton} buttons={[prevNextButton]}>
          <h5>{QUESTION.CAPITALIZED_SINGULAR} not available</h5>
        </DashboardSection>
      </Dashboard>
    )
  };

  const standardName = standards[altCode]?.name || '';
  const standardCode = utr.getTypeCode(altCode);

  const displayCheckbox: DisplayCheckBox = {};
  const valueType = utr.getValueType();
  const originalValueData = getValueData(utrv, valueType);
  if (isCheckboxType(valueType) && originalValueData.data) {
    Object.keys(originalValueData.data).forEach((k) => displayCheckbox[k] = true);
  }

  const naType = getNaType(utrv);
  const isVerified = utrv.status === UtrvStatus.Verified;

  const instructionsText = `View ${standards[altCode]?.shortName || ''} Instructions`;
  const instructionsLink = utr.getInstructionsLink(altCode);

  return (
    <Dashboard className='assurance-question-view mt-0'>
      <DashboardSection title={backButton} padding={3} buttons={[prevNextButton]}>
        <div className='mb-3 d-relative text-ThemeTextPlaceholder'>
          <div className='d-flex'>
            <div>{`${standardName} - ${standardCode}`}</div>
            <div className='d-flex align-items-center ml-auto text-right'>
              <VerifiedBadge isVerified={isVerified} />
              <NotApplicableBadge notReported={naType} showExplanation={false} />
              {instructionsLink && (
                <div>
                  <Button color='link' size='sm' onClick={() => window.open(instructionsLink, '_blank')}>
                    <u>{instructionsText}</u>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='mt-3 pt-2 strong text-xl'>
          {utr.getValueLabel(altCode)}
        </div>
        <div className='mt-3'>{nl2br(utr.getInstructions(altCode))}</div>
        <div className='mt-4'>
          <QuestionInput utrv={utrv} utr={utr} isReadOnly={true} displayCheckbox={displayCheckbox} />
        </div>
      </DashboardSection>
    </Dashboard>
  );
}
