import { UniversalTrackerBase } from '../../model/UniversalTracker';
import { canAddTarget } from '../../utils/universalTracker';

interface Props {
  utrPlain: UniversalTrackerBase;
}

export const AdditionalInfo = ({ utrPlain }: Props) => {
  const trendUp = utrPlain.targetDirection !== 'decrease';
  const canDrawChart = canAddTarget(utrPlain);

  return (
    <div className='text-center mt-3 text-ThemeTextPlaceholder'>
      {canDrawChart ? (
        <span>
          <i className={`fa ${trendUp ? 'fa-arrow-up' : 'fa-arrow-down'} text-ThemeSuccessMedium mr-2`} />
          Trend expectation
        </span>
      ) : null}
      <i className='ml-3 fa fa-info-circle mr-2' />
      Only <i className='fa fa-user-check text-ThemeSuccessMedium' /> <strong>verified</strong> values are displayed in
      data history.
    </div>
  );
};
