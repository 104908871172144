const GOAL_CODE_REGEX = /^(?:sdg\/)(\d+)/;

export const getGoalCodeFromSDG = (code: string | undefined) => {
  if (!code) {
    return undefined;
  }
  const match = code.match(GOAL_CODE_REGEX);

  return match ? match[1] : undefined;
};

export const getExtendedGoalCode = (sdgCode: string) => sdgCode.split('/').pop();
