import { useParams } from 'react-router-dom';
import { useGetDelegatedUserQuery } from '../../../../api/admin-dashboard';
import { BasicAlert } from '../../../../components/alert/BasicAlert';
import Dashboard, { DashboardRow } from '../../../../components/dashboard';
import Loader from '../../../../components/loader';
import { ROUTES } from '../../../../constants/routes';
import { getFullName } from '../../../../utils/user';
import { generateUrl } from '../../../util';
import { AdminBreadcrumbItemType } from '../../AdminBreadcrumbItem';
import { AdminBreadcrumbs } from '../../AdminBreadcrumbs';
import { SubsidiaryDelegationTable } from './SubsidiaryDelegationTable';

interface RouteParams {
  initiativeId: string;
  userId: string;
}

export const SubsidiaryUserDelegation = () => {
  const { initiativeId, userId } = useParams<RouteParams>();
  const breadcrumbs: AdminBreadcrumbItemType[] = [
    { label: 'Admin Dashboard', url: generateUrl(ROUTES.ADMIN_DASHBOARD, { initiativeId }) },
    { label: 'Delegation to user' },
  ];

  const { data, isError, isLoading, isFetching, error } = useGetDelegatedUserQuery({ initiativeId, userId });

  if (isFetching || isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Dashboard>
        <BasicAlert type={'danger'}>{error.message}</BasicAlert>
      </Dashboard>
    );
  }

  return (
    <div className='admin-dashboard'>
      <Dashboard>
        <DashboardRow>
          <AdminBreadcrumbs breadcrumbs={breadcrumbs} initiativeId={initiativeId} />
        </DashboardRow>
        <SubsidiaryDelegationTable initiativeId={initiativeId} userId={userId} fullName={data ? getFullName(data) : ''} />
      </Dashboard>
    </div>
  );
};
