import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { InsightDashboardItemType, InsightDashboardTempItemType } from '../../../types/insight-custom-dashboard';
import { AddWidgetBtn } from './AddWidgetBtn';
import { DuplicateDropdownItem } from './menu-items/DuplicateDropdownItem';
import { FloatingToolbar } from '../../floating-toolbar';
import { EditingDashboardItem } from '../../../routes/custom-dashboard/CustomDashboardEditing';
import { useToggle } from '../../../hooks/useToggle';
import { DeleteConfirmation } from '../../confirm-modal/DeleteConfirmation';

interface Props {
  openAddingModal: (item: EditingDashboardItem) => void;
  saveDashboardChanges: () => void;
  handleDelete: () => void;
  handleCancel: () => void;
}

export const CustomDashboardToolbar = (props: Props) => {
  const { saveDashboardChanges, openAddingModal, handleDelete, handleCancel } = props;

  const [isConfirmingDelete, toggleDelete] = useToggle(false);

  const addItemButtons = (
    [InsightDashboardTempItemType.TempChart, InsightDashboardItemType.Table, InsightDashboardItemType.Headline] as const
  ).map((type) => (
    <AddWidgetBtn key={type} type={type} handleClick={() => openAddingModal({ type } as EditingDashboardItem)} />
  ));

  const dashboardMenuDropdown = (
    <UncontrolledDropdown direction='up'>
      <DropdownToggle color='secondary'>
        <i className={'fal fa-bars mr-2'}></i>
        <span>Dashboard menu</span>
      </DropdownToggle>
      <DropdownMenu>
        <DuplicateDropdownItem />
        <DropdownItem toggle={false} color='secondary' onClick={toggleDelete}>
          Delete dashboard
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const saveButton = (
    <Button color='primary' onClick={() => saveDashboardChanges()}>
      Save
    </Button>
  );

  return (
    <>
      <FloatingToolbar
        handleClose={handleCancel}
        isOpen
        items={[...addItemButtons, dashboardMenuDropdown, saveButton]}
      />
      {isConfirmingDelete ? (
        <DeleteConfirmation title='Delete dashboard' toggle={toggleDelete} handleConfirm={handleDelete} />
      ) : null}
    </>
  );
};
