import Loader from '../../components/loader';
import { useSearchParams } from '../../hooks/useSearchParams';
import { useAppSelector } from '../../reducers';
import { getCurrentUser } from '../../selectors/user';
import { Tree } from '../../types/tree';
import { InitiativeStructureFolders } from './InitiativeStructureFolders';
import OldInitiativeStructureFolders from './initiative-structure-folders';
import { InitiativeStructureArchived } from './InitiativeStructureArchived';
import { getRootOrg } from '../../selectors/initiative';
import './styles-folders.scss';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useGetArchivedSubsidiariesQuery } from '../../api/organisation-map';

export interface InitiativeStructureContainerProps {
  onClick?: (portfolioId?: string) => void;
  customTree: Tree;
  isPortfolioTracker: boolean;
}

export const InitiativeStructureContainer = (props: InitiativeStructureContainerProps) => {
  const currentUser = useAppSelector(getCurrentUser);
  const rootOrg = useAppSelector(getRootOrg);
  const searchParams = useSearchParams();
  const isLegacyMap = searchParams.get('legacyMap') === '1';
  const { path } = useRouteMatch();

  const { data: archivedInitiatives = [], isLoading } = useGetArchivedSubsidiariesQuery(
    {
      initiativeId: rootOrg?._id || '',
    },
    { skip: !rootOrg }
  );

  if (!currentUser || isLoading || !rootOrg) {
    return <Loader />;
  }

  if (isLegacyMap || !currentUser.isStaff) {
    return <OldInitiativeStructureFolders {...props} />;
  }

  return (
    <Switch>
      <Route path={`${path}/archived`}>
        <InitiativeStructureArchived
          currentUser={currentUser}
          rootOrg={rootOrg}
          archivedInitiatives={archivedInitiatives}
        />
      </Route>
      <Route>
        <InitiativeStructureFolders currentUser={currentUser} archivedInitiatives={archivedInitiatives} {...props} />
      </Route>
    </Switch>
  );
};
