import { QUESTION } from '../../constants/terminology';
import { BasicAlert } from '../alert/BasicAlert';

export const OverrideConfigAlert = ({
  showOverrideConfigAlert = false,
  questionCount,
}: {
  showOverrideConfigAlert: boolean;
  questionCount: number;
}) => {
  return showOverrideConfigAlert ? (
    <BasicAlert type='warning' className='text-ThemeWarningExtradark my-3'>
      <span>{`Decimal previously set on ${questionCount} ${QUESTION.SINGULAR}(s). Updating will override these existing configurations.`}</span>
    </BasicAlert>
  ) : null;
};
