import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import config from '../../../config';
import { useState } from 'react';
import { AppFlavour, getDefaultConfig } from '../../../config/app-config';

interface RegionOption {
  url: string;
  name: string;
  value: AppFlavour;
}

const regionSwitcherOptions: RegionOption[] = [
  {
    url: config.platform.singapore,
    name: 'ASEAN',
    value: AppFlavour.SINGAPORE,
  },
  {
    url: config.platform.international,
    name: 'EMEA',
    value: AppFlavour.DEFAULT,
  },
];

export const RegionSwitcher = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleChangeRegion = (option: RegionOption) => window.location.href = option.url;

  return (
    <div className='d-flex flex-column'>
      <Dropdown className='d-none d-md-inline' isOpen={dropdownOpen} toggle={() => setDropdownOpen((open) => !open)}>
        <DropdownToggle
          caret
          color='transparent'
          className='d-none d-md-inline text-truncate'
          style={{ maxWidth: '300px' }}
        >
          {regionSwitcherOptions.find((option) => option.value === getDefaultConfig().flavour)?.name ?? 'Not Available'}
        </DropdownToggle>
        <DropdownMenu end>
          {regionSwitcherOptions.map((option) => (
            <DropdownItem key={`co_${option.value}`} onClick={() => handleChangeRegion(option)}>
              {option.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
