import React from 'react';
import { Progress } from 'reactstrap';
import { TemplateHistory } from '../survey-templates/types';
import { JobStatus } from '../../types/background-jobs';

interface BulkSurveysProgressBarProps {
  templateHistory: TemplateHistory;
}

export const BulkSurveysProgressBar = ({ templateHistory }: BulkSurveysProgressBarProps) => {

  const { snapshot, results } = templateHistory;
  const total = snapshot.reportingLevels?.length ?? results.length;

  const { completed, errors } = results.reduce((acc, result) => {
    switch (result.status) {
      case JobStatus.Completed:
        acc.completed++;
        return acc;
      case JobStatus.Error:
        acc.errors++
        return acc;
      default:
        return acc;
    }
  }, { completed: 0, errors: 0 })

  const toCreate = total - completed - errors;

  return (
    <>
      <div className='text-ThemeHeadingMedium'>Surveys created successfully</div>
      <Progress className={'mt-2 mb-1'} multi style={{ height: '1.2rem' }}>
        <Progress bar value={completed} max={total}>{completed}</Progress>
        <Progress className={'background-ThemeDangerMedium'} max={total} bar value={errors}>{errors}</Progress>
        <Progress className={'background-ThemeNeutralsLight'} max={total} bar value={toCreate} />
      </Progress>
      <div className='text-xs text-medium text-end'>{completed}/{total}</div>
    </>
  );
};
