import { ChangeEvent } from 'react';
import Select, { StylesConfig } from 'react-select';
import { Input } from 'reactstrap';
import { clearDropdownOption } from '../industry-sector-selector/ClearDropdownOption';
import { useIndustrySector } from '../industry-sector-selector/useIndustrySector';
import { getStyles as getFilterStyles } from '../select-packs';
import { DataScopeAccess } from '../../types/dataShare';

export type SelectOption = { value: string | null; label: string | JSX.Element; level?: string };
export const getStyles = (): StylesConfig<any> => {
  const ravenGrey = '#6C757D';
  return {
    ...getFilterStyles(),
    input: (provided) => ({
      ...provided,
      color: ravenGrey,
      width: 160,
    }),
  };
};

export interface PackAssigmentFilters {
  sector?: string;
  industry?: string | null;
  dataShareStatus?: string;
}

interface Props {
  searchText: string;
  filters: PackAssigmentFilters;
  changeSearchText: (searchText: string) => void;
  changeFilters: (filters: PackAssigmentFilters) => void;
}

const dataShareStatusOptions = [
  clearDropdownOption('No status filter'),
  {
    label: (
      <div className='d-flex align-items-center'>
        <i className='text-ThemeIconDark mr-2 fa-solid fa-circle fa-lg' />
        Full access
      </div>
    ),
    value: DataScopeAccess.Full,
  },
  {
    label: (
      <div className='d-flex align-items-center'>
        <i className='text-ThemeIconDark mr-2 fa-solid fa-circle-half-stroke fa-lg' />
        Partial access
      </div>
    ),
    value: DataScopeAccess.Partial,
  },
  {
    label: (
      <div className='d-flex align-items-center'>
        <i className='text-ThemeIconDark mr-2 fa-regular fa-circle fa-lg' />
        No access
      </div>
    ),
    value: DataScopeAccess.None,
  },
];

export const PackAssignmentFilters = (props: Props) => {
  const { searchText, filters, changeSearchText, changeFilters } = props;
  const { sectorOptions, industryOptions } = useIndustrySector(filters.sector);

  const handleChangeSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    changeSearchText(value);
  };

  const handleChangeFilters = (name: string, option: SelectOption) => {
    const newFilters = { ...filters, [name]: option.value };
    changeFilters(newFilters);
  };

  const handleChangeSectorIndustryFilters = (name: string, option: SelectOption) => {
    let newFilters = { ...filters };
    if (name === 'sector') {
      // reset industry if sector change
      newFilters = { ...newFilters, industry: null };
    }
    newFilters = { ...newFilters, [name]: option.value ? option.label : null };

    changeFilters(newFilters);
  };

  return (
    <div className='pack-assignment-filters-container mt-3'>
      <div className='d-flex justify-content-between align-items-center gap-3' style={{ height: '36px' }}>
        <Input placeholder={'Search for a company...'} onChange={handleChangeSearchText} value={searchText} />
        <Select
          placeholder='Sector'
          styles={getStyles()}
          options={sectorOptions}
          value={sectorOptions.find((item) => item.label === filters.sector) ?? null}
          onChange={(option) => handleChangeSectorIndustryFilters('sector', option)}
        />
        <Select
          placeholder='Industry'
          styles={getStyles()}
          options={industryOptions}
          value={industryOptions.find((item) => item.label === filters.industry) ?? null}
          onChange={(option) => handleChangeSectorIndustryFilters('industry', option)}
          isDisabled={!filters.sector}
        />
        <Select
          placeholder='Data share status'
          styles={getStyles()}
          options={dataShareStatusOptions}
          value={
            dataShareStatusOptions.find((item) => item.value === filters.dataShareStatus && item.value !== null) ?? null
          }
          onChange={(option) => handleChangeFilters('dataShareStatus', option)}
        />
      </div>
    </div>
  );
};
