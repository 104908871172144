import { statusColumns } from '../../constants/assurance';
import { UtrvStatus } from '../../constants/status';
import { AssuranceStatus, UniversalTrackerValueAssurance } from '../../types/assurance';
import SimpleTooltip from '../simple-tooltip';

export const QuestionStatus = ({ assuranceUtrv }: { assuranceUtrv?: UniversalTrackerValueAssurance }) => {
  if (!assuranceUtrv) return <></>;

  switch (assuranceUtrv.status) {
    case AssuranceStatus.Created:
    case AssuranceStatus.Pending:
    case AssuranceStatus.PendingAfterPortfolioComplete:
      if (assuranceUtrv.utrv?.status === UtrvStatus.Verified) {
        return (
          <SimpleTooltip text={statusColumns.verified.text}>
            <i className={`${statusColumns.verified.name} fa-xl`} />
          </SimpleTooltip>
        );
      }
      return (
        <SimpleTooltip text={statusColumns.notReady.text}>
          <i className={`${statusColumns.notReady.name} fa-xl`} />
        </SimpleTooltip>
      );
    case AssuranceStatus.Completed:
    case AssuranceStatus.Rejected:
      return (
        <SimpleTooltip text={statusColumns[assuranceUtrv.status].text}>
          <i className={`${statusColumns[assuranceUtrv.status].name} fa-xl`} />
        </SimpleTooltip>
      );
    default:
      return <></>;
  }
};
