import { SurveyType } from '@g17eco/types/survey';

export type SurveyTypeOptions = 'all' | SurveyType.Default | SurveyType.Aggregation;

export type SurveyTypeOption<T> = { value: T; label: string };

export const getSurveyTypeOptions = <T extends SurveyTypeOptions>(hasAllOption: boolean): SurveyTypeOption<T>[] => {
  const options: SurveyTypeOption<T>[] = [
    { value: SurveyType.Default as T, label: 'Surveys' },
    { value: SurveyType.Aggregation as T, label: 'Combined surveys' },
  ];

  return (hasAllOption ? [{ value: 'all', label: 'All survey types' }, ...options] : options) as SurveyTypeOption<T>[];
};
