import { Col, Row } from 'reactstrap';
import { AdminBreadcrumbs } from '../../routes/admin-dashboard/AdminBreadcrumbs';
import { Portfolio } from '../../types/portfolio';
import Dashboard, { DashboardRow, DashboardSection } from '../dashboard';
import { ProfileLogo } from '../profile/ProfileLogo';
import { PortfolioRow } from './PortfolioRow';

interface PortfolioDefailsProps {
  portfolio: Portfolio;
}

export const PortfolioDetails = ({ portfolio }: PortfolioDefailsProps) => {
  return (
    <Dashboard className='company-settings__wrapper'>
      <DashboardRow>
        <AdminBreadcrumbs
          isPortfolioTracker
          breadcrumbs={[{ label: 'Company Settings' }]}
          initiativeId={portfolio._id}
        />
      </DashboardRow>
      <DashboardRow>
        <h3 className='pl-2'>Company settings</h3>
      </DashboardRow>
      <DashboardSection>
        <div className='mt-4'>
          <Row>
            <Col className='col-md-8 col-12'>
              <PortfolioRow portfolio={portfolio} />
            </Col>
            <Col className='col-md-4 col-12 d-flex justify-content-center'>
              <ProfileLogo defaultPicture={portfolio?.profile} initiativeId={portfolio._id} />
            </Col>
          </Row>
        </div>
      </DashboardSection>
    </Dashboard>
  );
};
