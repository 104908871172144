import { useContext, useMemo, useState } from 'react';
import { ModalHeader } from 'reactstrap';
import { InsightDashboardItemType } from '../../../../../types/insight-custom-dashboard';
import { ChartData, isDashboardItem } from '../../../utils';
import { PreviewModal } from './PreviewModal';
import { useToggle } from '../../../../../hooks/useToggle';
import { checkIsCustomTitle, getChartData, isPreviewChartData } from './utils';
import { SelectChartType } from './SelectChartType';
import { SelectSDG } from './SelectSDG';
import { SelectUtr } from './SelectUtr';
import { ChartAddingModalProps, HistoricalUtrsState, ModalChartItem } from './types';
import { CustomDashboardContext } from '../../../context/CustomDashboardWrapper';
import { HistoricalUtrs } from '../../../../../api/insights';
import { SelectMultipleMetrics } from './multiple-utrs/SelectMetrics';
import { useGetInitiativeBlueprintQuestionsQuery } from '@api/admin-dashboard';
import { UniversalTrackerBlueprintMin } from '@g17eco/types/universalTracker';
import Loader from '@components/loader';
import { QueryError } from '@components/query/QueryError';

enum Steps {
  SelectChartType,
  SelectMultiple,
  SelectUtr,
  SelectSDG,
}

const initialState = {
  historicalUtrs: [],
  isLoading: false,
  errorMessage: '',
};

const isMultipleUtrsItem = (item: ModalChartItem) => {
  return Boolean('variables' in item && item.variables && Object.values(item.variables).length > 1);
};

export const ChartAddingModal = ({
  initiativeId,
  toggle,
  item,
  handleSubmitChart,
  queryParams,
  survey,
}: ChartAddingModalProps) => {
  const [chartData, setChartData] = useState(() => getChartData(item));
  const [step, setStep] = useState<Steps>(Steps.SelectChartType);
  const [isPreviewing, togglePreviewModal] = useToggle(false);
  const [isMultiple, setIsMultiple] = useState<boolean>(isMultipleUtrsItem(item));

  const { getHistoricalUtrsByCodes, userIsStaff } = useContext(CustomDashboardContext);
  const [historicalUtrsState, setHistoricalUtrsState] = useState<HistoricalUtrsState>(initialState);

  const {
    data: blueprintQuestions,
    isFetching,
    isError,
    error,
  } = useGetInitiativeBlueprintQuestionsQuery({ initiativeId });
  const questionsMap = useMemo(() => {
    if (!blueprintQuestions?.length) {
      return new Map<string, UniversalTrackerBlueprintMin>();
    }

    return new Map(
      blueprintQuestions.map((question) => {
        return [question.code, question];
      })
    );
  }, [blueprintQuestions]);

  const changeChartData = (newChartData: Partial<ChartData>) => {
    setChartData((chartData) => ({ ...chartData, ...newChartData }));
  };

  const onTogglePreviewModal = () => {
    if (!isPreviewing && isPreviewChartData(chartData)) {
      const utrCodes = chartData.metrics.map(({ code }) => code);
      setHistoricalUtrsState((prev) => ({ ...prev, isLoading: true }));
      getHistoricalUtrsByCodes({ initiativeId, utrCodes, queryParams })
        .then((result: HistoricalUtrs[]) => {
          setHistoricalUtrsState((prev) => ({ ...prev, isLoading: false, historicalUtrs: result }));
        })
        .catch((e) => {
          setHistoricalUtrsState((prev) => ({ ...prev, isLoading: false, errorMessage: e.message }));
        });
    } else {
      setHistoricalUtrsState(initialState);
    }

    togglePreviewModal();
  };

  const handleClickSelectDataPoint = () => {
    // Clear metrics if chart type is changed before advance to next step.
    if (chartData.type !== item.type) {
      setChartData((chartData) => ({ ...chartData, metrics: [] }));
    }

    setStep((step) => {
      switch (true) {
        case isMultiple:
          return Steps.SelectMultiple;
        case chartData.type === InsightDashboardItemType.Chart:
          return Steps.SelectUtr;
        case chartData.type === InsightDashboardItemType.SDGTracker:
          return Steps.SelectSDG;
        default:
          return step;
      }
    });
  };

  const backToEntryPoint = () => {
    setStep(Steps.SelectChartType);
  };

  const action = isDashboardItem(item) ? 'Edit' : 'Add';

  const isCustomTitle = checkIsCustomTitle({ item, chartData, questionsMap });

  const renderStep = () => {
    switch (step) {
      case Steps.SelectChartType:
        return (
          <SelectChartType
            {...{
              chartData,
              changeChartData,
              toggle,
              handleClickSelectDataPoint,
              isMultiple,
              setIsMultiple,
              userIsStaff,
            }}
          />
        );
      case Steps.SelectMultiple:
        return (
          <SelectMultipleMetrics
            {...{
              initiativeId,
              item,
              chartData,
              changeChartData,
              backToEntryPoint,
              handleSubmitChart,
              togglePreviewModal: onTogglePreviewModal,
              isCustomTitle,
              questionsMap,
            }}
          />
        );
      case Steps.SelectUtr:
        return (
          <SelectUtr
            {...{
              initiativeId,
              item,
              chartData,
              changeChartData,
              backToEntryPoint,
              handleSubmitChart,
              togglePreviewModal: onTogglePreviewModal,
              questionsMap,
              isCustomTitle,
            }}
          />
        );
      case Steps.SelectSDG:
        return (
          <SelectSDG
            {...{
              item,
              chartData,
              changeChartData,
              backToEntryPoint,
              handleSubmitChart,
              togglePreviewModal: onTogglePreviewModal,
              isCustomTitle,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ModalHeader toggle={toggle}>{action} chart</ModalHeader>
      {isError ? <QueryError error={error} type={'danger'} /> : null}
      {isFetching ? <Loader /> : null}
      {!isError && !isFetching ? renderStep() : null}
      {isPreviewChartData(chartData) ? (
        <PreviewModal
          initiativeId={initiativeId}
          isOpen={isPreviewing}
          toggleOpen={onTogglePreviewModal}
          chartData={chartData}
          historicalUtrsState={historicalUtrsState}
          survey={survey}
          isMultiple={isMultiple}
        />
      ) : null}
    </>
  );
};
