import { createRef, useEffect } from 'react';

export const useScrollToElement = (callback?: () => void) => {
  const elementRef = createRef<HTMLDivElement>();

  useEffect(() => {
    let scrollInterval: NodeJS.Timer;

    const scrollIntoViewIfNeeded = () => {
      if (elementRef?.current) {
        const rect = elementRef.current.getBoundingClientRect();
        const verticalViewportThreshold = (window.innerHeight || document.documentElement.clientHeight) / 2;
        const isInView = rect.top >= 0 && rect.y <= verticalViewportThreshold;

        if (!isInView) {
            elementRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        } else {
          callback?.();
        }
      }
    };

    scrollInterval = setInterval(scrollIntoViewIfNeeded, 500);

    return () => clearInterval(scrollInterval);
  }, [elementRef, callback]);

  return { elementRef };
};
