import Lightbox, { CaptionsRef, ZoomRef } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';

export interface LightboxSlide {
  src: string;
  title?: string;
  description?: JSX.Element;
}

interface ZoomProps {
  ref?: React.ForwardedRef<ZoomRef>;
  maxZoomPixelRatio?: number;
  zoomInMultiplier?: number;
  doubleTapDelay?: number;
  doubleClickDelay?: number;
  doubleClickMaxStops?: number;
  keyboardMoveDistance?: number;
  wheelZoomDistanceFactor?: number;
  pinchZoomDistanceFactor?: number;
  scrollToZoom?: boolean;
}

interface CaptionsProps {
  ref?: React.ForwardedRef<CaptionsRef>;
  showToggle?: boolean;
  descriptionTextAlign?: 'center' | 'start' | 'end';
  descriptionMaxLines?: number;
}

interface Props {
  slides: LightboxSlide[];
  photoIndex: number;
  hasZoom?: boolean;
  zoomProps?: ZoomProps;
  hasCaptions?: boolean;
  captionsProps?: CaptionsProps;
  handleReset: () => void;
}

const DEFAULT_ZOOM = {
  maxZoomPixelRatio: 2,
  wheelZoomDistanceFactor: 5,
  pinchZoomDistanceFactor: 5,
};

const DEFAULT_CAPTIONS: CaptionsProps = { descriptionTextAlign: 'center' };

export const CLOSED_PHOTO_INDEX = -1;

const getPlugins = ({ hasZoom, hasCaptions }: Pick<Props, 'hasZoom' | 'hasCaptions'>) => {
  const plugins = [];
  if (hasZoom) {
    plugins.push(Zoom);
  }
  if (hasCaptions) {
    plugins.push(Captions);
  }
  return plugins;
};

export const G17Lightbox = ({
  slides,
  photoIndex,
  hasZoom = true,
  hasCaptions = false,
  zoomProps = DEFAULT_ZOOM,
  captionsProps = DEFAULT_CAPTIONS,
  handleReset,
}: Props) => {
  const zoom = hasZoom ? zoomProps : undefined;
  const captions = hasCaptions ? captionsProps : undefined;
  const plugins = getPlugins({ hasZoom, hasCaptions });

  return (
    <Lightbox
      plugins={plugins}
      captions={captions}
      zoom={zoom}
      index={photoIndex}
      slides={slides}
      open={photoIndex >= 0}
      close={handleReset}
    />
  );
};
