import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { BasicAlert, BasicAlertParams } from '../alert/BasicAlert';

interface QueryErrorProps extends Omit<BasicAlertParams, 'children'> {
  error: FetchBaseQueryError | SerializedError;
}

const getMsg = (error: QueryErrorProps['error']) => {
  if ('error' in error) {
    return error.error;
  }

  if ('message' in error) {
    return error.message;
  }

  return 'Generic Error';
};

export const QueryError = (props: QueryErrorProps) => {
  const { error } = props;

  if (!error) {
    return null;
  }

  return <BasicAlert {...props}>{getMsg(error)}</BasicAlert>;
};
