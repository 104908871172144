import { Fragment } from 'react';
import SimpleTooltip from '../../../components/simple-tooltip';
import { useAppSettings } from '../../../hooks/app/useAppSettings';
import { SurveyActionData } from '../../../model/surveyData';
import { UniversalTrackerPlain } from '../../../types/universalTracker';
import { getName } from '../../../utils/universalTracker';
import { UtrvLink } from './UtrvLink';
import { QUESTION } from '../../../constants/terminology';
import { HistoricalUtrs } from '../../../api/insights';
import { UtrvType } from '../../../constants/status';
import { sortEffectiveDateDesc } from '@utils/sort';

type LinkText = string | React.JSX.Element;

interface Props {
  initiativeId: string;
  survey?: Pick<SurveyActionData, '_id' | 'fragmentUniversalTrackerValues'>;
  utrsCodes: string[];
  utrsData: HistoricalUtrs[];
  getName?: (utrCode: string) => LinkText;
  btnClass?: string;
  tooltip?: string;
}

export const QuestionReference = (props: Props) => {
  const { initiativeId, survey, utrsCodes = [], utrsData, btnClass, tooltip } = props;

  const uniqueUtrsCodes = [...new Set(utrsCodes)];
  const { settingsRecommendedAddons: defaultAlternativeCodes } = useAppSettings();

  const findUtr = (utrCode: string) => utrsData.find((utrData) => utrData.utr.code === utrCode)?.utr;

  const getUtrName = (utrCode: string, utr?: UniversalTrackerPlain) => {
    // Allow name override by custom function
    if (typeof props.getName === 'function') {
      return props.getName(utrCode);
    }

    return utr ? getName(utr, defaultAlternativeCodes) : utrCode;
  };

  const findUtrv = (utr: UniversalTrackerPlain | undefined) => {
    if (!utr) {
      return; // Nothing to do;
    }

    // if question has been answered, use utrData to get latest utrv
    const utrData = utrsData.find((utrData) => utrData.utr._id === utr._id);
    if (utrData) {
      const [latestUtrv] = utrData.utrvs
        .filter((utrv) => utrv.type === UtrvType.Actual)
        .sort(sortEffectiveDateDesc);
      if (latestUtrv) {
        return latestUtrv;
      }
    }
    // if question has not been answered, find question in survey
    return survey?.fragmentUniversalTrackerValues?.find((utrv) => utrv.universalTrackerId === utr._id);
  };

  const length = uniqueUtrsCodes.length;
  if (length === 1) {
    const utrCode = uniqueUtrsCodes[0];
    const utr = findUtr(utrCode);
    const utrv = findUtrv(utr);
    const utrName = getUtrName(utrCode, utr);

    return (
      <SimpleTooltip text={tooltip ?? utrName} className='w-100 overflow-auto'>
        <UtrvLink
          text={utrName}
          initiativeId={initiativeId}
          surveyId={survey?._id}
          utrv={utrv}
          btnClass={`w-100 ${btnClass}`}
          utr={utr}
        />
      </SimpleTooltip>
    );
  }

  return (
    <div>
      <span className='mr-2 text-ThemeTextPlaceholder'>Multiple {QUESTION.PLURAL}:</span>
      {uniqueUtrsCodes.map((utrCode, ix) => {
        const utr = findUtr(utrCode);
        const utrv = findUtrv(utr);
        const utrName = getUtrName(utrCode, utr);

        return (
          <Fragment key={`utr_${utrCode}-${ix}`}>
            <SimpleTooltip text={utrName}>
              <UtrvLink text={`[${ix + 1}]`} initiativeId={initiativeId} surveyId={survey?._id} utrv={utrv} utr={utr} />
            </SimpleTooltip>
            {ix < length - 1 ? <span>, </span> : null}
          </Fragment>
        );
      })}
    </div>
  );
};
