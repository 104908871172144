import { UtrvStatus } from '../../../constants/status';
import { QUESTION } from '../../../constants/terminology';
import { ScopeGroups } from '../../../model/surveyData';
import { CustomInitiatives, NewCustomReport, SurveyData, SurveyDataRange } from '../../../types/custom-report';
import { DownloadGroup, DownloadType, VisibilityStatus } from '../../../types/download';
import { MetricGroup } from '../../../types/metricGroup';
import { UtrvAssuranceStatus } from '../../../types/universalTrackerValue';
import { DownloadSettingPropType } from '../DownloadSettingModal';

export interface DownloadSimple {
  (type: string, code?: string, visibility?: VisibilityStatus, utrvStatus?: DownloadUtrvStatus): Promise<void>
}
/** @deprecated detailed report is merged to simple report */
export interface DownloadXlsx {
  (code?: string, visibility?: VisibilityStatus, utrvStatus?: DownloadUtrvStatus): Promise<void>
}
export interface DownloadPptx {
  (code?: string, visibility?: VisibilityStatus, utrvStatus?: DownloadUtrvStatus, config?: { debug?: boolean }): void
}
/** @deprecated detailed report is merged to simple report */
export interface DownloadCsv {
  (code?: string, visibility?: VisibilityStatus, utrvStatus?: DownloadUtrvStatus): Promise<void>
}
interface DownLoadReportType {
  type: string;
  code: string;
  config?: { debug?: boolean };
  downloadSettings: DownloadSettingsType;
  /** @deprecated detailed report is merged to simple report */
  downloadXlsx?: DownloadXlsx;
  downloadPptx?: DownloadPptx;
  /** @deprecated detailed report is merged to simple report */
  downloadCsv?: DownloadCsv;
  downloadSimple?: DownloadSimple;
}

export enum DownloadUtrvStatusCombined {
  AllAnswered = 'allanswered',
  All = 'all',
  Assured = 'assured',
}
export type DownloadUtrvStatus = UtrvStatus | DownloadUtrvStatusCombined

export interface AssuranceUtrvCombinedStatus {
  statuses: UtrvStatus[];
  assuranceStatus?: UtrvAssuranceStatus[],
}

const UtrvStatuses = {
  [DownloadUtrvStatusCombined.AllAnswered]: [UtrvStatus.Verified, UtrvStatus.Updated],
  [DownloadUtrvStatusCombined.All]: [UtrvStatus.Verified, UtrvStatus.Updated, UtrvStatus.Created, UtrvStatus.Rejected],
  [UtrvStatus.Verified]: [UtrvStatus.Verified],
  [DownloadUtrvStatusCombined.Assured]: [UtrvAssuranceStatus.Completed, UtrvAssuranceStatus.CompletedOpen],
}

export const convertToUtrvStatuses = (status?: DownloadUtrvStatus): AssuranceUtrvCombinedStatus => {
  if (!status) {
    return { statuses: [] };
  }
  if (status === DownloadUtrvStatusCombined.AllAnswered) {
    return { statuses: UtrvStatuses[DownloadUtrvStatusCombined.AllAnswered] };
  }
  if (status === DownloadUtrvStatusCombined.All) {
    return { statuses: UtrvStatuses[DownloadUtrvStatusCombined.All] };
  }
  if (status === DownloadUtrvStatusCombined.Assured) {
    return {
      statuses: UtrvStatuses[UtrvStatus.Verified],
      assuranceStatus: UtrvStatuses[DownloadUtrvStatusCombined.Assured],
    };
  }
  return { statuses: [status] };
};

export const getDisplayStatus = (
  survey?: Pick<SurveyData, 'statuses' | 'assuranceStatus'>
): DownloadUtrvStatus => {
  if (!survey) {
    return UtrvStatus.Verified;
  }
  const { statuses, assuranceStatus } = survey;
  switch (true) {
    case assuranceStatus && assuranceStatus.length > 0:
      return DownloadUtrvStatusCombined.Assured;
    case statuses && UtrvStatuses[DownloadUtrvStatusCombined.All].every((status) => statuses?.includes(status)):
      return DownloadUtrvStatusCombined.All;
    case statuses && UtrvStatuses[DownloadUtrvStatusCombined.AllAnswered].every((status) => statuses?.includes(status)):
      return DownloadUtrvStatusCombined.AllAnswered;
    case statuses && UtrvStatuses[UtrvStatus.Verified].every((status) => statuses?.includes(status)):
    default:
      return UtrvStatus.Verified;
  }
};

export interface SelectedGroup extends Pick<MetricGroup, 'universalTrackers'> {
  code: string
  name: string
  checked: boolean;
  type: ScopeGroups;
}

export interface DownloadSettingsType {
  selectedScopes: SelectedGroup[];
  visibility: VisibilityStatus;
  status?: DownloadUtrvStatus;
  assuranceStatus?: DownloadUtrvStatus;
}

export const downloadReportHandler = async ({
  type,
  code,
  config,
  downloadSettings,
  downloadXlsx,
  downloadPptx,
  downloadCsv,
  downloadSimple,
}: DownLoadReportType) => {
  const combinedStatus = downloadSettings.assuranceStatus ?? downloadSettings.status;
  switch (type) {
    case DownloadType.SimpleCsv:
    case DownloadType.SimpleXlsx:
      return downloadSimple?.(type, code, downloadSettings.visibility, combinedStatus);
    case DownloadType.Xlsx:
      return downloadXlsx?.(code, downloadSettings.visibility, combinedStatus);
    case DownloadType.Pptx:
      return downloadPptx?.(code, downloadSettings.visibility, combinedStatus, config);
    case DownloadType.Csv:
      return downloadCsv?.(code, downloadSettings.visibility, combinedStatus);
    default:
      return;
  }
};


export interface FormData extends Partial<Omit<NewCustomReport, 'assuranceStatus'>> {
  _id?: string;
  status: DownloadUtrvStatus;
}

export const getInitialState = (
  customReport?: Pick<CustomInitiatives, '_id' | 'name' | 'description'> & { survey: SurveyData | SurveyDataRange }
): FormData => {
  return {
    _id: customReport?._id,
    name: customReport?.name,
    description: customReport?.description,
    status: getDisplayStatus(customReport?.survey),
  };
};

export const statusOptions = [
  { code: DownloadUtrvStatusCombined.All, name: `All ${QUESTION.CAPITALIZED_PLURAL} (including unanswered)` },
  { code: DownloadUtrvStatusCombined.AllAnswered, name: `All answered ${QUESTION.PLURAL} (answered and verified)` },
  { code: UtrvStatus.Verified, name: `Verified ${QUESTION.PLURAL} only` },
];

export const privacyOptions = [
  { code: VisibilityStatus.ExcludeData, name: `Public ${QUESTION.PLURAL} only` },
  { code: VisibilityStatus.Include, name: `All ${QUESTION.PLURAL} (public & private)` },
];

export const getAssuredOption = (hasDisabledTooltip: boolean) => [
  {
    code: DownloadUtrvStatusCombined.Assured,
    name: `Assured ${QUESTION.PLURAL} only`,
    tooltip: hasDisabledTooltip ? `There are no assured ${QUESTION.PLURAL}` : '',
  },
];

export const getDownLoadOptions = (
  props: Pick<DownloadSettingPropType, 'code' | 'disabledOptions' | 'titleOptions'> & {
    handleDownloadDoc?: () => Promise<void>;
    handleDownloadReport: (downloadType: DownloadType, config?: { debug: boolean }) => Promise<void>;
    isStaff: boolean;
  }
): DownloadGroup[] => {
  const { code, disabledOptions = [], titleOptions, handleDownloadDoc, handleDownloadReport, isStaff } = props;

  if (code === 'sdg') {
    return [
      {
        id: 'sdg',
        title: '',
        tooltip: '',
        options: [
          {
            type: 'Download CSV report',
            handler: () => handleDownloadReport(DownloadType.Csv),
            icon: 'fa fa-file-csv',
          },
        ],
      },
    ];
  }

  const options: DownloadGroup[] = [
    {
      id: 'simple',
      title: titleOptions?.simple?.title ?? 'Data report',
      tooltip: '',
      options: [
        {
          type: 'Download Excel report',
          handler: () => handleDownloadReport(DownloadType.SimpleXlsx),
          icon: 'fa fa-file-excel',
        },
        {
          type: 'Download CSV report',
          handler: () => handleDownloadReport(DownloadType.SimpleCsv),
          icon: 'fa fa-file-csv',
        },
      ],
    },
  ];

  if (handleDownloadDoc) {
    options.unshift({
      id: 'word',
      title: titleOptions?.word?.title ?? 'Sustainability Word report',
      tooltip: titleOptions?.word?.tooltip ?? '',
      options: [
        {
          type: 'Download Word report',
          handler: handleDownloadDoc,
          icon: 'fas fa-file-word',
        },
      ],
    });
  }

  if (isStaff) {
    const pptxOptions = [
      {
        type: 'Download Powerpoint report',
        handler: () => handleDownloadReport(DownloadType.Pptx),
        icon: 'fas fa-file-powerpoint',
      },
    ];

    if (isStaff) {
      pptxOptions.push(
        {
          type: '(DEBUG)',
          handler: () => handleDownloadReport(DownloadType.Pptx, { debug: true }),
          icon: 'fas fa-file-powerpoint',
        }
      );
    }

    options.unshift({
      id: 'pptx',
      title: 'SDG Aligned Powerpoint report',
      tooltip: '',
      options: pptxOptions
    });
  }

  return disabledOptions.length === 0 ? options : options.filter((o) => !disabledOptions.includes(o.id));
};
