import { EditOptionProps } from '../../types/initiative';
import { IndustryRow } from './IndustryRow';
import { OrganizationRow } from './OrganizationRow';
import { TextRow } from './TextRow';
import { MonthDayRow } from './MonthDayRow';
import { EditOptionType } from '../../types/initiative';
import { BankingSettingsRow } from './BankingSettingsRow';

export const SETTING_INPUT_TYPES = {
  ORGANIZATION: 'organization',
  TEXT: 'text',
  PARAGRAPH: 'paragraph',
  INDUSTRY: 'industry',
  MONTH_PICKER: 'month_picker',
  BANKING_WITH: 'banking_with'
};

export const companyEditOptions: EditOptionType[] = [
  {
    label: 'Organisation:',
    type: SETTING_INPUT_TYPES.ORGANIZATION,
  },
  {
    label: 'Current level name:',
    type: SETTING_INPUT_TYPES.TEXT,
    accessor: 'name',
    required: true,
  },
  {
    label: 'Description:',
    type: SETTING_INPUT_TYPES.PARAGRAPH,
    accessor: 'description',
    required: false,
  },
  {
    label: 'Mission statement:',
    type: SETTING_INPUT_TYPES.PARAGRAPH,
    accessor: 'missionStatement',
    required: false,
  },
  {
    label: 'Location:',
    type: SETTING_INPUT_TYPES.TEXT,
    accessor: 'geoLocation',
    required: false,
  },
  {
    label: 'Industry:',
    type: SETTING_INPUT_TYPES.INDUSTRY,
    required: true,
  },
  {
    label: 'Last day of financial year:',
    type: SETTING_INPUT_TYPES.MONTH_PICKER,
    required: true,
  },
  {
    label: 'Banking with:',
    type: SETTING_INPUT_TYPES.BANKING_WITH,
  },
];

export const EditOption = (props: EditOptionProps) => {
  const { type } = props;
  switch (type) {
    case SETTING_INPUT_TYPES.ORGANIZATION:
      return <OrganizationRow {...props} />;
    case SETTING_INPUT_TYPES.TEXT:
      return <TextRow {...props} />;
    case SETTING_INPUT_TYPES.PARAGRAPH:
      return <TextRow isParagraph {...props} />;
    case SETTING_INPUT_TYPES.INDUSTRY:
      return <IndustryRow {...props} />;
    case SETTING_INPUT_TYPES.MONTH_PICKER:
      return <MonthDayRow {...props} />;
    case SETTING_INPUT_TYPES.BANKING_WITH:
      return <BankingSettingsRow {...props} />;
    default:
      return null;
  }
};
