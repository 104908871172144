import Chart from 'react-google-charts';
import { HistoricalUtrs } from '../../../../../api/insights';
import {
  CalculationType,
  ChartDataLoaded,
  GridDashboardChartItem
} from '../../../../../types/insight-custom-dashboard';
import { createExpression, FormulaVariables, tryCalculation } from '@utils/formula';
import { getChartOptions } from '../../../../summary/insights/partials/GenericChart';
import { chartStyleNames } from '../../../../summary/insights/utils/chartStyles';
import { ChartType } from '../../../../summary/insights/utils/constants';
import { TakenFromText } from '../common/TakenFromText';
import { getLatestActualUtrvs, getVariablesWithValues } from './utils';
import { Note } from '../common/Note';
import { processCalculation } from '@services/aggregation/stageAggregation';
import { useEffect, useState } from 'react';
import { getLatestActualHistoricalUtrs } from '@services/aggregation/utrData';

type Props = Pick<GridDashboardChartItem, 'calculation' | 'variables' | 'note'> & {
  // This is already filtered to
  utrsData: HistoricalUtrs[];
};

export interface BarChartDataLoaded<T = unknown> extends ChartDataLoaded<T> {
  variablesWithValues: FormulaVariables;
}

const getChartData = async ({ utrsData, variables, calculation }: Required<Omit<Props, 'note'>>) => {

  const { headers = [] } = calculation;
  const chartDataHeaders = headers.map((header) => {
    if ('name' in header) {
      return header.name;
    }

    if ('role' in header) {
      return { role: header.role };
    }

    return '';
  });

  const fallback = 0;
  if (calculation.type === CalculationType.Stages) {
    const { effectiveDate, period, latestUtrsData } = getLatestActualHistoricalUtrs(utrsData);
    const acc = { result: fallback };
    const chartData = [
      chartDataHeaders,
      ...await Promise.all(calculation.values.map(async ({ name, stages, options }) => [
        name,
        await processCalculation({
          variables,
          utrsData: latestUtrsData,
          stages,
          fallback,
          acc
        }),
        createExpression(options?.tooltip.formula ?? '', acc, fallback),
        options?.style ?? '',
      ])),
    ];

    return { chartData, effectiveDate, period, variablesWithValues: acc };
  }

  const { latestActualUtrvs = [], effectiveDate, period } = getLatestActualUtrvs(utrsData);
  const variablesWithValues = getVariablesWithValues({ utrsData, variables, utrvs: latestActualUtrvs });
  const chartData = [
    chartDataHeaders,
    ...calculation.values.map(({ name, formula = '', options }) => [
      name,
      tryCalculation({ formula, variables: variablesWithValues, fallback: 0 }),
      createExpression(options?.tooltip.formula ?? '', variablesWithValues, 0),
      options?.style ?? '',
    ]),
  ];

  return { chartData, effectiveDate, period, variablesWithValues };
};

export const BarChart = ({ utrsData, variables, calculation, note }: Props) => {
  const [data, setData] = useState<BarChartDataLoaded | undefined>();

  useEffect(() => {
    if (calculation) {
      getChartData({ utrsData, variables, calculation })
        .then(data => setData(data))
    }
  }, [calculation, utrsData, variables]);

  if (!utrsData.length || !variables || !calculation || !data) {
    return null;
  }

  const { chartData, effectiveDate = '', period, variablesWithValues } = data;

  return (
    <>
      <Note note={note} variablesWithValues={variablesWithValues} />
      <div className='w-100 h-100'>
        <Chart
          chartType={ChartType.BarChart}
          data={chartData}
          width={'100%'}
          height={'100%'}
          options={getChartOptions({
            chartType: ChartType.BarChart,
            options: chartStyleNames.chartStyleHorizontalBar,
          })}
        />
      </div>
      <TakenFromText effectiveDate={effectiveDate} period={period} />
    </>
  );
};
