import { Button, ModalFooter } from 'reactstrap';
import { ChartData, isDashboardItem } from '../../../utils';
import { ChartAddingModalProps } from './types';
import IconButton from '../../../../../components/button/IconButton';

export const SelectDataPointFooter = ({
  chartData,
  togglePreviewModal,
  handleBack,
  handleCreate,
  item,
}: Pick<ChartAddingModalProps, 'item'> & {
  chartData: ChartData;
  handleBack: () => void;
  handleCreate: () => void;
  togglePreviewModal: () => void;
}) => {
  const isDisabled = !chartData.metrics?.length;
  const action = isDashboardItem(item) ? 'Edit' : 'Add';

  return (
    <ModalFooter className='w-100 d-flex justify-content-between'>
      <IconButton icon={'fal fa-eye'} color={'secondary'} disabled={isDisabled} onClick={togglePreviewModal} />
      <div>
        <Button color='link-secondary' onClick={handleBack} className='mr-3'>
          <i className='fa fa-chevron-left mr-2' />
          Cancel
        </Button>
        <Button color='primary' disabled={isDisabled} onClick={handleCreate}>
          {action} chart
        </Button>
      </div>
    </ModalFooter>
  );
};
