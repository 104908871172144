import { useState } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { EditOptionAccessorType } from '../../types/initiative';
import { EditOptionProps } from '../../types/initiative';
import { saveReportingLevelDetails } from '../../actions/initiative';
import { useDispatch } from 'react-redux';
import { reloadRootInitiatives } from '../../slice/rootInitiativeListSlice';
import { loadInitiativeTree } from '../../actions';

interface TextOptionProps extends EditOptionProps {
  isParagraph?: boolean;
}

const defaultTextAreaRows = 4;

export const TextRow = ({ label, accessor, required, rootOrg, initiative, isParagraph }: TextOptionProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(initiative[accessor as EditOptionAccessorType] ? String(initiative[accessor as EditOptionAccessorType]) : '');
  const dispatch = useDispatch();
  const isRoot = rootOrg._id === initiative._id;
  const isDisabled = required && !editValue;
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter' || e.shiftKey || isDisabled) {
      return;
    }
    handleSubmit();
  };
  const toggleShowInput = () => setIsEditing(!isEditing);
  const handleSubmit = async () => {
    if (required && !editValue) {
      return;
    }
    const formData = { [accessor as EditOptionAccessorType]: editValue };
    await dispatch(saveReportingLevelDetails(initiative._id, formData));
    dispatch(loadInitiativeTree({ organization: rootOrg, forceRefresh: true, blocking: false }));
    if (isRoot) {
      await dispatch(reloadRootInitiatives());
    }
    toggleShowInput();
  };
  const handleCancel = () => {
    setEditValue(String(initiative[accessor as EditOptionAccessorType]));
    toggleShowInput();
  }

  return (
    <Row className='ml-0 mb-3 mr-0 text-row__wrapper'>
      <Col className={'col-md-4 col-12 align-self-start text-label-uppercase'}>{label}</Col>
      {isEditing ? (
        <Col className='col-md-6 col-10'>
          <Input
            invalid={required ? editValue.length === 0 : undefined}
            type={!!isParagraph ? 'textarea' : 'text'}
            required={required}
            defaultValue={editValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            rows={defaultTextAreaRows}
            className='text-row__input'
          />
        </Col>
      ) : (
        <Col className='col-md-6 col-10 text-right text-ellipsis__paragraph'>
          {editValue !== '' ? editValue : 'not set'}
        </Col>
      )}

      <Col className='col-2 d-flex align-self-end'>
        {isEditing ? (
          <>
            <Button color='link' onClick={handleSubmit} disabled={isDisabled}>
              Save
            </Button>
            <Button color='link-secondary' onClick={handleCancel} disabled={isDisabled}>
              Cancel
            </Button>
          </>
        ) : (
          <Button color='link' onClick={toggleShowInput}>
            Edit
          </Button>
        )}
      </Col>
    </Row>
  );
};
