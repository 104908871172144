import { Button, Input, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { RadioInput } from '../../../../components/form/RadioInput';
import { useState } from 'react';
import {
  GridDashboardItemCreate,
  GridDashboardTextItem,
  GridDashboardTextItemBase,
  InsightDashboardItemType,
} from '../../../../types/insight-custom-dashboard';
import { WidgetProps, isDashboardItem } from '../../utils';
import { TextData } from './types';
import { getTextData } from './utils';

interface Props {
  toggle: () => void;
  item: GridDashboardTextItem | GridDashboardTextItemBase;
  handleSubmitChart: (updatingItem: GridDashboardItemCreate) => Promise<void>;
}

const textStyleOptions = [
  { code: InsightDashboardItemType.Headline, name: 'Headline' },
  { code: InsightDashboardItemType.Text, name: 'Paragraph/text' },
];

export const TextWidgetAdding = ({ toggle, item, handleSubmitChart }: Props) => {
  const [widgetChanges, setWidget] = useState<TextData>(getTextData(item));

  const changeWidget = (key: keyof TextData, value: TextData['type'] | TextData['text']) =>
    setWidget((widget) => ({ ...widget, [key]: value }));
  const handleChangeType = ({ value }: { value: TextData['type'] }) => changeWidget('type', value);
  const handleChangeText = (value: TextData['text']) => changeWidget('text', value);
  const handleSubmit = () => handleSubmitChart({ ...item, type: widgetChanges.type, text: widgetChanges.text.trim() });

  const label = WidgetProps[InsightDashboardItemType.Headline].label;
  const action = isDashboardItem(item) ? 'Edit' : 'Add';

  return (
    <>
      <ModalHeader toggle={toggle}>
        {action} a {label}
      </ModalHeader>
      <ModalBody>
        <h5>Text style</h5>
        <RadioInput
          groupCode='text-widget'
          value={widgetChanges.type}
          options={textStyleOptions}
          onChange={handleChangeType}
          className='d-flex'
          formGroupClassName='mr-5'
        />
        <h6 className='text-ThemeTextDark'>Enter text</h6>
        <Input
          type='textarea'
          placeholder='Enter your headline, description or explanatory text here'
          value={widgetChanges.text}
          onChange={(e) => handleChangeText(e.currentTarget.value)}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='link-secondary' onClick={toggle}>
          Cancel
        </Button>
        <Button color='primary' onClick={handleSubmit} disabled={!widgetChanges.text}>
          {action} {label}
        </Button>
      </ModalFooter>
    </>
  );
};
