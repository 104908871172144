import { FormGroup, Input, Label } from 'reactstrap';
import { SurveyQuestionListToolbarProps } from '..';

export const ShowingBookmarksToggle = (props: SurveyQuestionListToolbarProps) => {
  const { toggleShowingBookmarks, isShowingBookmarks } = props;

  if (!toggleShowingBookmarks) {
    return null;
  }

  return (
    <FormGroup check className='showing-bookmarks-toggle text-md'>
      <Label>
        <Input
          type='checkbox'
          checked={isShowingBookmarks}
          onChange={(e) => toggleShowingBookmarks?.(e.target.checked)}
          className='m-0 mr-2'
        />{' '}
        Bookmarks
      </Label>
    </FormGroup>
  );
};
