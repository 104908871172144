import { InsightDashboardItemType } from '../../../../types/insight-custom-dashboard';
import { TextData } from './types';

const commonClassnames = 'w-100 h-100 d-flex align-items-center text-break';

type Props = TextData & {
  isEditing?: boolean;
};

export const TextWidget = ({ type, text, isEditing }: Props) => {
  switch (type) {
    case InsightDashboardItemType.Headline:
      return <Headline text={text} />;
    case InsightDashboardItemType.Text:
      return <TextArea text={text} isEditing={isEditing} />;
    default:
      return null;
  }
};

const TextArea = ({ text, isEditing }: Pick<Props, 'text' | 'isEditing'>) => {
  const className = isEditing ? '' : 'border border-ThemeBorderDefault border-1 rounded-1';

  return (
    <div className={`${className} ${commonClassnames} text-ThemeTextMedium background-ThemeBgExtralight p-3`}>
      {text}
    </div>
  );
};

const Headline = ({ text }: Pick<Props, 'text'>) => {
  return <h2 className={`px-3 ${commonClassnames} text-ThemeHeadingLight background-ThemeBgLight m-0`}>{text}</h2>;
};
