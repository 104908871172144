import { Button, ModalBody } from 'reactstrap';
import { ChartAddingModalProps, SelectDataPointProps } from '../types';
import { QUESTION } from '../../../../../../constants/terminology';
import { QuestionData } from '../../../../utils';
import { useState } from 'react';
import { emptyUtrData } from '../utils';
import { MetricsFooter } from './MetricsFooter';
import { View } from '../types';
import { MetricsContent } from './MetricsContent';

export const SelectMultipleMetrics = ({
  initiativeId,
  item,
  chartData,
  changeChartData,
  backToEntryPoint,
  handleSubmitChart,
  togglePreviewModal,
  isCustomTitle,
  questionsMap,
}: Pick<ChartAddingModalProps, 'initiativeId'> & SelectDataPointProps) => {
  const [view, setView] = useState<View>(View.CombineMetrics);
  const [questionData, setQuestionData] = useState<QuestionData>(emptyUtrData);

  const openAddMetric = () => setView(View.TrackMetric);
  const closeAddMetric = () => setView(View.CombineMetrics);

  return (
    <>
      <ModalBody>
        <p>
          <Button color='link' onClick={backToEntryPoint} className='p-0'>
            Chart selection
          </Button>{' '}
          / Combine {QUESTION.PLURAL}
        </p>
        <MetricsContent
          initiativeId={initiativeId}
          view={view}
          openAddMetric={openAddMetric}
          chartData={chartData}
          questionsMap={questionsMap}
          changeChartData={changeChartData}
          questionData={questionData}
          setQuestionData={setQuestionData}
        />
      </ModalBody>
      <MetricsFooter
        view={view}
        closeAddMetric={closeAddMetric}
        chartData={chartData}
        questionsMap={questionsMap}
        item={item}
        questionData={questionData}
        setQuestionData={setQuestionData}
        backToEntryPoint={backToEntryPoint}
        togglePreviewModal={togglePreviewModal}
        changeChartData={changeChartData}
        handleSubmitChart={handleSubmitChart}
        isCustomTitle={isCustomTitle}
      />
    </>
  );
};
