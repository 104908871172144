import React from 'react';
import Table, { TableColumn } from '../../../../components/table/Table';
import { Button } from 'reactstrap';
import { TagItem } from '../../../../components/survey/question/tags/TagItem';
import { getInitialState } from '../../../../components/utr-decimal/utils';
import { DecimalInputType } from '../../../../types/initiativeUniversalTracker';
import { Checkbox, CheckboxState } from '../../../../components/common/Checkbox';
import { InitiativeQuestionManagementResponse } from '../../../../api/admin-dashboard';
import { BulkActionUtr } from '../../../../components/survey-question-list/partials/BulkActionToolbar';
import { UniversalTrackerBlueprintMin } from '../../../../types/universalTracker';
import { QUESTION } from '../../../../constants/terminology';

interface QuestionTableProps {
  data: InitiativeQuestionManagementResponse['utrs'];
  utrTagMap: InitiativeQuestionManagementResponse['utrTagMap'];
  rootInitiativeUtrs: InitiativeQuestionManagementResponse['rootInitiativeUtrs'];
  toggleSelectAll: () => void;
  selectedQuestions: BulkActionUtr[];
  toggleSelectQuestion: (utr: BulkActionUtr, status: CheckboxState) => void;
  isSelectAll: boolean;
  isRootOrganization: boolean;
  pageSize?: number;
}

export const QuestionTable = (props: QuestionTableProps) => {
  const {
    data,
    utrTagMap,
    rootInitiativeUtrs,
    selectedQuestions,
    toggleSelectAll,
    toggleSelectQuestion,
    isSelectAll = false,
    isRootOrganization = false,
    pageSize,
  } = props;

  const isQuestionSelected = (utrId: string) => selectedQuestions.some((utr) => utr._id === utrId);

  const columns: TableColumn<UniversalTrackerBlueprintMin>[] = [
    {
      Header: QUESTION.CAPITALIZED_SINGULAR,
      accessor: 'name',
    },
    {
      id: 'tags',
      Header: 'Tags',
      Cell: (c) => {
        const tags = utrTagMap[c.row.original._id];
        return tags && tags.length
          ? tags.map((tag: string) => {
              return <TagItem key={tag} groupName={tag} />;
            })
          : '-';
      },
      disableSortBy: true,
    },
    {
      id: 'decimals',
      Header: 'Decimals',
      cellProps: { style: { width: 20 } },
      accessor: (c) => {
        const rootInitiativeUtr = rootInitiativeUtrs.find((utr) => utr.universalTrackerId === c._id);
        if (!rootInitiativeUtr) {
          return '-';
        }

        const defaultDecimal = getInitialState({
          inputType: DecimalInputType.SingleInput,
          tableColumns: [],
          valueValidation: rootInitiativeUtr.valueValidation,
        });

        return <div>{defaultDecimal.value || '-'}</div>;
      },
    },
  ];

  if (isRootOrganization) {
    columns.push({
      id: 'action',
      cellProps: { style: { width: 20 }, className: 'text-center' },
      Header: () => (
        <Button color='link' onClick={toggleSelectAll}>
          {isSelectAll ? 'Unselect all' : 'Select all'}
        </Button>
      ),
      Cell: (c) => {
        const status = isQuestionSelected(c.row.original._id) ? CheckboxState.Checked : CheckboxState.Unchecked;
        return (
          <Checkbox
            status={isQuestionSelected(c.row.original._id) ? CheckboxState.Checked : CheckboxState.Unchecked}
            onChange={() => toggleSelectQuestion(c.row.original, status)}
          />
        );
      },
      disableSortBy: true,
    });
  }

  return <Table data={data} columns={columns} pageSize={pageSize ?? data.length} />;
};
