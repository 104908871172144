import { SurveyPeriodDropdown } from '@components/survey-period-dropdown';
import { PeriodOption } from '@components/survey-period-dropdown/utils';
import { SurveyTypeDropdown } from '@components/survey-type-dropdown/SurveyTypeDropdown';
import { SurveyTypeOptions } from '@components/survey-type-dropdown/utils';
import { DataPeriods } from '@g17eco/types/universalTracker';
import { useState } from 'react';

export interface Filters {
  surveyType: SurveyTypeOptions;
  period: PeriodOption;
}

export interface SurveyFiltersProps {
  initialFilters: Filters;
  onChangeFilters: (filter: Filters) => void;
}

export const SurveyFilters = ({ initialFilters, onChangeFilters }: SurveyFiltersProps) => {
  const [filters, setFilters] = useState(initialFilters);

  const handleChangeFilters = (key: keyof Filters, value: SurveyTypeOptions | PeriodOption) => {
    const newFilters = { ...filters, [key]: value };
    setFilters(newFilters);
    onChangeFilters(newFilters);
  };

  return (
    <div className='d-flex'>
      <SurveyTypeDropdown
        hasAllOption
        surveyType={filters.surveyType}
        setSurveyType={(surveyType) => handleChangeFilters('surveyType', surveyType)}
      />
      <SurveyPeriodDropdown
        hasAllOption
        className='ml-3'
        period={filters.period}
        availablePeriods={['all', DataPeriods.Yearly, DataPeriods.Quarterly, DataPeriods.Monthly]}
        setPeriod={(period) => handleChangeFilters('period', period)}
      />
    </div>
  );
};
