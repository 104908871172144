import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { DataShare, DataShareActions, DataShareCreateBasic, DataShareStatus, DataShareWithRequester } from '../../../types/dataShare';
import { DataShareForm } from '../DataShareForm';

export const DataShareAcceptModal = (props: {
  dataShare: DataShareWithRequester;
  actions: DataShareActions;
  toggle: () => void;
}) => {
  const { dataShare, actions, toggle } = props;
  const { accept, remove, revoke } = actions;
  const [updateDataShare, setUpdateDataShare] = useState<DataShare>(dataShare);

  const onChange = (u: Partial<DataShareCreateBasic>) => setUpdateDataShare((f) => ({ ...f, ...u }));

  const isPendingRequest = dataShare.status === DataShareStatus.Pending;
  return (
    <Modal isOpen={true} toggle={toggle} backdrop='static'>
      <ModalHeader toggle={toggle}>Data share request</ModalHeader>
      <ModalBody>
        <DataShareForm form={updateDataShare} onChange={onChange} disabledContent={true} />
      </ModalBody>
      <ModalFooter className='justify-content-between'>
        <Button
          color='danger'
          outline={true}
          onClick={() => (isPendingRequest ? remove(dataShare) : revoke(dataShare))}
        >
          {isPendingRequest ? 'Reject request' : 'Reject'}
        </Button>
        <Button color='primary' onClick={() => accept(updateDataShare)}>
          {isPendingRequest ? 'Accept' : 'Allow'}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
