import { useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { DashboardRow, DashboardSection } from '../../components/dashboard';
import {
  GridDashboardItem,
  GridDashboardItemBase,
  GridDashboardItemCreate,
  GridDashboardTempItem,
  InsightDashboard,
} from '../../types/insight-custom-dashboard';
import { TitleEditing } from './TitleEditing';
import IconButton from '../../components/button/IconButton';
import { FiltersEditing } from './FiltersEditing';
import { GridLayoutDashboard } from './GridLayoutDashboard';
import { Layout } from 'react-grid-layout';
import { useDebouncedCallback } from 'use-debounce';
import { calculateItemHeight, getDisplayDashboardItemType, getGridSize } from './utils';
import { UnsavedChangesWarningPrompt } from '../../components/common/UnsavedChangesWarningPrompt';
import { GridItemProps } from './GridItem';
import { SiteAlertColors } from '../../slice/siteAlertsSlice';
import { DashboardItemFilters, HistoricalUtrs } from '../../api/insights';
import { replaceItem } from '../../utils/array';
import { useSiteAlert } from '../../hooks/useSiteAlert';
import { CompanyProfile } from '../../components/company-profile';
import { SafeInitiativeFields } from '../../types/initiative';
import { SdgChart } from '../../components/impact-performance/SdgChart';
import { ScorecardResponse } from '../../types/scorecard';
import { CustomDashboardModalContainer } from './CustomDashboardModalContainer';
import { DataPeriods } from '../../types/universalTracker';
import { CustomDashboardToolbar } from '../../components/custom-dashboard/action-toolbar/CustomDashboardToolbar';
import { CustomDashboardContext } from './context/CustomDashboardWrapper';
import { generateObjectId } from '@utils/object-id';
import { CancelConfirmationModal } from '@components/custom-dashboard/CancelConfirmationModal';
import { useToggle } from '@hooks/useToggle';
import { SurveyType } from '@g17eco/types/survey';
import { SurveyTypeDropdown, SurveyTypeDropdownProps } from '@components/survey-type-dropdown/SurveyTypeDropdown';
import { SurveyPeriodDropdown } from '@components/survey-period-dropdown';

type Props = Pick<GridItemProps, 'handleOpenUtrvHistoryModal' | 'survey'> &
  Partial<Pick<SurveyTypeDropdownProps, 'surveyType'>> & {
    dashboard: InsightDashboard;
    handleCancel: () => void;
    handleSave: (dashboard: Partial<InsightDashboard>) => void;
    handleDelete: () => void;
    initiative: SafeInitiativeFields;
    scorecard?: ScorecardResponse;
    availablePeriods: DataPeriods[];
    period: DataPeriods | undefined;
  };

export type EditingDashboardItem = GridDashboardItem | GridDashboardItemBase | undefined | GridDashboardTempItem;

export const CustomDashboardEditing = ({
  dashboard,
  handleCancel,
  handleSave,
  handleDelete,
  handleOpenUtrvHistoryModal,
  initiative,
  survey,
  availablePeriods,
  period,
  surveyType,
}: Props) => {
  const initiativeId = initiative._id;
  const queryParams: DashboardItemFilters = {
    period,
    surveyType: surveyType ?? SurveyType.Default,
    ...dashboard.filters,
  };
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [dashboardChanges, setDashboardChanges] = useState(dashboard);
  const [editingItem, setEditingItem] = useState<EditingDashboardItem>(undefined);
  const { getHistoricalUtrsByCodes, hideFilterOptions, hideQuestionReference, readOnly } =
    useContext(CustomDashboardContext);
    const [isConfirmingCancel, toggleConfirmingCancelModal] = useToggle();

  const { addSiteAlert, addSiteError } = useSiteAlert();

  const handleChangeTitle = (title: InsightDashboard['title']) => {
    setDashboardChanges((dashboard) => ({ ...dashboard, title }));
    setIsEditingTitle(false);
  };

  const onLayoutChange = (layout: Layout[]) => {
    // Explicitly extract only what needed to track changes in the layout
    const layoutMap = new Map(
      layout.map(({ i, x, y, w, h, minH, minW, maxH, maxW }) => [i, { x, y, w, h, minW, minH, maxW, maxH }])
    );
    setDashboardChanges((dashboard) => ({
      ...dashboard,
      items: dashboard.items.map((item) => ({ ...item, gridSize: layoutMap.get(item._id) ?? item.gridSize })),
    }));
  };

  const onItemRemove = (item: GridDashboardItem) => {
    setDashboardChanges((dashboard) => ({
      ...dashboard,
      items: dashboard.items.filter((gridItem) => gridItem._id !== item._id),
    }));
  };

  const handleClickEdit = (item: GridDashboardItem) => {
    setEditingItem(item);
  };

  const onItemResize = useDebouncedCallback((newItemSize: Layout) => {
    const updated = dashboardChanges.items.map((gridItem) => {
      if (gridItem._id === newItemSize.i) {
        return { ...gridItem, gridSize: newItemSize };
      }
      return gridItem;
    });
    setDashboardChanges((dashboard) => ({ ...dashboard, items: updated }));
  }, 100);

  const handleToggleConfirmingCancelModal = () => {
    if (hasUnsavedChanges) {
      toggleConfirmingCancelModal();
      return;
    }

    handleCancel();
  }

  const saveAndCancelBtn = (
    <>
      <Button onClick={handleToggleConfirmingCancelModal} color='transparent' outline className='ml-3'>
        Cancel
      </Button>
      <Button
        color='primary'
        onClick={() =>
          handleSave({
            items: dashboardChanges.items,
            title: dashboardChanges.title,
            utrsData: dashboardChanges.utrsData,
          })
        }
        className='ml-3'
      >
        Save
      </Button>
    </>
  );

  const updateItemData = (updatingItem: GridDashboardItemCreate) => {
    if (!('variables' in updatingItem) || !updatingItem.variables) {
      // skipping as no additional data to load
      return;
    }
    const utrCodes = Object.values(updatingItem.variables).map((variable) => variable.code);
    const existingCodes = dashboardChanges.utrsData.map((utrData) => utrData.utr.code);

    const codesToLoad = utrCodes.filter((code) => !existingCodes.includes(code));

    if (codesToLoad.length === 0) {
      return;
    }

    return getHistoricalUtrsByCodes({ initiativeId, utrCodes: codesToLoad, queryParams })
      .then((result: HistoricalUtrs[]) => {
        setDashboardChanges((dashboard) => {
          return {
            ...dashboard,
            utrsData: [...dashboard.utrsData, ...result],
          };
        });
      })
      .catch((e) => {
        addSiteError(e.message);
      });
  };

  const updateItem = async (updatingItem: GridDashboardItemCreate) => {
    if ('_id' in updatingItem && updatingItem._id) {
      setDashboardChanges((dashboard) => {
        const index = dashboard.items.findIndex((item) => item._id === updatingItem._id);
        if (index < 0) {
          return dashboard;
        }

        const gridSize = dashboard.items[index].gridSize;
        const itemHeights = calculateItemHeight(updatingItem, gridSize.w);
        const item = {
          ...updatingItem,
          gridSize: { ...gridSize, ...itemHeights },
        };

        return {
          ...dashboard,
          items: replaceItem(dashboard.items, item, index),
        };
      });
      await updateItemData(updatingItem);
      setEditingItem(undefined);
      return;
    }

    setDashboardChanges((dashboard) => {
      const item = {
        _id: generateObjectId(),
        ...updatingItem,
        gridSize: getGridSize(updatingItem, dashboardChanges.items),
      } as GridDashboardItem;

      return {
        ...dashboard,
        items: [...dashboard.items, item],
      };
    });
    await updateItemData(updatingItem);
    addSiteAlert({
      content: `${getDisplayDashboardItemType(updatingItem.type)} added`,
      timeout: 2000,
      color: SiteAlertColors.Success,
    });
    setEditingItem(undefined);
  };

  const hasUnsavedChanges =
    dashboard.title !== dashboardChanges.title ||
    JSON.stringify(dashboard.items) !== JSON.stringify(dashboardChanges.items);

  return (
    <>
      <DashboardRow>
        <div className='d-flex w-100 justify-content-end'>
          <div className='d-flex'>
            <FiltersEditing handleSave={handleSave} dashboard={dashboard} hideOptions={hideFilterOptions} />
            {saveAndCancelBtn}
            {surveyType ? <SurveyTypeDropdown surveyType={surveyType} disabled/> : null}
            <SurveyPeriodDropdown
              disabled
              className='ml-3'
              period={period}
              availablePeriods={availablePeriods}
            />
          </div>
        </div>
      </DashboardRow>
      {dashboard.filters.initiativeInfo?.enabled ? <CompanyProfile initiative={initiative} readOnly={false} /> : null}
      <DashboardRow>
        <div className='d-flex align-items-center'>
          {isEditingTitle ? (
            <TitleEditing
              title={dashboardChanges.title}
              handleSave={handleChangeTitle}
              handleCancel={() => setIsEditingTitle(false)}
            />
          ) : (
            <>
              <h3 className='text-ThemeHeadingLight'>{dashboardChanges.title}</h3>
              <IconButton
                tooltip='Edit title'
                color='transparent'
                outline={false}
                onClick={() => setIsEditingTitle(true)}
                icon='fa-light fa-pencil'
                className='text-ThemeIconSecondary ml-2'
              />
            </>
          )}
        </div>
      </DashboardRow>
      {dashboard.filters.sdgContribution?.enabled && dashboard.scorecard ? (
        <DashboardSection>
          <SdgChart initiativeId={initiativeId} scorecard={dashboard.scorecard} />
        </DashboardSection>
      ) : null}
      <GridLayoutDashboard
        isEditing
        hideQuestionReference={hideQuestionReference}
        gridItems={dashboardChanges.items}
        utrsData={dashboardChanges.utrsData}
        onLayoutChange={onLayoutChange}
        onItemResize={onItemResize}
        onItemRemove={onItemRemove}
        handleClickEdit={handleClickEdit}
        handleOpenUtrvHistoryModal={handleOpenUtrvHistoryModal}
        readOnly={readOnly || !dashboardChanges.filters.baselinesTargets?.enabled}
        initiativeId={initiativeId}
        survey={survey}
      />
      <CustomDashboardToolbar
        openAddingModal={(item: EditingDashboardItem) => setEditingItem(item)}
        handleDelete={handleDelete}
        handleCancel={handleToggleConfirmingCancelModal}
        saveDashboardChanges={() =>
          handleSave({
            items: dashboardChanges.items,
            title: dashboardChanges.title,
            utrsData: dashboardChanges.utrsData,
          })
        }
      />
      <CustomDashboardModalContainer
        editingItem={editingItem}
        initiativeId={initiativeId}
        toggleModal={() => setEditingItem(undefined)}
        updateItem={updateItem}
        queryParams={queryParams}
        survey={survey}
      />
      <UnsavedChangesWarningPrompt hasUnsavedChanges />
      {isConfirmingCancel ? <CancelConfirmationModal toggle={toggleConfirmingCancelModal} handleCancel={handleCancel} /> : null}
    </>
  );
};
