import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { useAddOverviewDashboardItemMutation } from '../../../api/insight-dashboards';
import { useSiteAlert } from '../../../hooks/useSiteAlert';
import { ChartSubType, GridDashboardItem, InsightDashboardItemType } from '../../../types/insight-custom-dashboard';
import { getDefaultGridSize, getOrdinate } from '../../../routes/custom-dashboard/utils';
import IconButton from '../../../components/button/IconButton';

interface Props {
  initiativeId: string;
  overviewItems: GridDashboardItem[];
  item: GridDashboardItem;
  disabled: boolean;
}

export const AddToOverviewBtn = ({ initiativeId, overviewItems, item, disabled }: Props) => {
  const { addSiteAlert, addSiteError } = useSiteAlert();
  const [addOverviewItem, { isLoading }] = useAddOverviewDashboardItemMutation();
  const handleClick = () => {
    const defaultGridSize = getDefaultGridSize('default');
    const newItem = {
      ...item,
      gridSize:
      'subType' in item && item.subType === ChartSubType.Table
          ? { ...item.gridSize, ...getOrdinate(item.gridSize.w, overviewItems) }
          : { ...defaultGridSize, ...getOrdinate(defaultGridSize.w, overviewItems) },
    } as GridDashboardItem;

    addOverviewItem({ initiativeId, item: newItem })
      .unwrap()
      .then(() => {
        addSiteAlert({
          content: 'Chart is added to Overview page',
        });
      })
      .catch((e) => {
        addSiteError(e.message);
      });
  };

  if (item.type !== InsightDashboardItemType.Chart) {
    return null;
  }

  if (overviewItems.some(({ _id }) => _id === item._id)) {
    return null;
  }

  const btnId = `add-to-overview-btn-${item._id}`;

  return (
    <>
      <IconButton icon='fa-plus' onClick={handleClick} disabled={disabled || isLoading} id={btnId} />
      <UncontrolledPopover placement='left' target={btnId} trigger='hover' hideArrow>
        <PopoverBody className='p-2'>Add to overview</PopoverBody>
      </UncontrolledPopover>
    </>
  );
};
