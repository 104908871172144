import { Button, ModalBody } from 'reactstrap';
import { InsightDashboardItemType } from '@g17eco/types/insight-custom-dashboard';
import { QuestionData, transformVariables } from '../../../utils';
import { SDGTargetDropdown } from './sdg-trackers/SDGTargetDropdown';
import { getExtendedGoalCode } from './sdg-trackers/utils';
import { getSrc } from '@components/sdg-icon/utils';
import { SelectDataPointFooter } from './SelectDataPointFooter';
import { SelectDataPointProps } from './types';
import { useState } from 'react';
import { emptyUtrData, isValidUtrVariable } from './utils';
import { getSDGTitle } from '@constants/sdg-data';

export const SelectSDG = ({
  item,
  chartData,
  changeChartData,
  backToEntryPoint,
  handleSubmitChart,
  togglePreviewModal,
  isCustomTitle,
}: Omit<SelectDataPointProps, 'questionsMap'>) => {
  const [questionData, setQuestionData] = useState<QuestionData>(chartData.metrics?.[0] ?? emptyUtrData);
  const handleCreate = async () => {
    if (!isValidUtrVariable(questionData)) {
      return;
    }
    const { code = '' } = questionData;
    const goalCode = getExtendedGoalCode(code);
    if (!code || !goalCode) {
      return;
    }

    const variables = transformVariables([{ code }]);
    const title = isCustomTitle ? chartData.title : getSDGTitle({ goalCode });
    const icon = getSrc(undefined, goalCode);
    const { subType } = chartData;

    return handleSubmitChart({
      ...item,
      type: InsightDashboardItemType.SDGTracker,
      title,
      variables,
      subType,
      icon,
      // unset calculation as single utr chart must not have calculation otherwise it will render multi utrs chart instead
      calculation: undefined,
    });
  };

  const handleSelect = (utr: QuestionData) => {
    setQuestionData(utr);
    if (isValidUtrVariable(utr)) {
      changeChartData({ ...chartData, metrics: [utr] });
    }
  };

  return (
    <>
      <ModalBody>
        <p>
          <Button color='link' onClick={backToEntryPoint} className='p-0'>
            Chart selection
          </Button>{' '}
          / SDG Contribution
        </p>
        <SDGTargetDropdown questionData={questionData} changeQuestionData={handleSelect} />
      </ModalBody>
      <SelectDataPointFooter {...{ chartData, handleBack: backToEntryPoint, handleCreate, togglePreviewModal, item }} />
    </>
  );
};
