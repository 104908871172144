import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Loader from '../../../../../components/loader';
import { BasicAlert } from '../../../../../components/alert/BasicAlert';
import { GridItemProps } from '../../../GridItem';
import { HistoricalUtrsState, PreviewChartData } from './types';
import { MultiUtrsChartWidget } from '../multi-utrs-chart/MultiUtrsChartWidget';
import { GridDashboardChartItem, InsightDashboardItemType } from '../../../../../types/insight-custom-dashboard';
import { ChartWidget } from '../ChartWidget';
import { getChartCalculation } from './utils';
import { transformVariables } from '../../../utils';

interface PreviewModalProps extends Pick<GridItemProps, 'survey'> {
  initiativeId: string;
  isOpen: boolean;
  toggleOpen: () => void;
  chartData: PreviewChartData;
  historicalUtrsState: HistoricalUtrsState;
  isMultiple: boolean;
}

export const PreviewModal = ({
  initiativeId,
  isOpen,
  toggleOpen,
  chartData,
  survey,
  historicalUtrsState,
  isMultiple,
}: PreviewModalProps) => {
  const { historicalUtrs, isLoading, errorMessage } = historicalUtrsState;

  const isSDGTracker = chartData.type === InsightDashboardItemType.SDGTracker;
  const altUnitText = isSDGTracker ? 'contribution' : '';
  const utrs = historicalUtrs.map((utr) => utr.utr);

  const tempVariables = transformVariables(chartData.metrics);
  const tempItem: Pick<GridDashboardChartItem, 'subType' | 'variables' | 'calculation' | 'unitText' | 'note'> = {
    ...chartData,
    variables: tempVariables,
    calculation: getChartCalculation({ variables: tempVariables, subType: chartData.subType, utrs }),
  };

  if (isLoading) {
    return <Loader />;
  }

  if (!chartData.metrics.length) {
    return null;
  }

  return (
    <Modal fade={false} isOpen={isOpen} toggle={toggleOpen} backdrop='static' keyboard>
      <ModalHeader toggle={toggleOpen}>Preview chart</ModalHeader>
      <ModalBody style={{ height: '260px' }}>
        <BasicAlert type={'danger'} className='mt-2'>
          {errorMessage}
        </BasicAlert>
        {isMultiple ? (
          <MultiUtrsChartWidget
            altUnitText={altUnitText}
            initiativeId={initiativeId}
            item={tempItem}
            readOnly
            utrsData={historicalUtrs}
          />
        ) : (
          <ChartWidget
            utrData={historicalUtrs?.[0]}
            selectedColumnCode={chartData.metrics[0].valueListCode}
            initiativeId={initiativeId}
            type={chartData.type}
            subType={chartData.subType}
            readOnly
            survey={survey}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
