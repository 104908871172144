import { Button } from 'reactstrap';
import { useAppConfig } from '../../../../hooks/app/useAppSettings';
import { SubscriptionService } from '../../../../services/SubscriptionService';
import { useAppSelector } from '../../../../reducers';
import { formatDate } from '../../../../utils/date';
import { hasSubscriptionSetup } from '../../../../selectors/initiative';
import G17Client from '../../../../services/G17Client';
import { useLocation } from 'react-router-dom';
import { useGetInitiativeSponsorshipsQuery } from '../../../../api/initiative-sponsorships';
import { useSiteAlert } from '../../../../hooks/useSiteAlert';

export const SubscriptionDetails = () => {
  const location = useLocation();
  const appConfig = useAppConfig();
  const { addSiteError } = useSiteAlert();
  const { data, loaded } = useAppSelector((state) => state.globalData);
  const organization = loaded ? data.organization : undefined;
  const hasSubscriptions = useAppSelector(hasSubscriptionSetup);
  const { data: sponsorships } = useGetInitiativeSponsorshipsQuery(organization?._id ?? '', { skip: !organization?._id });

  const subsidisedBy =
    sponsorships && sponsorships.length > 0 ? `Subsidised by ${sponsorships.map(({ name }) => name).join(', ')}.` : '';

  if (!appConfig || !loaded) {
    return null;
  }

  const subscription = SubscriptionService.getBestValidSubscription(
    appConfig.productCode,
    organization?.calculatedSubscriptions
  );

  const canManageSubs = hasSubscriptions && SubscriptionService.canManageSubscriptions({ appConfig })

  const handleManageSubscription = () => {
    if (!organization) {
      return;
    }
    G17Client.createCustomerPortal({ initiativeId: organization._id, returnUrl: location.pathname })
      .then((s) => window.open(s.url, '_blank', ''))
      .catch((e) => addSiteError(e));
  };

  return (
    <div className='border border-2 rounded-1 border-ThemeAccentLight'>
      <div className='d-flex justify-content-between align-items-center p-3 background-ThemeBgMedium'>
        <h4 className='d-flex align-items-center m-0'>
          {appConfig.name}{' '}
          {subscription ? (
            <span className='h6 ml-2 my-0'>(renewal date {formatDate(new Date(subscription.periodEnd * 1000))})</span>
          ) : null}
        </h4>
        <i className='fa-solid fa-circle-check text-lg text-ThemeInfoDark' />
      </div>
      {subsidisedBy || canManageSubs ? (
        <div className='d-flex justify-content-between align-items-center p-3'>
          {subsidisedBy ? <div className='w-100 text-left text-md text-ThemeHeadingLight'>{subsidisedBy}</div> : null}
          {canManageSubs ? (
            <div className='w-100 text-right'>
              <Button type='button' color='link' className='text-sm' onClick={handleManageSubscription}>
                Manage subscription<i className='fa-light fa-arrow-up-right text-sm ml-2'></i>
              </Button>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
