import React from 'react'
import Chart from 'react-google-charts';
import { GoogleChartWrapper } from 'react-google-charts/dist/types';
import { DataSource } from '..';
import { CHART_DEFAULTS, GenerateChartsProps } from '../GenerateCharts';

const WIDTH = 600;
const HEIGHT = 400;

interface CountryDataSource {
  loaded: boolean;
  width: number;
  height: number;
  name: string;
  chart: string;
  mediumPay: number;
  highestPay: number;
  payRatio: number;
};

export interface PayGapDataSource extends DataSource {
  countries?: CountryDataSource[]
}

const PayGap = React.memo((props: GenerateChartsProps) => {
  const { questionData, updateData } = props;

  const [isUpdated, setUpdated] = React.useState(false);
  const [reportData, setReportData] = React.useState<PayGapDataSource>({
    loaded: true,
    width: WIDTH,
    height: HEIGHT,
    countries: []
  });

  const addReportData = (data: CountryDataSource) => {
    const d = { ...reportData };
    d.countries?.push(data);
    setReportData(d);
  }

  const utr = questionData.find(d => d.universalTracker.code === 'gri/2020/102-38');
  const data = utr?.valueData?.table ?? [];
  const reportDataCount = React.useMemo(() => reportData.countries?.length ?? undefined, [reportData]);
  const rowCount = data.length;

  React.useEffect(() => {
    const noData = reportDataCount === 0;
    const dataUpdated = rowCount === reportDataCount;
    const shouldUpdate = noData || dataUpdated;

    if (shouldUpdate && !isUpdated) {
      setUpdated(true);
      updateData('payGap', reportData);
    }
  }, [reportData, isUpdated, reportDataCount, rowCount, updateData]);

  return <>
    {data.map(row => {
      const country_value = row[0];
      const numerator_value = row[1];
      const denominator_value = row[2];
      const compensation_ratio = row[3];
      const chartData = [
        ['Role', 'Pay', { role: 'style' }],
        ['Medium of employees', Number(numerator_value.value), '#A6CFE5'],
        ['Highest paid', Number(denominator_value.value), '#D6F5DF'],
      ];
      return <Chart
        key={`paygap_${country_value.value}`}
        chartType='ColumnChart'
        data={chartData}
        width={WIDTH}
        height={HEIGHT}
        options={{
          ...CHART_DEFAULTS.OPTIONS,
          chartArea: {
            top: 40,
            bottom: 20,
            left: 100,
            right: 20,
          },
          legend: {
            position: 'none'
          },
          title: 'Employee compensation'
        }}
        chartEvents={[
          {
            eventName: 'ready',
            callback: (ChartRef: { chartWrapper: GoogleChartWrapper }) => {
              const name = String(country_value.value).toLocaleUpperCase()
              const existing = reportData.countries?.find(d => d.name === name);
              if (existing) {
                return;
              }
              addReportData({
                loaded: true,
                width: WIDTH,
                height: HEIGHT,
                name: name,
                chart: (ChartRef.chartWrapper.getChart().getImageURI() as unknown as string).split(',')[1],
                mediumPay: Number(numerator_value.value),
                highestPay: Number(denominator_value.value),
                payRatio: Number(compensation_ratio.value)
              })
            }
          }
        ]}
      />
    })}
  </>;
})

export default PayGap;
