import { ChangeEvent, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { FormGroup, Input, Label } from 'reactstrap';
import { InitiativeBenchmarking } from '../../../../types/initiative';
import { TIME_RANGE_OPTIONS } from '../../../../utils/date';
import IconButton from '../../../button/IconButton';
import { PeriodDropdown } from '../../../period-dropdown';
import { DateRangeType } from '../../../period-dropdown/DateRangePicker';
import { FilterSurveyPacks } from '../partials/FilterSurveyPacks';
import SimpleTooltip from '../../../simple-tooltip';
import { SelectOption } from '../../exchange-view/exchange-filters';
import {
  getAnswered,
  getEffectiveDate,
  getPercentNa,
  getPercentNr,
  getPercentPrivate,
  getProgress,
  getTotalQuestions,
  getUnanswered,
} from './utils';
import { useIndustrySector } from '../../../industry-sector-selector/useIndustrySector';
import { RequestScope } from '../../../../actions/api';
import { exportToExcel } from '../../../downloads/util/exportToExcel';
import { QUESTION } from '../../../../constants/terminology';

const rowHeaders = [
  'Company name',
  'Surveys',
  'Latest survey',
  `${QUESTION.CAPITALIZED_PLURAL} in scope`,
  'Unanswered',
  'Answered',
  '% Private',
  '% N/A',
  '% N/R',
];

const getStyles = (): StylesConfig<any> => {
  const ravenGrey = '#6C757D';
  return {
    container: (provided) => ({
      ...provided,
      flexGrow: 1,
      marginTop: '0 !important',
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 32,
      height: 32,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
    }),
    input: (provided) => ({
      ...provided,
      color: ravenGrey,
      width: 120,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: ravenGrey,
      padding: '4px 10px',
    }),
  };
};

export interface Filters {
  sector?: string;
  industry?: string | null;
  marketCap?: string;
  surveyPacks?: string[];
}

interface LatestSurveyFiltersProps {
  filteredCompanies: InitiativeBenchmarking[];
  filters: Filters;
  dateRange: DateRangeType;
  changeCheckedCompletedSurveys: (checked: boolean) => void;
  changeFilters: (filters: Filters) => void;
  changeDateRange: (dateRange: DateRangeType) => void;
}

const LatestSurveyFilters = (props: LatestSurveyFiltersProps) => {
  const { filteredCompanies, filters, dateRange, changeCheckedCompletedSurveys, changeFilters, changeDateRange } =
    props;
  const [period, setPeriod] = useState(TIME_RANGE_OPTIONS[0].code);
  const { sectorOptions, industryOptions } = useIndustrySector(filters.sector);

  const handleCheckboxCompletedSurveys = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;
    changeCheckedCompletedSurveys(checked);
  };

  const handleChangeFilters = (name: string, option: SelectOption | RequestScope[]) => {
    const newFilters = {
      ...filters,
      [name]: Array.isArray(option) ? option.map(option => option.code) : option.value,
    };
    changeFilters(newFilters);
  };

  const handleChangeSectorIndustryFilters = (name: string, option: SelectOption) => {
    let newFilters = { ...filters };
    if (name === 'sector') {
      // reset industry if sector change
      newFilters = { ...newFilters, industry: null };
    }
    newFilters = { ...newFilters, [name]: option.value ? option.label : null };

    changeFilters(newFilters);
  };

  const getRowsData = () => {
    const rowValues = filteredCompanies.map((company: InitiativeBenchmarking) => {
      return [
        company.name,
        getEffectiveDate(company).display,
        getProgress(company).display,
        getTotalQuestions(company),
        getUnanswered(company),
        getAnswered(company),
        getPercentPrivate(company).display,
        getPercentNa(company).display,
        getPercentNr(company).display,
      ];
    });
    return { rowHeaders, rowValues };
  };

  const downloadLatestSurveys = () => {
    const { rowHeaders, rowValues } = getRowsData();
    exportToExcel({ headers: rowHeaders, values: rowValues, fileName: 'benchmarking-latest-surveys' });
  };

  return (
    <div className='latest-survey-filters'>
      <div className='d-flex justify-content-between align-items-center gap-3 filters-wrapper'>
        <Select
          placeholder='Sector'
          styles={getStyles()}
          options={sectorOptions}
          value={sectorOptions.find((item) => item.label === filters.sector) ?? null}
          onChange={(option) => handleChangeSectorIndustryFilters('sector', option)}
        />
        <Select
          placeholder='Industry'
          styles={getStyles()}
          options={industryOptions}
          value={industryOptions.find((item) => item.label === filters.industry) ?? null}
          onChange={(option) => handleChangeSectorIndustryFilters('industry', option)}
          isDisabled={!filters.sector}
        />
        <div className='flex-grow-1'>
          <SimpleTooltip text='Filter currently unavailable'>
            <Select
              placeholder='Market cap'
              styles={getStyles()}
              options={[]}
              onChange={(option) => handleChangeFilters('marketCap', option)}
              isDisabled
            />
          </SimpleTooltip>
        </div>
        <div className='flex-grow-1'>
          <FilterSurveyPacks
            selectedScopes={filters.surveyPacks}
            handleSurveyPackFilter={(option: RequestScope[]) => handleChangeFilters('surveyPacks', option)}
          />
        </div>
      </div>
      <div className='d-flex justify-content-end align-items-center gap-3 mt-5'>
        <FormGroup check key='completedSurvey'>
          <Input
            type='checkbox'
            id='completedSurvey'
            name='completedSurvey'
            onChange={handleCheckboxCompletedSurveys}
          />
          <Label check for='completedSurvey'>
            Completed surveys
          </Label>
        </FormGroup>
        <PeriodDropdown
          period={period}
          onChangePeriod={(value) => setPeriod(value)}
          dateRange={dateRange}
          onChangeDateRange={(range) => changeDateRange(range)}
          timeRangeOptions={TIME_RANGE_OPTIONS}
        />
        <IconButton
          onClick={downloadLatestSurveys}
          outline={false}
          color='transparent'
          className='text-ThemeAccentDark'
          icon='fal fa-file-excel'
        />
      </div>
    </div>
  );
};

export default LatestSurveyFilters;
