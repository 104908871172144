import { MainDownloadCode } from '../config/app-config';
import { UniversalTrackerModalServiceUtrv } from '../reducers/universal-tracker-modal';
import { ApiResponse } from '../types/api';
import { DashboardSurveyType, InsightDashboard } from '../types/insight-custom-dashboard';
import { DataPeriods, UniversalTrackerPlain } from '../types/universalTracker';
import { g17ecoApi } from './g17ecoApi';

export type DashboardItemFilters = Partial<
  Pick<InsightDashboard['filters'], 'baselinesTargets' | 'privacy' | 'survey' | 'utrv'>
> & {
  period?: DataPeriods;
  mainDownloadCode?: MainDownloadCode;
  surveyType?: DashboardSurveyType;
};

export interface HistoricalUtrsQueryByCodeParams {
  initiativeId: string;
  utrCodes: string[];
  queryParams: DashboardItemFilters;
}

export interface HistoricalUtrs {
  utr: UniversalTrackerPlain;
  utrvs: UniversalTrackerModalServiceUtrv[];
}

const transformResponse = <T>(response: ApiResponse<T>) => response.data;

export const insightsApi = g17ecoApi
  .enhanceEndpoints({
    addTagTypes: ['insights'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getHistoricalUtrsByCodes: builder.query<HistoricalUtrs[], HistoricalUtrsQueryByCodeParams>({
        transformResponse,
        query: ({ initiativeId, utrCodes, queryParams }) => {
          return {
            url: `universal-trackers/utrs-history/initiativeId/${initiativeId}`,
            method: 'post',
            data: { utrCodes, filters: queryParams },
            params: { period: queryParams.period, surveyType: queryParams.surveyType },
          };
        },
        providesTags: ['insights'],
      }),
    }),
  });

export const { useGetHistoricalUtrsByCodesQuery, useLazyGetHistoricalUtrsByCodesQuery } = insightsApi;
