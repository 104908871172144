import { useMemo } from 'react';
import { Input } from 'reactstrap';
import { Toggle } from '../../../../components/common/Toggle';
import { MultipleSelect } from '../../../../components/multiple-select';
import { noTagOption } from '../../../../components/survey/utils/filters';
import { getFrameworksOptions, getStandardsOptions } from '../../../../constants/standards-frameworks';
import { useGetCustomTagsQuery } from '../../../../api/metric-groups';
import Loader from '../../../../components/loader';
import { BasicAlert } from '../../../../components/alert/BasicAlert';
import { ViewType } from './QuestionManagement';
import { QUESTION } from '../../../../constants/terminology';

export interface QuestionFilters {
  packs: string[];
  tags: string[];
}

export interface QuestionFilterProps {
  viewType: ViewType;
  toggleViewType: (viewType: ViewType) => void;
  showFilters?: boolean;
  filters: QuestionFilters;
  handleQuestionFilter: (props: { name: string; value: string[] | boolean }) => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rootInitiativeId: string;
}

export const QuestionFilter = ({
  showFilters = false,
  viewType,
  toggleViewType,
  filters,
  rootInitiativeId,
  handleQuestionFilter,
  onSearch,
}: QuestionFilterProps) => {
  const { data: tags = [], error, isFetching } = useGetCustomTagsQuery(rootInitiativeId, { skip: !rootInitiativeId });

  const showInScopeOnly = viewType === ViewType.InScope;

  const standardsOptions = getStandardsOptions();
  const frameworksOptions = getFrameworksOptions();
  const tagOptions = useMemo(() => {
    const options = [noTagOption];
    tags.forEach((tag) => {
      options.push({ value: tag._id, searchString: tag.groupName, label: tag.groupName });
    });
    return options;
  }, [tags]);

  return (
    <div className='d-flex align-items-center mb-3'>
      {isFetching ? <Loader /> : null}
      {error ? <BasicAlert type='danger'>{error.message}</BasicAlert> : null}
      {showFilters ? (
        <div className='d-flex align-items-center gap-2'>
          <Input
            type='text'
            placeholder={`Search for ${QUESTION.SINGULAR}`}
            className='border-ThemeBorderDefault'
            onChange={onSearch}
            style={{ width: '300px' }}
          />
          <div className='col-4'>
            <MultipleSelect
              placeholder='Select survey pack'
              options={[...standardsOptions, ...frameworksOptions]}
              onChange={(options: string[]) => handleQuestionFilter({ name: 'packs', value: options })}
              values={filters.packs}
            />
          </div>
          <div className='col-3'>
            <MultipleSelect
              placeholder='Select tag'
              options={tagOptions}
              onChange={(options: string[]) => handleQuestionFilter({ name: 'tags', value: options })}
              values={filters.tags}
            />
          </div>
        </div>
      ) : null}
      <Toggle
        className={{ form: 'd-flex align-items-center ml-auto', label: showFilters ? 'ml-1' : undefined }}
        checked={viewType === ViewType.InScope}
        onChange={() => toggleViewType(showInScopeOnly ? ViewType.Blueprint : ViewType.InScope)}
        label='Show only in scope'
      />
    </div>
  );
};
