import { getGroup } from '@g17eco/core';
import Select, { createFilter } from 'react-select';
import { isGroupEnabled } from '../../../constants/groups';
import { PackOption } from '../../../constants/standards-frameworks';
import { useAppSelector } from '../../../reducers';
import { SelectOption } from '../../../types/select';

const filterOption = createFilter<SelectOption>({
  stringify: ({ data }) => `${data.searchString ?? data.label}`,
});

interface PackSelectProps {
  pack: string | undefined;
  packOptions: PackOption[];
  handleSelectPack: (pack: string) => void;
}

export const PackSelect = (props: PackSelectProps) => {
  const { pack, packOptions, handleSelectPack } = props;
  const scopeConfig = useAppSelector((state) =>
    state.globalData.loaded ? state.globalData.data?.config?.survey.scope : undefined
  );

  const isDisabled = (code: string) => {
    const group = getGroup('standards-and-frameworks', code);
    return !isGroupEnabled(group, scopeConfig);
  };

  return (
    <Select<SelectOption>
      placeholder='Select a standard or framework'
      options={packOptions}
      value={packOptions.find((v) => v.value === pack) ?? null}
      onChange={(option) => handleSelectPack(option?.value || '')}
      filterOption={filterOption}
      isClearable
      isOptionDisabled={(option) => !option.value || isDisabled(option.value)}
    />
  );
};
