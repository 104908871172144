import { createApi } from '@reduxjs/toolkit/query/react'
import { CalculatorInfo } from './types';
import { ApiResponse } from '../../types/api';
import { axiosBaseQuery } from '../../api/axiosQuery';

const transformResponse = <T>(response: ApiResponse<T>) => response.data ?? [];

export const carbonCalculatorsApi = createApi({
  reducerPath: 'carbonCalculatorsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['carbon-calculators'],
  endpoints: (builder) => ({
    getCarbonCalculatorsList: builder.query<CalculatorInfo[], void>({
      transformResponse,
      query: () => ({
        url: '/o/carbon-calculators',
        method: 'get',
      })
    })
  }),
});

export const { useGetCarbonCalculatorsListQuery } = carbonCalculatorsApi;
